import { appStore } from '@kaltura-ott/tvpil-shared';

import { NETWORK_ERROR } from 'consts';

import { isAuthError } from './checkErrorActionName';
import { getLoginErrorTranslationId } from './getLoginErrorTranslationId';

type ErrorParamsProps = {
  keyForTranslation: string;
  message: string;
  onRetry(): void;
  title: string;
  actionName?: string;
  code?: number | string;
  onClear(): void;
  stopLoader(): void;
  isErrorFromTvpil?: boolean;
};

export function getErrorParams({
  keyForTranslation,
  message,
  onRetry,
  title,
  actionName,
  code,
  onClear,
  stopLoader,
  isErrorFromTvpil,
}: ErrorParamsProps) {
  const isNetworkError = code === NETWORK_ERROR;
  const isAuthErr = isAuthError(actionName) && !isNetworkError;
  let icon = isNetworkError ? 'noConnection' : '';
  const errorType = isNetworkError ? 'networkError' : 'serverError';
  let titleId = `${keyForTranslation}.title.${errorType}`;
  let titleDefault = isNetworkError ? 'No Connection' : 'Server Error';
  let messageId = `${keyForTranslation}.description.${errorType}`;
  let messageDefault = isNetworkError
    ? 'Please check your internet connection and try again'
    : 'Something went wrong, please try again in few minutes';

  if (isAuthErr) {
    const { translationKey, translationId } = getLoginErrorTranslationId(actionName, code);
    icon = '';
    titleId = `${translationKey}.title`;
    titleDefault = title;
    messageId = translationId;
    messageDefault = message;
  }

  const resolveOnRetry = async () => {
    await onRetry();

    stopLoader();

    if (isNetworkError) {
      appStore.restoreAfterConnectionError();
    }
  };

  const resolveOnClear = () => {
    if (isNetworkError) {
      appStore.restoreAfterConnectionError();
    }

    onClear();
  };

  return {
    error: { titleId, titleDefault, messageId, messageDefault, code, icon },
    retryButton: {
      onRetry: resolveOnRetry,
      translationId: `${keyForTranslation}.retry`,
      defaultMessage: 'Try again',
    },
    goBackButton: isAuthErr
      ? {
          defaultMessage: 'Go to Sign in',
          translationId: `${keyForTranslation}.goBackToLogin`,
        }
      : undefined,
    onClear: resolveOnClear,
    isErrorFromTvpil,
  };
}
