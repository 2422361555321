import { action, observable } from 'mobx';

export const DEFAULT_START_OVER_PROPS = {
  isStartOver: false,
  isSetOutsidePlayer: false,
  startTime: 0,
};

class StartOverInfoStorage {
  @observable startOverInfo = { ...DEFAULT_START_OVER_PROPS };

  @action
  getStartOver() {
    return this.startOverInfo;
  }

  @action
  setStartOver(isStartOver: boolean, isSetOutsidePlayer: boolean = false, startTime = 0) {
    this.startOverInfo = {
      isStartOver,
      isSetOutsidePlayer,
      startTime,
    };
  }

  @action
  resetStartOverInfo() {
    this.startOverInfo = { ...DEFAULT_START_OVER_PROPS };
  }
}

const startOverInfoStorage = new StartOverInfoStorage();

export { startOverInfoStorage };
