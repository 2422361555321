import { useEffect } from 'react';
import { useNavigationType } from 'react-router-dom';

interface Props {
  setShowSearchInput: (value: boolean) => void;
  setIsShowHistory: (value: boolean) => void;
  isSearchPage: boolean;
  isSearchPageWithDetailsInfo: boolean;
  isSearchPageWithDetailsSeries: boolean;
  searchParam: string;
  state?: { isInputOpen?: boolean };
}

export const useOpenSearchInputAndHistory = ({
  setShowSearchInput,
  setIsShowHistory,
  isSearchPage,
  isSearchPageWithDetailsInfo,
  isSearchPageWithDetailsSeries,
  searchParam,
  state,
}: Props) => {
  const navType = useNavigationType();

  useEffect(() => {
    if ((isSearchPage && searchParam.length !== 0) || isSearchPageWithDetailsInfo || isSearchPageWithDetailsSeries) {
      setShowSearchInput(true);
    } else if (!isSearchPage && navType !== 'POP' && state?.isInputOpen) {
      setShowSearchInput(true);
      setIsShowHistory(true);
    }
  }, []);
};
