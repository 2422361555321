import React from 'react';
import { useIntl } from 'react-intl';

import { PinCodeModalTypes } from '../../PinCodeModalTypesEnum';

export const useGetPinModalMessage = (
  type: PinCodeModalTypes,
  title?: string | undefined,
  description?: string | React.ReactNode,
) => {
  const intl = useIntl();

  let text = description;
  let modalTitle = title;

  if (!title) {
    modalTitle = intl.formatMessage({
      id: 'PinCodeModal.title',
      defaultMessage: 'Enter PIN Code',
    });
  }

  switch (type) {
    case PinCodeModalTypes.Purchase:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.purchaseTextConfirm',
          defaultMessage: 'Enter PIN code to confirm purchase:',
        });
      }
      break;

    case PinCodeModalTypes.UpdateParental:
      text = intl.formatMessage({
        id: 'PinCodeModal.parentalTextNew',
        defaultMessage: 'Enter new Parental PIN',
      });
      modalTitle = intl.formatMessage({
        id: 'PinCodeModal.parentalTitle',
        defaultMessage: 'Change Parental PIN',
      });
      break;

    case PinCodeModalTypes.UpdatePurchase:
      text = intl.formatMessage({
        id: 'PinCodeModal.purchaseTextNew',
        defaultMessage: 'Enter new Purchase PIN',
      });
      modalTitle = intl.formatMessage({
        id: 'PinCodeModal.purchaseTitleChange',
        defaultMessage: 'Change Purchase PIN',
      });
      break;

    case PinCodeModalTypes.ConfirmPurchase:
      text = intl.formatMessage(
        {
          id: 'PinCodeModal.purchaseTextNewConfirm',
          defaultMessage: 'Enter new purchase again',
        },
        {
          strong: (value: React.ReactNode) => <b>{value}</b>,
        },
      );
      modalTitle = intl.formatMessage({
        id: 'PinCodeModal.purchaseTitleChange',
        defaultMessage: 'Change Purchase PIN',
      });
      break;

    case PinCodeModalTypes.ConfirmParental:
      text = intl.formatMessage(
        {
          id: 'PinCodeModal.parentalTextNewConfirm',
          defaultMessage: 'Enter new Parental PIN again',
        },
        {
          strong: (value: React.ReactNode) => <b>{value}</b>,
        },
      );
      modalTitle = intl.formatMessage({
        id: 'PinCodeModal.parentalTitle',
        defaultMessage: 'Change Parental PIN',
      });
      break;

    case PinCodeModalTypes.RestrictedContent:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.unlockText',
          defaultMessage: 'Enter PIN code to unlock:',
        });
      }
      modalTitle = intl.formatMessage({
        id: 'Modals.PinCode.enterPinToViewContent',
        defaultMessage: 'Enter PIN Code To View This Content',
      });
      break;

    case PinCodeModalTypes.EnterParental:
      text = intl.formatMessage({
        id: 'PinCodeModal.parentalTextEdit',
        defaultMessage: 'To edit your parental control settings you must enter PIN code',
      });
      break;

    default:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.title',
          defaultMessage: 'Enter PIN Code',
        });
      }
      break;
  }

  return { pinText: text, modalTitle };
};
