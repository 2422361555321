import { useCallback, useRef } from 'react';

import { useEpgSettings } from '@kaltura-ott/tvpil-shared';

import { useEpgHooksCombinerOutputProps } from '../../interface';
import { calculateEpgParams } from '../../utils';
import { useEventsListener } from '../useEventsListener/useEventsListener';
import { useHandleRef } from '../useHandleRef/useHandleRef';
import { useInitializationHook } from '../useInitializationHook/useInitializationHook';
import { useUpdateTimestamp } from '../useUpdateTimestamp/useUpdateTimestamp';

function useEpgHooksCombiner(referenceId: string): useEpgHooksCombinerOutputProps {
  const forwardGridMovement = useRef<HTMLDivElement | null>(null);
  const firstVisibleIndex = useRef<number | null>(null);
  const gridStartYPosition = useRef<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const nowLineRef = useRef<HTMLDivElement | null>(null);
  const refTime = useRef<HTMLDivElement | null>(null);
  const userActive = useRef(false); // is epg touched by user
  const setFirstVisibleIndex = useCallback((id: number) => {
    firstVisibleIndex.current = id;
  }, []);

  useInitializationHook();

  const settings = useEpgSettings({ referenceId });
  // Define backward and forward limits for the EPG
  const maxEpgDaysBackward = settings?.maxEpgDaysBackward ?? 7;
  const maxEpgDaysForward = settings?.maxEpgDaysForward ?? 7;
  // Handle references and calculate parameters for EPG display
  const handleRef = useHandleRef(settings?.branding);
  const epgParams = calculateEpgParams(maxEpgDaysBackward, maxEpgDaysForward);

  useUpdateTimestamp({ userActive, ref });

  const { setUserActive } = useEventsListener({
    ref,
    refTime,
    userActive,
    epgParams,
  });

  return {
    setUserActive,
    setFirstVisibleIndex,
    handleRef,
    nowLineRef,
    epgParams,
    gridStartYPosition,
    userActive,
    refTime,
    ref,
    forwardGridMovement,
    settings,
  };
}

export { useEpgHooksCombiner };
