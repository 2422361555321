import { useEffect } from 'react';

import { useAssetCardContext } from '../../../AssetCardContext/AssetCardContext';

export const useSetIndexToListStore = () => {
  const { listStore, listIndex } = useAssetCardContext();

  useEffect(() => {
    listStore.setVisibleIndex(listIndex);

    return () => {
      listStore.setInvisibleIndex(listIndex);
    };
  }, [listIndex, listStore]);
};
