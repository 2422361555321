import { useEffect } from 'react';

import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import routes from 'routesPaths';

export function useScrollbar() {
  const {
    location: { pathname },
  } = useStableReactRouter();
  useEffect(() => {
    const { innerHeight } = window;
    const { scrollHeight } = document.body;

    if (scrollHeight > innerHeight && pathname.includes(routes.settings.path)) {
      document.body.style.overflowY = 'scroll';
    }

    return () => {
      document.body.style.overflowY = '';
    };
    // eslint-disable-next-line
  }, []);
}
