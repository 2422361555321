import React from 'react';

import { useCardProgramChannelInfoCombiner } from './hooks/useCardProgramChannelInfoCombiner/useCardProgramChannelInfoCombiner';

export const CardProgramChannelInfo = () => {
  const { channelName } = useCardProgramChannelInfoCombiner();

  if (channelName) {
    return <span>{channelName}</span>;
  }

  return null;
};
