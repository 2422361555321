import { useEffect } from 'react';

import { AppApiError } from '@kaltura-ott/tvpil-shared';

import { Form, useAppStore } from 'hooks';
import { isLoginFormError } from 'utils';

interface Props {
  loginForm: Form;
  setLoginForm(form: Form): void;
  setLoginFormError(err: AppApiError): void;
}

export function useLoginFormErrors({ loginForm, setLoginForm, setLoginFormError }: Props) {
  const {
    appStore: { apiError },
  } = useAppStore();

  useEffect(() => {
    if (!apiError) return;

    const err = apiError[Object.keys(apiError)[0]];
    const { code, onCancel, actionName } = err;

    if (!isLoginFormError(actionName)) return;

    setLoginFormError(err);

    const isWrongCredentials = !!code && (+code === 401 || +code === 1011);

    // for wrong credentials error need to highlight inputs and clean password field
    if (isWrongCredentials) {
      const updatedForm = {
        email: {
          ...loginForm.email,
          isFailedValidation: true,
          errorMessage: '',
        },
        password: {
          value: '',
          isFailedValidation: true,
          errorMessage: '',
        },
      };
      setLoginForm(updatedForm);
    }

    // to clear apiError
    onCancel();
  }, [apiError]);
}
