import React from 'react';
import cn from 'classnames';

import styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
  percent: number;
  className?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, className }) => {
  return (
    <div data-type='progressBar' className={cn(styles.progressBar, className)}>
      <div
        style={{
          width: `${Math.min(percent, 100)}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
