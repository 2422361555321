import React from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useModalContext } from 'context/modal/modal';
import { ActionButtonTypesEnum } from 'interface';

import styles from '../../DefaultModal.module.scss';

interface DefaultModalActionButtonProps {
  onAccept?: (event?: any) => void;
  acceptButtonText?: string | undefined;
  acceptButtonType?: ActionButtonTypesEnum | undefined;
  loading?: boolean;
}

export const DefaultModalActionButton = ({
  onAccept,
  acceptButtonText,
  acceptButtonType,
  loading,
}: DefaultModalActionButtonProps) => {
  const intl = useIntl();
  const modalContext = useModalContext();

  return onAccept ? (
    <ActionButton
      className={styles.actionButton}
      disabled={loading}
      onClick={() => {
        modalContext.closeModal();
        onAccept();
      }}
      submit
      type={acceptButtonType ? ActionButtonTypesEnum[acceptButtonType] : ActionButtonTypesEnum.danger}
    >
      {acceptButtonText || intl.formatMessage({ id: 'DefaultModal.buttonConfirm', defaultMessage: 'Confirm' })}
    </ActionButton>
  ) : null;
};
