import { NEXT_BUTTON_WIDTH } from 'components/widgets/Rail/constants';
import { HALF_DIVIDER } from 'consts';

export const HEIGHT_GROWTH = 32;
export const PADDING = 5;

interface ZoomParams {
  bounding: DOMRect;
  height: number;
  width: number;
  nextButtons: any;
}

export function reCalculateCardZoom({ bounding, nextButtons, height, width }: ZoomParams) {
  const { isLeftNextButton, isRightNextButton } = nextButtons.current;
  const { clientWidth } = document.body;
  const nextLeftButtonWidth = isLeftNextButton ? NEXT_BUTTON_WIDTH : 0;
  const ratio = width / height;
  const rightNextButtonWidth = isRightNextButton ? NEXT_BUTTON_WIDTH : 0;
  const widthGrowth = (height + HEIGHT_GROWTH) * ratio - width;

  // calculate default zoom
  let zoomToLeft = widthGrowth / HALF_DIVIDER;
  let zoomToRight = widthGrowth / HALF_DIVIDER;

  // change default zoom if after hover part of element will be out of screen
  const leftOverFlow = zoomToLeft + PADDING + nextLeftButtonWidth - bounding.left;
  const rightOverFlow = bounding.right + zoomToRight + PADDING + rightNextButtonWidth - clientWidth;

  if (leftOverFlow > 0) {
    zoomToLeft = leftOverFlow - zoomToLeft;
    zoomToRight = (leftOverFlow + zoomToRight) * -1;
  } else if (rightOverFlow > 0) {
    zoomToLeft = (rightOverFlow + zoomToLeft) * -1;
    zoomToRight = rightOverFlow - zoomToRight;
  } else {
    zoomToLeft = (widthGrowth / HALF_DIVIDER) * -1;
    zoomToRight = zoomToLeft;
  }

  return { zoomToLeft, zoomToRight };
}
