import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { MediaType } from '@kaltura-ott/tvpil';

import episodesIcon from 'assets/images/episodes.svg';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { SERIES_ID } from 'consts';
import routes from 'routesPaths';

export interface EpisodesButtonProps {
  className: string;
  mediaType: MediaType;
  seriesId: string;
}

export function EpisodesButton({ className, mediaType, seriesId }: EpisodesButtonProps) {
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  function resolvePathToSeriesPage() {
    let url = pathname.replace(routes.details.pathDetailsInfo.path, routes.details.pathDetailsSeries.path);

    if (mediaType === MediaType.Episode) {
      url = url.replace(MediaType.Episode.toLowerCase(), MediaType.Series.toLowerCase());
      params.append(SERIES_ID, seriesId);
    }

    return {
      to: {
        pathname: url,
        search: params.toString(),
      },
      state: {
        goBackUrl: `${pathname}${search}`,
      },
    };
  }

  return (
    <ActionButton
      className={className}
      icon={<SVG data-target='episodesIcon' src={episodesIcon} />}
      isLarge
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...resolvePathToSeriesPage()}
    >
      <FormattedMessage defaultMessage='Episodes' id={`${COMPONENT_NAME}.VideoContent.buttonEpisodes`} />
    </ActionButton>
  );
}
