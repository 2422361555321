import { Dispatch, SetStateAction, useCallback } from 'react';

import { AppApiError } from '@kaltura-ott/tvpil-shared';

interface Props {
  setError: Dispatch<SetStateAction<AppApiError | null>>;
  setIsPending: Dispatch<SetStateAction<boolean>>;
}

export const useOnHandleError = ({ setError, setIsPending }: Props) => {
  return useCallback((err: AppApiError) => {
    setError(err);
    setIsPending(false);
  }, []);
};
