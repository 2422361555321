import React from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { Episode, Program } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import AirTime from 'components/presenters/AirTime/AirTime';
import airTimeStyles from 'components/presenters/AirTime/AirTime.module.scss';
import { ExpiresBadge, PurchaseIcon } from 'components/presenters/icons';
import Img from 'components/presenters/Img/Img';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';
import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';
import { LOCK_ICON } from 'consts';
import { useAppStore, useIsNpvr } from 'hooks';
import { getDefaultImagePath, getLockIcon } from 'utils';

import styles from './EpisodeCard.module.scss';

export type Props = {
  episode: Program | Episode;
  presentationRatio?: AssetPresentationRatioEnum;
  isPlayable?: boolean;
  isContentRestricted?: boolean;
};

function EpisodeCard({ episode, presentationRatio, isPlayable, isContentRestricted = false }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const isNpvr = useIsNpvr();
  const { startDate, isPurchasable, bookmark: programBookmark, recordBookmark } = detailsStoreDataResolver(episode);
  const bookmark = isNpvr ? recordBookmark : programBookmark;
  const { catchUpAirDate, recordingExpirationInDays, record } = episode as Program;
  const isRecordExpires = isNpvr && record && Number.isInteger(recordingExpirationInDays!);
  const isFutureProgram = startDate && startDate > Date.now();
  const isOnGoingRecord = record?.data.isOngoing;
  const isLandscapeRatio =
    presentationRatio === AssetPresentationRatioEnum['16x9'] || presentationRatio === AssetPresentationRatioEnum['5x3'];
  const isBookmarked = bookmark?.position !== undefined;
  const isShowAirTime = isFutureProgram && catchUpAirDate && !isContentRestricted;
  const defaultImage = `${staticAssetsUrl}${getDefaultImagePath(isLandscapeRatio)}`;
  const additionalParamForImage = `/height/${isLandscapeRatio ? 123 : 154}/width/${isLandscapeRatio ? 228 : 113}`;

  function renderProgressBar() {
    if (isBookmarked) {
      const finishedPercent = bookmark?.finishedWatching ? 100 : (bookmark!.position / bookmark!.duration) * 100;
      return <ProgressBar className={styles.progressBar} percent={finishedPercent} />;
    }

    return null;
  }

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.landscape]: isLandscapeRatio,
        [styles.restricted]: isContentRestricted,
      })}
    >
      <Img
        alt={episode.data.title}
        additionalParam={additionalParamForImage}
        srcList={[(presentationRatio && episode.data.images[presentationRatio]?.url) || '', defaultImage]}
      />
      {(isShowAirTime || isOnGoingRecord) && (
        <div className={cn(airTimeStyles.futureProgram, styles.airTime)}>
          <AirTime catchUpAirDate={catchUpAirDate} showNowLabel={isOnGoingRecord} />
        </div>
      )}
      {isPurchasable && <PurchaseIcon isBadge />}
      {isRecordExpires && (
        <ExpiresBadge
          className={styles.expires}
          translationId='Rail.RailCard'
          expiredInDays={recordingExpirationInDays!}
        />
      )}
      <div
        className={cn(styles.content, {
          [styles.bookmarked]: isBookmarked,
        })}
      >
        {isPlayable && !isContentRestricted && !isOnGoingRecord && (
          <>
            <PlayButton className={styles.play} />
            {renderProgressBar()}
          </>
        )}
        {isContentRestricted && <SVG src={`${staticAssetsUrl}${getLockIcon(LOCK_ICON)}`} />}
      </div>
    </div>
  );
}

export default observer(EpisodeCard);
