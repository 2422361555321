import React, { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Node } from 'types';

import { SubpageStore } from '@kaltura-ott/tvpil-shared';

import MainLayout from 'components/presenters/layouts/main/MainLayout';
import { useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { applyStyles } from 'utils';

import SettingsMenu from './SettingsMenu/SettingsMenu';
import { useParentalControlRedirect } from './hooks';

import styles from './Settings.module.scss';

function Settings() {
  const { settingsStore } = useSettingsStore();
  const location = useLocation();

  useParentalControlRedirect();

  useEffect(() => {
    settingsStore.init();
  }, [settingsStore]);

  const handleRef = useCallback((node: Node) => {
    const { uiDesignElements } = settingsStore;

    if (uiDesignElements && node !== null) {
      applyStyles(node, uiDesignElements, false, true);
    }
  }, []); // eslint-disable-line

  if (location.pathname === routes.settings.path) {
    return <Navigate to={routes.settings.link((settingsStore.children[0] as SubpageStore)?.link)} replace />;
  }

  return (
    <MainLayout className={styles.layout}>
      <div ref={handleRef} className={styles.profile}>
        <SettingsMenu settingsStore={settingsStore} />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </MainLayout>
  );
}

export default observer(Settings);
