import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore } from '@kaltura-ott/tvpil-shared';

function useServicesStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  return { servicesStore: appStore.servicesStore };
}

export { useServicesStore };
