import { EpgParamsType } from 'types';

import { config } from '../../config';
import { epgLocalTimeStampStorage } from '../../storages';
import { formatPixelsToTimestamp } from '../../utils';

export const moveAlongX = (step: number, epgParams: EpgParamsType) => {
  const { epgStartTimestamp, epgEndTimestamp, ratio } = epgParams;
  const screenTimestamp = formatPixelsToTimestamp(ratio, document.body.clientWidth);
  const channelWidth = formatPixelsToTimestamp(ratio, config.channelWidth);
  const minPositionX = epgStartTimestamp - channelWidth;
  const maxPositionX = epgEndTimestamp - screenTimestamp;

  const prevState = epgLocalTimeStampStorage.viewTimestamp;
  const newPositionX = prevState + step;
  let newValue = newPositionX;

  if (newPositionX > maxPositionX) {
    newValue = maxPositionX;
  } else if (newPositionX < minPositionX) {
    newValue = minPositionX;
  }

  epgLocalTimeStampStorage.setViewTimestamp(newValue);
};
