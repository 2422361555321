import { useCallback, useEffect, useRef, useState } from 'react';
import { addMinutes } from 'date-fns';
import { reaction } from 'mobx';

import { Linear, Program, ProgramPlaceholder } from '@kaltura-ott/tvpil-shared';

import { config } from 'components/pages/Epg/config';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { useEpgContext } from 'context/epgContext/epgContext';
import { useEpgStore } from 'hooks';

function useProgramsListCombiner(channel: Linear, index: number) {
  const { epgStore } = useEpgStore();
  const [filteredArray, setFilteredArray] = useState<(Program | ProgramPlaceholder)[]>([]);
  const { tvChannels } = epgStore;
  const firstVisible = useRef(true);

  const { setFirstVisibleIndex } = useEpgContext();

  const setArray = useCallback(() => {
    const { programs } = channel;
    setFilteredArray(
      programs.filter(
        (program: Program | ProgramPlaceholder) =>
          program.data.endDate >= epgLocalTimeStampStorage.viewTimestamp &&
          program.data.startDate <=
            addMinutes(epgLocalTimeStampStorage.viewTimestamp, epgLocalTimeStampStorage.screenWidthInMinutes).valueOf(),
      ),
    );
  }, []);

  useEffect(() => {
    epgStore.setVisibleTvChannel(channel, index);

    if (firstVisible.current) {
      setFirstVisibleIndex(index);
      firstVisible.current = false;
    }

    if (index === tvChannels.length - 1) {
      firstVisible.current = true;
    }

    const disposer = reaction(
      () => epgLocalTimeStampStorage.viewTimestamp,
      () => setArray(),
      {
        delay: config.delayForHorisontalFiltering,
      },
    );

    return () => {
      epgStore.setInvisibleTvChannel(channel);
      disposer();
    };
  }, []);

  useEffect(() => {
    setArray();
  }, [channel.programs]);

  return { filteredArray };
}

export { useProgramsListCombiner };
