import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useRailContext } from '../../RailHorisontal/components/RailProvider/RailProvider';

interface ContextType {
  store: RailStoreV2;
  offset: any;
  totalWidth: number;
  isLogoRail: boolean;
  forwardHeaderRef: any;
  forwardWrapperRef: any;
  forwardElementInnerRef: any;
  forwardRailCardsListRef: any;
  forwardRefElement: any;
  handleClickNavigationButton: (increase: boolean) => void;
  onCardClick: (linearId?: number) => void;
  checkResize: () => void;
  onCardHover: (isHover: boolean, value?: number) => void;
  componentHeight: number;
  nextButtons: any;
  setNextButtons: (value: any) => void;
}

interface Props {
  children: React.ReactNode;
  totalWidth: number;
  offset: any;
  isLogoRail: boolean;
  forwardHeaderRef: any;
  forwardWrapperRef: any;
  forwardElementInnerRef: any;
  forwardRailCardsListRef: any;
  handleClickNavigationButton: any;
  onCardClick: (linearId?: number) => void;
  checkResize: () => void;
  onCardHover: (isHover: boolean, value?: number) => void;
  componentHeight: number;
}

const RailHorisontalDataContext = createContext<ContextType>({
  isLogoRail: false,
  totalWidth: 0,
  offset: 0,
  forwardHeaderRef: null,
  forwardWrapperRef: () => {},
  forwardElementInnerRef: () => {},
  forwardRailCardsListRef: () => {},
  forwardRefElement: () => {},
  handleClickNavigationButton: () => {},
  onCardClick: () => {},
  onCardHover: () => {},
  checkResize: () => {},
  componentHeight: 0,
  store: {} as RailStoreV2,
  nextButtons: {},
  setNextButtons: () => {},
});

const RailHorisontalDataProvider = ({
  children,
  onCardClick,
  onCardHover,
  checkResize,
  isLogoRail,
  handleClickNavigationButton,
  forwardHeaderRef,
  forwardElementInnerRef,
  forwardWrapperRef,
  forwardRailCardsListRef,
  componentHeight,
  totalWidth,
  offset,
}: Props) => {
  const { refElement, store } = useRailContext();
  const nextButtons = useRef({
    isLeftNextButton: offset > 0,
    isRightNextButton:
      offset + (forwardElementInnerRef?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });
  const setNextButtons = useCallback((value: any) => {
    nextButtons.current = value;
  }, []);

  const contextValue = useMemo(() => {
    return {
      isLogoRail,
      forwardHeaderRef,
      forwardWrapperRef,
      forwardRefElement: refElement,
      forwardElementInnerRef,
      forwardRailCardsListRef,
      handleClickNavigationButton,
      onCardClick,
      onCardHover,
      checkResize,
      componentHeight,
      store,
      totalWidth,
      offset,
      nextButtons,
      setNextButtons,
    };
  }, [
    isLogoRail,
    onCardClick,
    onCardHover,
    checkResize,
    forwardElementInnerRef,
    forwardHeaderRef,
    forwardWrapperRef,
    refElement,
    forwardRailCardsListRef,
    handleClickNavigationButton,
    componentHeight,
    store,
    totalWidth,
    offset,
    nextButtons,
    setNextButtons,
  ]);
  return <RailHorisontalDataContext.Provider value={contextValue}>{children}</RailHorisontalDataContext.Provider>;
};

const useRailHorisontalDataContext = () => useContext(RailHorisontalDataContext);

export { RailHorisontalDataProvider, useRailHorisontalDataContext };
