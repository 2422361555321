import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import { LinkAsAsset, MenuStore } from '@kaltura-ott/tvpil-shared';

export function findMenuItems(menuStore: MenuStore, id?: string) {
  let itemById;

  const items = menuStore?.items?.filter(
    (item: LinkAsAsset) =>
      item.data.linkItem?.typeOfAppLink === TypeOfApplicationLink.page ||
      item.data.linkItem?.typeOfAppLink === TypeOfApplicationLink.grid,
  );

  if (id) {
    itemById = items.find((item: LinkAsAsset) => {
      const { linkItem } = item.data;
      const itemId =
        linkItem?.typeOfAppLink === TypeOfApplicationLink.page ? linkItem?.inAppLink : linkItem?.attachedCollectionId;
      return id === String(itemId);
    });
  }

  return { items, itemById };
}
