import { getCookie } from '@kaltura-ott/tvpil-shared';

import { SILENT_LOGIN_COOKIE } from 'consts';

function getSilentLoginParams() {
  const silentLoginCookie = getCookie(SILENT_LOGIN_COOKIE);

  let silentLoginCookieResult;

  if (silentLoginCookie) {
    silentLoginCookieResult = {
      silentLoginParams: {
        WEBSLID: silentLoginCookie,
      },
    };
  }
  return silentLoginCookieResult;
}

export { getSilentLoginParams };
