import { useCallback } from 'react';

export const useHandleClick = (onChangePin: (type: string, isConfirmErrorMessage?: boolean | undefined) => void) => {
  const handleClick = useCallback((type: string) => {
    return () => {
      onChangePin(type);
    };
  }, []);

  return { handleClick };
};
