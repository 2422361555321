import { appStore } from '@kaltura-ott/tvpil-shared';

import { ERROR_ACTION_NAMES } from 'consts';

export function getLoginErrorTranslationId(actionName?: string, code?: string | number) {
  let translationKey = '';

  if (
    actionName === ERROR_ACTION_NAMES.LOGIN_AUTH ||
    actionName === ERROR_ACTION_NAMES.LOGIN_OTT ||
    actionName === ERROR_ACTION_NAMES.LOGIN_JWT
  ) {
    translationKey = 'Errors.login';
  } else if (actionName === ERROR_ACTION_NAMES.LOGIN_SSO) {
    translationKey = 'Errors.login.loginForm';
  }

  const translationKeyWithCode = `${translationKey}.${code}`;
  const isTranslationKeyExists = !!appStore.localizationStore.translation[translationKeyWithCode];
  const translationId = isTranslationKeyExists ? translationKeyWithCode : `${translationKey}.default`;

  return { translationKey, translationId };
}
