import { useCardContext } from 'context/сardContext/сardContext';

function useCardProgramChannelInfoCombiner() {
  const {
    resolvedCardProps: { channelInfo },
  } = useCardContext();
  const channelName = channelInfo?.data.title;

  return { channelName };
}

export { useCardProgramChannelInfoCombiner };
