import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';

import { COMPONENT_NAME } from '../../../constants';

import styles from '../../AdditionPlans.module.scss';

interface Props {
  provider: string;
  title: string;
}

export function ActivationModalForUnPurchased({ provider, title }: Props) {
  const intl = useIntl();
  const defaultMessage = `Every customer with an <strong>certain tariff</strong> or an activated Kino package will automatically get access to ${title}{br}${title} allows you to watch <strong>the best series and movies</strong> at any time on your smart TV, computer, tablet, or mobile phone.`;

  return (
    <DefaultModal
      className={styles.modal}
      title={intl.formatMessage({
        id: `${COMPONENT_NAME}.additionPlanAccessTitle.${provider}`,
        defaultMessage: `How do I access ${title}?`,
      })}
    >
      <p>
        <FormattedMessage
          id={`${COMPONENT_NAME}.additionPlanAccessDescription.${provider}`}
          defaultMessage={defaultMessage}
          values={{
            br: <br />,
            strong: (text: React.ReactNode) => <strong>{text}</strong>,
          }}
        />
      </p>
    </DefaultModal>
  );
}
