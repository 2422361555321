import React from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { RatingProviderEnum } from '@kaltura-ott/tvpil';
import { EntityRating, useRating } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks';
import { getRatingIconPath } from 'utils';

import styles from './Rating.module.scss';

interface Props {
  rating: EntityRating;
  className?: string;
  isDetailsInfo?: boolean;
}

export function RatingV2({ rating, className, isDetailsInfo = false }: Props) {
  const ratingData = useRating(rating.name);
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  if (!ratingData) return null;

  const image = isDetailsInfo ? ratingData?.long : ratingData?.short;
  const imageSrc = `${staticAssetsUrl}${getRatingIconPath(image)}`;

  return (
    <div data-target='rating' className={cn(styles.rating, className, { [styles.detailsInfo]: isDetailsInfo })}>
      <SVG className={cn({ [styles.star]: imageSrc.includes(RatingProviderEnum.star) })} src={imageSrc} />
      <span>{rating.value}</span>
    </div>
  );
}
