import { HALF_DIVIDER } from 'consts';

export const HEIGHT_GROWTH = 32;
export const PADDING = 5;

interface ZoomParams {
  height: number;
  width: number;
}

export function calculateCardZoom({ height, width }: ZoomParams) {
  const ratio = width / height;
  const widthGrowth = (height + HEIGHT_GROWTH) * ratio - width;

  // calculate default zoom
  const zoomToLeft = (widthGrowth / HALF_DIVIDER) * -1;
  const zoomToRight = zoomToLeft;
  const zoomToTop = HEIGHT_GROWTH / HALF_DIVIDER;

  return { zoomToLeft, zoomToRight, zoomToTop };
}
