import { useState } from 'react';

import { useRailContext } from '../../../RailProvider/RailProvider';
import { useArrowsClickHook } from '../useArrowsClickHook/useArrowsClickHook';

function useNextButtonsCombiner() {
  const {
    setNextButtons,
    refElementInner,
    totalWidth,
    offset,
    setRailCardsListRefScrollTo,
    railData,
    railCardsListRef,
    railSettings,
  } = useRailContext();
  const [state, setState] = useState({
    isLeftNextButton: offset?.current! > 0,
    isRightNextButton:
      offset?.current! + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });

  const scrollHandle = (newOffset: number, blockWidtht: number) => {
    const buttonsView = {
      isLeftNextButton: newOffset > 0,
      isRightNextButton: newOffset + blockWidtht < totalWidth,
    };
    // set value for RailContext
    setNextButtons({ ...buttonsView });
    // set value for local state
    setState({ ...buttonsView });
  };

  const clickHandle = useArrowsClickHook({
    scrollHandle,
    setRailCardsListRefScrollTo,
    items: railData.items,
    totalWidth,
    offset,
    railCardsListRef,
    refElementInner,
    railSettings,
  });
  const { isLeftNextButton, isRightNextButton } = state;

  return { isLeftNextButton, isRightNextButton, clickHandle, imageHeight: railSettings.imageHeight || 0 };
}

export { useNextButtonsCombiner };
