import { NETWORK_ERROR_FOR_DISPLAY, RHINO_NETWORK_ERROR } from 'consts';

const COMPONENT_NAME = 'EPG';

export const getEpgErrorPayload = (goBackLink: () => void, code?: string) => {
  const isConnectionError = code !== RHINO_NETWORK_ERROR;

  const noConnectionErrorPayload = {
    error: {
      titleId: `${COMPONENT_NAME}.noConnection.errorTitle`,
      titleDefault: 'No Connection',
      messageId: `${COMPONENT_NAME}.noConnection.errorMessage`,
      messageDefault: 'Please check your internet connection and try again',
      icon: 'noConnection',
      code: code ?? NETWORK_ERROR_FOR_DISPLAY,
    },
    goBackButton: {
      goBackLink,
      translationId: 'GeoError.playerButtonText',
      defaultMessage: 'Go Back',
    },
  };

  const errorPayload = {
    error: {
      titleId: `${COMPONENT_NAME}.errorTitle`,
      titleDefault: 'Server Error',
      messageId: `${COMPONENT_NAME}.errorMessage`,
      messageDefault: 'Something went wrong, please try again in few minutes',
      code,
    },
    goBackButton: {
      goBackLink,
      translationId: 'GeoError.playerButtonText',
      defaultMessage: 'Go Back',
    },
  };

  return isConnectionError ? errorPayload : noConnectionErrorPayload;
};
