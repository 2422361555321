import { CollectionAsset, getAssetImageUrl, RailSettings } from '@kaltura-ott/tvpil-shared';

export async function preloadImage(
  cleanupFunction: React.MutableRefObject<boolean>,
  setImagesLoading: Function,
  items: CollectionAsset[],
  settings: RailSettings,
) {
  const promises: Promise<void>[] = [];
  const { imageHeight = 0, imageType, imageWidth = 0 } = settings;

  items.forEach(({ images }) => {
    const src = getAssetImageUrl(images, {
      height: imageHeight,
      type: imageType,
      width: imageWidth,
    });

    if (images && src) {
      promises.push(
        new Promise((resolve) => {
          const img = new Image();

          img.src = src!;
          img.onload = resolve as any;
          img.onerror = resolve as any;
        }),
      );
    }
  });

  if (!cleanupFunction.current) {
    setImagesLoading(true);
  }

  await Promise.all(promises);

  if (!cleanupFunction.current) {
    setImagesLoading(false);
  }
}
