import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import Modal from 'components/widgets/Modal/Modal';

import { ContextType, ModalProps } from './interface';

import styles from './modal.module.scss';

interface Props {
  children: React.ReactNode;
}

const ModalContext = createContext<ContextType>({
  openModal: () => {},
  closeModal: () => {},
});

const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState<React.ReactNode | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<React.ReactNode | null>(null);
  const [forbidOnRequestClose, setForbidOnRequestClose] = useState(false);

  const openModal = useCallback((value: React.ReactNode, props: ModalProps = {}, isConfirmationModal?: boolean) => {
    if (isConfirmationModal) {
      setConfirmationModal(value);
      return;
    }

    setForbidOnRequestClose(!!props.forbidOnRequestClose);
    setModal(value);
  }, []);

  const closeModal = useCallback(() => {
    if (confirmationModal) {
      setConfirmationModal(null);
      return;
    }

    setModal(null);
  }, [confirmationModal]);

  const contextValue = useMemo(() => {
    return { openModal, closeModal };
  }, [openModal, closeModal]);

  const onRequestClose = useCallback(() => {
    if (forbidOnRequestClose) return;
    setModal(null);
  }, [forbidOnRequestClose]);

  const onRequestCloseConfirmation = useCallback(() => {
    if (forbidOnRequestClose) return;
    setConfirmationModal(null);
  }, [forbidOnRequestClose]);

  return (
    <ModalContext.Provider value={contextValue}>
      {!!modal && (
        <Modal
          afterOpenClassName={styles.afterOpen}
          beforeCloseClassName={styles.beforeClose}
          id='mainModal'
          onRequestClose={onRequestClose}
          overlayClassName={styles.overlay}
        >
          {modal}
        </Modal>
      )}

      {!!confirmationModal && (
        <Modal
          afterOpenClassName={styles.afterOpen}
          beforeCloseClassName={styles.beforeClose}
          id='confirmationModal'
          onRequestClose={onRequestCloseConfirmation}
          overlayClassName={styles.overlay}
        >
          {confirmationModal}
        </Modal>
      )}
      {children}
    </ModalContext.Provider>
  );
};

// TODO In the future better to use in components useModalContext instead ModalContext
const useModalContext = () => useContext(ModalContext);

export { ModalContext, ModalProvider, useModalContext };
