import React from 'react';
import cn from 'classnames';

import { NEXT_BUTTON_WIDTH } from 'components/widgets/Rail/constants';

import { Position } from './types';

import styles from './NavigationButton.module.scss';

interface Props {
  position: Position;
  handleClickCallback: (value: boolean) => void;
  imageHeight: number;
}

const NavigationButton: React.FC<Props> = ({ position, handleClickCallback, imageHeight }) => {
  const handleClick = () => handleClickCallback(position === Position.Right);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.left]: position === Position.Left,
        [styles.right]: position === Position.Right,
      })}
      style={{
        height: imageHeight,
      }}
    >
      <button
        className={cn(styles.nextButton)}
        onClick={handleClick}
        style={{ width: NEXT_BUTTON_WIDTH }}
        type='button'
      >
        &gt;
      </button>
    </div>
  );
};

export default NavigationButton;
