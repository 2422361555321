import { useEffect } from 'react';

import { useServicesStore } from 'hooks/common/useServicesStore';

export const useResetServiceList = () => {
  const { servicesStore } = useServicesStore();

  useEffect(() => {
    return () => {
      servicesStore.resetStore();
    };
  }, []);
};
