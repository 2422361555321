import React from 'react';
import { observer } from 'mobx-react';

import { useGridMovementHooksCombiner } from './hooks';

import styles from './GridMovementHooks.module.scss';

function GridMovementHooks() {
  const { onDragOver, epgWidth, forwardGridMovement } = useGridMovementHooksCombiner();

  return (
    <div
      ref={forwardGridMovement}
      className={styles.wrapper}
      onMouseMove={onDragOver}
      style={{
        width: epgWidth,
      }}
      role='presentation'
    />
  );
}

export default observer(GridMovementHooks);
