import React from 'react';

import { useAppStore } from 'hooks';
import { formatLocalTime } from 'utils';

export interface Props {
  className?: string;
  date?: Date | number;
  hours?: number;
  minutes?: number;
  withRangeDelimiter?: boolean;
}

function LocalTime({ className, date, hours, minutes = 0, withRangeDelimiter = false }: Props) {
  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const locale = latinLanguage || displayLanguage;
  const time = formatLocalTime({ date, hours, minutes, locale, withRangeDelimiter });

  return <span className={className}>{time}</span>;
}

export default LocalTime;
