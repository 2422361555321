import { config } from 'components/pages/EpgV2/config';
import { epgLocalDrugStorage } from 'components/pages/EpgV2/storages';

/**
 * Function to determine if the drag and drop event should be prevented based on the mouse click delta.
 *
 * @param {React.MouseEvent<HTMLElement>} e - The MouseEvent object triggered by the user.
 * @returns {boolean} Returns true if the drag and drop event should be prevented (just a click), false otherwise.
 */
function handleDragDelta(e: React.MouseEvent<HTMLElement>): boolean {
  const { clientX, clientY } = e;
  const { xStart, yStart } = epgLocalDrugStorage.dragPosition;

  if (Math.abs(clientX - xStart) < config.deltaForDragging && Math.abs(clientY - yStart) < config.deltaForDragging) {
    return true;
  }

  return false;
}

export { handleDragDelta };
