import { playerStorage } from '../../storages';
import { filterQualities } from '../../utils/filterUniqueQualities/filterUniqueQualities';
import { minBitrateFromVideoTracks } from '../../utils/minBitrateFromVideoTracks/minBitrateFromVideoTracks';

export function handleMediaLoaded() {
  const videoTracks = filterQualities();
  const lowestBitrateFromVideoTracks = minBitrateFromVideoTracks(videoTracks);

  if (videoTracks) {
    playerStorage.setVideoTrackInfo(videoTracks, lowestBitrateFromVideoTracks);
  }
}
