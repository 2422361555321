import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';

export const IMAGES_URL_FORMAT =
  AssetPresentationRatioEnum['16x9'] ||
  AssetPresentationRatioEnum['1x1'] ||
  AssetPresentationRatioEnum['2x3'] ||
  AssetPresentationRatioEnum['3x4'] ||
  AssetPresentationRatioEnum['5x3'];

export async function preloadChannelImage(items: any[], setImagesLoading: Function) {
  const promises: Promise<void>[] = [];
  setImagesLoading(true);
  items.forEach((item) => {
    const urlFormat = Object.keys(item.data.images).filter((image) => image.includes(IMAGES_URL_FORMAT))[0] || null;
    const url = urlFormat ? item.data.images[urlFormat].url : null;

    if (url) {
      promises.push(
        new Promise((resolve) => {
          const img = new Image();

          img.src = url;
          img.onload = resolve as any;
          img.onerror = resolve as any;
        }),
      );
    }
  });

  await Promise.all(promises);
  setImagesLoading(false);
}
