import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MosaicChannelsInfo, MosaicInfoTag } from '@kaltura-ott/tvpil';

import { Stream } from './Stream/Stream';

import styles from './StreamsList.module.scss';

type Props = {
  chooseStream: (index: number) => () => void;
  translationId: string;
  isShowingPlayIcon?: boolean;
  mosaicInfo?: MosaicInfoTag;
  activeStreamId?: string;
};

export const StreamsList = ({
  activeStreamId,
  chooseStream,
  mosaicInfo,
  translationId,
  isShowingPlayIcon = false,
}: Props) => {
  const streams = mosaicInfo?.mosaicChannelsInfo;
  const totalStreamsNum = streams?.length;

  if (!totalStreamsNum) return null;

  return (
    <div className={styles.wrapper}>
      <FormattedMessage defaultMessage='Choose stream to watch' id={`${translationId}.watchStreams`} tagName='h3' />
      <ul>
        {streams!.map(({ programExternalId, order, title }: MosaicChannelsInfo, index: number) => {
          const isActive = !!activeStreamId && activeStreamId === programExternalId;
          return (
            <Stream
              key={programExternalId}
              index={index}
              isActive={isActive}
              isShowingPlayIcon={isShowingPlayIcon}
              onClick={chooseStream}
              order={order}
              title={title}
              totalStreamsNum={totalStreamsNum}
              translationId={translationId}
            />
          );
        })}
      </ul>
    </div>
  );
};
