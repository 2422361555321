import React from 'react';

import { useAppStore } from 'hooks';
import { formatLocalTimeV2 } from 'utils';

export interface Props {
  className?: string;
  time: Date | number;
  minutes?: number;
}

function LocalTimeV2({ className, time, minutes = 0 }: Props) {
  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const locale = latinLanguage || displayLanguage;
  const result = formatLocalTimeV2({ locale, time, minutes });

  return <span className={className}>{result}</span>;
}

export default LocalTimeV2;
