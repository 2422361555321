import { IntlShape } from 'react-intl';

import { RESPONSE } from '../constants';
import { Action } from '../enum';

export const getResultMessage = (actionType: string, messageType: string, intl: IntlShape) => {
  if (messageType === RESPONSE.SUCCESS) {
    return intl.formatMessage({
      id: `Settings.Favorites.${actionType}.${messageType}`,
      defaultMessage:
        actionType === Action.AddFavorite
          ? 'Channel added to favorites successfully'
          : 'Channel removed from favorites successfully',
    });
  }
  return intl.formatMessage({
    id: `Settings.Favorites.${actionType}.${messageType}`,
    defaultMessage:
      actionType === Action.AddFavorite
        ? 'Channel could not be added to favorites'
        : 'Channel could not be removed from favorites',
  });
};
