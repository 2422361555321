import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { IMAGE_PARAM } from 'consts';
import { sizeImageResolve } from 'utils';

import styles from './Banner.module.scss';

interface Props {
  hero: {
    image: string;
    images?: Object;
  };
  className?: string;
  link: string;
}

export default function Banner({ link, className, hero: { images } }: Props) {
  const imageResolve = sizeImageResolve({ images, size: 'long', imageParam: IMAGE_PARAM });

  return (
    <Link to={link}>
      <div className={cn(styles.banner, className)} style={{ backgroundImage: `url(${imageResolve})` }} />
    </Link>
  );
}
