import { MediaType } from '@kaltura-ott/tvpil-analytics';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from './playoutStoreDataResolver';

export function getPerformanceAdditionalParams() {
  const { epgId, mediaFileId, type, record } = playoutStoreDataResolver();
  let assetId;
  let mediaType;

  if (type === PlayoutStoreV2Type.NPVR) {
    assetId = record?.data.id;
    mediaType = MediaType.recording;
  } else if (type === PlayoutStoreV2Type.VOD) {
    assetId = mediaFileId;
    mediaType = MediaType.vod;
  } else if (type === PlayoutStoreV2Type.CATCHUP) {
    assetId = epgId;
    mediaType = MediaType.catchup;
  } else {
    // for live, startover
    assetId = epgId;
    mediaType = MediaType.live;
  }

  return { assetId: String(assetId), mediaType };
}
