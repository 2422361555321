import { ENV_ENDPOINTS } from 'config';

export const legacyPaths = {
  staging: 'www-stage.o2tv.cz',
  production: 'www.o2tv.cz',
};

export function getLegacyAppPath(endpoint: string = '') {
  const domain = endpoint === ENV_ENDPOINTS.STAGING ? legacyPaths.staging : legacyPaths.production;
  return domain;
}
