import React, { useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { AssetPresentationRatioEnum, QuotaStatusEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { MediaType as userEventType, UserActionType } from '@kaltura-ott/tvpil-analytics';
import { Episode as EpisodeType, Program } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { FROM_REFERENCE_ID, FUTURE_RECORDINGS, RECORDINGS } from 'consts';
import { useEpisodesContext } from 'context/episodesContext/episodesContext';
import { useModalContext } from 'context/modal/modal';
import { useAppStore, useIsNpvr, useParentalPinStore, useRecordRepository, useSearchParam } from 'hooks';
import routes from 'routesPaths';
import { playProviderAsset, saveAssetToGlobalStorage, userActionEvents } from 'utils';

import EpisodeCard from './EpisodeCard/EpisodeCard';
import EpisodeContent from './EpisodeContent/EpisodeContent';

import styles from './Episode.module.scss';

type Props = {
  episode: Program | EpisodeType;
  presentationRatio?: AssetPresentationRatioEnum;
  index: number;
};

function Episode({ episode, presentationRatio, index }: Props) {
  const {
    catchupAvailableTime,
    startDate,
    duration,
    description,
    episodeNumber,
    episodeName,
    id,
    linear,
    mediaTypeParam,
    isPurchasable,
    record,
    seriesRecord,
    parentalRating,
    isExternalProviderAsset,
    isFuture,
    isLive,
    isPlayable,
    isRecordable,
    isVodSingle,
  } = detailsStoreDataResolver(episode);
  const { catchUpAirDate } = episode as Program;
  const channelName = linear?.data?.title || '';

  const {
    appStore: { localizationStore },
  } = useAppStore();
  const recordRepository = useRecordRepository();
  const parentalPinStore = useParentalPinStore();
  const { episodesStore } = useEpisodesContext();
  const modalContext = useModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isNpvr = useIsNpvr();
  const fromPage = location.pathname.split(routes.details.commonPath)[0];
  const fromReferenceId = useSearchParam(FROM_REFERENCE_ID) || '';
  const isAdultContent = parentalPinStore.isBlockByAdult(episode) && !isExternalProviderAsset;
  const isAgeContent = !!parentalRating && parentalPinStore.isBlockByParentRating(episode);
  const isShowExpirationTime = !!(startDate && catchupAvailableTime && !isFuture);
  const convertDurationToMinute = duration ? Math.round(duration / 60000) : '';
  const typeForUserEvent = isVodSingle ? userEventType.vod : userEventType.catchup;
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isSeriesRecord = (!!record && record.isSeriesRecord) || !!seriesRecord;
  const isRecordIconCondition = (!!record || !!seriesRecord) && isNpvrEnabled && isNpvr;
  const isOnGoingRecord = record?.data.isOngoing;

  useEffect(() => {
    if (!episodesStore) return;
    episodesStore.setVisibleIndex(index);

    // eslint-disable-next-line consistent-return
    return () => {
      episodesStore.setInvisibleIndex(index);
    };
  }, [episodesStore, index]);

  const resolvePathToWatch = () => {
    let link = routes.catchUp.link(id, mediaTypeParam);

    if (isLive) {
      link = routes.live.link((episode as Program).linearId!, mediaTypeParam);
    }

    if (isNpvr) {
      link = routes.program.link(id, mediaTypeParam);
    }

    if (isVodSingle) {
      link = routes.vod.link(id, mediaTypeParam);
    }

    return {
      to: link,
      state: {
        goBackUrl: `${location.pathname}${location.search}`,
      },
    };
  };

  const { to, state } = resolvePathToWatch();
  const handlePiCodeModalCallback = useCallback(
    (success: boolean) => {
      if (success) {
        saveAssetToGlobalStorage(episode, isLive);
        navigate(to, { state });
      }
    },
    [episode, isLive, navigate, to, state],
  );
  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isExternalProviderAsset) {
        playProviderAsset(e, episode as EpisodeType, modalContext);
        return;
      }

      if (isAdultContent) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModal
            asset={episode}
            context={UIDAPresentationTypeEnum.asset_details_page}
            type={PinCodeModalTypes.RestrictedContent}
          />,
        );
        return;
      }

      const shouldRedirectToDetails =
        (!isPurchasable && isRecordable && !isPlayable) || // checked Recordable in future
        (isPurchasable && isPlayable) || // Buyable for play
        (isPurchasable && isRecordable) || // Buyable for record
        isOnGoingRecord ||
        !isPlayable;

      if (shouldRedirectToDetails) {
        e.preventDefault();

        userActionEvents(UserActionType.details, id, typeForUserEvent);
        let resolvedPath = `${fromPage}${routes.detailsInfo.link(id, mediaTypeParam)}`;

        const params = new URLSearchParams();

        if (fromReferenceId) {
          params.append(FROM_REFERENCE_ID, fromReferenceId);
        }

        if (isOnGoingRecord) {
          params.append('type', RECORDINGS);
        }

        if (isFuture) {
          params.append('type', FUTURE_RECORDINGS);
        }

        if (params.toString()) {
          resolvedPath += `?${params.toString()}`;
        }

        navigate(resolvedPath);
        return;
      }

      if (isAgeContent && !shouldRedirectToDetails) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModal
            asset={episode}
            callback={handlePiCodeModalCallback}
            description={parentalRating ? <DescriptionWithAge age={parentalRating} /> : undefined}
            context={UIDAPresentationTypeEnum.asset_details_page}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      if (!isPurchasable) {
        saveAssetToGlobalStorage(episode, isLive);
      }
    },

    [
      isAdultContent,
      modalContext,
      record,
      isPurchasable,
      id,
      mediaTypeParam,
      isAgeContent,
      parentalPinStore,
      isPlayable,
      parentalRating,
    ],
  );

  return (
    <Link className={styles.wrapper} onClick={clickHandler} to={to} state={state}>
      <EpisodeCard
        isContentRestricted={isAdultContent}
        episode={episode}
        isPlayable={!!isPlayable && !isPurchasable}
        presentationRatio={presentationRatio}
      />

      <EpisodeContent
        isRecordIconCondition={isRecordIconCondition}
        isShowExpirationTime={isShowExpirationTime}
        isSeriesRecord={isSeriesRecord}
        description={description}
        episodeName={episodeName}
        episodeNumber={episodeNumber}
        convertDurationToMinute={convertDurationToMinute}
        duration={duration}
        channelName={channelName}
        isContentRestricted={isAdultContent}
        catchUpAirDate={catchUpAirDate}
        catchupAvailableTime={catchupAvailableTime}
        displayLanguage={localizationStore.displayLanguage}
      />
    </Link>
  );
}

export default observer(Episode);
