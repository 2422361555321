import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MosaicInfoTag } from '@kaltura-ott/tvpil';
import { Program, ProgramStateV2 } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { StreamsList } from 'components/presenters/StreamsList/StreamsList';
import { useVideoContext } from 'context/videoContext/videoContext';
import routes from 'routesPaths';
import { resolvePathToDetailsPage } from 'utils';

export const MosaicStreamsList = () => {
  const navigate = useNavigate();
  const { program } = useVideoContext();
  const {
    data: { id, mediaType, mosaicInfo },
    linearId,
    programState,
  } = program as Program;
  const isFutureStream = programState === ProgramStateV2.FUTURE;
  const isLive = programState === ProgramStateV2.LIVE;
  const isShowingPlayIcon = programState === ProgramStateV2.PAST || isLive;
  const streams = mosaicInfo?.mosaicChannelsInfo!;

  if (!streams?.length) return null;

  const chooseStream = (index: number) => {
    return () => {
      const { programExternalId, channelExternalId } = streams![index];

      if (isFutureStream) {
        navigate(
          resolvePathToDetailsPage({
            id,
            externalId: programExternalId,
            mediaTypeParam: mediaType.toLowerCase(),
          }),
        );
        return;
      }

      if (isLive) {
        navigate(routes.live.link(linearId!, mediaType.toLowerCase(), channelExternalId));
        return;
      }

      navigate(routes.catchUp.link(id, mediaType.toLowerCase(), programExternalId));
    };
  };

  return (
    <StreamsList
      chooseStream={chooseStream}
      isShowingPlayIcon={isShowingPlayIcon}
      mosaicInfo={mosaicInfo as MosaicInfoTag}
      translationId={`${COMPONENT_NAME}.VideoContent`}
    />
  );
};
