import { Link, To } from 'react-router-dom';
import { MoreLinkType } from 'types';

import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import { MenuStore } from '@kaltura-ott/tvpil-shared';

import { FROM_REFERENCE_ID } from 'consts';
import routes from 'routesPaths';
import { findMenuItems, resolvePathToDetailsPage } from 'utils';

function resolveLink(data: MoreLinkType, referenceId: string | undefined, menuStore: MenuStore) {
  const { inAppLink, linkType, link } = data;
  const isLinkInMenu = !!findMenuItems(menuStore, inAppLink).itemById;
  let url: To = '';

  switch (linkType) {
    case TypeOfApplicationLink.page:
      url = link;
      if (referenceId && !isLinkInMenu) {
        url += `?${FROM_REFERENCE_ID}=${referenceId}`;
      }
      break;
    case TypeOfApplicationLink.external:
      url = link;
      break;
    case TypeOfApplicationLink.asset:
      url = resolvePathToDetailsPage({ id: inAppLink! });
      break;
    case TypeOfApplicationLink.grid:
      url = routes.grid.link(link);
      if (referenceId && !isLinkInMenu) {
        url += `?${FROM_REFERENCE_ID}=${referenceId}`;
      }
      break;
    default:
      console.error(Error(`${linkType} - linkType is not defined`));
  }

  // @ts-ignore
  let Tag: Link | string = Link;
  let conditionalProps = {
    to: url,
  };

  if (linkType === TypeOfApplicationLink.external) {
    Tag = 'a';
    conditionalProps = {
      // @ts-ignore
      href: url,
    };
  }

  return { Tag, conditionalProps };
}

export { resolveLink };
