import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { SearchStore } from '@kaltura-ott/tvpil-shared/lib/stores/search/search-store';

import RailGrid from 'components/widgets/Rail/RailGrid/RailGrid';

import styles from './SearchWrapper.module.scss';

interface Props {
  search: SearchStore;
}

const COMPONENT_NAME = 'SearchWrapper';

function SearchWrapper({ search }: Props) {
  const wrapperRef = useRef(null);

  if (!search?.railStore) {
    return null;
  }

  const searchRailTitle = () => {
    if (search.railStore?.totalItemsCount) {
      return (
        <FormattedMessage
          id={`${COMPONENT_NAME}.title`}
          defaultMessage={`Search results for ${search.searchString}`}
          values={{ searchString: search.searchString }}
          tagName='h3'
        />
      );
    }

    return (
      <>
        <FormattedMessage
          id={`${COMPONENT_NAME}.title`}
          defaultMessage={`Search results for ${search.searchString}`}
          values={{ searchString: search.searchString }}
          tagName='h3'
        />
        <FormattedMessage
          id={`${COMPONENT_NAME}.NotFound.title`}
          defaultMessage={`No results for "${search.searchString}"`}
          values={{ searchString: search.searchString }}
          tagName='h3'
        />
      </>
    );
  };

  return (
    <div ref={wrapperRef} className={styles.searchWrapper}>
      {searchRailTitle()}
      <RailGrid className={styles.searchGridPosition} store={search.railStore} />
    </div>
  );
}

export default observer(SearchWrapper);
