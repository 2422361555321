import React, { useEffect, useState } from 'react';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { CUSTOM_RAIL_EVENTS } from 'components/widgets/Rail/constants';

import NavigationButton, { Position } from '../NavigationButton/NavigationButton';

const NextButtons = () => {
  const { setNextButtons, forwardElementInnerRef, totalWidth, offset } = useRailHorisontalDataContext();
  const [state, setState] = useState({
    isLeftNextButton: offset > 0,
    isRightNextButton:
      offset + (forwardElementInnerRef?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });

  function scrollHandle({ detail: { newOffset, newBlockWidth, newTotalWidth } }: CustomEvent) {
    const buttonsView = {
      isLeftNextButton: newOffset > 0,
      isRightNextButton: newOffset + newBlockWidth < newTotalWidth,
    };
    // set value for RailHorisontalDataContext
    setNextButtons({ ...buttonsView });
    // set value for local state
    setState({ ...buttonsView });
  }

  useEffect(() => {
    const element = forwardElementInnerRef.current;

    element.addEventListener(CUSTOM_RAIL_EVENTS.SCROLL, scrollHandle);

    return () => element.removeEventListener(CUSTOM_RAIL_EVENTS.SCROLL, scrollHandle);
  }, [forwardElementInnerRef]); // eslint-disable-line

  return (
    <>
      {state.isLeftNextButton && <NavigationButton position={Position.Left} />}

      {state.isRightNextButton && <NavigationButton position={Position.Right} />}
    </>
  );
};

export default NextButtons;
