import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { DayProps } from 'components/pages/Epg/interface';

import { useIsActiveDay, useSelectDay } from './hooks';

interface Porps {
  day: DayProps;
  forwardRef: any;
}

function DayHooksCombiner({ day, forwardRef }: Porps) {
  const selectDay = useSelectDay();

  useIsActiveDay(day, forwardRef);

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.callBack = selectDay;
    }
  }, []);

  return null;
}

export default observer(DayHooksCombiner);
