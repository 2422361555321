import React, { createContext, useContext, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { RailFetchData } from 'types';

import { RailSettings } from '@kaltura-ott/tvpil-shared';

import { useRailProviderCombiner } from './hooks/useRailProviderCombiner/useRailProviderCombiner';

export interface ContextType {
  railData: RailFetchData;
  railSettings: RailSettings;
  railType: string;
  index: number;
  checkResize: () => void;
  onHover: (isHover: boolean, value?: number) => void;
  onCardClick: (id: number) => void;
  setNextButtons: (value: any) => void;
  setRailCardsListRefScrollTo: (value: any) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  totalWidth: number;
  componentHeight: number;
  headerRef: React.MutableRefObject<HTMLElement | null>;
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
  railCardsListRef: React.MutableRefObject<List | null>;
  nextButtons: React.MutableRefObject<any> | undefined;
  isLogoRail: boolean;
  offset: React.MutableRefObject<any> | undefined;
}

export interface Props {
  railData: RailFetchData;
  railSettings: RailSettings;
  children: React.ReactNode;
  railType: string;
  index: number;
}

const RailContext = createContext<ContextType>({
  railData: {} as RailFetchData,
  railSettings: {} as RailSettings,
  railType: '',
  checkResize: () => {},
  setRailCardsListRefScrollTo: () => {},
  onHover: () => {},
  onCardClick: () => {},
  setNextButtons: () => {},
  wrapperRef: { current: null },
  totalWidth: 0,
  componentHeight: 0,
  headerRef: { current: null },
  refElementInner: { current: null },
  railCardsListRef: { current: null },
  offset: undefined,
  nextButtons: undefined,
  index: 0,
  isLogoRail: false,
});

const RailProvider = ({ children, railData, railSettings, railType, index }: Props) => {
  const {
    checkResize,
    onHover,
    onCardClick,
    wrapperRef,
    totalWidth,
    componentHeight,
    headerRef,
    refElementInner,
    railCardsListRef,
    offset,
    setNextButtons,
    setRailCardsListRefScrollTo,
    nextButtons,
    isLogoRail,
  } = useRailProviderCombiner(railSettings, railData, railType);
  const contextValue = useMemo(() => {
    return {
      railData,
      railSettings,
      railType,
      checkResize,
      onHover,
      onCardClick,
      wrapperRef,
      totalWidth,
      componentHeight,
      headerRef,
      refElementInner,
      railCardsListRef,
      offset,
      index,
      setNextButtons,
      setRailCardsListRefScrollTo,
      nextButtons,
      isLogoRail,
    };
  }, [railData.isLoading, railSettings.imageHeight]);

  return <RailContext.Provider value={contextValue}>{children}</RailContext.Provider>;
};

const useRailContext = () => useContext(RailContext);

export { RailProvider, useRailContext };

export default RailProvider;
