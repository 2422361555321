import React from 'react';
import { observer } from 'mobx-react';

import CardTitleBubbleView from './components/CardTitleBubbleView/CardTitleBubbleView';
import CardTitleStaticView from './components/CardTitleStaticView/CardTitleStaticView';
import { useCardTitleCombiner } from './hooks/useCardTitleCombiner/useCardTitleCombiner';

const CardTitle = ({ isStaticInfo }: { isStaticInfo?: boolean }) => {
  const {
    resolvedTitle,
    isRecord,
    isWrapperWithIcon,
    isWithSeriesRecord,
    isSeriesType,
    seasonEpisodeTitle,
  } = useCardTitleCombiner();

  return (
    <>
      {isStaticInfo ? (
        <CardTitleStaticView
          title={resolvedTitle}
          seasonEpisodeTitle={seasonEpisodeTitle}
          isRecord={isRecord}
          isWrapperWithIcon={isWrapperWithIcon}
          isWithSeriesRecord={isWithSeriesRecord}
          isSeriesType={isSeriesType}
        />
      ) : (
        <CardTitleBubbleView
          title={resolvedTitle}
          isRecord={isRecord}
          isWrapperWithIcon={isWrapperWithIcon}
          isWithSeriesRecord={isWithSeriesRecord}
          isSeriesType={isSeriesType}
        />
      )}
    </>
  );
};

export default observer(CardTitle);
