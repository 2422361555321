import React from 'react';
import { minutesInHour } from 'date-fns';

import { HALF_DIVIDER } from 'consts';

import { amountResolver } from './amountResolver';

export interface priceProps {
  amount?: number;
  currency?: string;
  currencySign?: string;
}

export interface PropsImage {
  images?: any;
  size?: string;
  image?: string;
  imageParam?: string;
}

export function priceResolve(price?: priceProps, description?: string) {
  let resultPrice;

  if (Number.isFinite(price?.amount!)) {
    resultPrice = amountResolver(price?.amount!, price?.currency!);
  }

  if (resultPrice && description) {
    return (
      <>
        <b>{resultPrice}</b>
        {` / ${description}`}
      </>
    );
  }

  return <b>{resultPrice}</b> || description || '';
}

const sizeMap: { [key: string]: string } = {
  large: '16x9',
  long: '5x3',
  medium: '4x3',
  small: '2x3',
  smallest: '1x1',
};

export function sizeImageResolve({ images, size = 'large', image = '', imageParam }: PropsImage) {
  if (images) {
    if (imageParam) {
      return `${images[sizeMap[size]]?.url}${imageParam}`;
    }
    return images[sizeMap[size]]?.url;
  }
  return image ? `${image}${imageParam}` : '';
}

export function paramImageResolveParamForLandscapeByHeigh(height: number) {
  // 16x9 is ratio
  const width = (height * 16) / 9;
  return `/height/${height}/width/${Math.floor(width)}`;
}

export function paramImageResolveParamForLandscapeByWidth(width: number) {
  // 16x9 is ratio
  const height = (width * 9) / 16;
  return `/height/${Math.floor(height)}/width/${width}`;
}

export function paramImageResolveForPosterImageByWidth(width: number) {
  // 2x3 is ratio
  const height = (width * 3) / HALF_DIVIDER;
  return `/height/${Math.floor(height)}/width/${width}`;
}

export function resolveMinutesToHours(duration: number) {
  return Math.round(duration / minutesInHour);
}
