import { playerStorage } from '../../storages';
import { PlayerEvent } from '../../types';

export function handleUpdateYouboraAnalytics(e: PlayerEvent) {
  const { player } = playerStorage.getPlayer();

  player.configure({
    plugins: {
      youbora: {
        options: { 'content.customDimension.2': e?.payload?.originalUrl },
      },
    },
  });
}
