// @ts-nocheck
export const railObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const rail = entry.target;

        if (rail.callback) {
          rail.callback();
        }
        observer.unobserve(rail);
      }
    });
  },
  {
    rootMargin: '400px 0px',
  },
);
