import React from 'react';
import { FormattedMessage } from 'react-intl';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useTransactionStore } from 'hooks';

import styles from '../../PinCodeModalDetails.module.scss';

function PinCodeDetailsDisclaimer() {
  const { lastDigits } = useTransactionStore();

  return (
    <p className={styles.disclaimer}>
      {lastDigits && (
        <span className={styles.cardInfo}>
          <FormattedMessage
            defaultMessage={`You are paying with credit card xxxx-xxxx-${lastDigits}`}
            id={`${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.creditCardInfo`}
            values={{ value: lastDigits }}
          />
        </span>
      )}
      <span>
        <FormattedMessage
          defaultMessage='Movie is rented automatically after PIN is entered'
          id={`${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.disclaimer`}
        />
      </span>
    </p>
  );
}

export default PinCodeDetailsDisclaimer;
