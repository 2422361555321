import React from 'react';
import cn from 'classnames';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';

import { LoadingStateIcon } from 'components/presenters/icons/LoadingStateIcon/LoadingStateIcon';
import { SvgView } from 'components/presenters/icons/SvgView/SvgView';
import { SERIES_RECORD, SINGLE_RECORD } from 'consts';
import { useAppStore, useRecordRepository } from 'hooks';
import { getRecordIcon } from 'utils';

import styles from './RecordIcon.module.scss';

interface Props {
  multiple?: boolean;
  active?: boolean;
  className?: string;
  pending?: boolean;
}

export function RecordIcon({ multiple, className, active, pending }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const recordRepository = useRecordRepository();

  const singleRecord = `${staticAssetsUrl}${getRecordIcon(SINGLE_RECORD)}`;
  const seriesRecord = `${staticAssetsUrl}${getRecordIcon(SERIES_RECORD)}`;
  const src = multiple ? seriesRecord : singleRecord;

  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;

  if (!isNpvrEnabled) {
    return null;
  }

  return pending ? (
    <LoadingStateIcon />
  ) : (
    <SvgView
      className={cn(styles.wrapper, className, {
        recordMultipleIcon: multiple,
        recordIcon: !multiple,
      })}
      active={active}
      url={src}
    />
  );
}
