import React, { createContext, useContext, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Subscription } from '@kaltura-ott/tvpil-shared';

export interface ContextType {
  imageResolve: string;
  title: string;
  isChangeState: boolean;
  isPurchasePending: boolean;
  loading: boolean;
  error: {
    errorMessage: string;
    errorCode: string;
  };
  priceResolved: JSX.Element | string;
  purchaseState: string;
  purchaseAction: string;
  description: string;
  disclaimer: string;
  purchaseDate: any;
  isRestricted: boolean;
  isSubscription: boolean;
  purchaseStatus: string;
  subscription: Subscription | null;
  onBuy(): void;
  handleButtonBack(): void;
  onCancelSubscription(): void;
}

interface Props extends ContextType {
  children: React.ReactNode;
}

const PackageDetailsContext = createContext<ContextType>({
  imageResolve: '',
  title: '',
  isChangeState: false,
  isPurchasePending: false,
  loading: false,
  error: {
    errorMessage: '',
    errorCode: '',
  },
  priceResolved: '',
  purchaseState: '',
  purchaseAction: '',
  description: '',
  disclaimer: '',
  purchaseDate: '',
  isRestricted: false,
  isSubscription: false,
  purchaseStatus: '',
  subscription: null,
  onBuy: () => {},
  handleButtonBack: () => {},
  onCancelSubscription: () => {},
});

const PackageDetailsProvider = ({
  imageResolve,
  title,
  isChangeState,
  isPurchasePending,
  loading,
  error,
  priceResolved,
  purchaseState,
  purchaseAction,
  description,
  disclaimer,
  purchaseDate,
  isRestricted,
  isSubscription,
  purchaseStatus,
  onBuy,
  handleButtonBack,
  onCancelSubscription,
  subscription,
  children,
}: Props) => {
  const contextValue = useMemo(() => {
    return {
      imageResolve,
      title,
      isChangeState,
      isPurchasePending,
      loading,
      error,
      priceResolved,
      purchaseState,
      purchaseAction,
      description,
      disclaimer,
      purchaseDate,
      isRestricted,
      isSubscription,
      purchaseStatus,
      onBuy,
      handleButtonBack,
      onCancelSubscription,
      subscription,
    };
  }, [
    imageResolve,
    title,
    isChangeState,
    isPurchasePending,
    loading,
    error,
    priceResolved,
    purchaseState,
    purchaseAction,
    description,
    disclaimer,
    purchaseDate,
    isRestricted,
    isSubscription,
    purchaseStatus,
    onBuy,
    handleButtonBack,
    onCancelSubscription,
    subscription,
  ]);

  return <PackageDetailsContext.Provider value={contextValue}>{children}</PackageDetailsContext.Provider>;
};

const usePackageDetailsContent = () => useContext(PackageDetailsContext);

export { PackageDetailsProvider, usePackageDetailsContent };

export default observer(PackageDetailsProvider);
