import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import Loader from 'components/presenters/Loader/Loader';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';

import { COMPONENT_NAME } from '../../constants';
import { usePackageDetailsContent } from '../PackageDetailsProvider/PackageDetailsProvider';
import PackageDetailsStateDefaultView from '../PackageDetailsStates/PackageDetailsStateDefaultView';
import PackageDetailsStateStatusView from '../PackageDetailsStates/PackageDetailsStateStatusView';

import styles from '../../PackageDetails.module.scss';

function PackageDetailsResolveRendering() {
  const {
    handleButtonBack,
    imageResolve,
    isChangeState,
    isRestricted,
    loading,
    subscription,
    title,
  } = usePackageDetailsContent();
  const modalContext = useModalContext();

  useEffect(() => {
    if (isRestricted) {
      modalContext.openModal(
        <PinCodeModal
          asset={subscription!}
          onDecline={handleButtonBack}
          context={UIDAPresentationTypeEnum.transaction_history}
          type={PinCodeModalTypes.RestrictedContent}
        />,
      );
    }
  }, []);

  return (
    <MainLayout>
      {!isRestricted && (
        <div className={styles.wrapper}>
          <div className={styles.image}>{imageResolve && <img alt='poster' src={imageResolve} />}</div>
          <div className={styles.details}>
            <button className={styles.buttonBack} onClick={handleButtonBack} type='button'>
              <FormattedMessage defaultMessage='Back' id={`${COMPONENT_NAME}.purchaseButtonBack`} />
            </button>
            <div className={styles.titleWrapper}>
              <h1 className={styles.title}>
                {title.substring(0, 35)}
                <span className={styles.titleInfo}>
                  {/* TODO this is a temporary solution */}
                  {isChangeState && (
                    <FormattedMessage
                      defaultMessage='yearly subscription'
                      id={`${COMPONENT_NAME}.purchaseYearlySubscriptionTitle`}
                    />
                  )}
                </span>
              </h1>
            </div>
            {loading && (
              <div className={styles.LoaderWrapperForEvents}>
                <LoaderWrapperForEvents hashKey={COMPONENT_NAME} component={<Loader />} />
              </div>
            )}
            {isChangeState ? <PackageDetailsStateStatusView /> : <PackageDetailsStateDefaultView />}
          </div>
        </div>
      )}
    </MainLayout>
  );
}

export default PackageDetailsResolveRendering;
