import React, { Suspense } from 'react';

import BackgroundLoader from './BackgroundLoader';

interface Props {
  className?: string;
  filterClassName?: string;
  image: string;
  defaultImage: string;
}

const BackgroundLoaderWrapper = ({ ...args }: Props) => {
  return (
    <Suspense>
      <BackgroundLoader {...args} />
    </Suspense>
  );
};
export default BackgroundLoaderWrapper;
