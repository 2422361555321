import { useEffect } from 'react';

import { Linear, PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useCleanupState } from 'hooks';
import { globalStorage } from 'services/globalStorage';

export const useInitializationHook = () => {
  const [loaded, setLoaded] = useCleanupState(() => false);
  const {
    appStore: { playoutStoreV2: playoutStore, lastWatchedLinear },
  } = useAppStore();
  const playableAsset: Linear = globalStorage.focusAsset!;

  useEffect(() => {
    async function effect() {
      setLoaded(false);

      await playoutStore.init({
        playableAsset,
        type: PlayoutStoreV2Type.LIVE,
        isMaxiPlayer: true,
        mosaic: {
          externalId: lastWatchedLinear.mainHeroRail?.lastWatchedSubLinearExternalId,
        },
      });

      setLoaded(!!playoutStore.currentAsset);
      globalStorage.clearFocusAsset();
    }

    if (playableAsset?.id && playoutStore) {
      effect();
    }
  }, [playoutStore, playableAsset, setLoaded]);

  return { loaded, playableAsset };
};
