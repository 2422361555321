import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getImageByHeightV2 } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME, LOGO_HEIGHT } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import LocalTime from 'components/presenters/LocalTime/LocalTime';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useDetectLocation } from 'hooks';
import { DateFormatStyle, formatDate } from 'utils';

import styles from '../../VideoContent.module.scss';

type Props = {
  displayLanguage: string;
  latinLanguage?: string;
};

export const Channel = ({ displayLanguage, latinLanguage }: Props) => {
  const { program, mosaicAsset } = useVideoContext();
  const { isDetailsSeries } = useDetectLocation();
  const { isFuture, isLive, isPast, linear, startDate, endDate } = detailsStoreDataResolver(mosaicAsset || program);
  const channelImages = linear?.data.images;
  const isProperlyImage = channelImages && (channelImages['16x9'] || channelImages['1x1']);
  const isStartEndDates = startDate && endDate;
  const originImageLandscape =
    channelImages && getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['16x9'], channelImages!);
  const originImageSquad =
    channelImages && getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['1x1'], channelImages!);

  return (
    <div className={styles.channel}>
      {isPast && (
        <div>
          <FormattedMessage defaultMessage='Aired on' id={`${COMPONENT_NAME}.VideoContent.chanelWasAiredOn`} />
        </div>
      )}
      {isFuture && (
        <div>
          <FormattedMessage defaultMessage='Will Air on' id={`${COMPONENT_NAME}.VideoContent.chanelWillAirOn`} />
        </div>
      )}
      {isLive && (
        <FormattedMessage defaultMessage='Now on' id={`${COMPONENT_NAME}.VideoContent.chanelNowOn`} tagName='div' />
      )}
      {isProperlyImage ? (
        <img alt={`${linear?.data.title}`} src={originImageLandscape?.url! || originImageSquad?.url!} />
      ) : (
        <p className={styles.channelTitle}>{linear?.data.title}</p>
      )}

      {!isDetailsSeries && (
        <>
          {startDate && (
            <div className={styles.date}>
              {formatDate(startDate, DateFormatStyle.WithShortWeekday, latinLanguage || displayLanguage)}
            </div>
          )}
          {isStartEndDates && (
            <div className={styles.time}>
              <LocalTime date={startDate} withRangeDelimiter />
              <LocalTime date={endDate} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
