import { globalStorage } from 'services/globalStorage';

import { seekStorage } from '../../components/SeekBar/storages/SeekStorage';
import { playerEventsStorage } from '../../storages';
import { changeLocalState } from '../../utils/customEvents/customEventChangeLocalState';
import { osdVisualiseHandler } from '../../utils/osdHelpers/helpers';

export function handleCanPlay() {
  const props = seekStorage.getSeekProps();
  const { playerHover, updatePlayerHoverState } = props;

  if (globalStorage.isFullPlayer) {
    osdVisualiseHandler(playerHover, updatePlayerHoverState);
  }

  playerEventsStorage.setIsPlayerDataLoaded(true);
  globalStorage.setPossiblePresentPlayer(true);
  globalStorage.setIsCanPlayFired(true);
  changeLocalState({ isControlsReady: true });
}
