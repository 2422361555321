import { createContext, useContext } from 'react';

import { DataRailV2 } from '@kaltura-ott/tvpil-shared';
import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';

import { AssetType } from 'components/widgets/Rail/typesOfAsset';

interface ContextType<T = AssetType> {
  listIndex: number;
  asset: T;
  listStore: DataRailV2;
  disableHover: boolean;
  isGrid: boolean;
  resolvedProps: any; // must be added the types
}

const AssetCardContext = createContext<ContextType>({
  listIndex: 0,
  asset: {} as MediaAsset,
  listStore: {} as DataRailV2,
  disableHover: false,
  isGrid: false,
  resolvedProps: undefined,
});

const useAssetCardContext = () => useContext(AssetCardContext);

export { AssetCardContext, useAssetCardContext };
