import { appStore, featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import { isEdge } from 'utils';

import { getKS } from '../utils/getKS/getKS';
import { isLive } from '../utils/isLive/isLive';

import { getContentOptionsYoubora } from './getContentOptionsYoubora/getContentOptionsYoubora';
import { broadpeakDefaultConfig, PlayerSettings, SessionType } from './consts';

export const getPlayerConfig = (KalturaPlayer: any, settings: PlayerSettings, mountId: string) => {
  const maxBitrate = appStore?.userStore?.userBitrate
    ? appStore?.userStore?.userBitrate * 1000000 // Mbits -> bits
    : Infinity;
  const keepAliveBeaconsFrequency = featureEnabler.variation(FEATURES.BROADPEAK_KEEP_ALIVE_FREQUENCY);
  const uiConfId = appStore?.dms?.playerSettings?.uiConfId || '';
  const { partnerId } = appStore?.params?.dmsSetting!;
  const ottAnalyticsTimerInterval = appStore?.dms?.playerSettings?.ottAnalytics?.timerInterval ?? 0;
  const playerConfig = {
    abr: {
      enabled: true,
      fpsDroppedFramesInterval: 5000,
      fpsDroppedMonitoringThreshold: 0.2,
      capLevelOnFPSDrop: false,
      capLevelToPlayerSize: false,
      defaultBandwidthEstimate: 800000,
      restrictions: {
        minBitrate: 0,
        maxBitrate,
      },
    },
    targetId: mountId,
    provider: {
      uiConfId,
      partnerId,
      ks: getKS(),
      env: {
        serviceUrl: `${appStore?.dms?.endpointUrl!}/api_v3`,
      },
    },
    sources: {
      type: settings.isLive ? SessionType.LIVE : SessionType.VOD,
      dvr: !!settings.isLive,
    },
    streaming: {
      lowLatencyMode: false,
    },
    ui: {
      hoverTimeout: 0,
      customPreset: [
        {
          template: (props: any) => settings.presets.live(props),
          condition: () => isLive(),
        },
        { template: (props: any) => settings.presets.playback(props) },
      ],
      disable: !!settings.hideUI,
    },
    // for disable user options (muted, volume, audioLanguage, subTitleLanguage)
    disableUserCache: true,
    playback: {
      textLanguage: appStore.localizationStore?.subTitleLanguage || '',
      autoplay: true,
      options: {
        html5: {
          dash: {
            manifest: {
              defaultPresentationDelay: 9,
            },
          },
        },
      },
    },
    text: {
      useNativeTextTrack: false,
      useShakaTextTrackDisplay: false,
    },
    drm: {
      keySystem: isEdge() ? KalturaPlayer.core.DrmScheme.WIDEVINE : '',
    },
    plugins: {
      broadpeak: {
        ...(appStore?.dms?.playerSettings?.broadpeak! || broadpeakDefaultConfig),
        uuid: appStore?.params?.udid,
        sessionKeepaliveFrequency: keepAliveBeaconsFrequency > 0 ? keepAliveBeaconsFrequency : undefined,
      },
      youbora: getContentOptionsYoubora(),
      ottAnalytics: {
        partnerId,
        ks: getKS(),
        serviceUrl: `${appStore?.dms?.endpointUrl!}/api_v3`,
        isAnonymous: false,
        ...(ottAnalyticsTimerInterval > 0 && { mediaHitInterval: ottAnalyticsTimerInterval }),
      },
    },
  };

  return { playerConfig };
};
