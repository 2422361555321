export const CLOSE_BTN_AREA = 'close';
export const pincodeLength = 4;
export const defaultPropsError = {
  errorMessage: '',
  errorCode: '',
};
export const DefaultError = {
  errorMessage: 'The PIN you entered doesn’t match. Please try again.',
  errorCode: '5999',
};
export const WRONG_PIN_ERR_CODE = '5002';
export const PIN_LIMIT_ERROR = 'pinlimit';
