import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { getAssetExpiration } from 'components/pages/VideoDetails/utils';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { overlayStorage } from 'components/widgets/Player/storages';
import { useDetectLocation, useIsNpvr } from 'hooks';

export function useCheckProgramAvailability(asset: ViewableEntitiesType) {
  const {
    catchupAvailableTime,
    catchupExpirationTime,
    recordAvailableTime,
    isRecordedAsset,
  } = detailsStoreDataResolver(asset);
  const { isDetailsPage } = useDetectLocation();
  const isNpvr = useIsNpvr();
  const isRecordingDetailsPage = isDetailsPage && isNpvr;
  const isOverlayOpen = overlayStorage.getShowOverlay();
  const isOverlayWithRecordedAsset = !!isOverlayOpen && isRecordedAsset;

  let programNotAvailable = false;

  if (isRecordingDetailsPage) {
    programNotAvailable = getAssetExpiration(catchupExpirationTime);
  } else if (isOverlayWithRecordedAsset) {
    programNotAvailable = getAssetExpiration(recordAvailableTime);
  } else {
    programNotAvailable = getAssetExpiration(catchupAvailableTime);
  }

  return programNotAvailable;
}
