import { useEffect } from 'react';

import { IN_APP_LINK_HOME } from 'components/widgets/Menu/constans';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { PerformanceContextType, PerformanceEvents } from 'interface';
import routes from 'routesPaths';

interface Props {
  isHomePageFirstRail: boolean;
  performanceEventsContext: PerformanceContextType;
}

export const useClearAppStartAndHomePageReady = ({ isHomePageFirstRail, performanceEventsContext }: Props) => {
  const { location } = useStableReactRouter();

  useEffect(() => {
    return () => {
      // to reset performance events start time if user left home page before it was finally loaded
      const shouldClearPerformanceEvents =
        isHomePageFirstRail && ![routes.home.path, routes.page.link(IN_APP_LINK_HOME)].includes(location.pathname);

      if (shouldClearPerformanceEvents) {
        performanceEventsContext.clearEvent(PerformanceEvents.appStart);
        performanceEventsContext.clearEvent(PerformanceEvents.homePageReady);
      }
    };
  }, [isHomePageFirstRail, location.pathname, performanceEventsContext]);
};
