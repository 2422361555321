import { useCallback } from 'react';
import { NavigateFunction } from 'react-router';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { Program } from '@kaltura-ott/tvpil-shared';

import { globalStorage } from 'services/globalStorage';
import { resolvePathToDetailsPage, userActionEvents } from 'utils';

interface Props {
  program: Program;
  mediaTypeForUserEvent: string;
  mediaType: string;
  navigate: NavigateFunction;
}

export function useOpenDetailsPage({ mediaType, mediaTypeForUserEvent, program, navigate }: Props) {
  return useCallback(() => {
    userActionEvents(UserActionType.details, program.id, mediaTypeForUserEvent);
    globalStorage.setDetailsAsset(program);
    navigate(resolvePathToDetailsPage({ id: program.id, mediaTypeParam: mediaType }));
  }, [mediaType, mediaTypeForUserEvent, program]);
}
