import React from 'react';

import { RailSkeletonAsset } from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import { DEFAULT_WIDTH } from 'consts';
import { useCollectionGridSettingsContext } from 'context/collectionGridSettingsContext/collectionGridSettingsContext';

import GridCollectionCardLogic from '../GridCollectionCardLogic/GridCollectionCardLogic';

const GridCollectionCardResolveRendering = ({ data }: { data: any }) => {
  const { gridSettingsData, itemWidth } = useCollectionGridSettingsContext();
  const { imageHeight } = gridSettingsData;
  const columnWidth = Math.round(itemWidth || DEFAULT_WIDTH);

  if (!data?.id) {
    return <RailSkeletonAsset height={imageHeight!} width={columnWidth} />;
  }

  return <GridCollectionCardLogic data={data} itemWidth={itemWidth} gridSettingsData={gridSettingsData} />;
};

export default GridCollectionCardResolveRendering;
