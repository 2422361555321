import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { BODY_OPEN_CLASS_NAME, ROOT } from 'consts';
import { useScrollbar } from 'hooks/common/useScrollbar';

import styles from './Modal.module.scss';

ReactModal.setAppElement(document.getElementById(ROOT)!);

interface Props {
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
  onRequestClose?(): void;
  closeTimeoutMS?: number;
  beforeCloseClassName?: string;
  afterOpenClassName?: string;
  id?: string;
  shouldReturnFocusAfterClose?: boolean;
}

const Modal: React.FC<Props> = ({
  children,
  onRequestClose,
  className,
  overlayClassName,
  closeTimeoutMS = 0,
  beforeCloseClassName = '',
  afterOpenClassName = '',
  shouldReturnFocusAfterClose = true,
  id,
}) => {
  useScrollbar();

  useEffect(() => {
    return () => {
      document.body.classList.remove(BODY_OPEN_CLASS_NAME);
    };
  }, []);

  return (
    <ReactModal
      bodyOpenClassName={BODY_OPEN_CLASS_NAME}
      className={cn(styles.content, className)}
      closeTimeoutMS={closeTimeoutMS}
      id={id}
      isOpen
      onRequestClose={onRequestClose}
      overlayClassName={{
        base: cn(styles.overlay, overlayClassName),
        beforeClose: beforeCloseClassName,
        afterOpen: afterOpenClassName,
      }}
      preventScroll
      parentSelector={() => document.getElementById(ROOT)!}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      {children}
    </ReactModal>
  );
};

export default observer(Modal);
