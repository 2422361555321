import { Location } from 'react-router-dom';

import { LAST_SCROLL_Y_POSITION } from 'consts';

interface Props {
  lastScrollYPosition?: number;
  historyAction: string;
  location?: Location;
  isRecordPage?: boolean;
  isRemove?: boolean;
}

const setScrollPosition = ({ lastScrollYPosition, isRemove = true }: Props) => {
  const savedScrollYPosition = Number(sessionStorage.getItem(LAST_SCROLL_Y_POSITION)) || 0;

  if (lastScrollYPosition) {
    window.scrollTo(0, lastScrollYPosition);
  }
  if (isRemove && savedScrollYPosition <= window.scrollY) {
    sessionStorage.removeItem(LAST_SCROLL_Y_POSITION);
  }
};

export { setScrollPosition };
