import { MediaType, TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import { Linear, LinkAsAsset, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { FROM_REFERENCE_ID } from 'consts';
import { useMenuStore, usePageId, useSearchParam } from 'hooks';
import routes from 'routesPaths';
import { findMenuItems, resolveLinkForMd, resolvePathToDetailsPage } from 'utils';

interface Props {
  asset: ViewableEntitiesType | Linear | LinkAsAsset | any;
  isProgramAsset: boolean;
  isLinearChannel: boolean;
  isLinearAsset: boolean;
  isLinkAsAsset: boolean;
  isContinueWatching: boolean;
  lastWatchedSubLinearExternalId: string | undefined;
  isPurchasable: boolean | undefined;
  isLive: boolean | undefined;
  linear: Linear | undefined;
  id: number;
  isSinglePlayableNpvr: boolean | undefined;
  isSingleVod: boolean;
  isSuppressedEpisode: boolean | undefined;
  isPlayableAsset: boolean;
  railStoreSubtype: string;
  isAggregatedSeriesRecord: boolean;
  isGroupedSeries: boolean;
  isFutureProgram: boolean;
  isMdCondition: boolean;
  isHero: boolean;
  isOnGoingRecord?: boolean;
}
// TODO: add unit
export function useCardLink({
  asset,
  isProgramAsset,
  isLinearChannel,
  isLinkAsAsset,
  isContinueWatching,
  lastWatchedSubLinearExternalId,
  isPurchasable,
  isLive,
  linear,
  id,
  isSinglePlayableNpvr,
  isSingleVod,
  isSuppressedEpisode,
  isPlayableAsset,
  railStoreSubtype,
  isAggregatedSeriesRecord,
  isGroupedSeries,
  isFutureProgram,
  isMdCondition,
  isHero,
  isOnGoingRecord,
}: Props) {
  const {
    data: { linkItem },
  } = asset;
  const menuStore = useMenuStore();
  const pageId = usePageId();
  const referenceId = useSearchParam(FROM_REFERENCE_ID) || pageId;
  const mediaTypeParam = asset.data.mediaType.toLowerCase();
  let link = '';

  if (isLinkAsAsset) {
    if (linkItem?.typeOfAppLink === TypeOfApplicationLink.asset && linkItem?.inAppLink) {
      link = resolvePathToDetailsPage({ id: linkItem.inAppLink });
    } else if (linkItem?.typeOfAppLink === TypeOfApplicationLink.external && linkItem?.inAppLink) {
      link = linkItem.inAppLink;
    } else if (linkItem?.inAppLink && linkItem?.link) {
      const isItemInMenu = !!findMenuItems(menuStore, linkItem?.inAppLink).itemById;
      link = linkItem.link;

      if (referenceId && !isItemInMenu) {
        link += `?${FROM_REFERENCE_ID}=${referenceId}`;
      }
    }
  }

  if (isProgramAsset) {
    if (linear && isLive && !isPurchasable && !isOnGoingRecord) {
      link = routes.live.link(linear.id, mediaTypeParam, lastWatchedSubLinearExternalId);
      if (isContinueWatching) {
        link = routes.startOver.link(id, mediaTypeParam);
      }
    } else if (isSinglePlayableNpvr && !isOnGoingRecord) {
      link += routes.program.link(id, mediaTypeParam);
    } else if (isSingleVod && !isSuppressedEpisode) {
      link += routes.vod.link(id, mediaTypeParam);
    } else if (isPlayableAsset) {
      link = routes.catchUp.link(id, mediaTypeParam);
    } else {
      // @ts-ignore
      const { seriesId } = asset.data;
      link = resolvePathToDetailsPage({
        id,
        subtype: railStoreSubtype,
        mediaTypeParam,
        isAggregatedSeriesRecord,
        isGroupedSeries,
        seriesId,
        isFuture: isFutureProgram, // for avoid the bugs with future recording
        isSuppressedEpisode,
      });
    }

    link += resolveLinkForMd(isMdCondition, link, railStoreSubtype); // to avoid displaying MD components in the player
  }

  if (isLinearChannel && !isHero) {
    link = routes.live.link(id, MediaType.Program.toLowerCase()); // MediaType.Program for open the details page about current program
  }

  return link;
}
