import { playerStorage } from 'components/widgets/Player/storages';

import { HOVER_TIMEOUT } from '../../constants';

let hoverTimeout: NodeJS.Timeout;

export const clearHoverTimeout = () => {
  if (hoverTimeout) {
    clearTimeout(hoverTimeout);
  }
};

export const osdVisualiseHandler = (playerHover: boolean, updatePlayerHoverState: (state: boolean) => void) => {
  if (!playerHover) {
    updatePlayerHoverState(true);
  }

  clearHoverTimeout();

  if (playerStorage.getIsMouseOverPlayerBottomBar()) {
    return;
  }

  hoverTimeout = setTimeout(() => {
    updatePlayerHoverState(false);
  }, HOVER_TIMEOUT);
};
