import { useEffect, useRef } from 'react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getImageByHeight } from '@kaltura-ott/tvpil-shared';

import { globalStorage } from 'services/globalStorage';

export const useMaxiPlayerPosterHook = () => {
  const { height } = window.screen;
  const { isCanPlayFired } = globalStorage;
  const isLogoPoster = useRef(false);
  const maxiPlayerPosterUrl = useRef<string | undefined>();

  useEffect(() => {
    if (globalStorage.focusAsset) {
      let image =
        globalStorage.focusAsset?.currentProgram &&
        getImageByHeight(
          height,
          AssetPresentationRatioEnum['16x9'],
          globalStorage.focusAsset.currentProgram.data.images,
        );
      isLogoPoster.current = !image?.url;

      if (isLogoPoster.current) {
        image = getImageByHeight(height, AssetPresentationRatioEnum['16x9'], globalStorage.focusAsset?.data.images!);
      }
      maxiPlayerPosterUrl.current = image?.url;
    }
  }, [globalStorage.focusAsset]);

  return {
    isCanPlayFired,
    isLogoPoster: isLogoPoster.current,
    maxiPlayerPosterUrl: maxiPlayerPosterUrl.current,
  };
};
