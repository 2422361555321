import React from 'react';

import { Channel as ChannelType } from '@kaltura-ott/tvpil-shared';

import { PurchaseIcon } from 'components/presenters/icons';
import { FavoriteIcon } from 'components/presenters/icons/Favorite/Favorite';
import { useEpgContext } from 'context/epgContext/epgContext';

import { useChannelCombiner } from './hooks';

import styles from './Channel.module.scss';

interface Props {
  channel: ChannelType;
}

function Channel({ channel }: Props) {
  const { title, channelNumber, isEntitled, isFavorite } = channel;
  const { settings } = useEpgContext();
  // getting channel image by ratio and type
  const { originImage } = useChannelCombiner(channel, {
    channelIconRatio: settings?.channelIconRatio!,
    channelIconType: settings?.channelIconType,
  });

  const shouldDisplayFavoriteIcon = isFavorite && Boolean(settings?.shouldShowFavoriteIndicator) && isEntitled;

  return (
    <li className={styles.item}>
      {!isEntitled && <PurchaseIcon className={styles.purchase} />}
      {isEntitled && !!channelNumber && <span className={styles.number}>{channelNumber}</span>}
      {originImage?.url ? (
        <img alt={title} className={styles.logo} src={originImage.url} />
      ) : (
        <span className={styles.name}>{title}</span>
      )}

      {shouldDisplayFavoriteIcon && <FavoriteIcon className={styles.favorite} />}
    </li>
  );
}

export default React.memo(Channel);
