import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { Linear } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';
import { useAppStore, useParentalPinStore } from 'hooks';
import routes from 'routesPaths';
import { saveAssetToGlobalStorage } from 'utils';

interface Props {
  isContentBlockedAsAdult: boolean;
  program: any;
  assetId: number;
  parentalRating: number;
  currentAsset: any;
  playableAsset: Linear;
}

export const useWatchNowHandler = ({
  isContentBlockedAsAdult,
  program,
  assetId,
  parentalRating,
  currentAsset,
  playableAsset,
}: Props) => {
  const modalContext = useModalContext();
  const {
    appStore: { lastWatchedLinear },
  } = useAppStore();
  const parentalPinStore = useParentalPinStore();
  const mediaType = program?.data?.mediaType?.toLowerCase();
  const isContentBlockedByAge = !!parentalRating && !!program && parentalPinStore.isBlockByParentRating(program);
  const navigate = useNavigate();

  return useCallback(() => {
    if (isContentBlockedAsAdult) {
      modalContext.openModal(
        <PinCodeModal
          context={UIDAPresentationTypeEnum.applications}
          asset={program}
          type={PinCodeModalTypes.RestrictedContent}
        />,
      );
      return;
    }

    if (isContentBlockedByAge) {
      modalContext.openModal(
        <PinCodeModal
          callback={(success: boolean) => {
            if (success) {
              navigate(
                routes.live.link(assetId!, mediaType!, lastWatchedLinear.mainHeroRail?.lastWatchedSubLinearExternalId),
              );
            }
          }}
          context={UIDAPresentationTypeEnum.applications}
          asset={program}
          description={parentalRating ? <DescriptionWithAge age={parentalRating} /> : undefined}
          type={PinCodeModalTypes.RestrictedContentParental}
        />,
      );
      return;
    }

    saveAssetToGlobalStorage(currentAsset);

    navigate(routes.live.link(assetId!, mediaType!, lastWatchedLinear.mainHeroRail?.lastWatchedSubLinearExternalId));
  }, [
    assetId,
    isContentBlockedAsAdult,
    isContentBlockedByAge,
    modalContext,
    parentalRating,
    mediaType,
    playableAsset,
    lastWatchedLinear.mainHeroRail,
  ]);
};
