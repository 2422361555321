import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom React hook that tracks changes in the current route.
 *
 * @returns {Function} A function `hasRouteChanged` that returns a boolean indicating whether the route has changed.
 *
 * @example
 * const hasChanged = useRouteChange();
 * if (hasChanged()) {
 *   console.log("Route has changed!");
 * }
 */
const useRouteChange = () => {
  const location = useLocation();
  const [lastPathname, setLastPathname] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname !== lastPathname) {
      setLastPathname(location.pathname);
    }
  }, [location.pathname, lastPathname]);

  const hasRouteChanged = useCallback(() => {
    return location.pathname !== lastPathname;
  }, [location.pathname, lastPathname]);

  return hasRouteChanged;
};

export default useRouteChange;
