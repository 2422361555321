export const VOD_SUBTITLES_STYLE = {
  fontSize: '5%',
  fontScale: 1, // available values: -2 => 50%, -1 => 75%, 0 => 100%, 2 => 200%, 3 => 300%, 4 => 400%
  fontFamily: 'Verdana',
};

export enum SessionType {
  LIVE = 'Live',
  VOD = 'Vod',
  START_OVER = 'START_OVER',
  CATCH_UP = 'CATCH_UP',
}

export enum StreamFormat {
  DASH = 'mpegdash',
  HLS = 'applehttp',
}

export enum ContextType {
  PLAYBACK = 'PLAYBACK',
  START_OVER = 'START_OVER',
  CATCHUP = 'CATCHUP',
}

export interface PlayerSettings {
  presets: { [key: string]: any };
  hideUI?: boolean;
  isLive?: boolean;
}

export const SUBTITLE_OFF = 'off';
export const PLAYER_STORAGE_SUBTITLE_KEY = 'kaltura-player-js_textLanguage';
export const FULLSCREEN_ID = 'fullscreen';
export const broadpeakDefaultConfig = {
  analyticsAddress: '',
  broadpeakDomainNames: '*',
};

export const DRM_SUPPORTED = 'DRM_SUPPORTED';
export const ADAPTER_DATA = {
  CODEC: {
    AVC: 'AVC',
  },
  QUALITY: {
    UHD: 'UHD',
    SD: 'SD',
    HD: 'HD',
  },
};
