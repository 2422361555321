import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useAppStore } from 'hooks';
import { ActionButtonTypesEnum } from 'interface';

import styles from './Gdpr.module.scss';

export default function Gdpr() {
  const {
    appStore: { cookieStore },
  } = useAppStore();

  const defaultText =
    'We use our own and third-party cookies to improve your user experience and offer content tailored to your interests. If you continue browsing without changing this configuration, we consider that you accept its use.';

  const handleAcceptCookie = () => {
    cookieStore.acceptCookiePromt(navigator.userAgent);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <FormattedMessage defaultMessage='Please Accept Our Cookies Policy' id='Gdpr.title' tagName='h4' />
          <div className={styles.main}>
            <p>
              <FormattedMessage defaultMessage={defaultText} id='Gdpr.text' />
              <a href={cookieStore.cookiePolicyUrl} rel='noopener noreferrer' target='_blank'>
                <FormattedMessage defaultMessage='See our cookies policy' id='Gdpr.link' />
              </a>
            </p>
            <ActionButton type={ActionButtonTypesEnum.primary} onClick={handleAcceptCookie}>
              <FormattedMessage defaultMessage='Accept' id='Gdpr.actionButton' />
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  );
}
