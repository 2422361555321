import React, { MouseEvent, useRef, useState } from 'react';
import cn from 'classnames';

import { useOnClickOutside } from 'hooks';

import Options from './components/Options/Options';

import styles from './Select.module.scss';

type Option = {
  value: string;
  text: string;
  additionalInfo: string;
};

interface Props {
  onChange(event: any): void;
  options: Option[];
  value: number;
}

function Select({ onChange, options, value }: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpened(false));

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    setIsOpened(!isOpened);
  }

  function renderSelectedOption() {
    return options.find((item) => Number(item.value) === value)?.text;
  }

  return (
    <div
      ref={ref}
      className={cn(styles.optionsWrapper, { [styles.opened]: isOpened })}
      onClick={handleClick}
      role='button'
      tabIndex={0}
    >
      {renderSelectedOption()}
      {isOpened && <Options onClick={onChange} options={options} />}
    </div>
  );
}

export default Select;
