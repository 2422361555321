import React from 'react';
import { FormattedMessage } from 'react-intl';

export function NewEpisodeBadge({ className, translationId }: { className?: string; translationId: string }) {
  return (
    <div className={className}>
      <FormattedMessage defaultMessage='New Episode' id={`${translationId}.newEpisodeBadge`} />
    </div>
  );
}
