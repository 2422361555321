import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { observer } from 'mobx-react';

import checkIcon from 'assets/images/check.svg';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useSettingsStore, useToastMessage, useUserStore } from 'hooks';
import { ActionButtonTypesEnum } from 'interface';

import SettingsContentWrapper from '../SettingsContentWrapper/SettingsContentWrapper';
import { findVideoStore } from '../utils';

import styles from './VideoSettings.module.scss';

const COMPONENT_NAME = 'VideoSettings';
const FULL_COMPONENT_NAME = `Settings.${COMPONENT_NAME}`;

function VideoSettings() {
  const userStore = useUserStore();
  const userBitrate = userStore.userBitrate ?? 0;
  const { settingsStore } = useSettingsStore();
  const [active, setActive] = useState(userBitrate);
  const videoStore = findVideoStore(settingsStore.children)!;
  const intl = useIntl();

  useToastMessage(COMPONENT_NAME);

  useEffect(() => {
    videoStore.init();
  }, []);

  useEffect(() => {
    setActive(userStore.userBitrate || 0);
  }, [userStore.userBitrate]);

  const bitrateClickHandler = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const { bitrate } = event.currentTarget.dataset;

    setActive(+bitrate!);
  }, []);

  const saveClickHandler = useCallback(() => {
    userStore.setBitrate(active);
  }, [active, userStore]);

  return (
    <SettingsContentWrapper
      description={intl.formatMessage({
        defaultMessage: 'Choose your preferred video quality',
        id: `${FULL_COMPONENT_NAME}.description`,
      })}
      title={intl.formatMessage({ defaultMessage: 'Video Settings', id: `${FULL_COMPONENT_NAME}.title` })}
    >
      <div className={styles.list}>
        {videoStore.bitrateLimits.map(({ bitrate, title }) => (
          <button
            key={bitrate}
            className={styles.item}
            data-bitrate={bitrate}
            onClick={bitrateClickHandler}
            type='button'
          >
            <SVG className={cn(styles.checkIcon, { [styles.active]: active === bitrate })} src={checkIcon} />
            {title}
          </button>
        ))}
      </div>

      {active !== userBitrate ? (
        <ActionButton className={styles.actionButton} onClick={saveClickHandler} type={ActionButtonTypesEnum.primary}>
          <FormattedMessage defaultMessage='Save' id={`${FULL_COMPONENT_NAME}.buttonSave`} />
        </ActionButton>
      ) : null}
    </SettingsContentWrapper>
  );
}

export default observer(VideoSettings);
