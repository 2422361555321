import React from 'react';
import { useImage } from 'react-image';
import cn from 'classnames';

interface Props {
  className?: string;
  filterClassName?: string;
  image: string;
  defaultImage: string;
}

function BackgroundLoader({ className, image, defaultImage, filterClassName }: Props) {
  const { src } = useImage({
    srcList: [image, defaultImage],
  });

  return (
    <>
      <img alt='background-loader' className={className} src={src} />
      {!!filterClassName && <div className={cn(className, filterClassName)} />}
    </>
  );
}

export default BackgroundLoader;
