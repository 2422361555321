export const changeState = (timeTop = '', daysTop = '') => {
  const time = document.getElementById('time');
  const days = document.getElementById('days');

  if (time) {
    time.style.top = timeTop;
  }
  if (days) {
    days.style.top = daysTop;
  }
};
