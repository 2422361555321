import React from 'react';
import cn from 'classnames';

import OverlayDetails from 'components/pages/VideoDetails/OverlayDetails/OverlayDetails';
import { PLAYER_MOUNT_NODE } from 'consts';
import { playoutStoreDataResolver } from 'utils';

import { handleHideInfoPopup } from '../../handlers/handleHideInfoPopup/handleHideInfoPopup';
import { handleSwitchToLive } from '../../handlers/handleSwitchToLive/handleSwitchToLive';
import MosaicStreams from '../Mosaic/MosaicStreams/MosaicStreams';

import styles from '../../Player.module.scss';

interface Props {
  isMaxi?: boolean;
  hideUI: boolean;
  isOpen: boolean;
  isControlsReady: boolean;
  isShowStreams: boolean;
  className: string;
  apiError: any;
  playerRef: any;
}

function KalturaPlayerView({
  isMaxi,
  hideUI,
  isOpen,
  isControlsReady,
  isShowStreams,
  className,
  apiError,
  playerRef,
}: Props) {
  const { isNotFound, id: assetId } = playoutStoreDataResolver();
  const isShowOverlay = !isNotFound && assetId && !hideUI && isOpen;
  const isPlayerLoading = !isControlsReady && !isMaxi;

  return (
    <>
      <div
        ref={playerRef}
        className={cn(className, styles.kalturaPlayer, {
          kalturaPlayerIsLoading: isPlayerLoading,
          visible: !isControlsReady,
          // watch apiError field to update state of RecordButton
          withApiError: !!apiError,
          disablePlayerGradients: isOpen,
          hiddenBottomBar: isShowStreams,
        })}
        id={`${PLAYER_MOUNT_NODE}`}
      />

      {isShowOverlay && (
        <OverlayDetails onClose={handleHideInfoPopup} programId={assetId} switchToLive={handleSwitchToLive} />
      )}
      {isShowStreams && <MosaicStreams />}
    </>
  );
}

export default KalturaPlayerView;
