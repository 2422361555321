import { CUSTOM_APP_EVENTS } from 'consts';

import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import {
  handleCancelRecord,
  handleCanPlay,
  handleError,
  handleLoadMeta,
  handleMediaLoaded,
  handlePausePlay,
  handlePlaybackEnded,
  handlePlayerPlaybackReset,
  handlePlaying,
  handleRecord,
  handleSelectAudio,
  handleShowInfoPopup,
  handleStartOver,
  handleSwitchToCatchUp,
  handleSwitchToLive,
  handleUIPausePlay,
  handleUpdateCurrentTime,
  handleUpdateYouboraAnalytics,
  handleVideoTrackChanged,
} from '../../handlers';

function addRegularEventListener(player: any) {
  player.addEventListener(CUSTOM_PLAYER_EVENTS.INFO_BUTTON_CLICKED, handleShowInfoPopup);
  player.addEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_START_OVER, handleStartOver);
  player.addEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_LIVE, handleSwitchToLive);
  player.addEventListener(CUSTOM_PLAYER_EVENTS.RECORD_BUTTON_CLICKED, handleRecord);
  player.addEventListener(CUSTOM_PLAYER_EVENTS.CANCEL_RECORD_BUTTON_CLICKED, handleCancelRecord);
  player.addEventListener(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED, handlePlaybackEnded);
  player.addEventListener(player.Event.TIME_UPDATE, handleUpdateCurrentTime);
  player.addEventListener(player.Event.LOADED_METADATA, handleLoadMeta);
  player.addEventListener(player.Event.PLAY, handlePausePlay);
  player.addEventListener(player.Event.PAUSE, handlePausePlay);
  player.addEventListener(player.Event.CAN_PLAY, handleCanPlay);
  player.addEventListener(player.Event.Core.SOURCE_URL_SWITCHED, handleUpdateYouboraAnalytics);
  player.addEventListener(player.Event.CONCURRENCY_LIMIT, handleError);
  player.addEventListener(player.Event.MEDIA_LOADED, handleMediaLoaded);
  player.addEventListener(player.Event.TRACKS_CHANGED, handleSelectAudio);
  player.addEventListener(player.Event.VIDEO_TRACK_CHANGED, handleVideoTrackChanged);
  player.addEventListener(player.Event.PLAYING, handlePlaying);
  player.addEventListener(player.Event.PLAYER_RESET, handlePlayerPlaybackReset);
  player.addEventListener(player.Event.UI.USER_CLICKED_PLAY, handleUIPausePlay);
  player.addEventListener(player.Event.UI.USER_CLICKED_PAUSE, handleUIPausePlay);
  document.addEventListener(CUSTOM_APP_EVENTS.SWITCH_TO_CATCH_UP, handleSwitchToCatchUp);
}

function removeRegularEventListener(player: any) {
  player.removeEventListener(player.Event.ERROR, handleError);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.INFO_BUTTON_CLICKED, handleShowInfoPopup);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_LIVE, handleSwitchToLive);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_START_OVER, handleStartOver);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.RECORD_BUTTON_CLICKED, handleRecord);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.CANCEL_RECORD_BUTTON_CLICKED, handleCancelRecord);
  player.removeEventListener(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED, handlePlaybackEnded);
  player.removeEventListener(player.Event.TIME_UPDATE, handleUpdateCurrentTime);
  player.removeEventListener(player.Event.LOADED_METADATA, handleLoadMeta);
  player.removeEventListener(player.Event.PLAY, handlePausePlay);
  player.removeEventListener(player.Event.PAUSE, handlePausePlay);
  player.removeEventListener(player.Event.CAN_PLAY, handleCanPlay);
  player.removeEventListener(player.Event.Core.SOURCE_URL_SWITCHED, handleUpdateYouboraAnalytics);
  player.removeEventListener(player.Event.CONCURRENCY_LIMIT, handleError);
  player.removeEventListener(player.Event.MEDIA_LOADED, handleMediaLoaded);
  player.removeEventListener(player.Event.TRACKS_CHANGED, handleSelectAudio);
  player.removeEventListener(player.Event.VIDEO_TRACK_CHANGED, handleVideoTrackChanged);
  player.removeEventListener(player.Event.PLAYING, handlePlaying);
  player.removeEventListener(player.Event.PLAYER_RESET, handlePlayerPlaybackReset);
  player.removeEventListener(player.Event.UI.USER_CLICKED_PLAY, handleUIPausePlay);
  player.removeEventListener(player.Event.UI.USER_CLICKED_PAUSE, handleUIPausePlay);
  document.removeEventListener(CUSTOM_APP_EVENTS.SWITCH_TO_CATCH_UP, handleSwitchToCatchUp);
}

export { addRegularEventListener, removeRegularEventListener };
