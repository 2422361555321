export const ADDITIONAL_EPISODE_PADDING = 25;
export const ASSET_SIDE_SCALE = 60;
export const ASSET_SIDE_SHADOW = 30;
export const ADDITIONAL_HEIGHT = ASSET_SIDE_SCALE + ASSET_SIDE_SHADOW; // scale + shadow space
export const CARD_PADDING = 8;
export const DEFAULT_ANIMATION_TIME = 850;
export const INITIAL_OFFSET = 60;
export const NEXT_BUTTON_WIDTH = 60;
export const TITLE_BLOCK_MARGIN = 8;
export const TITLE_SERIES_MARGIN = 5;
export const TITLE_SERIES_SIZE_MULTIPLIER = 0.8;
export const CUSTOM_RAIL_EVENTS = {
  VISIBLE: 'VISIBLE',
  SCROLL: 'SCROLL',
};
