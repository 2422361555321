import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { reaction } from 'mobx';

import { ExponeaUiResponse } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useIsComponentMounted } from 'hooks';
import routes from 'routesPaths';

import { EXPONEA_BANNER } from '../constans';

export const initialState = {
  display: false,
  type: '',
  title: '',
  buttons: [],
  time: 0,
} as ExponeaUiResponse;

function useBannerHook() {
  const alive = useIsComponentMounted();
  const {
    appStore: { exponeaStore },
  } = useAppStore();

  const location = useLocation();
  const [exponeaBanner, setExponeaBanner] = useState(initialState);

  const resetExponeaBanner = useCallback(() => {
    if (alive.current) {
      setExponeaBanner(initialState);
    }
  }, [alive]);

  const disposer = reaction(
    () => exponeaStore?.bannerData! && exponeaStore.isReady,
    () => {
      const { bannerData, isReady } = exponeaStore;
      if (bannerData && isReady) {
        setExponeaBanner(bannerData);
      }
    },
  );

  useEffect(() => {
    if (
      location.pathname === routes.home.path ||
      location.pathname === '/page/homepage' ||
      location.pathname === routes.home.alias
    ) {
      exponeaStore.init(EXPONEA_BANNER.type, EXPONEA_BANNER.page);
    } else {
      exponeaStore.resetStore();
    }

    return () => {
      exponeaStore.resetStore();
      resetExponeaBanner();
      disposer();
    };
  }, [location.pathname]); // eslint-disable-line

  return { exponeaBanner, resetExponeaBanner };
}

export { useBannerHook };
