import { useCallback } from 'react';
import { NavigateFunction } from 'react-router';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { RhinoEPGProgram } from '@kaltura-ott/tvpil-shared';

import { resolvePathToDetailsPage, userActionEvents } from 'utils';

interface Props {
  program: RhinoEPGProgram;
  mediaTypeForUserEvent: string;
  mediaType: string;
  navigate: NavigateFunction;
  mosaicExternalId: string | undefined;
}

export function useOpenDetailsPage({ mediaType, mediaTypeForUserEvent, program, mosaicExternalId, navigate }: Props) {
  return useCallback(() => {
    userActionEvents(UserActionType.details, program.id, mediaTypeForUserEvent);
    // globalStorage.setDetailsAsset(program);
    navigate(resolvePathToDetailsPage({ id: program.id, mediaTypeParam: mediaType, externalId: mosaicExternalId }));
  }, [mediaType, mediaTypeForUserEvent, program]);
}
