import React, { Suspense } from 'react';
import { useImage } from 'react-image';

import { useAppStore } from 'hooks/common/useAppStore';

export interface Props {
  alt: string;
  srcList: string[] | string;
  additionalParam?: string;
  className?: string;
}

function ImageComponent({ alt, srcList, additionalParam = '', className }: Props) {
  const { isLoading, src, error } = useImage({ srcList });
  const { appStore } = useAppStore();
  const staticAssetsUrl = appStore.staticAssetsUrl || '';

  if (isLoading || error || !src) {
    return null;
  }

  const imageSrc = src.includes(staticAssetsUrl) ? src : `${src}${additionalParam}`;

  return <img alt={alt} className={className} src={imageSrc} />;
}

export default function Img({ srcList, additionalParam = '', alt, className }: Props) {
  return (
    <Suspense>
      <ImageComponent alt={alt} srcList={srcList} additionalParam={additionalParam} className={className} />
    </Suspense>
  );
}
