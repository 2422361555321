export enum PinCodeModalTypes {
  Purchase,
  EnterParental,
  UpdatePurchase,
  UpdateParental,
  ConfirmPurchase,
  ConfirmParental,
  RestrictedContent,
  RestrictedContentParental,
  RestrictedContentForLink,
}
