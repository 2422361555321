import { contextStorage, overlayStorage, playerStorage } from '../../storages';
// it is needed for removing cycle dependencies
import { changeLocalState } from '../../utils/customEvents/customEventChangeLocalState';
import { focusOnPlayerNode } from '../../utils/focusOnPlayerNode/focusOnPlayerNode';

export async function handleHideInfoPopup() {
  const {
    toastContext: { clearToast },
  } = contextStorage.getContext();
  const { player } = playerStorage.getPlayer();

  player.configure({ ui: { disable: false } });

  changeLocalState({ isOpen: false });
  overlayStorage.setShowOverlay(false);
  focusOnPlayerNode();

  // close error toast if there was error on info popup
  clearToast();
}
