import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { Ppv, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useErrorScreenContext } from 'context/error/error';
import { useModalContext } from 'context/modal/modal';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useParentalPinStore, usePurchase, useRootStore } from 'hooks';
import { PerformanceEvents } from 'interface';

import PinCodeModalDetailsResult from '../../components/PinCodeModalDetailsResult/PinCodeModalDetailsResult';
import { RESPONSE_ERROR, RESPONSE_PENDING } from '../../constants';
import { useRedirectToPlayer } from '../useRedirectToPlayer/useRedirectToPlayer';

interface Props {
  asset: ViewableEntitiesType;
  switchPurchaseRentLoading: (flag: boolean) => void;
  context: UIDAPresentationTypeEnum;
  rentOption: Ppv;
}

export const useOnRentCombiner = ({ asset, switchPurchaseRentLoading, context, rentOption }: Props) => {
  const { detailsStore } = useRootStore();
  const parentalControlStore = useParentalPinStore();
  const navigate = useNavigate();
  const redirectToPlayer = useRedirectToPlayer(asset);
  const modalContext = useModalContext();
  const performanceEventsContext = usePerformanceEventsContext();
  const errorScreenContext = useErrorScreenContext();

  const onGeoError = () =>
    errorScreenContext.setError({
      error: {
        titleId: 'Rent.geoError.title',
        titleDefault: 'You can’t rent geoblocked content',
        messageId: 'Rent.geoError.message',
        messageDefault: 'Purchase was prevented as content is not available in your country',
      },
      goBackButton: {
        goBackLink: () => {
          errorScreenContext.clearError();
        },
        translationId: 'GeoError.playerButtonText',
        defaultMessage: 'Go Back',
      },
    });

  const declineHandler = async () => {
    await detailsStore.init({ asset });
    switchPurchaseRentLoading(false);
  };

  const onError = () => {
    modalContext.openModal(<PinCodeModalDetailsResult type={RESPONSE_ERROR} onDecline={declineHandler} />);
  };

  const onPending = () => {
    modalContext.openModal(<PinCodeModalDetailsResult type={RESPONSE_PENDING} onDecline={declineHandler} />);
  };

  const checkParentalAndRedirectToPlayer = () => {
    const { parentalRating } = detailsStoreDataResolver(asset);

    if (parentalControlStore.isBlockByParentRating(asset)) {
      // To set endTime for performance event purchase after successfully entering the pin code before BlockContentChecker
      performanceEventsContext.setEndTime({
        value: PerformanceEvents.purchase,
        param1: String(asset.mediaFileId),
      });
      const onDecline = () => {
        navigate(0);
      };

      modalContext.openModal(
        <PinCodeModal
          callback={(success: boolean) => {
            if (success) {
              redirectToPlayer();
            }
          }}
          asset={asset}
          onDecline={onDecline}
          description={!!parentalRating && <DescriptionWithAge age={parentalRating} />}
          type={PinCodeModalTypes.RestrictedContentParental}
        />,
      );
    } else {
      redirectToPlayer();
    }
  };

  const { onPurchase } = usePurchase({
    callbackGeo: onGeoError,
    callbackError: onError,
    callbackPending: onPending,
    callbackSuccess: checkParentalAndRedirectToPlayer,
    errorContext: context,
    purchase: rentOption.rent.bind(rentOption),
    setIsLoading: switchPurchaseRentLoading,
  });

  return {
    checkParentalAndRedirectToPlayer,
    onGeoError,
    onPurchase,
    onPending,
    onError,
  };
};
