import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';

import { COMPONENT_NAME } from '../../../constants';

import styles from '../../AdditionPlans.module.scss';

interface Props {
  code?: string;
  fullActivationUrl: string;
  provider: string;
  shortActivationUrl: string;
  title: string;
}

export function ActivationModal({ code, fullActivationUrl, provider, shortActivationUrl, title }: Props) {
  const intl = useIntl();

  return (
    <DefaultModal
      className={styles.modal}
      description={intl.formatMessage({
        id: `${COMPONENT_NAME}.additionPlanDescription.${provider}`,
        defaultMessage: `To pair your device with ${title} go to:`,
      })}
      title={intl.formatMessage({
        id: `${COMPONENT_NAME}.additionPlanTitle.${provider}`,
        defaultMessage: title,
      })}
    >
      <div className={styles.modalContent}>
        <a href={fullActivationUrl} rel='noopener noreferrer' target='_blank'>
          {shortActivationUrl}
        </a>
        {code && (
          <>
            <FormattedMessage
              defaultMessage='And enter this code:'
              id={`${COMPONENT_NAME}.additionPlanInstruction.${provider}`}
              tagName='span'
            />
            <p>{code}</p>
          </>
        )}
      </div>
    </DefaultModal>
  );
}
