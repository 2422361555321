import React, { useEffect, useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { observer } from 'mobx-react';

import { UserServiceTariff } from '@kaltura-ott/tvpil';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useAppStore, useServicesStore, useUserStore } from 'hooks';
import { PerformanceEvents } from 'interface';
import routes from 'routesPaths';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils';

import Loader from '../Loader/Loader';

import styles from './ServiceList.module.scss';

function ServiceList() {
  const {
    servicesStore: { servicesList, selectService: selectServiceStore, resetStore },
  } = useServicesStore();
  const {
    appStore: { applicationRoute },
  } = useAppStore();
  const userStore = useUserStore();
  const navType = useNavigationType();
  const navigate = useNavigate();
  const performanceEventsContext = usePerformanceEventsContext();
  const [isServiceSelected, setIsServiceSelected] = useState(false);
  const resolvePathToRedirect = applicationRoute || routes.home.path;

  useEffect(() => {
    return () => {
      if (!isServiceSelected && navType === 'POP') {
        resetStore(); // to see login form after user pressed browser back button
      }
    };
  }, [navType]);

  function selectService(id: string) {
    return async () => {
      if (isServiceSelected) return;
      performanceEventsContext.setStartTime(PerformanceEvents.appStart);

      const service = servicesList?.find((item) => item.id === id);
      if (service) {
        setIsServiceSelected(true);
        const result = await performanceEventsContext.getEvent(PerformanceEvents.interimTimeForloginSuccess);
        performanceEventsContext.setStartTime(PerformanceEvents.loginSuccesses, '', result?.interimTime || 0);
        await selectServiceStore(service.id);

        performanceEventsContext.setEndTime({ value: PerformanceEvents.loginSuccesses });
        performanceEventsContext.clearEvent(PerformanceEvents.interimTimeForloginSuccess);
        navigate(resolvePathToRedirect);
      }
    };
  }

  if (userStore === undefined) return null;

  return (
    <div className={styles.list}>
      {isServiceSelected && (
        <div className={styles.loaderWrapper}>
          <LoaderWrapperForEvents
            hashKey={APPLICATION_SPINNER_EVENT_NAME.SELECT_SERVICE}
            component={<Loader center />}
          />
        </div>
      )}

      {servicesList?.map(({ id, name }: UserServiceTariff) => (
        <button key={id} className={styles.service} onClick={selectService(id)} type='button'>
          {`${id}/${name}`}
        </button>
      ))}
    </div>
  );
}

export default observer(ServiceList);
