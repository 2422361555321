import React, { createContext, useContext, useMemo } from 'react';
import { CardResolvedProps } from 'types';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

interface Props {
  data: CollectionAsset;
  resolvedCardProps: CardResolvedProps;
  itemWidth: number;
  children: React.ReactNode;
}

interface ContextType {
  data: CollectionAsset | undefined;
  itemWidth: number;
  resolvedCardProps: CardResolvedProps;
}

const CardContext = createContext<ContextType>({
  data: undefined,
  resolvedCardProps: {} as CardResolvedProps,
  itemWidth: 0,
});

const CardProvider = ({ data, itemWidth, resolvedCardProps, children }: Props) => {
  const contextValue = useMemo(() => {
    return {
      data,
      itemWidth,
      resolvedCardProps,
    };
  }, []);

  return <CardContext.Provider value={contextValue}>{children}</CardContext.Provider>;
};

const useCardContext = () => useContext(CardContext);

export { CardProvider, useCardContext };
