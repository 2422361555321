import { useCallback } from 'react';

interface Props {
  drag: React.MutableRefObject<boolean>;
  blockRef: React.MutableRefObject<HTMLDivElement | null>;
  startX: React.MutableRefObject<number>;
  scrollLeft: React.MutableRefObject<number>;
}

export const useMouseMoveHandler = ({ drag, blockRef, startX, scrollLeft }: Props) =>
  useCallback((e: MouseEvent) => {
    if (drag.current) {
      e.preventDefault();

      const x = e.pageX - blockRef.current!.offsetLeft;
      const walk = (x - startX.current) * 1;

      blockRef.current!.scrollLeft = scrollLeft.current - walk;
    }
  }, []);
