import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Subscription } from '@kaltura-ott/tvpil-shared';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';

import { COMPONENT_NAME } from '../constants';

import styles from './UnsubscribeModal.module.scss';

interface Props {
  callback(success: boolean, error?: string): void;
  title: string;
  store: Subscription;
}

function UnsubscribeModal({ callback, title, store }: Props) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  async function onAccept() {
    setLoading(true);
    const result = await store.cancel();
    setLoading(false);

    if (result && result.state) {
      if (result.message) {
        callback(false, result.message);
        return;
      }
      callback(true);
    }
  }

  return (
    <div className={styles.unsubscribeModal}>
      <DefaultModal
        buttonProps={{
          onAccept,
          acceptButtonText: intl.formatMessage({ id: `${COMPONENT_NAME}.DefaultModal.buttonUnsubscribe` }),
        }}
        loading={loading}
        title={intl.formatMessage({
          id: `${COMPONENT_NAME}.DefaultModal.titleUnsubscribe`,
        })}
      >
        <p className={styles.infoBig}>
          <FormattedMessage
            id={`${COMPONENT_NAME}.DefaultModal.infoUnsubscribe`}
            values={{
              title,
              span: (text: React.ReactNode) => <span>{text}</span>,
            }}
          />
        </p>
      </DefaultModal>
    </div>
  );
}

export default UnsubscribeModal;
