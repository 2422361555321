import React, { createContext, useContext, useMemo } from 'react';

interface Props {
  children: React.ReactNode;
  ageThreshold: string;
  onChangeAgeRange: (event: React.SyntheticEvent) => Promise<void>;
  onChangePin: (type: string) => () => void;
}

export interface ContextType {
  ageThreshold: string;
  onChangeAgeRange: (event: React.SyntheticEvent) => Promise<void>;
  onChangePin: (type: string) => () => void;
}

const ParentalControlContext = createContext<ContextType>({
  ageThreshold: '',
  onChangeAgeRange: () => Promise.resolve(),
  onChangePin: () => () => {},
});

const ParentalControlContextProvider = ({ children, ageThreshold, onChangeAgeRange, onChangePin }: Props) => {
  const contextValue = useMemo(() => {
    return { ageThreshold, onChangeAgeRange, onChangePin };
  }, [ageThreshold, onChangeAgeRange, onChangePin]);

  return <ParentalControlContext.Provider value={contextValue}>{children}</ParentalControlContext.Provider>;
};

const useParentalControlContext = () => useContext(ParentalControlContext);

export { ParentalControlContextProvider, useParentalControlContext };
