import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';

import { useCardContext } from 'context/сardContext/сardContext';
import { useParentalPinStore } from 'hooks';

interface Props {
  handleLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
}

function CardBodyContentRestrictedHook({ handleLinkRef }: Props) {
  const {
    resolvedCardProps: { adult, isExternalProviderAsset, lockIcon },
  } = useCardContext();
  const parentalPinStore = useParentalPinStore();
  const isBlockByAdult = !parentalPinStore.isAdultPinEntered;
  const isShowAsContentRestricted = !!(adult && isBlockByAdult && !isExternalProviderAsset);

  useEffect(() => {
    if (isShowAsContentRestricted) {
      handleLinkRef?.current?.setAttribute('ui-type', 'restrictedContent');
    } else {
      handleLinkRef?.current?.removeAttribute('ui-type');
    }
  }, [isShowAsContentRestricted, handleLinkRef]);

  return <> {isShowAsContentRestricted && <SVG data-target='lock' src={lockIcon!} />}</>;
}

export default observer(CardBodyContentRestrictedHook);
