import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore } from '@kaltura-ott/tvpil-shared';

function useLoginStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  return appStore.loginStore;
}

export { useLoginStore };
