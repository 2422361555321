import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useModalContext } from 'context/modal/modal';
import { useParentalPinStore } from 'hooks';

import { FULL_COMPONENT_NAME, typePin } from '../../constans';

import styles from '../../ParentalControl.module.scss';

export const useOnSuccessChangedPin = () => {
  const intl = useIntl();
  const { updatePurchasePin, updateParentalPin } = useParentalPinStore();
  const modalContext = useModalContext();

  const onSuccessChangedPin = useCallback(async (type: string) => {
    const currentPin = sessionStorage.getItem('pin') as string;
    let updatePinCode;

    switch (type) {
      case typePin.purchase:
        updatePinCode = await updatePurchasePin(currentPin);
        break;
      case typePin.parental:
        updatePinCode = await updateParentalPin(currentPin);
        break;
      default:
        break;
    }

    try {
      if (updatePinCode) {
        modalContext.openModal(
          <DefaultModal
            confirm
            title={intl.formatMessage({ defaultMessage: 'Done', id: `${FULL_COMPONENT_NAME}.DefaultModal.title` })}
          >
            <p className={styles.description}>
              <FormattedMessage
                defaultMessage='Your {type} PIN changed successfully'
                id={`${FULL_COMPONENT_NAME}.textYourPin`}
                values={{ type: type.toLowerCase() }}
              />
            </p>
          </DefaultModal>,
        );
      } else {
        throw new Error('Something wrong');
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return { onSuccessChangedPin };
};
