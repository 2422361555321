import React from 'react';
import cn from 'classnames';

import styles from './Options.module.scss';

type Option = {
  value: string;
  text: string | JSX.Element;
  additionalInfo: string | JSX.Element;
};

export interface Props {
  options: Option[];
  onClick(event: any): void;
  className?: string;
}

function Options({ className, options, onClick }: Props) {
  return (
    <div className={cn(styles.optionsList, className)}>
      {options.map(({ value, text, additionalInfo }) => {
        return (
          <div
            key={value}
            className={styles.optionsItem}
            data-value={value}
            onClick={onClick}
            role='button'
            tabIndex={0}
          >
            {text}
            <span>{additionalInfo}</span>
          </div>
        );
      })}
    </div>
  );
}

export default Options;
