import React from 'react';
import cn from 'classnames';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';

import NextButtons from '../NextButtons/NextButtons';
import RailCardsWrapper from '../RailCardsWrapper/RailCardsWrapper';
import Title from '../Title/Title';

import styles from './RailView.module.scss';

const RailView = () => {
  const {
    store,
    forwardHeaderRef,
    forwardWrapperRef,
    forwardElementInnerRef,
    isLogoRail,
    componentHeight,
    forwardRefElement,
    checkResize,
  } = useRailHorisontalDataContext();

  return (
    <div ref={forwardRefElement} className={cn(styles.rail, { [styles.logo]: isLogoRail })}>
      <div ref={forwardElementInnerRef}>
        <Title forwardHeaderRef={forwardHeaderRef} isLogoRail={isLogoRail} store={store} />
        <div
          ref={forwardWrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <RailCardsWrapper checkResize={checkResize} />
          <NextButtons />
        </div>
      </div>
    </div>
  );
};

export default RailView;
