import { useCallback } from 'react';

import { useParentalControlStore } from 'hooks';

export const useOnChangeAgeRange = (setAgeThreshold: (value: React.SetStateAction<string>) => void) => {
  const { parentalControlStore } = useParentalControlStore();

  const onChangeAgeRange = useCallback(async (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    await parentalControlStore.setAgeThresholdValue(target.value);
    setAgeThreshold(target.value);
  }, []);

  return { onChangeAgeRange };
};
