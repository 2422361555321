import React from 'react';

import { Program } from '@kaltura-ott/tvpil-shared';

import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';
import TimeProgressBar from 'components/widgets/ProgressBar/TimeProgressBar';

import { useAssetCardContext } from '../../AssetCardContext/AssetCardContext';

import styles from './AssetCardProgressBar.module.scss';

export const AssetCardProgressBar = () => {
  const {
    resolvedProps: { isHero, isContinueWatching, currentAsset, bookmark },
  } = useAssetCardContext();

  const {
    data: { startDate, endDate },
  } = currentAsset as Program;

  if (isHero && endDate && startDate) {
    return (
      <TimeProgressBar endDate={endDate} startDate={startDate}>
        {(percent: number) => {
          return <ProgressBar className={styles.progressBar} percent={percent} />;
        }}
      </TimeProgressBar>
    );
  }

  // added finishedWatching because sometimes backend sent wrong position that equals 0
  if (!isContinueWatching && !bookmark?.position && !bookmark?.finishedWatching) {
    // empty progress bar can be displayed only on CW rail
    return null;
  }

  if (bookmark) {
    const finishedWatchPercent = bookmark?.finishedWatching ? 100 : (bookmark.position / bookmark.duration) * 100;
    return <ProgressBar className={styles.progressBar} percent={finishedWatchPercent} />;
  }

  return null;
};
