import React, { useCallback, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import {
  CARD_PADDING,
  CUSTOM_RAIL_EVENTS,
  DEFAULT_ANIMATION_TIME,
  INITIAL_OFFSET,
  NEXT_BUTTON_WIDTH,
} from 'components/widgets/Rail/constants';
import { calculateItemWidth } from 'components/widgets/Rail/utils';

import { animate } from '../../utils';

interface Props {
  setRailCardsListRefScrollTo: (value: number) => void;
  items: any[];
  totalWidth: number;
  offset: React.MutableRefObject<number>;
  store: RailStoreV2;
  railCardsListRef: React.MutableRefObject<List | null>;
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
}

export const useArrowsClickHook = ({
  setRailCardsListRefScrollTo,
  items,
  totalWidth,
  offset,
  store,
  railCardsListRef,
  refElementInner,
}: Props) => {
  const isAnimationActive = useRef(false);
  const setIsAnimation = useCallback((value: boolean) => {
    isAnimationActive.current = value;
  }, []);

  return useCallback(
    (increase: boolean) => {
      if (isAnimationActive.current) {
        return;
      }
      const blockWidth = refElementInner?.current?.clientWidth || document.body.clientWidth;
      let newOffset = 0;
      // start rail from full card when navigating
      const arr = increase ? items : items.concat().reverse();
      let totalAssetsWidth = 0;

      for (let i = 0; i < arr.length; i += 1) {
        const width = calculateItemWidth(store, i);

        // i element overlow to right
        if (increase && totalAssetsWidth + width + INITIAL_OFFSET > offset.current + blockWidth) {
          newOffset = totalAssetsWidth - CARD_PADDING;
        } else if (!increase && totalAssetsWidth + width > totalWidth - offset.current) {
          newOffset = totalWidth - totalAssetsWidth - blockWidth + NEXT_BUTTON_WIDTH;

          // in some situations small part of space may stay, and we will have left arrow button
          if (newOffset <= NEXT_BUTTON_WIDTH) {
            newOffset = 0;
          }
        } else {
          totalAssetsWidth += width;
        }
      }

      if (newOffset < 0) {
        newOffset = 0;
      }

      let delta = newOffset - offset.current;
      if (newOffset + blockWidth > totalWidth) {
        newOffset = totalWidth - blockWidth;
        delta = totalWidth - offset.current - blockWidth;
      }

      const animationTime = (Math.abs(delta) / blockWidth) * DEFAULT_ANIMATION_TIME;

      animate(setIsAnimation, railCardsListRef, delta, animationTime, () => {
        setRailCardsListRefScrollTo(newOffset);

        if (refElementInner.current) {
          refElementInner.current.dispatchEvent(
            new CustomEvent(CUSTOM_RAIL_EVENTS.SCROLL, {
              detail: { newOffset, newBlockWidth: blockWidth, newTotalWidth: totalWidth },
            }),
          );
        }
      });
    },
    [isAnimationActive, totalWidth, items, offset.current],
  ); // eslint-disable-line
};
