import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Masonry } from 'masonic';
import { observer } from 'mobx-react';
import { Node } from 'types';

import { DataRailV2 } from '@kaltura-ott/tvpil-shared';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import Channel from 'components/pages/Settings/Favorites/Channel/Channel';
import { preloadChannelImage } from 'components/pages/Settings/Favorites/helper/preloadChannelImage';
import SettingsContentWrapper from 'components/pages/Settings/SettingsContentWrapper/SettingsContentWrapper';
import { findFavoritesStore } from 'components/pages/Settings/utils';
import RailSkeleton from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import { railObserver } from 'components/widgets/Rail/utils';
import { useOnClickOutside, useSettingsStore } from 'hooks';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils';

import {
  COLUMN,
  COLUMN_GUTTER,
  DEFAULT_HEIGHT,
  DEFAULT_HEIGHT_PLUS_MARGIN,
  DEFAULT_ITEMS_LENGTH,
  DEFAULT_MIN_WIDTH,
  DEFAULT_WIDTH,
  FAVORITES,
  FOUR_COLUMNS_RESOLUTION,
  HEADER_HEIGHT,
  MAX_GRID_WIDTH_FOR_DEFAULT_CARD_SIZE,
  MENU_HEIGHT,
  ROW_GUTTER,
  SETTINGS_TOP_PADDING,
} from './constants';

import styles from './Favorites.module.scss';

function Favorites() {
  const intl = useIntl();
  const { settingsStore } = useSettingsStore();
  const ref = useRef<HTMLDivElement | null>(null);
  const favoritesStore = findFavoritesStore(settingsStore.children)!;
  const channelList = favoritesStore.channelList.filter((item) => !item.data.adult) || [];
  const [isImagesLoading, setImagesLoading] = useState(false);
  const isSkeletonVisible = isImagesLoading || !favoritesStore.isReady;

  useEffect(() => {
    favoritesStore.init();

    return () => {
      favoritesStore.resetStore();
    };
  }, []);

  const countVisibleChannel =
    (Math.round(
      (window.innerHeight - MENU_HEIGHT - HEADER_HEIGHT - SETTINGS_TOP_PADDING) / DEFAULT_HEIGHT_PLUS_MARGIN,
    ) +
      1) *
    COLUMN;

  useEffect(() => {
    if (channelList.length) {
      const length = channelList.length > DEFAULT_ITEMS_LENGTH ? DEFAULT_ITEMS_LENGTH : channelList.length;
      preloadChannelImage(channelList.slice(0, length), setImagesLoading);
    }
  }, [channelList.length]);

  const handleSkeletonRef = useCallback((node: Node) => {
    if (node) {
      railObserver.observe(node);
    }
  }, []);

  useOnClickOutside(ref, (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.dataset.type === FAVORITES || target.closest('a')?.dataset.type === FAVORITES) {
      favoritesStore.init();
    }
  });

  return (
    <SettingsContentWrapper
      title={intl.formatMessage({
        id: 'Settings.Favorites.SettingsContentWrapper.title',
        defaultMessage: 'Favorite Channels',
      })}
      description={intl.formatMessage({
        id: 'Settings.Favorites.SettingsContentWrapper.description',
        defaultMessage: 'Add or remove channels to create your personal list of channels',
      })}
      className={styles.favoriteWrapper}
      forwardRef={ref}
    >
      {isSkeletonVisible ? (
        <LoaderWrapperForEvents
          hashKey={APPLICATION_SPINNER_EVENT_NAME.FAVORITES}
          component={
            <RailSkeleton
              className={styles.favorites}
              handleRef={handleSkeletonRef}
              isGrid
              items={countVisibleChannel}
              store={
                ({
                  ...favoritesStore,
                  itemDefaultWidth: window.innerWidth > FOUR_COLUMNS_RESOLUTION ? DEFAULT_MIN_WIDTH : DEFAULT_WIDTH,
                  railHeight: DEFAULT_HEIGHT,
                } as unknown) as DataRailV2
              }
            />
          }
        />
      ) : (
        <Masonry
          style={{
            maxWidth: window.innerWidth < FOUR_COLUMNS_RESOLUTION ? MAX_GRID_WIDTH_FOR_DEFAULT_CARD_SIZE : '100%',
          }}
          className={styles.grid}
          items={channelList}
          itemKey={(data) => data.data.id}
          columnGutter={COLUMN_GUTTER}
          rowGutter={ROW_GUTTER}
          overscanBy={5}
          render={Channel}
        />
      )}
    </SettingsContentWrapper>
  );
}

export default observer(Favorites);
