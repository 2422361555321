import { ERROR_ACTION_NAMES } from 'consts';

export const isAuthError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.LOGIN_AUTH || actionName === ERROR_ACTION_NAMES.LOGIN_JWT;
};

export const isLoginFormError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.LOGIN_OTT || actionName === ERROR_ACTION_NAMES.LOGIN_SSO;
};

export const isRecordingTypeError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.RECORDING_ADD || actionName === ERROR_ACTION_NAMES.RECORDING_REMOVE;
};

export const isAppInitError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.APP_INIT || actionName === ERROR_ACTION_NAMES.APP_REBOOT;
};

export const isDetailsInitError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.DETAILS_INIT;
};

export const isSelfServicesInitError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.SELFSERVICE_INIT;
};

export const isSubscriptionsInitError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.SUBSCRIPTIONS_INIT;
};

export const isEpgError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.EPG_INIT || actionName === ERROR_ACTION_NAMES.EPG_SET_VISIBLE;
};

export const isServicesSelectError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.SERVICES_SELECT;
};

export const isPlayoutV2InitError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.PLAYOUT_INIT;
};

export const isSearchInvokeError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.SEARCH_INVOKE;
};

export const isParentalPinError = (actionName = ''): boolean => {
  return actionName === ERROR_ACTION_NAMES.PARENTAL_PIN_UPDATE || actionName === ERROR_ACTION_NAMES.PURCHASE_PIN_UPDATE;
};
