import { useEffect } from 'react';

import { useAppStore } from 'hooks/common/useAppStore';
import customScriptService from 'services/customScriptService';

export const SCRIPT_ID = 'customGdpr';

export function useAppGDPRCustomScript() {
  const {
    appStore: { cookiePopupDetails },
  } = useAppStore();

  useEffect(() => {
    (async () => {
      const { loadExternalCookiePopup, externalPopupUrl } = cookiePopupDetails || {};
      if (loadExternalCookiePopup && externalPopupUrl) {
        await customScriptService.prepareScript(externalPopupUrl, SCRIPT_ID);
      }
    })();
  }, [cookiePopupDetails]);
}
