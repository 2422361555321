import { useEffect } from 'react';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useEpgStore } from 'hooks';
import { PerformanceEvents } from 'interface';

export function useGridPerformance() {
  const { epgStore } = useEpgStore();

  const performanceEventsContext = usePerformanceEventsContext();

  useEffect(() => {
    if (epgStore.isReadyTvChannel) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.epgReady });
    }
  }, [epgStore.isReadyTvChannel, performanceEventsContext]);

  useEffect(() => {
    return () => {
      performanceEventsContext.clearEvent(PerformanceEvents.epgReady);
    };
  }, [performanceEventsContext]);
}
