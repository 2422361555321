import React, { ReactNode } from 'react';
import cn from 'classnames';

import styles from './SettingsContentWrapper.module.scss';

interface Props {
  title: string;
  description?: string | ReactNode;
  className?: string;
  isSkeletonView?: boolean;
  children: React.ReactNode;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
}

function SettingsContentWrapper({ title, description, children, className, isSkeletonView, forwardRef }: Props) {
  return (
    <section ref={forwardRef} className={cn(styles.wrapper, className)}>
      {!isSkeletonView && (
        <header>
          <h3>{title}</h3>
          {description && <p>{description}</p>}
        </header>
      )}

      {children}
    </section>
  );
}

export default SettingsContentWrapper;
