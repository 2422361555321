import React from 'react';
import { FormattedMessage } from 'react-intl';

import ServiceList from 'components/presenters/ServiceList/ServiceList';

import styles from './ServiceView.module.scss';

const ServiceView: React.FC = () => {
  return (
    <>
      <div className={styles.heading}>
        <FormattedMessage id='LoginServices.title' defaultMessage='Service ID/Tariff activation' tagName='h1' />
        <FormattedMessage
          id='LoginServices.text'
          defaultMessage='Please choose the service ID/Tariff you chose to activate'
          tagName='p'
        />
      </div>
      <ServiceList />
    </>
  );
};

export default ServiceView;
