import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AppApiError } from '@kaltura-ott/tvpil-shared';

import { ReactComponent as ErrorIcon } from 'assets/images/icon-error.svg';
import routes from 'routesPaths';
import { getLoginErrorTranslationId } from 'utils';

import styles from './ErrorMessage.module.scss';

export const COMPONENT_NAME = 'Login.LoginForm.ErrorMessage';

function ErrorMessage({ error }: { error: AppApiError }) {
  const { actionName, code, message } = error;
  const { translationId } = getLoginErrorTranslationId(actionName, code);
  const isWrongCredentials = !!code && (+code === 401 || +code === 1011);

  return (
    <div className={styles.errorBlock}>
      <p>
        <ErrorIcon className={styles.errorIcon} />
        <FormattedMessage defaultMessage={message} id={translationId} />
        {isWrongCredentials && (
          <>
            {` `}
            <FormattedMessage defaultMessage='Please try again or' id={`${COMPONENT_NAME}.retry`} />
            {` `}
            <Link to={routes.forgotPassword.path}>
              <FormattedMessage defaultMessage='reset your password' id={`${COMPONENT_NAME}.resetPassword`} />
            </Link>
          </>
        )}
      </p>
    </div>
  );
}

export default ErrorMessage;
