import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useAppStore, useDetectLocation, useDeviceStore, useLoginStore, useRootStore } from 'hooks';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

const MIN_SYMBOLS_TO_START_SEARCH = 3;

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const { appStore } = useAppStore();
  const rootStore = useRootStore();
  const { deviceLimitExceeded } = useDeviceStore();
  const { isAnonymous } = useLoginStore();
  const { isSearchPage } = useDetectLocation();
  if (isAnonymous && !globalStorage.isLogoutInProgress) {
    appStore?.saveApplicationRoute(location.pathname);
    globalStorage.setIsOnBoarding(true);

    return <Navigate to={routes.login.path} />;
  }

  if (deviceLimitExceeded && location.pathname !== routes.household.path) {
    return <Navigate to={routes.household.path} />;
  }

  if (rootStore?.search && isSearchPage && location?.search) {
    const urlSearchParam = location.search;
    const searchParam = urlSearchParam ? decodeURI(urlSearchParam.split('?name=')[1]) : '';

    if (searchParam.length < MIN_SYMBOLS_TO_START_SEARCH) {
      return <Navigate to={routes.home.path} />;
    }
  }

  return <Outlet />;
};

export default observer(PrivateRoute);
