import { RefObject, useCallback } from 'react';

import { applyStyle } from 'utils';

interface Props {
  headerRef: RefObject<HTMLElement>;
  refElementInner: RefObject<HTMLElement>;
  componentHeight: number;
}

export const useOnHoverHook = ({ headerRef, refElementInner, componentHeight }: Props) =>
  useCallback(
    (isHover: boolean, value: number = 0) => {
      if (headerRef.current) {
        applyStyle(headerRef.current, 'z-index', isHover ? '0' : '1');
      }

      if (refElementInner.current) {
        applyStyle(
          refElementInner.current,
          '--increase-height',
          isHover ? `${value + componentHeight}px` : `${componentHeight}px`,
        );
      }
    },
    [headerRef.current], // eslint-disable-line
  );
