import { CacheConfig, CachingServiceImpl } from '@kaltura-ott/tvpil';

export class LocalStorageService implements CachingServiceImpl {
  prefix = '';

  constructor(config: CacheConfig) {
    this.prefix = config.prefix;
  }

  getItem(key: string) {
    const systemKey = this.prefix + key;
    const itemStr = localStorage.getItem(systemKey);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(systemKey);
      return null;
    }
    return item.value;
  }

  setItem(key: string, data: unknown, ttl?: number) {
    const TTL = new Date().setFullYear(2120);

    const systemKey = this.prefix + key;
    const now = new Date();
    const item = {
      value: data,
      expiry: now.getTime() + (ttl || TTL),
    };
    try {
      localStorage.setItem(systemKey, JSON.stringify(item));
    } catch (e) {
      console.warn(e);
    }
  }

  removeItem(key: string) {
    const systemKey = this.prefix + key;
    localStorage.removeItem(systemKey);
  }

  clear() {
    localStorage.clear();
  }

  removeAllBySubPrefix(subPrefix: string) {
    const prefix = `${this.prefix}${subPrefix}`;
    Object.keys(localStorage)
      .filter((key) => key.indexOf(prefix) === 0)
      .forEach((key) => localStorage.removeItem(key));
  }
}

const ls = new LocalStorageService({ prefix: '' });

export default ls;
