import { useCallback } from 'react';

import { Form, InputError } from 'hooks';
import { validateEmail } from 'utils';

interface Props {
  form: Form;
  setForm: (updatedForm: Form) => void;
  defaultInputError: InputError;
}

export const useOnValidateOnBlur = ({ form, setForm, defaultInputError }: Props) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const isValid = validateEmail(name, value);
      const updatedForm = {
        ...form,
        [name]: {
          ...form[name],
          isFailedValidation: !isValid,
          errorMessage: defaultInputError[name],
        },
      };
      setForm(updatedForm);
    },
    [form],
  );
};
