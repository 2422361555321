import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import routes from 'routesPaths';
import localStorageService from 'services/localStorageService';

import { generateRandomString, pkceChallengeFromVerifier } from '../../helper';

export async function login(e: MouseEvent) {
  e.preventDefault();

  const {
    oauthBaseUrl,
    oAuthResponseType,
    oAuthClientId,
    oAuthCodeChallengeMethod,
    oAuthScope,
  } = featureEnabler.variation(FEATURES.AUTH_PARAMS, {});

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const codeVerifier = generateRandomString();
  localStorageService.setItem('codeVerifier', codeVerifier);

  // Hash and base64-urlencode the secret to use as the challenge
  const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);

  const { origin } = window.location;
  const authUrl = `${origin}${routes.auth.path}`;
  const newUrl = new URL(oauthBaseUrl);
  const { searchParams } = newUrl;

  searchParams.set('response_type', oAuthResponseType);
  searchParams.set('client_id', oAuthClientId);
  searchParams.set('redirect_uri', authUrl);
  searchParams.set('code_challenge', codeChallenge);
  searchParams.set('code_challenge_method', oAuthCodeChallengeMethod);
  searchParams.set('scope', oAuthScope);

  window.location.href = newUrl.href;
}
