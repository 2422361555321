import { useCallback, useRef } from 'react';

import { useHandleRef } from 'components/widgets/CardV2/hooks/useHandleRef/useHandleRef';

interface HeroCardRef {
  ref: React.RefObject<HTMLDivElement | null>;
  handleRef: (value: HTMLDivElement | null) => void;
}

export function useHeroCardRef(itemWidth: number, imageHeight: number): HeroCardRef {
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((value: HTMLDivElement | null) => {
    ref.current = value;
  }, []);
  const handleRef = useHandleRef({ setRef, itemWidth, imageHeight });

  return { ref, handleRef };
}
