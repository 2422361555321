import { getTextWidth } from 'utils';

import { ellipsis, textStyles } from './constants';

export function checkTextIsClipped(width: number, text: string, type: string) {
  const { font, padding } = textStyles[type];
  const measuredText = type === 'title' ? `${text?.slice(0, 1)}${ellipsis}` : text;
  const isClipped = width - padding < getTextWidth(measuredText, font);
  return isClipped;
}
