import { useCallback } from 'react';

import { EpgSettings } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useRootStore } from 'hooks';
import { applyStyle, applyStyleHexToHSL, applyStyles, isYettelEnvGroup } from 'utils';

type BrandingType = EpgSettings['branding'];

export const useHandleRef = (branding?: BrandingType) => {
  const {
    appStore: {
      params: {
        dmsSetting: { partnerId },
      },
    },
  } = useAppStore();
  const rootStore = useRootStore();
  return useCallback((node: HTMLElement) => {
    if (!node || !branding) {
      return;
    }

    const textFocusColor = isYettelEnvGroup(partnerId) ? rootStore.uiDesignElements.btnTextColor : branding.textColor;

    if (branding) {
      applyStyles(node, branding, false, true);
      applyStyleHexToHSL(node, branding.backgroundColor);
      applyStyle(node, '--text-focus-color', textFocusColor!);
    }
  }, []); // eslint-disable-line
};
