import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DeviceApplicationStartEvent } from '@kaltura-ott/tvpil-analytics';

import { IN_APP_LINK_HOME } from 'components/widgets/Menu/constans';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useAppStore } from 'hooks/common/useAppStore';
import { useLoginStore } from 'hooks/common/useLoginStore';
import { PerformanceEvents } from 'interface';
import routes from 'routesPaths';
import networkService from 'services/networkService';

export const APP_START = 'application-start';

export function useAppEvents() {
  const location = useLocation();
  const performanceEventsContext = usePerformanceEventsContext();
  const { isAnonymous } = useLoginStore();
  const {
    appStore,
    appStore: { dms },
  } = useAppStore();

  function handleAppStartEvent() {
    if (appStore.dms.analytics?.events?.allowDeviceActionEvents) {
      const isAppAlreadyStarted = Boolean(sessionStorage.getItem(APP_START));

      if (!isAppAlreadyStarted) {
        appStore.kuxAnalytics.send(new DeviceApplicationStartEvent());
        sessionStorage.setItem(APP_START, 'true');
        return;
      }

      // do not send event on redirect from 3d part login service
      if (location.pathname !== routes.auth.path) {
        // pass additional param on page refresh
        appStore.kuxAnalytics.send(new DeviceApplicationStartEvent(true));
      }
    }
  }

  async function handlePerformanceEvents() {
    const homePageReadyEvent = await performanceEventsContext.getEvent(PerformanceEvents.homePageReady);
    const isAppStartEvent =
      !homePageReadyEvent &&
      [routes.auth.path, routes.home.path, routes.page.link(IN_APP_LINK_HOME)].includes(location.pathname);

    if (isAppStartEvent) {
      performanceEventsContext.setStartTime(PerformanceEvents.appStart);
    }
  }

  useEffect(() => {
    if (!dms) return;

    handlePerformanceEvents();
    networkService.init();
    handleAppStartEvent();
  }, [dms]);

  useEffect(() => {
    if (isAnonymous && location.pathname === routes.auth.path) {
      performanceEventsContext.setStartTime(PerformanceEvents.loginSuccesses);
    }
  }, [location.pathname]);
}
