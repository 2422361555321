import { KuxDms } from '@kaltura-ott/tvpil';
import { appStore, getCookie } from '@kaltura-ott/tvpil-shared';

export const onDmsReady = (dms: KuxDms) => {
  const { jwt } = dms || {};

  if (jwt && jwt.isJWTSeamlessLoginEnabled) {
    appStore.setJwtCookie({
      jwt: getCookie(jwt.jwtKeyName || '') || '',
      refreshToken: getCookie(jwt.jwtRefreshTokenKeyName || '') || '',
    });
  }
};
