import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import playIcon from 'assets/images/player/play.svg';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useIsNpvr } from 'hooks';
import { ActionButtonTypesEnum } from 'interface';

import { useResolvePathToWatch } from '../../../../utils';
import { useHandleWatchResumeClick } from '../../handlers';
import { getMessageForWatchResumeButton } from '../../helpers/getMessageForWatchResumeButton';

export interface WatchResumeButtonProps {
  asset: ViewableEntitiesType;
  className: string;
  seasonEpisodeTitle: string;
  programNotAvailable: boolean;
  isFinishWatchCondition: boolean;
}

export function WatchResumeButton({
  asset,
  className,
  seasonEpisodeTitle,
  programNotAvailable,
  isFinishWatchCondition,
}: WatchResumeButtonProps) {
  const isNpvr = useIsNpvr();
  const performanceContext = usePerformanceEventsContext();
  const { navigate, modalContext, parentalPinStore, closeOverlay } = useVideoContext();
  const { enableCatchUp, isVodSingle } = detailsStoreDataResolver(asset);
  const isWatchResumeDisabled = !isVodSingle && (!enableCatchUp || (!isNpvr && programNotAvailable));
  const watchResumeMessage = getMessageForWatchResumeButton(isFinishWatchCondition);

  const handleWatchResumeClick = useHandleWatchResumeClick({
    asset,
    performanceContext,
    closeOverlay,
    navigate,
    modalContext,
    parentalPinStore,
  });

  const resolvePathToWatch = useResolvePathToWatch(asset);

  return (
    <ActionButton
      data-disabled={isWatchResumeDisabled ? 'disabled' : null}
      className={className}
      icon={<SVG data-target='playIconPlayer' src={playIcon} />}
      isLarge
      onClick={handleWatchResumeClick}
      type={ActionButtonTypesEnum.primary}
      disabled={isWatchResumeDisabled}
      {...resolvePathToWatch}
    >
      <FormattedMessage defaultMessage={watchResumeMessage.defaultMessage} id={watchResumeMessage.id} />{' '}
      {seasonEpisodeTitle}
    </ActionButton>
  );
}
