import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import OnBoardingLayout from 'components/presenters/layouts/onboarding/OnBoardingLayout';
import Loader from 'components/presenters/Loader/Loader';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useAppStore, useDeviceStore, useLoginStore, useRootStore } from 'hooks';
import { PerformanceEvents } from 'interface';
import routes from 'routesPaths';

import styles from './Auth.module.scss';

function Auth() {
  const { navigate } = useStableReactRouter();
  const performanceEventsContext = usePerformanceEventsContext();
  const { deviceLimitExceeded } = useDeviceStore();
  const { appStore } = useAppStore();
  const rootStore = useRootStore();
  const { isAnonymous, userLogin } = useLoginStore();

  useEffect(() => {
    (async () => {
      if (isAnonymous === false) return;

      const { host, protocol } = window.location;
      const redirectUri = encodeURIComponent(`${protocol}//${host}${routes.auth.path}`);

      await userLogin({
        oauth: {
          code: new URL(window.location.href).searchParams.get('code') || '',
          redirectUri,
        },
      });
      performanceEventsContext.setEndTime({ value: PerformanceEvents.loginSuccesses });
      navigate(appStore?.applicationRoute || routes.home.path);
    })();
  }, [appStore, performanceEventsContext, isAnonymous, userLogin]);

  useEffect(() => {
    const { uiDesignElements } = rootStore!;

    if (uiDesignElements) {
      document.body.style.backgroundColor = uiDesignElements.backgroundColor;
    }

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    if (!isAnonymous) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.loginSuccesses });
    }
  }, [isAnonymous, performanceEventsContext]);

  if (deviceLimitExceeded) {
    return <Navigate to={routes.household.path} replace />;
  }

  return (
    <OnBoardingLayout className={styles.wrapper}>
      <LoaderWrapperForEvents hashKey='auth' component={<Loader />} />
    </OnBoardingLayout>
  );
}

export default observer(Auth);
