import { MutableRefObject, SetStateAction, useEffect } from 'react';

export const useNextButtonsDuringResize = ({
  width,
  offset,
  scrolling,
  prevWidth,
  totalWidth,
  refElementInner,
  setState,
}: {
  width: number;
  totalWidth: number;
  prevWidth: MutableRefObject<number>;
  scrolling: MutableRefObject<boolean>;
  offset: MutableRefObject<number> | undefined;
  refElementInner: MutableRefObject<HTMLDivElement | null>;
  setState: (
    value: SetStateAction<{
      isLeftNextButton: boolean;
      isRightNextButton: boolean;
    }>,
  ) => void;
}) => {
  useEffect(() => {
    if (width !== prevWidth.current && !scrolling.current) {
      setState({
        isLeftNextButton: offset?.current! > 0,
        isRightNextButton:
          offset?.current! + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
      });
    }
  }, [width]);
};
