import { addDays, endOfDay, millisecondsInHour, startOfDay, subDays } from 'date-fns';

import { config } from '../../config';
import { formatPixelsToTimestamp } from '../formatPixelsToTimestamp/formatPixelsToTimestamp';

export function calculateEpgParams(maxEpgDaysBackward: number, maxEpgDaysForward: number = 7) {
  // equal maxEpgDaysBackward
  const epgStartTimestamp = startOfDay(subDays(new Date(), maxEpgDaysBackward)).valueOf();
  // lower than maxEpgDaysForward
  const epgEndTimestamp = endOfDay(addDays(new Date(), maxEpgDaysForward - 1)).valueOf();
  const epgStartEndDifference = epgEndTimestamp - epgStartTimestamp + 1;
  const epgWidth = Math.round((epgStartEndDifference / millisecondsInHour) * config.hourScale);
  const ratio = Math.round(epgStartEndDifference / epgWidth);
  const channelWidth = formatPixelsToTimestamp(ratio, config.channelWidth);

  return { channelWidth, epgStartTimestamp, epgEndTimestamp, epgStartEndDifference, epgWidth, ratio };
}
