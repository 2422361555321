import { ORIENTATION } from 'consts';

export const getDefaultImagePath = (isLandscape = true) => {
  const orientation = isLandscape ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT;
  return `assets/web/common/default-image-${orientation}.png`;
};

export const getMDIconPath = (mdIcon: string) => `assets/web/mosaic/${mdIcon}.svg`;

export const getRatingIconPath = (ratingProvider: string) => `assets/web/rating-provider/${ratingProvider}`;

export const getPurchaseIcon = (purchaseIcon: string) => `assets/web/purchase/${purchaseIcon}.svg`;

export const getSearchIcon = (searchIcon: string) => `assets/web/search/${searchIcon}.svg`;

export const getSearchHistoryIcon = (searchHistoryIcon: string) => `assets/web/search/${searchHistoryIcon}.svg`;

export const getSingleRecordIcon = (singleRecord: string) => `assets/web/recordings/${singleRecord}.svg`;

export const getSeriesRecordIcon = (seriesRecord: string) => `assets/web/recordings/${seriesRecord}.svg`;

export const getFavoriteChannelIcon = (favoriteIcon: string) => `assets/web/favorite/${favoriteIcon}.svg`;

export const getRecordIcon = (recordIcon: string) => `assets/web/recordings/${recordIcon}.svg`;

export const getConcurrencyIcon = (icon: string) => `assets/web/concurrency/${icon}.svg`;

export const getWarningIcon = (icon: string) => `assets/web/warning/${icon}.svg`;

export const getVoiceIcon = (icon: string) => `assets/web/voice/${icon}.svg`;

export const getSubtitlesIcon = (icon: string) => `assets/web/subtitles/${icon}.svg`;

export const getLockIcon = (icon: string) => `assets/web/common/${icon}.svg`;
