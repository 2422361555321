import React, { useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { AssetPresentationRatioEnum, getWidthByHeight } from '@kaltura-ott/tvpil';

import { DEFAULT_HEIGHT } from 'consts';

import { CARD_PADDING } from '../../constants';
import { useRailContext } from '../RailProviderV2/RailProviderV2';
import { OnItemsRendered } from '../RailProviderV2/types';
import VirtualizedCardItem from '../VirtualizedCardItem/VirtualizedCardItem';

import { ChildProps, Item } from './types';

import styles from './VirtualizedContainer.module.scss';

export type Props<T extends Item> = {
  children: ({ imageHeight, itemWidth, item }: ChildProps<T>) => React.ReactNode;
  width: number;
  height: number;
  onItemsRendered?: OnItemsRendered;
};

/**
 * A virtualized container component designed for efficient rendering of large lists.
 *
 * @typeparam T - The type of items the container will render.
 * @param props - The properties passed to the component, including dimensions and the render prop for children.
 * @returns The React element representing the virtualized list.
 */
const VirtualizedContainer = <T extends Item>(props: Props<T>) => {
  const {
    railCardsListRef,
    settings,
    data: { items },
    savedOffset,
  } = useRailContext();

  const outerRef = useRef<HTMLDivElement | null>(null);

  const { children, width, height, onItemsRendered } = props;
  const imageHeight = settings.imageHeight || DEFAULT_HEIGHT;
  const { imageWidth } = settings;
  // get width by height for default landcape ratio if imageWidth is undefined
  const fallbackItemWidth = getWidthByHeight(imageHeight, AssetPresentationRatioEnum['16x9']);

  return (
    <List
      ref={railCardsListRef}
      className={styles.itemsWrapper}
      height={height}
      initialScrollOffset={savedOffset}
      innerElementType='ul'
      itemCount={items.length}
      itemData={{ children, items, imageHeight }}
      itemSize={() => (imageWidth ? imageWidth + CARD_PADDING : fallbackItemWidth + CARD_PADDING || 0)}
      layout='horizontal'
      onItemsRendered={onItemsRendered}
      outerRef={outerRef}
      overscanCount={10}
      width={width}
    >
      {VirtualizedCardItem}
    </List>
  );
};

export default VirtualizedContainer;
