import { useMemo } from 'react';

import { RAIL_TYPE } from 'components/widgets/RailV2/constants';
import { LAST_RAIL_X_POSITION } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';

export function useRailLastXPosition(railId: number, railType: string) {
  const navigationType = useStableReactRouter();

  return useMemo(() => {
    let railPosition = 0;
    const lastRailXPosition = sessionStorage.getItem(LAST_RAIL_X_POSITION);

    if (lastRailXPosition && railType !== RAIL_TYPE.CW && navigationType !== 'PUSH') {
      const { id, position } = JSON.parse(lastRailXPosition);

      if (railId === id && railType !== RAIL_TYPE.HERO) {
        railPosition = position;
      }
    }

    return railPosition;
  }, [railId, railType]);
}
