import React from 'react';
import { observer } from 'mobx-react';

import { VideoPlayoutProvider } from './components/VideoPlayoutProvider/VideoPlayoutProvider';
import VideoPlayoutResolveRendering from './components/VideoPlayoutResolveRendering/VideoPlayoutResolveRendering';
import { useVideoPlayoutCombiner } from './hooks/useVideoPlayoutCombiner/useVideoPlayoutCombiner';

import styles from './VideoPlayout.module.scss';

const VideoPlayout: React.FC = () => {
  const {
    muted,
    loaded,
    program,
    programTitle,
    programName,
    playableAsset,
    currentAsset,
    logoImages,
    timeRange,
    isLinear,
    isPlayerCondition,
    isContentRestricted,
    isContentGeoBlocking,
    isImagesCondition,
    isMosaicProgram,
    isContentBlockedAsAdult,
    isNoProgramCondition,
    isImagesErrorCondition,
    isLinearWithoutCurrentProgram,
    muteHandler,
    watchNowHandler,
    handleClickInfoButton,
    setChannelLogoErrorForward,
  } = useVideoPlayoutCombiner();

  return (
    <div className={styles.videoPlayout}>
      <VideoPlayoutProvider
        muted={muted}
        asset={currentAsset}
        playableAssetId={playableAsset?.id}
        loaded={loaded}
        isContentRestricted={isContentRestricted}
        isContentGeoBlocking={isContentGeoBlocking}
        isPlayerCondition={isPlayerCondition}
        isContentBlockedAsAdult={isContentBlockedAsAdult}
        isLinearWithoutCurrentProgram={isLinearWithoutCurrentProgram}
        isNoProgramCondition={isNoProgramCondition}
        isLinear={isLinear}
        program={program}
        programTitle={programTitle}
        logoImages={logoImages}
        mosaicSubstreamTitle={program?.data?.title}
        programName={programName}
        timeRange={timeRange}
        isImagesCondition={isImagesCondition}
        isImagesErrorCondition={isImagesErrorCondition}
        isMosaicProgram={isMosaicProgram}
        watchNowHandler={watchNowHandler}
        setChannelLogoError={setChannelLogoErrorForward}
        muteHandler={muteHandler}
        handleInfoButton={handleClickInfoButton}
      >
        <VideoPlayoutResolveRendering />
      </VideoPlayoutProvider>
    </div>
  );
};

export default observer(VideoPlayout);
