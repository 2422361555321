import { KalturaRecordingStatus, KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';
import { Record, SeriesRecord } from '@kaltura-ott/tvpil-shared';

export const getCorrectSeriesId = (type: KalturaRecordingType, seriesId?: any) => {
  return type === KalturaRecordingType.series ? seriesId : undefined;
};

export const isMultipleRecordIconCheck = (seriesRecord: SeriesRecord, isDetailsSeries: boolean) => {
  return !!seriesRecord || isDetailsSeries;
};

export const isActiveRecordIconCheck = (isRecord: boolean, isSeriesRecord: boolean, isDetailsSeries: boolean) => {
  return ((isRecord || isSeriesRecord) && !isDetailsSeries) || (isDetailsSeries && isSeriesRecord);
};

export const isSingleRecord = (record: Record) => !!record && !!record?.isSingleRecord;

export const isSeriesRecord = (record: Record) => !!record && !!record?.isSeriesRecord;

const isRecordInStatus = (record: Record, statuses: KalturaRecordingStatus[]) => {
  return statuses.includes(record?.data.status!);
};
export const isRecordedAsSeries = (record: Record, seriesRecord: SeriesRecord) => {
  return (
    (isSeriesRecord(record) || isSingleRecord(record)) &&
    !!seriesRecord &&
    isRecordInStatus(record, [KalturaRecordingStatus.recorded])
  );
};

export const isNotRecorded = (record: Record, seriesRecord: SeriesRecord) => {
  return !record && !seriesRecord;
};

export const isOnlyCancelSeriesCondition = (record: Record, seriesRecord: SeriesRecord, isDetailsSeries: boolean) => {
  return (
    isDetailsSeries ||
    (!record && !!seriesRecord) ||
    (isSingleRecord(record) &&
      !!seriesRecord &&
      isRecordInStatus(record, [KalturaRecordingStatus.recording, KalturaRecordingStatus.scheduled]))
  );
};
