import React from 'react';
import { observer } from 'mobx-react';

import { Channel, RhinoEPGProgram } from '@kaltura-ott/tvpil-shared';

import ProgramCard from '../ProgramCard/ProgramCard';

import { useVisiblePrograms } from './hooks';

interface Props {
  channel: Channel;
}

function ProgramsList({ channel }: Props) {
  const visiblePrograms = useVisiblePrograms(channel);

  return (
    <>
      {visiblePrograms.map((program: RhinoEPGProgram) => (
        <ProgramCard key={program.id} program={program} />
      ))}
    </>
  );
}

export default observer(ProgramsList);
