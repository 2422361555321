import { useMemo } from 'react';
import { addMinutes, isAfter, isBefore } from 'date-fns';

import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { useEpgStore } from 'hooks';

function useNowLineCombiner() {
  const {
    epgStore: { isReadyTvChannel },
  } = useEpgStore();
  const { currentTimestamp, viewTimestamp, screenWidthInMinutes } = epgLocalTimeStampStorage;
  const isNowLine =
    isBefore(currentTimestamp, addMinutes(viewTimestamp, screenWidthInMinutes)) &&
    isAfter(currentTimestamp, viewTimestamp) &&
    isReadyTvChannel;

  return useMemo(() => ({ isNowLine }), [viewTimestamp, currentTimestamp, isReadyTvChannel]);
}

export { useNowLineCombiner };
