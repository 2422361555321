import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useToastMessageContext } from 'context/toast/toast';
import { useAppStore } from 'hooks';
import routes from 'routesPaths';

import { IN_APP_LINK_HOME } from '../../constans';

import styles from './Logo.module.scss';

const Logo: React.FC<{ className?: string; image?: string; path?: string }> = ({ className, image, path = '/' }) => {
  const { appStore } = useAppStore();
  const toastMessageContext = useToastMessageContext();
  const { navigate } = useStableReactRouter();

  const handleClick = useCallback(
    async (e: React.SyntheticEvent) => {
      if ([routes.home.path, routes.page.link(IN_APP_LINK_HOME)].includes(path)) {
        e.preventDefault();
        toastMessageContext.clearToast();
        navigate(path);
        await appStore.rebootApp(true);
      }
    },
    [navigate, path, toastMessageContext],
  );

  return (
    <NavLink className={cn(styles.logo, className)} onClick={handleClick} to={path} data-target='logo'>
      <img alt='Logo' src={image} />
    </NavLink>
  );
};

export default React.memo(Logo);
