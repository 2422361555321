import React from 'react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getImageByHeightV2 } from '@kaltura-ott/tvpil-shared';

import { LOGO_HEIGHT } from 'components/pages/VideoDetails/constants';

import { useVideoPlayoutContext } from '../../../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from '../../Description.module.scss';

const NoProgramInfo = () => {
  const { asset, isImagesCondition, setChannelLogoError } = useVideoPlayoutContext();

  return (
    <>
      {asset.data.title && <h5 className={styles.title}>{asset.data.title}</h5>}
      {isImagesCondition && (
        <img
          alt='logo'
          className={styles.logo}
          onError={() => setChannelLogoError(true)}
          src={getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['16x9'], asset.data.images).url}
        />
      )}
    </>
  );
};

export default NoProgramInfo;
