import React from 'react';
import { observer } from 'mobx-react';

import { MenuContextProvider } from 'context/menuContext/menuContext';

import Menu from './components/Menu';
import { useMenuWrapperCombiner } from './hooks/useMenuWrapperCombiner/useMenuWrapperCombiner';

import styles from './Menu.module.scss';

const MenuWrapper = () => {
  const { store, isNotFound, isOnBoarding, isHouseHold, handleRef } = useMenuWrapperCombiner();

  if (!store || isHouseHold) {
    return null;
  }

  return (
    <header id='header' ref={handleRef} className={styles.menu}>
      <MenuContextProvider isOnBoarding={isOnBoarding} isNotFound={isNotFound} store={store}>
        <Menu />
      </MenuContextProvider>
    </header>
  );
};

export default observer(MenuWrapper);
