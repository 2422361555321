import { useRef } from 'react';

import { useMouseDownHandler, useMouseMoveHandler, useMouseUpHandler } from '../../handlers';
import { useGenerateDays } from '../useGenerateDays/useGenerateDays';
import { useScrollToCenter } from '../useScrollToCenter/useScrollToCenter';

function useDaysHooksCombiner() {
  const blockRef = useRef<HTMLDivElement | null>(null);
  const drag = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const days = useGenerateDays();
  const onMouseDownHandler = useMouseDownHandler({ drag, blockRef, startX, scrollLeft });
  const onMouseMoveHandler = useMouseMoveHandler({ drag, blockRef, startX, scrollLeft });
  const onMouseUpHandler = useMouseUpHandler(drag);

  useScrollToCenter(blockRef);

  return {
    days,
    blockRef,
    onMouseDownHandler,
    onMouseMoveHandler,
    onMouseUpHandler,
  } as any;
}

export { useDaysHooksCombiner };
