import { useCallback } from 'react';
import { EpgParamsType } from 'types';

import { Y_DRAG_MULTIPLIER } from 'components/pages/Epg/constans';
import { moveAlongX } from 'components/pages/Epg/helpers';
import { epgLocalDrugStorage, epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { formatPixelsToTimestamp } from 'components/pages/Epg/utils';
import { LAST_DRAG_X_POSITION } from 'consts';

import { handleDragDelta } from '../../../../utils/handleDragDelta';

interface Props {
  epgParams: EpgParamsType;
  forwardGridMovement: React.MutableRefObject<HTMLDivElement | null>;
}

export const useOnHandleDragOver = ({ epgParams, forwardGridMovement }: Props) => {
  const { xStart, yStart } = epgLocalDrugStorage.dragPosition;

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (['clicked', 'dragging'].includes(epgLocalDrugStorage.dragStatus) && yStart && xStart) {
        const { clientX, clientY } = e;

        if (handleDragDelta(e)) return;

        const step = formatPixelsToTimestamp(epgParams.ratio, xStart - clientX);

        moveAlongX(step, epgParams);

        window.scrollTo(0, document.documentElement.scrollTop + (yStart - clientY) * Y_DRAG_MULTIPLIER);
        epgLocalDrugStorage.setDragState({ xStart: clientX, yStart: clientY }, { status: 'dragging' });
      }

      if (['end'].includes(epgLocalDrugStorage.dragStatus) && yStart && xStart) {
        sessionStorage.setItem(LAST_DRAG_X_POSITION, epgLocalTimeStampStorage.viewTimestamp.toString());
        if (forwardGridMovement.current) {
          forwardGridMovement.current.style.zIndex = '0';
        }
      }
    },
    [xStart, yStart, epgLocalDrugStorage.dragStatus],
  );
};
