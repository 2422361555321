import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useTariffPlansData } from 'hooks';

import CardWithActionButton from '../../CardWithActionButton/CardWithActionButton';
import { COMPONENT_NAME } from '../constants';

export default function TariffPlans() {
  const { externalPortalUrl, plans, buttonParamsText } = useTariffPlansData();

  return (
    <>
      <FormattedMessage defaultMessage='Current plan' id={`${COMPONENT_NAME}.currentPlanTitle`} tagName='h3' />
      {plans.map(({ packageData }: any) => (
        <CardWithActionButton
          key={packageData.id}
          buttonParams={{
            text: buttonParamsText,
            link: externalPortalUrl,
            blank: true,
          }}
          name={packageData.title}
        />
      ))}
    </>
  );
}
