import React from 'react';
import { observer } from 'mobx-react';

import { EpgContextProvider } from 'context/epgContext/epgContext';

import EpgInner from './components/EpgInner/EpgInner';
import { useEpgHooksCombiner } from './hooks';
import { useEpgHooksCombinerOutputProps } from './interface';

function Epg() {
  const {
    setUserActive,
    forwardGridMovement,
    setFirstVisibleIndex,
    handleRef,
    nowLineRef,
    epgParams,
    gridStartYPosition,
    userActive,
    refTime,
    ref,
  } = useEpgHooksCombiner() as useEpgHooksCombinerOutputProps;

  return (
    <EpgContextProvider
      epgRef={ref}
      nowLineRef={nowLineRef}
      forwardGridMovement={forwardGridMovement}
      epgParams={epgParams}
      refTime={refTime}
      userActive={userActive}
      gridStartYPosition={gridStartYPosition}
      handleRef={handleRef}
      setUserActive={setUserActive}
      setFirstVisibleIndex={setFirstVisibleIndex}
    >
      <EpgInner />
    </EpgContextProvider>
  );
}

export default observer(Epg);
