import { matchPath } from 'react-router-dom';

import { IN_APP_LINK_HOME } from 'components/widgets/Menu/constans';
import routes from 'routesPaths';

export function usePageId() {
  let pageId;
  const { pathname } = window.location;
  const path = pathname.split(routes.details.commonPath)[0];
  const routeMatchPage = matchPath(routes.page.path, path);
  const routeMatchHome = matchPath(routes.home.path, path);
  const routeMatchGrid = matchPath(routes.grid.path, path);

  if (routeMatchPage) {
    pageId = routeMatchPage.params.id;
  }

  if (routeMatchHome) {
    pageId = IN_APP_LINK_HOME;
  }

  if (routeMatchGrid) {
    pageId = routeMatchGrid.params.id;
  }

  return pageId;
}
