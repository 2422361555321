import React from 'react';
import cn from 'classnames';

import { SpecialWidgetTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { CollectionRailStoreV2, DataRailV2 } from '@kaltura-ott/tvpil-shared';

import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from 'consts';

import styles from './RailSkeleton.module.scss';

interface Props<A extends DataRailV2 = DataRailV2> {
  store: A;
  isGrid?: boolean;
  items: number;
  handleRef?: (node: HTMLDivElement) => void;
  className?: string;
}

function RailSkeleton({ store, isGrid, items, handleRef, className }: Props) {
  const { railHeight, itemDefaultWidth, subtype } = store;
  const width = Math.round(itemDefaultWidth) || DEFAULT_WIDTH;
  const heigh = railHeight || DEFAULT_HEIGHT;
  const isLogo = (store as CollectionRailStoreV2).specialWidgetType === SpecialWidgetTypeEnum.logo;
  const isShowHeader = subtype !== UIDAPresentationTypeEnum.hero && !isGrid && !isLogo;
  const skeletonItemsNum = isLogo ? 1 : items;

  return (
    <div
      ref={handleRef}
      className={cn(styles.railSkeleton, className, { [styles.grid]: isGrid, [styles.logo]: isLogo })}
    >
      {isShowHeader && (
        <header
          style={{
            width,
          }}
        />
      )}
      <div>
        {new Array(skeletonItemsNum).fill(0).map((_, i) => {
          /* eslint-disable react/no-array-index-key */
          return <RailSkeletonAsset key={i} height={heigh} width={width} />;
        })}
      </div>
    </div>
  );
}

export function RailSkeletonAsset({ height, width }: { height: number; width: number }) {
  return (
    <div data-type='skeletonAsset' className={styles.railSkeletonAsset}>
      <div
        style={{
          height,
          width,
        }}
      />
      <div />
    </div>
  );
}

export default RailSkeleton;
