import React from 'react';
import cn from 'classnames';

import { createSkeletonCollection } from 'utils';

import styles from './SkeletonView.module.scss';

const SKELETON_ITEMS_NUMBER = 3;
const SKELETON_ITEMS_PREFIX = 'long-skeleton';

function SkeletonView() {
  const longs = createSkeletonCollection(SKELETON_ITEMS_NUMBER, SKELETON_ITEMS_PREFIX);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleInfo}>
        <div className={styles.tags} />
        <div className={styles.title} />
        <div className={styles.specifications} />
      </div>
      <div className={styles.buttons}>
        <div className={styles.button} />
        <div className={styles.button} />
      </div>
      <div className={styles.content}>
        {longs.map((long) => (
          <div key={long.id} className={cn(styles.info, styles.long)} />
        ))}
        <div className={cn(styles.info, styles.rest)} />
        <div className={cn(styles.info, styles.short)} />
        <div className={cn(styles.info, styles.medium)} />
        <div className={cn(styles.info, styles.short)} />
      </div>
    </div>
  );
}

export default SkeletonView;
