import { CollectionAsset, getAssetImageUrl } from '@kaltura-ott/tvpil-shared';

import { useCardIconsPaths } from 'components/widgets/CardV2/hooks';

interface RecommendationCardImagesProps {
  item: CollectionAsset;
  imageType: number | undefined;
  imageHeight: number;
  imageWidth: number;
}

export function useRecommendationCardImages({
  item,
  imageWidth,
  imageType,
  imageHeight,
}: RecommendationCardImagesProps) {
  // getting background image url
  const backgroundImageUrl = getAssetImageUrl(item.images, {
    type: imageType,
    width: imageWidth,
    height: imageHeight,
  });
  const { defaultImage } = useCardIconsPaths({ height: imageHeight, width: imageWidth });

  return {
    height: imageHeight,
    backgroundImageUrl,
    width: imageWidth,
    defaultImageUrl: defaultImage,
  };
}
