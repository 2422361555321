import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { FFUser, LaunchDarklyApiKeys, LaunchdarklyClient } from '@kaltura-ott/tvpil';

class WebLaunchdarklyClient implements LaunchdarklyClient {
  private client?: LDClient;

  async initialize(keys: LaunchDarklyApiKeys, user: FFUser): Promise<LDFlagSet> {
    if (!keys.sdk) {
      throw new Error('Launchdarkly: key is not defined');
    }

    this.client = initialize(keys.sdk, user, { fetchGoals: false });
    await this.client.waitForInitialization();
    return this.client.allFlags();
  }

  async identify(user: FFUser): Promise<LDFlagSet> {
    const newValues = (await this.client?.identify(user)) || {};

    return newValues;
  }
}

export { WebLaunchdarklyClient };
