import { observer } from 'mobx-react';

import { useCheckRestrictions, usePlaybackStartPerformance, useSetLastPlaybackUrl } from './hooks';

interface Props {
  children(isAllowed: boolean): JSX.Element | null;
}

function BlockContentChecker({ children }: Props) {
  usePlaybackStartPerformance();

  useSetLastPlaybackUrl();

  const isPlaybackAllowed = useCheckRestrictions();

  return children(isPlaybackAllowed);
}

export default observer(BlockContentChecker);
