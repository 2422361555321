import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { EntityRating } from '@kaltura-ott/tvpil-shared';

import { Rating } from 'components/presenters/icons';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';

import styles from './RecommendationCardContent.module.scss';

function RecommendationCardContent({
  shouldDisplayPlayButton,
  isDisplayRating,
  assetRating,
}: {
  assetRating: EntityRating;
  shouldDisplayPlayButton: boolean;
  isDisplayRating: boolean;
}) {
  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      {isDisplayRating && (
        <div data-type='ratingInfo' className={styles.ratingInfoRow}>
          <Rating className={styles.rating} rating={assetRating} />
        </div>
      )}
      {shouldDisplayPlayButton && <PlayButton data-type='playButton' className={styles.playBtn} />}
    </div>
  );
}

export default observer(RecommendationCardContent);
