import React, { createContext, useContext, useMemo } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

export interface ContextType {
  isSkeletonCondition: boolean;
  isGrid: boolean;
  handleSkeletonRef?: (node: HTMLDivElement) => void;
  refElement?: (node: HTMLDivElement) => void;
  store: RailStoreV2;
}

export interface Props {
  isSkeletonCondition?: boolean;
  isGrid?: boolean;
  handleSkeletonRef?: (node: HTMLDivElement) => void;
  refElement?: (node: HTMLDivElement) => void;
  store: RailStoreV2;
  children: React.ReactNode;
}

const RailContext = createContext<ContextType>({
  isSkeletonCondition: false,
  isGrid: false,
  handleSkeletonRef: undefined,
  refElement: undefined,
  store: {} as RailStoreV2,
});

const RailProvider = ({
  children,
  isGrid = false,
  isSkeletonCondition = false,
  handleSkeletonRef = undefined,
  refElement = undefined,
  store,
}: Props) => {
  const contextValue = useMemo(() => {
    return {
      isGrid,
      isSkeletonCondition,
      handleSkeletonRef,
      refElement,
      store,
    };
  }, [isSkeletonCondition, handleSkeletonRef, refElement, store, isGrid]);

  return <RailContext.Provider value={contextValue}>{children}</RailContext.Provider>;
};

const useRailContext = () => useContext(RailContext);

export { RailProvider, useRailContext };

export default RailProvider;
