import { Linear } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import routes from 'routesPaths';
import { checkIsMosaicProgram, playoutStoreDataResolver } from 'utils';

// to change url without reload
export function handleRouteUpdates() {
  const { currentAsset, id, linearId, mediaType, mosaicAsset, mosaicInfo, type } = playoutStoreDataResolver() as any;
  const isMosaic = checkIsMosaicProgram(mosaicInfo);
  const externalId = isMosaic ? (currentAsset as Linear)?.data?.externalId : '';
  const programId = isMosaic ? mosaicAsset?.id : id;

  if (type === PlayoutStoreV2Type.LIVE) {
    const assetId = isMosaic ? (mosaicAsset as Linear)?.linearId : linearId;

    window.history.replaceState(null, '', routes.live.link(assetId!, mediaType!.toLowerCase(), externalId));
  }

  if (type === PlayoutStoreV2Type.START_OVER) {
    window.history.replaceState(null, '', routes.startOver.link(programId!, mediaType!.toLowerCase(), externalId));
  }

  if (type === PlayoutStoreV2Type.CATCHUP) {
    window.history.replaceState(null, '', routes.catchUp.link(programId!, mediaType!.toLocaleLowerCase(), externalId));
  }

  if (type === PlayoutStoreV2Type.VOD) {
    window.history.replaceState(null, '', routes.vod.link(id!, mediaType!.toLowerCase()));
  }
}
