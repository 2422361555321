import React from 'react';
import SVG from 'react-inlinesvg';
import { useIntl } from 'react-intl';

import IconClose from 'assets/images/icon-error-big.svg';
import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { PropsValue } from 'context/error/types';
import { useAppStore } from 'hooks/common/useAppStore';
import { ActionButtonTypesEnum } from 'interface';

import { BUTTON_TEXT_DEFAULT, BUTTON_TEXT_ID, MODAL_TITLE_DEFAULT, MODAL_TITLE_ID } from './constants/constants';
import { formatOutcomeLog } from './helpers/formatOutcomeLog';

import styles from './ExpandedErrorLogModal.module.scss';

interface ModalContentProps {
  outcome: string;
}

interface ExpandedErrorLogModalProps {
  errorData: PropsValue;
}

const ModalContent: React.FC<ModalContentProps> = ({ outcome }) => <pre>{outcome}</pre>;

const ExpandedErrorLogModal: React.FC<ExpandedErrorLogModalProps> = ({ errorData }) => {
  const intl = useIntl();
  const {
    appStore: {
      playoutStoreV2: { currentAsset },
      apiError,
    },
  } = useAppStore();
  const outcome = formatOutcomeLog({ errorData, apiError, currentAsset: currentAsset || null });
  const handleClick = () => navigator.clipboard.writeText(outcome);

  return (
    <div className={styles.modal}>
      <DefaultModal
        className={styles.modalInner}
        icon={<SVG src={IconClose} />}
        title={intl.formatMessage({ defaultMessage: MODAL_TITLE_DEFAULT, id: MODAL_TITLE_ID })}
        skipClickOutside
        buttonProps={{
          acceptButtonType: ActionButtonTypesEnum.primary,
          acceptButtonText: intl.formatMessage({ defaultMessage: BUTTON_TEXT_DEFAULT, id: BUTTON_TEXT_ID }),
          onAccept: handleClick,
        }}
      >
        <ModalContent outcome={outcome} />
      </DefaultModal>
    </div>
  );
};

export default ExpandedErrorLogModal;
