import { action, observable } from 'mobx';

import { mediaRequest } from './mediaRequest/mediaRequest';
import { ContextType, PlayerSettings, SessionType } from './consts';
import { getPlayerConfig } from './getPlayerConfig';

declare const KalturaPlayer: any;

export class PlayerService {
  private mountId: string = '';

  private player: any = null;

  @observable isMediaLoaded: boolean = false;

  @observable type: SessionType = SessionType.LIVE;

  constructor(mountId: string) {
    this.mountId = mountId;
  }

  @action('Mount Player')
  async mountPlayer(settings: PlayerSettings): Promise<void> {
    if (this.player) {
      this.unmountPlayer();
    }

    const { playerConfig } = getPlayerConfig(KalturaPlayer, settings, this.mountId);
    this.player = KalturaPlayer.setup(playerConfig);

    return Promise.resolve();
  }

  @action('Unmount Player')
  unmountPlayer() {
    this.isMediaLoaded = false;

    if (this.player) {
      this.player.pause();
      this.player.destroy();
      this.player = null;
    }
  }

  @action('Load New Media')
  async loadMedia(assetId: number, startTime = -1) {
    await mediaRequest(assetId);

    if (this.player) {
      this.player.configure({ playback: { autoplay: true }, sources: { startTime } });
    }
  }

  // startTime = 1 - for startOver; startTime = -1 - for Live
  @action('Start Over')
  async startOver(epgId: number, startTime = 1) {
    await mediaRequest(epgId, ContextType.START_OVER, 'epg', 'epg_internal', { startTime });
  }

  @action('Catch up')
  async catchUp(epgId: number, startTime: number) {
    await mediaRequest(epgId, ContextType.CATCHUP, 'epg', 'epg_internal', { startTime });
  }

  @action('Records')
  async records(recordId: number, startTime: number) {
    await mediaRequest(recordId, ContextType.PLAYBACK, 'recording', 'npvr', { startTime });
  }

  @action('Toggle Mute Player')
  toggleMutePlayer(muted?: boolean) {
    if (this.player) {
      this.player.muted = muted !== undefined ? muted : !this.player.muted;
    }
  }

  @action('Set Player Volume')
  setVolumePlayer(volume: number) {
    if (this.player) {
      this.player.volume = volume;
    }
  }

  @action('Mute')
  setMuted(muted: boolean = true) {
    if (this.player) {
      this.player.muted = muted;
    }
  }

  getPlayerInstance() {
    return this.player;
  }

  get volume(): number {
    return this.player?.volume || 0;
  }

  get muted(): boolean {
    return !!this.player?.muted;
  }
}
