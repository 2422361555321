import { millisecondsToSeconds } from 'date-fns';

import { appStore, Program } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { bingeWatchingStorage, playerStorage } from 'components/widgets/Player/storages';
import { playoutStoreDataResolver } from 'utils';

import { seekStorage } from '../../components/SeekBar/storages/SeekStorage';
import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { dispatchEvent } from '../../utils/dispatchEvent/dispatchEvent';

interface Props {
  target: {
    normalizedCurrentTime: number;
    normalizedDuration: number;
    paused: boolean;
  };
}

// eslint-disable-next-line consistent-return
export async function handleUpdateCurrentTime({ target }: Props) {
  const pr = seekStorage.getSeekProps();
  const { currentTime: currentSeekbarTime } = pr;
  const { normalizedCurrentTime, normalizedDuration, paused } = target;
  if (normalizedCurrentTime && normalizedDuration) {
    const { currentEntity, isVod, type } = playoutStoreDataResolver();
    const program = currentEntity as Program;
    const isNpvr = type === PlayoutStoreV2Type.NPVR;
    const isLive = type === PlayoutStoreV2Type.LIVE;
    const isStartOver = type === PlayoutStoreV2Type.START_OVER || (isLive && playerStorage.isPlayBackPaused);
    const isOnLiveEdge = isLive && !playerStorage.isPlayBackPaused;
    const playoutStoreDuration = millisecondsToSeconds(program?.data?.endDate - program?.data?.startDate);

    let currentTime = normalizedCurrentTime;
    let duration =
      isVod || isNpvr || isStartOver
        ? normalizedDuration || 0
        : millisecondsToSeconds((currentEntity as Program)?.data?.duration ?? 0);

    if (type === PlayoutStoreV2Type.CATCHUP || isOnLiveEdge) {
      duration = playoutStoreDuration;
    }

    if (type === PlayoutStoreV2Type.CATCHUP && playoutStoreDuration > normalizedDuration) {
      duration = normalizedDuration;
    }

    if (isOnLiveEdge) {
      currentTime = millisecondsToSeconds(Date.now() - (program?.data?.startDate || 0));
    }

    if (currentTime >= duration) {
      if (
        currentSeekbarTime &&
        isLive &&
        currentTime > Math.round(currentSeekbarTime) &&
        Date.now() > program?.data?.endDate
      ) {
        currentTime = Math.round(currentSeekbarTime);
      }

      dispatchEvent(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED);
    }

    if (!paused || currentTime >= duration) {
      await appStore?.playoutStoreV2?.setCurrentTime(currentTime, duration, bingeWatchingStorage.settings);
    }
  }
}
