import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { PageStore, SubpageStore } from '@kaltura-ott/tvpil-shared';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { DO_NOT_SHOW_AGAIN, IMAGE_PARAM } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useAppStore, useLoginStore, useParentalPinStore } from 'hooks';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';
import localStorageService from 'services/localStorageService';

import { FAVORITES } from '../Favorites/constants';

import styles from './SettingsMenu.module.scss';

interface Props {
  settingsStore: PageStore;
}

function SettingsMenu({ settingsStore }: Props) {
  const modalContext = useModalContext();
  const { logoutUser } = useLoginStore();
  const {
    appStore: {
      dms: { providers },
    },
  } = useAppStore();
  const parentalControlStore = useParentalPinStore();
  const { navigate } = useStableReactRouter();
  const intl = useIntl();

  function handleSettingsLinkClick(substore: SubpageStore) {
    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const isActiveLink = e.currentTarget.className.includes('active');

      if (isActiveLink) {
        return;
      }

      if (parentalControlStore.isBlockByParentRating(substore)) {
        const callback = (success: boolean) => {
          if (success) {
            navigate(`${routes.settings.path}${substore.link}`);
          }
        };

        e.preventDefault();

        modalContext.openModal(
          <PinCodeModal
            asset={substore}
            callback={callback}
            type={PinCodeModalTypes.EnterParental}
            context={UIDAPresentationTypeEnum.settings}
          />,
        );
      }
    };
  }

  const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    async function onAccept() {
      modalContext.closeModal();

      Object.keys(providers).forEach((provider) => {
        localStorageService.removeItem(`${provider}${DO_NOT_SHOW_AGAIN}`);
      });
      globalStorage.setIsLogoutInProgress(true);
      await logoutUser();
      navigate(routes.login.path, { replace: true });
    }

    modalContext.openModal(
      <DefaultModal
        buttonProps={{
          onAccept,
          acceptButtonText: intl.formatMessage({
            defaultMessage: 'Logout',
            id: 'Settings.SettingsMenu.DefaultModal.buttonLogout',
          }),
        }}
        className={styles.modalLogout}
        title={intl.formatMessage({ defaultMessage: 'Logout', id: 'Settings.SettingsMenu.DefaultModal.title' })}
      >
        <p>
          <FormattedMessage defaultMessage='Are you sure you want to logout?' id='Settings.SettingsMenu.textLogout' />
        </p>
      </DefaultModal>,
    );
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.menu}>
        {settingsStore.children.map((substore) => {
          const { link, name, image } = substore as SubpageStore;
          const isFavorites = substore.subtype === UIDAPresentationTypeEnum.favorites;

          return (
            <li key={link}>
              <NavLink
                className={({ isActive }) => (isActive ? ' active' : '')}
                data-type={isFavorites ? FAVORITES : ''}
                onClick={handleSettingsLinkClick(substore as SubpageStore)}
                to={`${routes.settings.path}${link}`}
              >
                {image && <img alt={name} src={image + IMAGE_PARAM} />}
                {name}
              </NavLink>
            </li>
          );
        })}

        <li>
          <NavLink className={({ isActive }) => (isActive ? ' active' : '')} onClick={handleLogout} to='/'>
            <FormattedMessage id='Settings.SettingsMenu.buttonLogout' defaultMessage='logout' />
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default observer(SettingsMenu);
