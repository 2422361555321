import { playerStorage } from '../../storages';

export function unpause() {
  const { player } = playerStorage.getPlayer();

  if (player?.paused) {
    playerStorage.setIsPlayBackPaused(false);
    player.play();
  }
}
