import { Channel, EpgSettings, getImageByRatio } from '@kaltura-ott/tvpil-shared';

const LOGO_HEIGHT = 40;
const DEFAULT_IMAGE_TYPE = 0;

type Options = Pick<EpgSettings, 'channelIconType' | 'channelIconRatio'>;

function useChannelCombiner(channel: Channel, { channelIconType, channelIconRatio }: Options) {
  const { images } = channel;

  const originImage = getImageByRatio(images, {
    imageHeight: LOGO_HEIGHT,
    imageRatio: channelIconRatio,
    imageType: channelIconType ?? DEFAULT_IMAGE_TYPE,
  });

  return { originImage };
}

export { useChannelCombiner };
