import { useState } from 'react';

interface Props {
  isOverlay?: boolean;
  onCloseModal?(): void;
}

function useCloseOverlayAndHideButtons({ isOverlay, onCloseModal }: Props) {
  const [hideDetailsBtn, setHideDetailsBtn] = useState(false);

  function closeOverlayAndHideButtons(isNotHidingButtonsCondition: boolean) {
    if (isOverlay && onCloseModal) {
      onCloseModal();
    } else if (!isNotHidingButtonsCondition) {
      setHideDetailsBtn(true);
    }
  }

  return { closeOverlayAndHideButtons, hideDetailsBtn };
}

export { useCloseOverlayAndHideButtons };
