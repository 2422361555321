import { useEffect } from 'react';
import { subMinutes } from 'date-fns';

import { HALF_DIVIDER } from 'consts';

import { config } from '../../config';
import { epgLocalTimeStampStorage } from '../../storages';

interface Props {
  userActive: React.MutableRefObject<boolean>;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

export const useUpdateTimestamp = ({ userActive, ref }: Props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      epgLocalTimeStampStorage.setCurrentTimestamp(Date.now());

      if (ref.current && !userActive.current) {
        epgLocalTimeStampStorage.setViewTimestamp(
          subMinutes(new Date(), epgLocalTimeStampStorage.screenWidthInMinutes / HALF_DIVIDER).valueOf(),
        );
      }
    }, config.refreshTime);

    return () => {
      clearInterval(interval);
    };
  }, []);
};
