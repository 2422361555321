import { NavigateFunction } from 'react-router-dom';

import { appStore, ParentalPinType } from '@kaltura-ott/tvpil-shared';

import { NETWORK_ERROR_FOR_DISPLAY } from 'consts';
import { globalStorage } from 'services/globalStorage';

import { COMPONENT_NAME } from '../../constants';

interface Props {
  isConnection: boolean;
  checkConnection(): void;
  handleRetry(): void;
  code: number;
  goBack?: NavigateFunction;
}

export const getErrorContent = ({ isConnection, checkConnection, handleRetry, code, goBack }: Props) => {
  let errorContent;
  const goBackLink = goBack
    ? {
        goBackLink: () => {
          globalStorage.clearIsPlayerError();
          appStore.parentalPinStore.clearParentalPin(ParentalPinType.parental);
          goBack(-1);
        },
      }
    : undefined;

  if (!isConnection) {
    errorContent = {
      error: {
        titleId: `${COMPONENT_NAME}.noConnection.errorTitle`,
        titleDefault: 'No Connection',
        messageId: `${COMPONENT_NAME}.noConnection.errorMessage`,
        messageDefault: 'Please check your internet connection and try again',
        icon: 'noConnection',
        code: NETWORK_ERROR_FOR_DISPLAY,
      },
      retryButton: {
        onRetry: checkConnection,
        translationId: `${COMPONENT_NAME}.noConnection.buttonTitle`,
        defaultMessage: 'Try Again',
      },
      onClear: appStore.restoreAfterConnectionError.bind(appStore),
    };
  } else {
    errorContent = {
      error: {
        titleId: `${COMPONENT_NAME}.playerErrorTitle`,
        titleDefault: 'Player Error',
        messageId: `${COMPONENT_NAME}.playerErrorMessage`,
        messageDefault: 'According to a problem in player content can not be played',
        code,
      },
      retryButton: {
        onRetry: handleRetry,
        translationId: `${COMPONENT_NAME}.playerErrorButtonTryAgain`,
        defaultMessage: 'Try Again',
      },
      goBackButton: {
        ...goBackLink,
        translationId: `${COMPONENT_NAME}.playerErrorButtonBack`,
        defaultMessage: 'Back',
      },
    };
  }

  return errorContent;
};
