import { useCallback } from 'react';

export const useOnValidatePinConfirm = (onDeclineChangePin: () => void) => {
  const onValidatePinConfirm = useCallback((pin: string) => {
    const currentPin = sessionStorage.getItem('pin') as string;

    if (currentPin !== pin) {
      onDeclineChangePin();
    } else {
      return {
        message: '',
        state: 'ok',
      };
    }
  }, []);

  return { onValidatePinConfirm };
};
