import { action, observable } from 'mobx';

const DEFAULT_DRAG_POSITION = {
  xStart: 0,
  yStart: 0,
};

class EpgLocalDrugStorage {
  @observable dragPosition = DEFAULT_DRAG_POSITION;

  @observable dragStatus = '';

  @action
  setDragState(value: { xStart?: number; yStart?: number }, options: { status: string }) {
    this.dragPosition = { ...this.dragPosition, ...value };
    this.dragStatus = options.status;
  }

  @action
  setDragStatus(value: string) {
    this.dragStatus = value;
  }

  @action
  getDragState() {
    return this.dragPosition;
  }

  @action
  clear() {
    this.dragPosition = DEFAULT_DRAG_POSITION;
    this.dragStatus = '';
  }
}

const epgLocalDrugStorage = new EpgLocalDrugStorage();

export { epgLocalDrugStorage };
