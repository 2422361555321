import { EpgParamsType } from 'types';

import { moveAlongX } from '../../helpers';
import { formatPixelsToTimestamp } from '../../utils';

export const onHandleWheel = (e: WheelEvent, refTime: any, epgParams: EpgParamsType) => {
  const { deltaX } = e;
  const step = formatPixelsToTimestamp(epgParams.ratio, deltaX);

  refTime?.current?.scrollTo(0, 0);
  moveAlongX(step, epgParams);
};
