import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { MD_UI_DISABLE, UI_MODE } from 'consts';

function resolveLinkForMd(isMdUiDisable?: boolean, prevLink?: string, subtype?: string | undefined) {
  const isConditions =
    (subtype && subtype !== UIDAPresentationTypeEnum.hero && isMdUiDisable) || (!subtype && isMdUiDisable);
  const specialChar = prevLink?.includes('?') ? '&' : '?';

  if (isConditions) {
    return `${specialChar}${UI_MODE}=${MD_UI_DISABLE}`; // to avoid displaying MD components in the player
  }

  return '';
}

export { resolveLinkForMd };
