import { useEffect } from 'react';

import { useLoginPageStore } from 'hooks';

export const useLoginInitializationHook = () => {
  const loginPageStore = useLoginPageStore();

  useEffect(() => {
    (() => {
      if (loginPageStore && !loginPageStore.isReady) {
        loginPageStore.init();
      }
    })();
  }, [loginPageStore]);
};
