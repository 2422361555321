import { playerStorage } from 'components/widgets/Player/storages';
import { useDetectLocation } from 'hooks';

function useCheckMdUiDisable() {
  const { isMdUiDisable } = useDetectLocation();

  playerStorage.setIsMdUiDisable(!!isMdUiDisable);
}

export { useCheckMdUiDisable };
