import { PlaybackErrorEventArgs } from '@kaltura-ott/tvpil-analytics';
import { appStore } from '@kaltura-ott/tvpil-shared';

import { ErrorSource } from 'interface';

import { DataAnalyticsType, PlaybackBaseEvent } from './PlaybackBaseEvent';

export class PlaybackErrorEvent extends PlaybackBaseEvent {
  readonly errorId: string | number;

  readonly errorMessage: string;

  constructor(data: DataAnalyticsType, error: any) {
    super(data);
    this.errorId = error.code?.toString();
    this.errorMessage = JSON.stringify(error.data, (key, value) => {
      if (value instanceof Error) {
        return Object.getOwnPropertyNames(value).reduce((acc, propName) => {
          // @ts-ignore
          acc[propName] = value[propName];
          return acc;
        }, {} as any);
      }

      return value;
    });
  }

  async sendEvent() {
    this.getPlaybackBaseEventData();

    const playbackErrorEventData = {
      errorCode: this.errorId,
      ...this.playbackBaseEventData,
      param1: this.errorMessage,
      param2: ErrorSource.Player,
    } as PlaybackErrorEventArgs;

    try {
      if (playbackErrorEventData.epgId) {
        await appStore.kuxAnalytics.send(new PlaybackErrorEventArgs(playbackErrorEventData));
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
