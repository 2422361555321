import { MutableRefObject } from 'react';

import { LAST_DRAG_X_POSITION } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useDetectLocation, useEpgStore } from 'hooks';
import { setScrollPosition } from 'utils';

import { epgLocalTimeStampStorage } from '../../../../storages';

interface Props {
  epgRef: MutableRefObject<HTMLDivElement | null>;
  gridStartYPosition: MutableRefObject<number>;
}

export const useSetUpEpgPositionOnTvChannelReady = ({ epgRef, gridStartYPosition }: Props) => {
  const { epgStore } = useEpgStore();
  const { navType } = useStableReactRouter();
  const { isEpg, isDetailsPage } = useDetectLocation();

  if (epgRef?.current && !gridStartYPosition.current) {
    gridStartYPosition.current = epgRef.current.getBoundingClientRect().top;
  }

  const lastDragXPosition = sessionStorage.getItem(LAST_DRAG_X_POSITION);

  if (lastDragXPosition && navType !== 'PUSH') {
    epgLocalTimeStampStorage.setViewTimestamp(Number(lastDragXPosition));
  }

  if (epgStore.isReadyTvChannel) {
    setScrollPosition({
      historyAction: navType,
      isRemove: !(isEpg && isDetailsPage), // do not remove LAST_SCROLL_Y_POSITION when DP opened
    });
  }
};
