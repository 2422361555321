import { appStore } from '@kaltura-ott/tvpil-shared';

import { PerformanceContextType, PerformanceEvents } from 'interface';

import { getAdditionalEventParam } from './getAdditionalEventParam';

/**
 * This function is for comparing lifecycle spiner and limit value from dms and setting endTime
 */

export async function checkLoadingLifecycle(hashKey: string | undefined, perfomanceContext: PerformanceContextType) {
  const resolveKey = hashKey?.toLowerCase();
  const event = await perfomanceContext.getEvent(PerformanceEvents.spinner, resolveKey);
  const spinerPeriod = (appStore?.dms?.analytics?.events as any)?.spinner?.period;
  const additionalEventParam = getAdditionalEventParam(hashKey!);

  if (Date.now() - event?.startTime >= spinerPeriod) {
    perfomanceContext.setEndTime({
      value: PerformanceEvents.spinner,
      withDmsToggle: undefined,
      hashKey: resolveKey,
      additionalEventParam,
    });
  } else {
    perfomanceContext.clearEvent(PerformanceEvents.spinner, resolveKey);
  }
}
