export const ellipsis = '...';

export const textStyles: { [key: string]: any } = {
  title: {
    font: '20px "Open Sans", sans-serif',
    padding: 35,
  },
  time: {
    font: '14px "Open Sans", sans-serif',
    padding: 30,
  },
};
