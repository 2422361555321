import { useEffect, useLayoutEffect, useRef } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { ParentalPinType } from '@kaltura-ott/tvpil-shared';

import { LAST_URL } from 'consts';
import { useModalContext } from 'context/modal/modal';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { useAppStore } from '../common/useAppStore';
import { useDetectLocation } from '../common/useDetectLocation';
import { useIsUrlToFullPlayer } from '../common/useIsUrlToFullPlayer';
import { useParentalPinStore } from '../common/useParentalPinStore';

import { useAppFindPageStore } from './useAppFindPageStore';

interface RouteMatch {
  pathname: string;
  route: {
    path: string;
  };
}

export function useAppHistoryListener() {
  const {
    appStore: {
      isReady: appStoreIsReady,
      rootStore,
      gridPageStoreV2: { railStore },
    },
  } = useAppStore();

  const modalContext = useModalContext();
  const parentalPinStore = useParentalPinStore();
  const location = useLocation();
  const { isHomePage, isEpg, isGrid } = useDetectLocation();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(location.pathname);
  const { findPageStore, findSettingsSubStore } = useAppFindPageStore();
  const prevUrl = useRef('');

  useLayoutEffect(() => {
    if (location.pathname !== prevUrl.current) {
      sessionStorage.setItem(LAST_URL, prevUrl.current);
      prevUrl.current = location.pathname;
    }

    globalStorage.setIsUrlToFullPlayer(isUrlToFullPlayer);

    const currentPage = findPageStore(location.pathname);
    const substore = findSettingsSubStore(location.pathname);
    const isRestricted =
      !!(currentPage && parentalPinStore.isBlockByAdult(currentPage)) ||
      !!(substore && parentalPinStore.isBlockByParentRating(substore));

    if (!isRestricted && document.getElementById('mainModal')) {
      modalContext.closeModal();
    }
  }, [location.pathname]);

  const isRouteMatch = ({ pathname, route }: RouteMatch) => {
    return !!matchRoutes([route], pathname) || pathname?.includes(route.path);
  };

  const isPlayerLocation = (pathname: string) => {
    return [routes.live, routes.catchUp, routes.program, routes.startOver, routes.vod].some((route) => {
      return isRouteMatch({ pathname, route });
    });
  };
  const isDetailsPage = (pathname: string) => {
    return [routes.details.pathDetailsInfo, routes.details.pathDetailsSeries].some((route) => {
      return isRouteMatch({ pathname, route });
    });
  };

  useEffect(() => {
    if (!appStoreIsReady) {
      return;
    }

    const prevLocation = sessionStorage.getItem(LAST_URL);
    const isLeavingPlayerPage = prevLocation && isPlayerLocation(prevLocation) && !isPlayerLocation(location.pathname);
    const isLeavingDetailsPage = prevLocation && isDetailsPage(prevLocation) && !isDetailsPage(location.pathname);

    if (isLeavingPlayerPage) {
      parentalPinStore.clearParentalPin(ParentalPinType.parental);
    }

    if (isLeavingDetailsPage && !isEpg) {
      const pageStore = findPageStore(location.pathname);
      const isNotPageStorePinProtected = pageStore && !pageStore?.parentalPinProtected;
      const isNotPinProtectedHomePage = isHomePage && !rootStore?.parentalPinProtected;
      const isNotPinProtectedGridPage = isGrid && !railStore?.parentalPinProtected;

      if (isNotPageStorePinProtected || isNotPinProtectedHomePage || isNotPinProtectedGridPage) {
        parentalPinStore.clearParentalPin(ParentalPinType.adult);
      }
    }
  }, [location.pathname, parentalPinStore, appStoreIsReady]);
}
