import { useCallback } from 'react';

import { useAppStore, useEpgStore, useRootStore } from 'hooks';
import { applyStyle, applyStyleHexToHSL, applyStyles, isYettelEnvGroup } from 'utils';

export const useHandleRef = () => {
  const {
    appStore: {
      params: {
        dmsSetting: { partnerId },
      },
    },
  } = useAppStore();
  const rootStore = useRootStore();
  const {
    epgStore: { uiDesignElements },
  } = useEpgStore();

  return useCallback((node: HTMLElement) => {
    if (!node) {
      return;
    }

    const textFocusColor = isYettelEnvGroup(partnerId)
      ? rootStore.uiDesignElements.btnTextColor
      : uiDesignElements.textColor;

    if (uiDesignElements) {
      applyStyles(node, uiDesignElements, false, true);
      applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
      applyStyle(node, '--text-focus-color', textFocusColor!);
    }
  }, []); // eslint-disable-line
};
