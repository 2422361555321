import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import watchLiveIcon from 'assets/images/details/watch-live.svg';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useVideoContext } from 'context/videoContext/videoContext';

import { useResolvePathToWatchLive } from '../../../../utils';
import { useHandleDisableStartOver } from '../../handlers';

export interface WatchLiveButtonProps {
  asset: ViewableEntitiesType;
  className: string;
}

export function WatchLiveButton({ asset, className }: WatchLiveButtonProps) {
  const { closeOverlay, switchToLive, navigate, modalContext, parentalPinStore } = useVideoContext();

  const handleDisableStartOver = useHandleDisableStartOver({
    asset,
    switchToLive,
    closeOverlay,
    navigate,
    modalContext,
    parentalPinStore,
  });
  const resolvePathToWatchLive = useResolvePathToWatchLive(asset);

  return (
    <ActionButton
      icon={<SVG data-target='watchLiveIcon' src={watchLiveIcon} />}
      isLarge
      onClick={handleDisableStartOver}
      className={className}
      {...resolvePathToWatchLive}
    >
      <FormattedMessage defaultMessage='Watch Live' id={`${COMPONENT_NAME}.VideoContent.buttonWatchLive`} />
    </ActionButton>
  );
}
