import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { RhinoEPGProgram, RhinoProgram } from '@kaltura-ott/tvpil-shared';

interface Props {
  program: RhinoEPGProgram;
  isRestrictedByAdult: boolean | undefined;
  isCatchUp: boolean | undefined;
  isCatchUpWithNoData: boolean;
  isAdultCathUpPlaybackEnabled: boolean;
}

export function useResolveTitle({
  isCatchUp,
  program,
  isRestrictedByAdult,
  isCatchUpWithNoData,
  isAdultCathUpPlaybackEnabled,
}: Props) {
  const intl = useIntl();
  const { isNotFound, isSeries, seriesName, name, isAdult } = program as RhinoProgram;

  return useMemo(() => {
    let title = isSeries && seriesName ? seriesName : name;

    if (isRestrictedByAdult) {
      title = intl.formatMessage({ id: 'Epg.ProgramCard.restrictedContent', defaultMessage: 'Restricted Content' });
    }

    if (isNotFound) {
      if (!isRestrictedByAdult) {
        title = intl.formatMessage({
          id: 'Epg.ProgramCard.noDataAvailable',
          defaultMessage: 'Program cannot be played or no data available',
        });
      }

      if (isCatchUp) {
        title = intl.formatMessage({
          id: 'Epg.ProgramCard.noDataCatchUp',
          defaultMessage: 'This Channel Does Not Show Past Programs',
        });
      }
    } else if ((isAdult && isCatchUp && !isAdultCathUpPlaybackEnabled) || isCatchUpWithNoData) {
      title = intl.formatMessage({
        id: 'Epg.ProgramCard.noDataCatchUp',
        defaultMessage: 'This Channel Does Not Show Past Programs',
      });
    }

    return title;
  }, [program.id, isRestrictedByAdult, isCatchUp]);
}
