import { PerformanceContextType, PerformanceEvents } from 'interface';
import { getPerformanceAdditionalParams } from 'utils';

import { CONCURRENCY_ERROR, CUSTOM_PLAYER_EVENTS } from '../../constants';
import { PlayerEvent } from '../../types';

export class PerformanceService {
  readonly context: PerformanceContextType;

  readonly player: any;

  constructor(context: PerformanceContextType, player: any) {
    this.context = context;
    this.player = player;
  }

  addEventListeners() {
    this.player.addEventListener(CUSTOM_PLAYER_EVENTS.START_PLAYBACK, this.onPlaybackStart);
    this.player.addEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_LIVE, this.onPlaybackStart);
    this.player.addEventListener(this.player.Event.UI.USER_CLICKED_PLAY, this.onPlaybackResume);
    this.player.addEventListener(this.player.Event.PLAYING, this.onPlaying);
    this.player.addEventListener(this.player.Event.FIRST_PLAY, this.onFirstPlay);
    this.player.addEventListener(this.player.Event.ERROR, this.onError);
  }

  removeEventListeners() {
    this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.START_PLAYBACK, this.onPlaybackStart);
    this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.SWITCH_TO_LIVE, this.onPlaybackStart);
    this.player.removeEventListener(this.player.Event.UI.USER_CLICKED_PLAY, this.onPlaybackResume);
    this.player.removeEventListener(this.player.Event.PLAYING, this.onPlaying);
    this.player.removeEventListener(this.player.Event.FIRST_PLAY, this.onFirstPlay);
    this.player.removeEventListener(this.player.Event.ERROR, this.onError);
  }

  onFirstPlay = () => {
    const { setEndTime } = this.context;
    const { assetId } = getPerformanceAdditionalParams();

    setEndTime({ value: PerformanceEvents.purchase, param1: assetId });
  };

  onError = ({ payload }: PlayerEvent) => {
    const { setEndTime } = this.context;
    const { assetId } = getPerformanceAdditionalParams();
    const code = payload.data?.data?.messages?.[0].code;

    if (code === CONCURRENCY_ERROR) {
      setEndTime({ value: PerformanceEvents.purchase, param1: assetId });
    }
  };

  onPlaybackStart = () => {
    const { setStartTime } = this.context;
    setStartTime(PerformanceEvents.playbackStart);
  };

  onPlaybackResume = () => {
    const { setStartTime } = this.context;
    setStartTime(PerformanceEvents.playbackResume);
  };

  onPlaying = () => {
    const { setEndTime } = this.context;
    const { assetId, mediaType } = getPerformanceAdditionalParams();

    setEndTime({
      value: PerformanceEvents.playbackStart,
      param1: assetId,
      param2: mediaType,
    });
    setEndTime({
      value: PerformanceEvents.playbackResume,
      param1: assetId,
      param2: mediaType,
    });
  };
}
