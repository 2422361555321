import React from 'react';
import cn from 'classnames';

import styles from './PlayButton.module.scss';

export interface PlayButtonProps {
  className: string;
}

function PlayButton({ className }: PlayButtonProps) {
  return (
    <div data-type='playButton' className={cn(styles.playBtn, className)}>
      <span>&gt;</span>
    </div>
  );
}

export default PlayButton;
