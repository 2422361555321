export const config = {
  hourScale: 300, // 1 hour in pixels
  channelLineHeight: 80,
  channelWidth: 150,
  minWidthForRecorddAndMd: 150,
  minWidthForMdIcon: 115,
  refreshTime: 60 * 1000, // 1 min
  swipeEnablingTime: 150,
  delayForHorisontalScroll: 5,
  delayForHorisontalFiltering: 100,
  delayForVerticalScroll: 5,
  deltaForDragging: 1.2,
};
