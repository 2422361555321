import React, { useCallback } from 'react';
import { useLocation } from 'react-router';

import { MediaType, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { MediaType as MediaTypeAnalytics } from '@kaltura-ott/tvpil-analytics';
import { Linear, Program } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useParentalPinStore, useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { getUserActionAdditionalParams, onMoveToPurchase } from 'utils';

import { useOpenDetailsPage } from '../useOpenDetailsPage/useOpenDetailsPage';

interface Props {
  isLoading: boolean;
  program: Program;
  isLive: boolean;
  notFound: boolean | undefined;
  isRestrictedByAdult: boolean | undefined;
  channel: Linear;
  isFutureDate: boolean;
}

export function useClickHandler({
  isLoading,
  program,
  isLive,
  notFound,
  isRestrictedByAdult,
  channel,
  isFutureDate,
}: Props) {
  const location = useLocation();
  const parentalPinStore = useParentalPinStore();
  const { settingsStore } = useSettingsStore();
  const { navigate } = useStableReactRouter();
  const modalContext = useModalContext();
  const { isPurchasable } = channel || {};
  const mediaType = program.data.mediaType?.toLowerCase() || MediaType.Program.toLowerCase();
  const { mediaType: mediaTypeFromAdditionalParams } = getUserActionAdditionalParams(program);
  const mediaTypeForUserEvent = isFutureDate ? MediaTypeAnalytics.live : mediaTypeFromAdditionalParams;
  const openDetailsPage = useOpenDetailsPage({ mediaType, mediaTypeForUserEvent, program, navigate });
  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isLoading) {
        return;
      }

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      const { parentalRating } = program;
      const linearId = program?.linearId;
      const isLiveNotFoundProgram = !!(isLive && linearId && notFound);

      if (e.button) return;

      if (notFound && !isLive) {
        return;
      }

      if (isRestrictedByAdult) {
        modalContext.openModal(
          <PinCodeModal
            asset={program}
            context={UIDAPresentationTypeEnum.epg}
            type={PinCodeModalTypes.RestrictedContent}
          />,
        );
        return;
      }

      if (isPurchasable) {
        if (notFound && linearId) {
          onMoveToPurchase({
            settingsStore,
            navigate,
            linearId,
            redirectLink: routes.live.link(linearId, mediaType),
            goBackUrl: location.pathname,
          });
        } else {
          openDetailsPage();
        }
        return;
      }

      // check parental before move to player
      if (isLiveNotFoundProgram && parentalPinStore.isBlockByParentRating(program)) {
        modalContext.openModal(
          <PinCodeModal
            callback={(success: boolean) => {
              if (success) {
                clickHandler(e);
              }
            }}
            asset={program}
            context={UIDAPresentationTypeEnum.epg}
            description={parentalRating ? <DescriptionWithAge age={parentalRating} /> : undefined}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      if (isLiveNotFoundProgram) {
        navigate(routes.live.link(linearId!, mediaType), {
          state: {
            goBackUrl: location.pathname,
          },
        });
        return;
      }

      openDetailsPage();
    },
    [
      mediaType,
      program,
      settingsStore,
      isRestrictedByAdult,
      modalContext,
      isPurchasable,
      isLive,
      isLoading,
      parentalPinStore,
      notFound,
    ],
  );

  return clickHandler;
}
