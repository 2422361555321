import { useEffect } from 'react';

interface Props {
  isShowSearchInput: boolean;
  searchRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const useFocusOnSearchInput = ({ isShowSearchInput, searchRef }: Props) => {
  useEffect(() => {
    if (isShowSearchInput) {
      const inputNode = searchRef?.current?.childNodes[1] as HTMLInputElement;

      if (inputNode) {
        inputNode.focus();
      }
    }
  }, [isShowSearchInput]);
};
