import { Program } from '@kaltura-ott/tvpil-shared';

import { checkTextIsClipped } from '../../utils';
import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useConditionsResolver } from '../useConditionsResolver/useConditionsResolver';
import { useResolveTitle } from '../useResolveTitle/useResolveTitle';
import { useVisibleProgram } from '../useVisibleProgram/useVisibleProgram';

export function useProgramHooksCombiner(program: Program) {
  const {
    data: { startDate, notFound },
  } = program;
  const {
    channel,
    isRestrictedByAdult,
    isFutureDate,
    isSeriesRecord,
    isMdIconCondition,
    iconMDUrl,
    duration,
    width,
    time,
    isCatchUp,
    isCatchUpWithNoData,
    isRestrictedCatchup,
    isRecordBtnCondition,
    isLive,
    isLoading,
    isDescriptionHidden,
    epgParams,
    widthForTimeEllipsis,
    isOnlyMd,
    isWithoutMd,
    isAdultCathUpPlaybackEnabled,
  } = useConditionsResolver(program);
  const title = useResolveTitle({ isRestrictedByAdult, isCatchUp, program });
  const clickHandler = useClickHandler({
    isLoading,
    program,
    isLive,
    notFound,
    isRestrictedByAdult,
    channel,
    isFutureDate,
  });
  const isTitleEllipsis = checkTextIsClipped(width, title, 'title') && !isDescriptionHidden;
  const isTimeEllipsis = checkTextIsClipped(widthForTimeEllipsis, time, 'time') && !isDescriptionHidden;

  useVisibleProgram(program);

  return {
    clickHandler,
    isLoading,
    epgParams,
    width,
    duration,
    startDate,
    isFutureDate,
    title,
    isDescriptionHidden,
    widthForTimeEllipsis,
    time,
    isOnlyMd,
    isWithoutMd,
    isCatchUpWithNoData,
    isRestrictedCatchup,
    isRecordBtnCondition,
    isMdIconCondition,
    isSeriesRecord,
    iconMDUrl,
    isTitleEllipsis,
    isTimeEllipsis,
    isAdultCathUpPlaybackEnabled,
  };
}
