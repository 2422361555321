import React, { useCallback, useRef } from 'react';

import ExponeaBanner from './components/ExponeaBanner/ExponeaBanner';
import { useBannerHook } from './hooks/useBannerHook';

import styles from './Banner.module.scss';

function Banner() {
  const { exponeaBanner, resetExponeaBanner } = useBannerHook();
  const ref = useRef(null);

  const handleCloseBanner = useCallback(() => resetExponeaBanner(), [resetExponeaBanner]);

  if (!exponeaBanner.display) return null;

  return (
    <div className={styles.wrapper}>
      <span className={styles.bg} onClick={handleCloseBanner} tabIndex={0} role='button' aria-label='banner' />
      <div ref={ref} className={styles.content}>
        <button aria-label='close' className={styles.closeBtn} onClick={handleCloseBanner} type='button' />
        <ExponeaBanner closeBannerHandle={handleCloseBanner} exponeaBanner={exponeaBanner} />
      </div>
    </div>
  );
}

export default React.memo(Banner);
