import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';
import { useAppFindPageStore } from 'hooks/App/useAppFindPageStore';
// Stores
import { useAppStore } from 'hooks/common/useAppStore';
import { useLoginStore } from 'hooks/common/useLoginStore';
import { useParentalPinStore } from 'hooks/common/useParentalPinStore';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

export function useAppParentalPin() {
  const {
    appStore: { isReady: appStoreIsReady },
  } = useAppStore();
  const parentalPinStore = useParentalPinStore();
  const modalContext = useModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { findPageStore } = useAppFindPageStore();
  const { isAnonymous } = useLoginStore();
  const { isPlayerError } = globalStorage;

  useEffect(() => {
    if (globalStorage.isAwakened) {
      parentalPinStore.clearPinCodes();
    }
  }, [globalStorage.isAwakened]);

  useEffect(() => {
    if (!appStoreIsReady || isAnonymous) {
      return;
    }

    const currentPage = findPageStore(location.pathname);

    if (currentPage && parentalPinStore.isBlockByAdult(currentPage)) {
      const onDecline = () => {
        if (location?.state) {
          navigate(-1);
        } else {
          navigate(routes.home.path);
        }
      };

      modalContext.openModal(
        <PinCodeModal
          asset={currentPage}
          onDecline={onDecline}
          context={UIDAPresentationTypeEnum.applications}
          type={PinCodeModalTypes.RestrictedContent}
        />,
      );
    }
  }, [
    findPageStore,
    location.pathname,
    modalContext,
    appStoreIsReady,
    parentalPinStore,
    isAnonymous,
    isPlayerError,
    globalStorage.isAwakened,
  ]);
}
