import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { LinkAsAsset } from '@kaltura-ott/tvpil-shared';

import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useToastMessageContext } from 'context/toast/toast';
import { useAppStore, useParentalPinStore, useRootStore } from 'hooks';
import { ApplicationError } from 'interface';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

import { IN_APP_LINK_HOME } from '../../constans';

import styles from '../../Menu.module.scss';

type Props = {
  path: string;
  menuItem: LinkAsAsset;
  children: any;
};

function LinkView({ path, menuItem: linkAsAsset, children }: Props) {
  const { appStore } = useAppStore();
  const { children: rootChildrens, grid } = useRootStore();
  const parentalPinStore = useParentalPinStore();
  const { navigate } = useStableReactRouter();
  const modalContext = useModalContext();
  const toastMessageContext = useToastMessageContext();
  const menuItem = linkAsAsset.data.linkItem!;

  const checkParentalRights = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // TODO should be checked the logic
    if (e.ctrlKey) {
      return;
    }

    e.preventDefault();

    const isValidPage = !!rootChildrens.find((page) => page.referenceId === menuItem.inAppLink);
    const isValidGrid = !!grid?.checkGridAvailability(menuItem.inAppLink);
    const isLinkHome = menuItem.inAppLink?.toLowerCase() === IN_APP_LINK_HOME;
    const isPin = await parentalPinStore.checkMenuParentalPin(menuItem);

    if (!(isValidPage || isValidGrid)) {
      sendApplicationErrorEvent({
        context: UIDAPresentationTypeEnum.menu,
        message: 'menuNotFound',
        code: menuItem.id.toString(),
        additionalInfo: ApplicationError.Menu,
      });
    }

    if (isPin) {
      modalContext.openModal(
        <PinCodeModal
          callback={(success: boolean) => {
            if (success) {
              navigate(path);
            }
          }}
          asset={linkAsAsset}
          context={UIDAPresentationTypeEnum.menu}
          type={PinCodeModalTypes.RestrictedContent}
        />,
      );
      return;
    }

    navigate(path);

    if (isLinkHome) {
      toastMessageContext.clearToast();
      navigate(path);
      await appStore.rebootApp(true);
    }
  };

  return (
    <NavLink
      className={({ isActive }) => (isActive ? styles.active : cn({ [styles.active]: isActive }))}
      onClick={checkParentalRights}
      to={path}
    >
      {children}
    </NavLink>
  );
}

export default React.memo(LinkView);
