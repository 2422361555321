import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Node } from 'types';

import MainLayout from 'components/presenters/layouts/main/MainLayout';
import { useDetectLocation, useRootStore } from 'hooks';
import routesPaths from 'routesPaths';
import { applyStyleHexToHSL, applyStyles } from 'utils';

import SearchWrapper from './SearchWrapper';

const MIN_SYMBOLS_TO_START_SEARCH = 3;

function SearchPage() {
  const rootStore = useRootStore();
  const { search } = rootStore!;
  const navigate = useNavigate();
  const location = useLocation();
  const { isModal } = useDetectLocation();

  useEffect(() => {
    const urlSearchParam = location.search;
    const searchParam = urlSearchParam ? decodeURI(urlSearchParam.split('?name=')[1]) : '';

    if (!search || (searchParam.length < MIN_SYMBOLS_TO_START_SEARCH && !isModal)) {
      navigate(routesPaths.home.path);
    }
  }, []);

  const handleRef = useCallback((node: Node) => {
    if (search) {
      const { uiDesignElements } = search;

      if (uiDesignElements && node !== null) {
        applyStyles(node, uiDesignElements, false, true);
        applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
      }
    }
  }, []); // eslint-disable-line

  if (!search) return null;

  return (
    <MainLayout forwardRef={handleRef}>
      <SearchWrapper search={search} />
    </MainLayout>
  );
}

export default SearchPage;
