import React, { useCallback } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { CategoryStore, Linear, LinkAsAsset, Program, VodAsset } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useAppFindPageStore, useDetectLocation, useParentalPinStore, useRootStore, useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';
import {
  onMoveToPurchase,
  playProviderAsset,
  resolvePathToDetailsPage,
  saveAssetToGlobalStorage,
  userActionEvents,
} from 'utils';

import { useAssetCardContext } from '../../../AssetCardContext/AssetCardContext';
import { useAssetCardBodyResolveData } from '../useAssetCardBodyResolveData/useAssetCardBodyResolveData';

const SEARCH_STRING = 'searchString';

export const useClickHandler = () => {
  const { findPageStore } = useAppFindPageStore();
  const { isSearchPage } = useDetectLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { settingsStore } = useSettingsStore();
  const rootStore = useRootStore();
  const parentalPinStore = useParentalPinStore();
  const { search } = rootStore!;
  const { onCardClick: onClick } = useRailHorisontalDataContext();
  const {
    mediaTypeForUserEvent,
    isPossibleToSaveAssetToGlobalStorage,
    isPlayableProviderAsset,
    isUrlToFullPlayer,
  } = useAssetCardBodyResolveData();
  const {
    listStore,
    resolvedProps: { isSuppressedEpisode, link, currentAsset, isContentRestricted, isContinueWatching, isSuggested },
  } = useAssetCardContext();
  const {
    data: { parentalRating },
  } = currentAsset;
  const { record, seriesRecord } = currentAsset as Program;

  // TODO: maybe change ref to css transform state
  const modalContext = useModalContext();

  return useCallback(
    (e: React.MouseEvent<any>) => {
      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      if (onClick) {
        onClick((currentAsset as Linear)?.currentProgram?.linearId || (currentAsset as Program)?.linearId);
      }

      if (isSearchPage) {
        search!.addToSearchHistory(currentAsset);
        sessionStorage.setItem(SEARCH_STRING, location.search);
      }

      if (isContinueWatching) {
        userActionEvents(UserActionType.continue, currentAsset.id, mediaTypeForUserEvent);
      }

      if (isPlayableProviderAsset) {
        playProviderAsset(e, currentAsset as VodAsset, modalContext);
        return;
      }

      if (isContentRestricted) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModal
            asset={currentAsset}
            context={UIDAPresentationTypeEnum.applications}
            type={PinCodeModalTypes.RestrictedContent}
          />,
        );
        return;
      }

      if (currentAsset instanceof LinkAsAsset) {
        const page = findPageStore(link.split('?')[0]) as CategoryStore;
        const isRestricted = page && parentalPinStore.isBlockByAdult(page);

        if (isRestricted) {
          e.preventDefault();

          modalContext.openModal(
            <PinCodeModal
              asset={currentAsset}
              callback={(success: boolean) => {
                if (success) {
                  navigate(link);
                }
              }}
              context={UIDAPresentationTypeEnum.applications}
              type={PinCodeModalTypes.RestrictedContent}
            />,
          );
          return;
        }
      }

      if (currentAsset.isPurchasable && !record && !seriesRecord) {
        e.preventDefault();
        if (currentAsset instanceof LinkAsAsset) return;

        if (
          currentAsset instanceof Linear &&
          (!currentAsset.currentProgram || currentAsset.currentProgram.data?.notFound)
        ) {
          onMoveToPurchase({
            settingsStore,
            navigate,
            linearId: currentAsset.linearId,
            redirectLink: link,
          });
        } else {
          userActionEvents(UserActionType.details, currentAsset.id, mediaTypeForUserEvent);
          globalStorage.setDetailsAsset(currentAsset);

          navigate(
            resolvePathToDetailsPage({
              id: currentAsset.id,
              mediaTypeParam: currentAsset.data.mediaType.toLowerCase(),
              subtype: listStore.subtype,
            }),
          );
        }

        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockByParentRating(currentAsset)) {
        e.preventDefault();
        modalContext.openModal(
          <PinCodeModal
            callback={(success: boolean) => {
              if (success) {
                saveAssetToGlobalStorage(currentAsset, !!matchRoutes([routes.live], link));
                navigate(link);
              }
            }}
            asset={currentAsset}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );

        return;
      }

      if (isPossibleToSaveAssetToGlobalStorage) {
        saveAssetToGlobalStorage(currentAsset, !!matchRoutes([routes.live], link));
      }

      // temp, better render <a> instead of <Link>
      if (link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link.includes('details')) {
        if (!(currentAsset instanceof LinkAsAsset) && !isSuppressedEpisode) {
          globalStorage.setDetailsAsset(currentAsset);
        }
        // to send the user event after clicking on an AssetCardLink which should open the details page
        userActionEvents(UserActionType.details, currentAsset.id, mediaTypeForUserEvent);
      }
    },

    [
      isContentRestricted,
      modalContext,
      currentAsset,
      isSuggested,
      link,
      parentalPinStore,
      parentalRating,
      record,
      seriesRecord,
      settingsStore,
      listStore.subtype,
    ],
  );
};
