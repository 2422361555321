import React from 'react';
import cn from 'classnames';

import Img from 'components/presenters/Img/Img';

import CardBodyContentRestrictedHookV2 from '../../hooks/useHandleSkeletonRef/CardBodyContentRestrictedHookV2/CardBodyContentRestrictedHookV2';

import styles from './CardInner.module.scss';

interface CardInnerProps {
  handleRef: (value: HTMLDivElement | null) => void;
  handleLinkRef: React.RefObject<HTMLAnchorElement>;
  isNoDataWithNoLogoImg: boolean;
  name?: string;
  url?: string;
  defaultImageUrl: string;
  contentOverlay: JSX.Element;
  cardBubbleInfo: JSX.Element;
  cardType: string;
  isAdult: boolean;
}

const CardInner: React.FC<CardInnerProps> = ({
  url = '',
  name,
  handleRef,
  handleLinkRef,
  contentOverlay,
  cardBubbleInfo,
  defaultImageUrl,
  isNoDataWithNoLogoImg,
  cardType,
  isAdult,
}) => {
  return (
    <div ref={handleRef} className={cn(styles.wrapper, styles[`type-${cardType}`])}>
      <div className={cn(styles.inner)}>
        <CardBodyContentRestrictedHookV2 handleLinkRef={handleLinkRef} isAdult={isAdult} />
        <div className={styles.contentWrapper}>
          <div
            className={cn(styles.imageWrapper, {
              [styles.noDataWithDefaultImg]: isNoDataWithNoLogoImg,
            })}
          >
            <Img className={styles.defaultImg} alt={name || cardType} srcList={[url, defaultImageUrl]} />
          </div>
          <div className={styles.content}>{contentOverlay}</div>
        </div>
        {cardBubbleInfo}
      </div>
    </div>
  );
};

export default CardInner;
