import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { addDays, setHours } from 'date-fns';

import { EVENING_START_HOUR } from 'components/pages/Epg/constans';
import { useAppStore, useEpgStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { DateFormatStyle, formatDate } from 'utils';

import { rangeArray } from '../../utils';

export function useGenerateDays() {
  const intl = useIntl();
  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const {
    epgStore: { primeTime = EVENING_START_HOUR, maxEpgDateBackward, maxEpgDateForward },
  } = useEpgStore();
  // lower or equal maxEpgDateBackward and lower maxEpgDateForward

  return useMemo(() => {
    const locale = latinLanguage || displayLanguage;
    const patternArray: Array<number> = rangeArray(-maxEpgDateBackward, maxEpgDateForward - 1);

    patternArray.splice(
      patternArray.findIndex((item) => item === 1),
      0,
      0.5,
    );

    const daysItems = patternArray.map((item) => {
      let day = addDays(new Date(), item);
      let name: string = '';

      switch (item) {
        case -1: {
          name = intl.formatMessage({ id: 'Epg.Days.yesterday', defaultMessage: 'yesterday' });
          break;
        }
        case 0: {
          name = intl.formatMessage({ id: 'Epg.Days.now', defaultMessage: 'now' });
          day = new Date();
          break;
        }
        case 0.5: {
          name = intl.formatMessage({ id: 'Epg.Days.evening', defaultMessage: 'evening' });
          day = setHours(new Date(), primeTime);
          break;
        }
        case 1: {
          name = intl.formatMessage({ id: 'Epg.Days.tomorrow', defaultMessage: 'tomorrow' });
          break;
        }
        default: {
          name = formatDate(addDays(new Date(), item).valueOf(), DateFormatStyle.WithShortWeekday, locale).replace(
            ',',
            '',
          );
          break;
        }
      }

      return {
        title: name,
        delta: item,
        day,
      };
    });

    return daysItems;
  }, [globalStorage.isAwakened]);
}
