import React from 'react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { SeriesItemType } from '@kaltura-ott/tvpil-shared';

import { useEpisodesContext } from 'context/episodesContext/episodesContext';
import { useRootStore } from 'hooks';

import Episode from './Episode/Episode';

import styles from './EpisodesList.module.scss';

function EpisodesList() {
  const rootStore = useRootStore();
  const { episodesStore } = useEpisodesContext();

  const ratio = rootStore?.detailsStore?.mainImageProperty?.ratio as AssetPresentationRatioEnum;
  return (
    <div className={styles.wrapper}>
      {(episodesStore?.items as SeriesItemType[])?.map((episode, index) => {
        return <Episode key={episode.id} episode={episode} index={index} presentationRatio={ratio} />;
      })}
    </div>
  );
}

export default EpisodesList;
