import { useCallback } from 'react';

import { Form, InputError } from 'hooks';
import { validateEmail } from 'utils';

interface Props {
  form: Form;
  setForm: (updatedForm: Form) => void;
  defaultInputError: InputError;
  setIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  callSignIn: () => Promise<void>;
}

export const useOnHandleSubmit = ({ form, setForm, defaultInputError, setIsPending, callSignIn }: Props) => {
  return useCallback(
    async (event: React.FormEvent<EventTarget>) => {
      event.preventDefault();
      const emailIsValid = validateEmail('email', form.email.value);
      const passwordIsValid = validateEmail('password', form.password.value);
      const updatedForm = {
        ...form,
        email: {
          ...form.email,
          isFailedValidation: !emailIsValid,
          errorMessage: defaultInputError.email,
        },
        password: {
          ...form.password,
          isFailedValidation: !passwordIsValid,
          errorMessage: defaultInputError.password,
        },
      };

      setForm(updatedForm);

      if (!emailIsValid || !passwordIsValid) {
        return;
      }

      setIsPending(true);
      await callSignIn();
      setIsPending(false);
    },
    [form],
  );
};
