import { useCallback, useEffect, useRef } from 'react';
import { DivNode } from 'types';

import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import { preloadImage, railObserver } from 'components/widgets/RailV2/utils';

/**
 * Custom hook that handles the skeleton reference for a rail component.
 * This hook is use to set up a callback function which will be called when the skeleton in the viewport of browser window.
 * The callback function takes a `DivNode` parameter representing the moundet node.
 * If the node parameter is defined/truthy, it means that node has been mounted and preloadImage helper function must be called with necessary parameters
 * The callback function depends on changes in the length of the item array
 *
 * @param setIsSkeletonConditionForward - A callback function that changes the local state of component
 * @param items - array of assets
 * @param railSettings - rails satting object
 * @returns A callback function that shows/hides the skeleton view
 */

export const useHandleSkeletonRef = (
  setIsSkeletonConditionForward: (value: React.SetStateAction<boolean>) => void,
  items: CollectionAsset[],
  railSettings: RailSettings,
) => {
  const cleanupFunction = useRef(false);

  useEffect(() => {
    return () => {
      cleanupFunction.current = true;
    };
  }, []);

  return useCallback(
    (node: DivNode) => {
      if (node) {
        // @ts-ignore
        node.callback = () => {
          preloadImage(cleanupFunction, setIsSkeletonConditionForward, items!, railSettings);
        };

        railObserver.observe(node);
      }
    },
    [items.length],
  );
};
