import { useEffect, useRef } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { preloadAssetsImage } from 'components/widgets/Rail/utils';

interface Props {
  setImagesLoadingForward: (value: React.SetStateAction<boolean>) => void;
  store: RailStoreV2;
}

export const useInitializationHook = ({ setImagesLoadingForward, store }: Props) => {
  const cleanupFunction = useRef(false);

  useEffect(() => {
    if (store.items.length) {
      // @ts-ignore
      preloadAssetsImage(cleanupFunction, setImagesLoadingForward, store);
    }

    return () => {
      cleanupFunction.current = true;
    };
  }, [store.items.length]);
};
