import { useHandleClickInfoButton } from '../useHandleClickInfoButton/useHandleClickInfoButton';
import { useInitializationHook } from '../useInitializationHook/useInitializationHook';
import { useMuteHook } from '../useMuteHook/useMuteHook';
import { useResolveConditionsVideoPlayout } from '../useResolveConditionsVideoPlayout/useResolveConditionsVideoPlayout';
import { useTimeRange } from '../useTimeRange/useTimeRange';
import { useWatchNowHandler } from '../useWatchNowHandler/useWatchNowHandler';

function useVideoPlayoutCombiner() {
  const { playableAsset, loaded } = useInitializationHook();
  const { muted, muteHandler } = useMuteHook();
  const {
    currentAsset,
    isContentBlockedAsAdult,
    program,
    assetId,
    programTitle,
    parentalRating,
    logoImages,
    programName,
    isPlayerCondition,
    isContentRestricted,
    isContentGeoBlocking,
    isLinear,
    isLinearWithoutCurrentProgram,
    isImagesCondition,
    isNoProgramCondition,
    isImagesErrorCondition,
    isMosaicProgram,
    setChannelLogoErrorForward,
  } = useResolveConditionsVideoPlayout();
  const watchNowHandler = useWatchNowHandler({
    isContentBlockedAsAdult,
    program,
    assetId,
    parentalRating,
    currentAsset,
    playableAsset,
  });
  const handleClickInfoButton = useHandleClickInfoButton(currentAsset);
  const { timeRange } = useTimeRange(program);

  return {
    muted,
    loaded,
    program,
    programTitle,
    programName,
    playableAsset,
    currentAsset,
    logoImages,
    timeRange,
    isLinear,
    isPlayerCondition,
    isContentRestricted,
    isContentGeoBlocking,
    isImagesCondition,
    isMosaicProgram,
    isContentBlockedAsAdult,
    isNoProgramCondition,
    isImagesErrorCondition,
    isLinearWithoutCurrentProgram,
    muteHandler,
    watchNowHandler,
    handleClickInfoButton,
    setChannelLogoErrorForward,
  };
}

export { useVideoPlayoutCombiner };
