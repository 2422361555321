import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { SHOW_TOAST_DELAY } from 'context/toast/toast';
import { useAppStore, useDetectLocation, useRootStore } from 'hooks';
import routesPaths from 'routesPaths';

import styles from './SeacrhWidget.module.scss';

interface Props {
  searchParam: string;
  setIsShowHistory: (value: boolean) => void;
  isShowHistory: boolean;
}

const MIN_SYMBOLS_TO_START_SEARCH = 3;

function SearchInput({ searchParam, setIsShowHistory, isShowHistory }: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isReadyForRedirectToSearch, setIsReadyForRedirectToSearch] = useState(false);
  const { isSearchPage } = useDetectLocation();
  const { appStore } = useAppStore();
  const rootStore = useRootStore();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { search } = rootStore!;
  const goBackUrl = state?.goBackUrl || '/';
  const isReady = search?.isReady && search.railStore && isReadyForRedirectToSearch;
  const isInitiated = search?.isInitiated;

  useEffect(() => {
    if (!isInitiated) {
      search?.init();
    }

    if (isSearchPage) {
      setIsReadyForRedirectToSearch(false);
    }

    if (!isSearchPage && isReady) {
      navigate(routesPaths.search.link(encodeURIComponent(searchValue)), { state: { goBackUrl: pathname } });
    }
  }, [isReady, isInitiated, searchValue]);

  useEffect(() => {
    if (isSearchPage && searchParam !== searchValue && searchParam.length >= MIN_SYMBOLS_TO_START_SEARCH) {
      setSearchValue(searchParam);
      search?.search(searchParam);
    }
  }, [searchParam]);

  const searchValueConditions = async (value: string) => {
    // clear search error if input is cleared (with same timeout as for toast appear)
    if (!value.length) {
      setTimeout(() => {
        appStore.clearAllApiError();
      }, SHOW_TOAST_DELAY);
    }

    if (value.length) {
      if (isShowHistory) {
        setIsShowHistory(false);
      }

      if (value.length >= MIN_SYMBOLS_TO_START_SEARCH) {
        await search?.search(value, !isSearchPage);
        // to prevent redirect to search page if error was received
        setIsReadyForRedirectToSearch(true);

        if (isSearchPage) {
          navigate(routesPaths.search.link(encodeURIComponent(value)), { state: { goBackUrl } }); // update url
        }
      }
    } else if (isSearchPage) {
      navigate(goBackUrl, { state: { isInputOpen: true } });
      setIsShowHistory(true);
    }
  };

  const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimmedValue = value.trimStart();

    setSearchValue(trimmedValue);
    await searchValueConditions(trimmedValue);
  };

  return (
    <input
      id='searchInput'
      autoComplete='off'
      className={styles.searchInput}
      value={searchValue}
      onChange={onChangeValue}
    />
  );
}

export default observer(SearchInput);
