import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { RailCardLogic } from '../RailCardLogic/RailCardLogic';
import { RailCardSkeleton } from '../RailSkeleton/RailSkeleton';

interface Props {
  asset: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function RailCardItem({ asset, itemWidth, imageHeight }: Props) {
  if (!asset?.id) {
    return <RailCardSkeleton height={imageHeight} width={itemWidth} />;
  }

  return <RailCardLogic itemWidth={itemWidth} asset={asset} />;
}

export { RailCardItem };
