import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { reaction } from 'mobx';

import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { BASE, FEATURES } from 'consts';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { useAppStore } from '../common/useAppStore';
import { useDetectLocation } from '../common/useDetectLocation';
import { useLoginStore } from '../common/useLoginStore';

export function useAppPreloadAfterLoad() {
  const {
    appStore,
    appStore: { isReady },
  } = useAppStore();
  const location = useLocation();
  const [isNotAllowedCall, setIsNotAllowedCall] = useState(false);
  const { isAnonymous, isPostLoginProcessing } = useLoginStore();
  const [isPlayerLoaded, setIsPlayerLoaded] = useState<boolean | undefined>(undefined);
  const { isUrlToFullPlayer } = useDetectLocation();
  const checkIsPlayerComponentPossible = useCallback(() => {
    return !!document.getElementById(BASE) || isUrlToFullPlayer;
  }, [isUrlToFullPlayer]);
  const isLoginPage = location.pathname.includes(routes.login.path);
  const callPreload = useCallback(() => {
    appStore.preloadAfterLoad(undefined, featureEnabler.variation(FEATURES.IS_NEW_EPG, true));
    setIsNotAllowedCall(true);
  }, []);
  const disposer = reaction(
    () => globalStorage.isPossiblePresentPlayer,
    (isPossiblePresentPlayer: boolean | undefined) => {
      setIsPlayerLoaded(isPossiblePresentPlayer);
    },
  );

  useEffect(() => {
    if (isLoginPage || isAnonymous || isNotAllowedCall || !isReady || isPostLoginProcessing) return;
    if (checkIsPlayerComponentPossible()) {
      if (isPlayerLoaded !== undefined) {
        callPreload();
      }
    } else {
      callPreload();
    }
    return () => {
      disposer();
    };
  }, [
    isReady,
    isAnonymous,
    isLoginPage,
    isNotAllowedCall,
    isPlayerLoaded,
    checkIsPlayerComponentPossible,
    callPreload,
    isPostLoginProcessing,
  ]);
}
