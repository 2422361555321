import { LinkToProp } from 'types';

import { UIDAPresentationTypeEnum, UIDAStructureTypeEnum } from '@kaltura-ott/tvpil';
import { PresentationAdditionalTypeEnum } from '@kaltura-ott/tvpil/interfaces';

export interface defaultStore {
  id: number;
  referenceId: string;
  type: UIDAStructureTypeEnum;
  subtype: UIDAPresentationTypeEnum | PresentationAdditionalTypeEnum;
}

// PerformanceEvents
// PerformanceEvents keys should be equal to dms.analytics.events keys
export enum PerformanceEvents {
  appStart = 'App Start',
  epgReady = 'EPG Ready',
  homePageReady = 'Home page Ready',
  loginSuccesses = 'Login Success',
  playbackResume = 'Playback resume',
  playbackStart = 'Playback Start',
  purchase = 'Purchase',
  spinner = 'Application Experience Spinners',
  interimTimeForloginSuccess = 'Interim Time',
}

export interface PerformanceEndTimeProps {
  value: PerformanceEvents;
  withDmsToggle?: boolean;
  hashKey?: string;
  param1?: string;
  param2?: string;
  isSendingAllowed?: boolean;
  additionalEventParam?: string;
}

export interface PerformanceContextType {
  resetEventsStartTime(): void;
  clearEvent(value: PerformanceEvents, hashKey?: string): void;
  getEvent(value: PerformanceEvents, hashKey?: string): any;
  setStartTime(value: PerformanceEvents, hashKey?: string, interimTime?: number): void;
  setEndTime({
    value,
    withDmsToggle,
    hashKey,
    param1,
    param2,
    isSendingAllowed,
    additionalEventParam,
  }: PerformanceEndTimeProps): void;
}

// ApplicationError
export enum ApplicationError {
  Boot = 'Boot',
  DeepLink = 'DeepLink',
  Details = 'DetailsPage',
  Epg = 'Epg',
  Favorites = 'Favorites',
  Household = 'Household',
  Menu = 'Menu',
  Overlay = 'Overlay',
  Purchase = 'Purchase',
  Pin = 'Pin',
  Playout = 'Playout',
  Recording = 'Recording',
  Search = 'Search',
  SelfServices = 'SelfServices',
  SelectServices = 'SelectServices',
  Settings = 'Settings',
  Subscriptions = 'Subscriptions',
}

export enum ErrorSource {
  BE = 'BE',
  Player = 'Player',
}

// ActionButton
export enum ActionButtonTypesEnum {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
}
export interface ActionButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  isLarge?: boolean;
  type?: ActionButtonTypesEnum;
  title?: string;
  to?: string | LinkToProp;
  icon?: JSX.Element;
  onClick?: Function;
  submit?: boolean;
  isPending?: boolean;
  dataType?: string;
  replace?: boolean;
  target?: '_blank'; // extend if will be needed
  forwardRef?: any;
}
