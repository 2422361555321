import { useEffect } from 'react';

import { applyStyle } from 'utils';

interface Props {
  refElementInner: any;
  componentHeight: number;
}

export const useApplyIncreaseHeightHook = ({ refElementInner, componentHeight }: Props) =>
  useEffect(() => {
    if (refElementInner.current) {
      applyStyle(refElementInner.current, '--increase-height', `${componentHeight}px`);
    }
  }, [refElementInner.current]); // eslint-disable-line
