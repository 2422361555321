import { MediaType } from '@kaltura-ott/tvpil';

import { getNormalizeMediaType } from 'utils';

export function getNormalizeOverlayMediaType(mediaTypeParam: string | undefined): MediaType | undefined {
  if (!mediaTypeParam) return;

  const mediaType = getNormalizeMediaType(mediaTypeParam);

  return mediaType !== MediaType.Linear ? mediaType : MediaType.Program;
}
