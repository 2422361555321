import React from 'react';

import { RatingV2 } from 'components/presenters/icons/Rating/RatingV2';

import { useCardContentCombiner } from '../CardContent/hooks/useCardContentCombiner/useCardContentCombiner';

import styles from './CardRating.module.scss';

function CardRating() {
  const { rating, isShowRating } = useCardContentCombiner();

  return (
    <div data-type='ratingInfo' className={styles.ratingInfoRow}>
      {isShowRating && <RatingV2 className={styles.rating} rating={rating} />}
      {/* TODO: must be added the FormattedMessage for AggregatedSeriesRecord in the next iteration */}
    </div>
  );
}

export default CardRating;
