import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { LanguageKeyEnum } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useOnClickOutside } from 'hooks';

import LanguagesList from '../../presenters/LanguagesList/LanguagesList';

import styles from './LanguagesDropDown.module.scss';

function LanguagesDropDown() {
  const {
    appStore: {
      localizationStore: { languageListForKey, displayLanguage, languageNativeNameByCode, languagesList },
    },
  } = useAppStore();
  const [list, setList] = useState([{ code: 'en', nativeName: 'English', isDefault: true }]);
  const [opened, setOpened] = useState(false);
  const [languageValue, setLanguage] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (languagesList.length) {
      setList(languageListForKey(LanguageKeyEnum.display));
      setLanguage(displayLanguage);
    }
  }, []); // eslint-disable-line

  useOnClickOutside(ref, () => setOpened(false));

  function toggleList() {
    setOpened(!opened);
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <button className={styles.current} onClick={toggleList} type='button'>
        <span className={styles.earth} />
        <span>{languageNativeNameByCode(languageValue)}</span>
        <span className={cn(styles.switcher, opened && styles.opened)} />
      </button>
      <div className={cn(styles.list, opened && styles.show)} onClick={toggleList} role='button' tabIndex={0}>
        <LanguagesList defaultValue={languageValue} isDropDown list={list} type='display' />
      </div>
    </div>
  );
}

export default LanguagesDropDown;
