import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BACK_NAVIGATION_TRY, FROM_REFERENCE_ID, LAST_VIEW_SEASON_NUMBER, SEARCH_STRING } from 'consts';
import { useDetectLocation, useSearchParam } from 'hooks';
import routes from 'routesPaths';

export const useCloseHandler = () => {
  const searchString = sessionStorage.getItem(SEARCH_STRING);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { isSearchPage } = useDetectLocation();
  const fromReferenceId = useSearchParam(FROM_REFERENCE_ID) || '';
  let backUrl = location.pathname.split(routes.details.commonPath)[0];

  if (fromReferenceId) {
    backUrl += `?${FROM_REFERENCE_ID}=${fromReferenceId}`;
  }

  return useCallback(() => {
    if (isSearchPage && searchString) {
      // to prevent delete search params in url
      navigate(routes.search.path + searchString, { state: { goBackUrl: state?.goBackUrl } });
    } else {
      navigate(backUrl || routes.home.path);
    }

    sessionStorage.removeItem(LAST_VIEW_SEASON_NUMBER);
    sessionStorage.removeItem(BACK_NAVIGATION_TRY);
  }, [backUrl, isSearchPage]); // eslint-disable-line
};
