import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RecordIcon } from 'components/presenters/icons';

interface Props {
  type: string;
  isActiveRecordIcon: boolean;
  defaultMessage: string;
  id: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  multiple?: boolean;
}

export default function RecordButton({
  type,
  isActiveRecordIcon,
  defaultMessage,
  id,
  onClick,
  className,
  multiple,
}: Props) {
  return (
    <button data-value={type} onClick={onClick} type='button'>
      <RecordIcon active={isActiveRecordIcon} className={className} multiple={multiple} />
      <FormattedMessage defaultMessage={defaultMessage} id={id} />
    </button>
  );
}
