import { useEffect } from 'react';

import { useMenuStore } from 'hooks';

export const useMenuInit = () => {
  const store = useMenuStore();

  useEffect(() => {
    if (store && !store.isReady) {
      store.init();
    }
  }, [store]);
};
