import React from 'react';
import cn from 'classnames';

import { RecordIcon } from 'components/presenters/icons';

import { Props } from '../../types';

import styles from './CardTitleStaticView.module.scss';

function CardTitleStaticView({
  title,
  isRecord,
  isWrapperWithIcon,
  isWithSeriesRecord,
  seasonEpisodeTitle,
  isSeriesType,
}: Props) {
  return (
    <>
      <div
        className={cn(
          styles.wrapper,
          { [styles.wrapperAsStaticInfoWithIcon]: isWrapperWithIcon },
          { [styles.seriesRecord]: isWithSeriesRecord },
        )}
      >
        <div className={styles.titleWrapper}>
          <h5>{title}</h5>
          {isRecord && (
            <span className={styles.recordIcon}>
              <RecordIcon active multiple={isSeriesType} />
            </span>
          )}
        </div>
        {seasonEpisodeTitle && <p>{seasonEpisodeTitle}</p>}
      </div>
    </>
  );
}

export default CardTitleStaticView;
