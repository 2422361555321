import React from 'react';
import { useIntl } from 'react-intl';

import { KuxImages } from '@kaltura-ott/tvpil';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { IMAGE_PARAM } from 'consts';
import { ActionButtonTypesEnum } from 'interface';
import { priceResolve, sizeImageResolve } from 'utils';

import styles from './Offer.module.scss';

interface Props {
  link: string;
  offer: {
    image: string;
    images: KuxImages;
    title: string;
    description: string;
    priceDescription?: string;
    price: {
      amount?: number;
      currency?: string;
      currencySign?: string;
    };
  };
  onHandleClick: Function;
}

export default function Offer({
  link,
  offer: { images, price, title = '', description = '', priceDescription = '' },
  onHandleClick,
}: Props) {
  const intl = useIntl();
  const imageResolve = sizeImageResolve({ images, size: 'large', imageParam: IMAGE_PARAM });

  return (
    <div className={styles.offer}>
      <div className={styles.descriptionBlock}>
        <h5 title={title}>{title}</h5>
        <p title={description}>{description}</p>
      </div>
      <div className={styles.imageBlock} style={{ backgroundImage: `url(${imageResolve})` }} />
      <div className={styles.priceBlock}>
        <p title={intl.formatNumber(price.amount!, { style: 'currency', currency: price.currency })}>
          {priceResolve(price, priceDescription)}
        </p>
      </div>
      <ActionButton
        className={styles.actionButton}
        onClick={onHandleClick}
        title={intl.formatMessage({ id: 'Settings.Subscriptions.offerButtonDetails' })}
        to={link}
        type={ActionButtonTypesEnum.primary}
      >
        {intl.formatMessage({ id: 'Settings.Subscriptions.offerButtonDetails' })}
      </ActionButton>
    </div>
  );
}
