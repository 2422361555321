import { useCallback } from 'react';

import { UIDAStructureTypeEnum } from '@kaltura-ott/tvpil';
import { SubpageStore } from '@kaltura-ott/tvpil-shared';

import { useRootStore } from '../common/useRootStore';
import { useSettingsStore } from '../common/useSettingsStore';

export function useAppFindPageStore() {
  const rootStore = useRootStore();
  const { settingsStore } = useSettingsStore();

  const findPageStore = useCallback(
    (pathname: string) => {
      return rootStore?.children?.find(
        ({ type, link }: any) => type === UIDAStructureTypeEnum.page && (link === pathname || pathname === '/'),
      );
    },
    [rootStore],
  );

  const findSettingsSubStore = useCallback((pathname: string) => {
    return settingsStore?.children.find((subPage) => pathname.includes((subPage as SubpageStore).link));
  }, []);

  return { findPageStore, findSettingsSubStore };
}
