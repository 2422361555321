import React from 'react';

import styles from '../../PackageDetails.module.scss';

interface Props {
  disclaimer: string;
}

function PinCodeDisclaimer({ disclaimer }: Props) {
  return disclaimer ? <p className={styles.pinCodeDisclaimer}>{disclaimer.substr(0, 150)}</p> : null;
}

export default PinCodeDisclaimer;
