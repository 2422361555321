import { millisecondsInSecond } from 'date-fns';

import { ConnectivityType, MediaType, PlaybackBaseEventData } from '@kaltura-ott/tvpil-analytics';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

const STARTOVER = 'STARTOVER';
export const IS_BOOKMARK_SENT = 'isBookmarkSent';

export type DataAnalyticsType = {
  player: any;
};

export class PlaybackBaseEvent {
  readonly player: any;

  private mediaType: MediaType | typeof STARTOVER = MediaType.live;

  private playbackUrl: string = '';

  private fileId: string = '';

  private fileType: string = 'n/a';

  private bitrate: number = 0;

  private mediaResolution: string = '';

  protected playbackBaseEventData: PlaybackBaseEventData | {} = {};

  constructor(data: DataAnalyticsType) {
    this.player = data.player;
  }

  getMediaType() {
    const { type } = playoutStoreDataResolver();

    if (type === PlayoutStoreV2Type.START_OVER) {
      this.mediaType = STARTOVER;
      return;
    }
    if (type === PlayoutStoreV2Type.LIVE) {
      this.mediaType = MediaType.live;
      return;
    }
    if (type === PlayoutStoreV2Type.CATCHUP) {
      this.mediaType = MediaType.catchup;
      return;
    }
    if (type === PlayoutStoreV2Type.NPVR) {
      this.mediaType = MediaType.recording;
      return;
    }
    this.mediaType = MediaType.vod;
  }

  getFileInfo() {
    if (this.player?.selectedSource) {
      const { id = null, url } = this.player?.selectedSource;
      if (id) {
        const [fileId, fileType] = id.split(',');

        this.fileId = fileId;
        this.fileType = fileType;
        this.playbackUrl = url;
      }
    }
  }

  getBitrate() {
    if (this.player?.getActiveTracks()?.video) {
      // eslint-disable-next-line no-underscore-dangle
      this.bitrate = this.player?.getActiveTracks()?.video?._bandwidth;
    }
  }

  getMediaResolution() {
    if (this.player?.getActiveTracks()?.video) {
      const { width, height } = this.player?.getActiveTracks()?.video;
      this.mediaResolution = `${width}x${height}`;
    }
  }

  getActiveTracks() {
    return this.player?.getActiveTracks();
  }

  getPlaybackBaseEventData() {
    const { currentEntity, record, seriesRecord, startDate, type } = playoutStoreDataResolver();
    const bookmark = currentEntity?.bookmark;
    const isLiveOrStartOver = type === PlayoutStoreV2Type.LIVE || type === PlayoutStoreV2Type.START_OVER;
    const isBookmarkSent = !!sessionStorage.getItem(IS_BOOKMARK_SENT);
    let mediaPosition = this.player?.currentTime;

    if (isLiveOrStartOver) {
      mediaPosition = Number(((Date.now() - startDate) / millisecondsInSecond).toFixed(3));
    } else if (bookmark && bookmark.position && !isBookmarkSent) {
      mediaPosition = bookmark.position;
    }

    this.getFileInfo();
    this.getBitrate();
    this.getMediaType();
    this.getMediaResolution();

    this.playbackBaseEventData = {
      mediaId: (currentEntity as any)?.linearId || currentEntity?.id,
      mediaPosition,
      epgId: currentEntity?.id?.toString(),
      recordingId: record?.data.id.toString() || seriesRecord?.data.id.toString() || 'n/a',
      mediaType: this.mediaType,
      fileId: this.fileId,
      fileType: this.fileType,
      bitrate: this.bitrate,
      mediaResolution: this.mediaResolution,
      connectivity: ConnectivityType.ott,
      playbackUrl: this.playbackUrl,
    };
  }
}
