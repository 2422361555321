import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore } from '@kaltura-ott/tvpil-shared';

function useLoginPageStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  return appStore.rootStore.login;
}

export { useLoginPageStore };
