import {
  MediaType,
  UserActionType,
  UserClickedContentDetailsEventArgs,
  UserClickedContinueEventArgs,
  UserClickedDeepLinkEventArgs,
  UserEnteredSelfServiceEventArgs,
  UserFavoriteAddEventArgs,
  UserFavoriteRemoveEventArgs,
  UserPurchaseTryEventArgs,
} from '@kaltura-ott/tvpil-analytics';
import { appStore } from '@kaltura-ott/tvpil-shared';

export const userActionEvents = (
  event: UserActionType,
  assetId?: string | number,
  param2?: string | MediaType,
  param3?: boolean,
) => {
  const { dms, kuxAnalytics } = appStore;

  if (!dms.analytics?.events?.allowDeviceUserActionEvents) return;

  switch (event) {
    case UserActionType.selfSrv:
      kuxAnalytics?.send(new UserEnteredSelfServiceEventArgs());
      break;
    case UserActionType.continue:
      kuxAnalytics?.send(new UserClickedContinueEventArgs(String(assetId!), param2 as MediaType));
      break;
    case UserActionType.details:
      kuxAnalytics?.send(new UserClickedContentDetailsEventArgs(String(assetId!), param2 as MediaType));
      break;
    case UserActionType.buyTry:
      // only for TVOD
      kuxAnalytics?.send(new UserPurchaseTryEventArgs(String(assetId!), MediaType.vod));
      break;
    case UserActionType.favoriteAdd:
      // only for LIVE
      kuxAnalytics?.send(new UserFavoriteAddEventArgs(String(assetId!), MediaType.live));
      break;
    case UserActionType.favoriteRemove:
      // only for LIVE
      kuxAnalytics?.send(new UserFavoriteRemoveEventArgs(String(assetId!), MediaType.live));
      break;
    case UserActionType.deepLink:
      kuxAnalytics?.send(new UserClickedDeepLinkEventArgs(String(assetId!), param2!, param3!));
      break;
    default:
      break;
  }
};
