import React from 'react';
import cn from 'classnames';

import Img from 'components/presenters/Img/Img';
import { getDefaultImagePath } from 'utils';

import styles from '../../VideoContent.module.scss';

type Props = {
  title: string;
  staticAssetsUrl: string;
  isLandscapeRatio?: boolean;
  image?: string;
};

export const Image = ({ title, staticAssetsUrl, isLandscapeRatio, image }: Props) => (
  <div className={styles.image}>
    <div>
      <Img
        alt={title}
        className={cn(styles.image, { [styles.imageEmpty]: !image })}
        srcList={[image!, `${staticAssetsUrl}${getDefaultImagePath(isLandscapeRatio)}`]}
      />
    </div>
  </div>
);
