import { useCallback } from 'react';

import { epgLocalDrugStorage } from '../../../../storages';

export const useOnHandleDragStart = (userActive: React.MutableRefObject<boolean>) =>
  useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.button) return;

    const { clientX, clientY } = e;

    epgLocalDrugStorage.setDragState({ xStart: clientX, yStart: clientY }, { status: 'clicked' });

    if (!userActive.current) {
      userActive.current = true;
    }
  }, []);
