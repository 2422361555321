import { useLocation } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { overlayStorage } from 'components/widgets/Player/storages';
import { useDetectLocation } from 'hooks';
import routes from 'routesPaths';
import { resolveLinkForMd } from 'utils';

export function useResolvePathToWatchLive(asset: ViewableEntitiesType) {
  const location = useLocation();
  const isOverlayOpen = overlayStorage.getShowOverlay();
  const { isLive, linear, mediaType: assetMediaType } = detailsStoreDataResolver(asset);
  const channelId = linear?.id || 0;
  const mediaType = assetMediaType.toLowerCase();
  const { isMdUiDisable } = useDetectLocation();

  if (!isOverlayOpen && isLive) {
    const goBackUrl = `${location.pathname}${location.search}`;
    let link = routes.live.link(channelId, mediaType);

    link += resolveLinkForMd(isMdUiDisable, link); // to avoid displaying MD components in the player

    return { to: link, state: { goBackUrl } };
  }

  return null;
}
