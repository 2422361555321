import { globalStorage } from 'services/globalStorage';

export function saveAssetToGlobalStorage(asset: any, isLive = false) {
  let entity = asset;

  if (isLive) {
    entity = asset.linear;
  }

  globalStorage.setFocusAsset(entity);
}
