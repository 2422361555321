import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Observer, Provider } from 'mobx-react';
import { router } from 'router';

import { appStore, generateUdid } from '@kaltura-ott/tvpil-shared';

import { DISPLAY_NAME, ROOT, UDID } from 'consts';
import localStorageService from 'services/localStorageService';
import { getSilentLoginParams, onDmsReady, WebLaunchdarklyClient } from 'utils';

import './utils/wdyr';

import config, { APP_TITLE } from './config';

import './assets/styles/global.scss';

const udid = localStorageService.getItem(UDID) || generateUdid(Math.random().toString().substring(2, 16), 'WEB');

if (!localStorageService.getItem(UDID)) {
  localStorageService.setItem(UDID, udid);
}

const container = document.getElementById(ROOT);
const root = createRoot(container!);

(async function initApp() {
  document.title = localStorageService.getItem(DISPLAY_NAME) || APP_TITLE[import.meta.env.VITE_PARTNER_ID!] || '';

  if (!appStore) return;

  await appStore.init({
    ...config,
    ...getSilentLoginParams(),
    udid,
    cacheService: localStorageService,
    useParentalPinStoreDepreciated: false,
    release: `${import.meta.env.VITE_APP_NAME}@${import.meta.env.VITE_VERSION}`,
    featureEnabledOptions: {
      ffClient: new WebLaunchdarklyClient(),
    },
    onDmsReady,
  });
})();

root.render(
  <Provider appStore={appStore}>
    <Observer>{() => <RouterProvider router={router} />}</Observer>
  </Provider>,
);
