import { useCallback } from 'react';
import { Node } from 'types';

import { useMenuStore } from 'hooks';
import { applyStyleHexToHSL, applyStyles } from 'utils';

export const useHandleRef = () => {
  const store = useMenuStore();
  return useCallback((node: Node) => {
    const { uiDesignElements } = store;

    if (uiDesignElements && node !== null) {
      applyStyles(node, uiDesignElements);
      applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
    }
  }, []); // eslint-disable-line
};
