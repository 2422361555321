import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './ExpiresBadge.module.scss';

export function ExpiresBadge({
  className,
  translationId,
  expiredInDays,
}: {
  className?: string;
  translationId: string;
  expiredInDays: number;
}) {
  const intl = useIntl();
  let message;

  if (expiredInDays === 0) {
    message = intl.formatMessage({
      id: `${translationId}.expiresToday`,
      defaultMessage: 'EXPIRES TODAY',
    });
  } else if (expiredInDays === 1) {
    message = intl.formatMessage({
      id: `${translationId}.expiresTomorrow`,
      defaultMessage: 'EXPIRES TOMORROW',
    });
  } else {
    message = (
      <FormattedMessage
        defaultMessage='{days, plural, few {EXPIRES IN # DAYS} other {EXPIRES IN # DAYS}}'
        id={`${translationId}.expiresDays.${intl.formatPlural(expiredInDays)}`}
        values={{ days: expiredInDays }}
      />
    );
  }

  return <span className={cn(styles.expiresBadge, className)}>{message}</span>;
}
