import React from 'react';
import { observer } from 'mobx-react';

import BlockContentChecker from 'components/pages/Video/BlockContentChecker';

import KalturaPlayer from './KalturaPlayer';

interface Props {
  assetId: number;
  isShowStreams?: boolean;
}

const LivePlayer: React.FC<Props> = ({ assetId, isShowStreams }) => {
  return (
    <BlockContentChecker>
      {(isAllowed) => (isAllowed ? <KalturaPlayer assetId={assetId!} isShowStreams={isShowStreams} /> : null)}
    </BlockContentChecker>
  );
};

export default observer(LivePlayer);
