import { useMemo } from 'react';

import { AssetPresentationRatioEnum, getWidthByHeight } from '@kaltura-ott/tvpil';

import { DEFAULT_HEIGHT } from 'consts';
import { useAppStore, useIsUrlToFullPlayer } from 'hooks';
import { getDefaultImagePath, getUserActionAdditionalParams } from 'utils';

import { useAssetCardContext } from '../../../AssetCardContext/AssetCardContext';

export const useAssetCardBodyResolveData = () => {
  const {
    listStore,
    resolvedProps: { currentAsset, imageUrl, isNoData, link, isSingleVod, isExternalProviderAsset },
  } = useAssetCardContext();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const isUrlToFullPlayer = useIsUrlToFullPlayer(link);
  const itemImage = useMemo(() => listStore.itemImage(currentAsset.data), [currentAsset.data.id]); // eslint-disable-line
  const height = itemImage?.height || DEFAULT_HEIGHT;
  const width = itemImage?.width || getWidthByHeight(height, AssetPresentationRatioEnum['16x9']);
  const ratio = width / height;
  const defaultImage = `${staticAssetsUrl}${getDefaultImagePath(ratio > 1)}`;
  const isPossibleToSaveAssetToGlobalStorage = isUrlToFullPlayer && !currentAsset?.isPurchasable!;
  const itemImageUrl = imageUrl !== null ? itemImage?.imageUrl || defaultImage : undefined;
  const isNoDataWithNoLogoImg = isNoData && itemImageUrl === defaultImage;
  const isPlayableProviderAsset = isSingleVod && isExternalProviderAsset;
  const { mediaType: mediaTypeForUserEvent } = getUserActionAdditionalParams(currentAsset);

  return {
    mediaTypeForUserEvent,
    isPlayableProviderAsset,
    isNoDataWithNoLogoImg,
    isPossibleToSaveAssetToGlobalStorage,
    isUrlToFullPlayer,
    itemImageUrl,
    itemImage,
    defaultImage,
    height,
    width,
  };
};
