import { useEffect } from 'react';

import { pincodeLength } from 'components/widgets/Modal/constants';

export const useSetAutoFocusAndSubmit = (
  pinCode: string,
  inputWrapper: React.RefObject<HTMLDivElement>,
  submit: (event?: any) => Promise<void>,
) => {
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    const node = inputWrapper?.current?.childNodes[0] as HTMLInputElement;

    if (node && !pinCode) {
      timeout = setTimeout(() => {
        node.focus();
      }, 0);
    }

    if (pinCode.length === pincodeLength) {
      submit();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [pinCode]); // eslint-disable-line
};
