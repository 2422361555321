import React from 'react';
import cn from 'classnames';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { NEXT_BUTTON_WIDTH } from 'components/widgets/Rail/constants';
import { DEFAULT_HEIGHT } from 'consts';

import styles from './NavigationButton.module.scss';

export enum Position {
  Left,
  Right,
}
interface Props {
  position: Position;
}

const NavigationButton: React.FC<Props> = ({ position }) => {
  const { handleClickNavigationButton, store } = useRailHorisontalDataContext();
  const { railHeight } = store;

  function handleClick() {
    handleClickNavigationButton(position === Position.Right);
  }
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.left]: position === Position.Left,
        [styles.right]: position === Position.Right,
      })}
      style={{
        height: railHeight || DEFAULT_HEIGHT,
      }}
    >
      <button
        className={cn(styles.nextButton)}
        onClick={handleClick}
        style={{ width: NEXT_BUTTON_WIDTH }}
        type='button'
      >
        &gt;
      </button>
    </div>
  );
};

export default NavigationButton;
