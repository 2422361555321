import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';

import { HouseholdDevice } from '@kaltura-ott/tvpil';

import { DefaultModal } from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useAppStore, useConcurrencyStore } from 'hooks';
import { getConcurrencyIcon } from 'utils';

import { useButtonsArray } from './hooks/useButtonsArray';
import { COMPONENT_NAME, CONCURRENCY_ICON_PATH } from './constants';

import styles from './ConcurrencyModal.module.scss';

function ConcurrencyModal({ hideUI, goBack }: { hideUI: boolean; goBack(): void }) {
  const intl = useIntl();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const concurrencyStore = useConcurrencyStore();
  const { concurrentLimit, totalStreamingDevices } = concurrencyStore.household!;
  const devices = concurrencyStore.streamingDevices
    ?.map((device: HouseholdDevice) => device.name || device.deviceBrandName)
    .join(', ');
  const buttons = useButtonsArray();
  const description = intl.formatMessage(
    {
      defaultMessage: `{concurrentLimit, plural, one {Your plan allows you up to # stream at a time <br /> Please make sure other streams are stopped${
        concurrencyStore.concurrencyBuymore ? ' or buy more streams' : ''
      }} other {Your plan allows you up to # streams at a time <br /> Please make sure other streams are stopped${
        concurrencyStore.concurrencyBuymore ? ' or buy more streams' : ''
      }}}`,
      id: `${COMPONENT_NAME}.streamsDescription${
        concurrencyStore.concurrencyBuymore ? 'WithBuyMore' : ''
      }.${intl.formatPlural(concurrentLimit)}`,
    },
    { concurrentLimit, br: <br /> },
  ) as string;

  const handleGoBack = useCallback(() => {
    if (hideUI) {
      return;
    }
    goBack();
  }, [hideUI, goBack]);

  return (
    <DefaultModal
      buttonsArray={buttons}
      className={styles.concurrencyModal}
      description={description}
      onDecline={handleGoBack}
      skipClickOutside
      title={intl.formatMessage({ defaultMessage: 'Sorry, Too Many Streams', id: `${COMPONENT_NAME}.title` })}
    >
      <div className={styles.streamingDevices}>
        <div>
          <SVG src={`${staticAssetsUrl}${getConcurrencyIcon(CONCURRENCY_ICON_PATH)}`} />
          <FormattedMessage
            defaultMessage='{totalStreamingDevices, plural, one {# device currently streaming} other {# devices currently streaming}}'
            id={`${COMPONENT_NAME}.streamingDevices.${intl.formatPlural(totalStreamingDevices || 0)}`}
            tagName='p'
            values={{ totalStreamingDevices }}
          />
        </div>
        <p>{devices}</p>
      </div>
    </DefaultModal>
  );
}

export default ConcurrencyModal;
