import React from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { RatingProviderEnum } from '@kaltura-ott/tvpil';
import { EntityRating } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks';
import { getRatingIconPath } from 'utils';

import styles from './Rating.module.scss';

interface Props {
  rating: EntityRating;
  className?: string;
  isDetailsInfo?: boolean;
}

export function Rating({ rating, className, isDetailsInfo = false }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const image = isDetailsInfo ? rating?.long : rating?.short;
  const imageSrc = `${staticAssetsUrl}${getRatingIconPath(image)}`;

  if (!image) {
    return null;
  }

  return (
    <div className={cn(styles.rating, className, { [styles.detailsInfo]: isDetailsInfo })}>
      <SVG className={cn({ [styles.star]: image.includes(RatingProviderEnum.star) })} src={imageSrc} />
      <span>{rating.value}</span>
    </div>
  );
}
