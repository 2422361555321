import { globalStorage } from 'services/globalStorage';

import { handleLoadPlayer } from '../../handlers/handleLoadPlayer/handleLoadPlayer';
import { contextStorage, intlStorage } from '../../storages';

export async function mountActions(hideUI: boolean, muted: boolean, assetId: number, intl: any, context: any) {
  intlStorage.setIntl(intl);
  contextStorage.setContext(context);
  globalStorage.setIsFullPlayer(!hideUI);

  await handleLoadPlayer(hideUI, muted, assetId);
}
