import { MutableRefObject, SetStateAction, useEffect } from 'react';

export const useNextButtonsDuringTotalWidth = ({
  scrolling,
  offset,
  totalWidth,
  refElementInner,
  setState,
}: {
  scrolling: MutableRefObject<boolean>;
  offset: MutableRefObject<number> | undefined;
  totalWidth: number;
  refElementInner: MutableRefObject<HTMLDivElement | null>;
  setState: (
    value: SetStateAction<{
      isLeftNextButton: boolean;
      isRightNextButton: boolean;
    }>,
  ) => void;
}) => {
  useEffect(() => {
    if (!scrolling.current) {
      setState({
        isLeftNextButton: offset?.current! > 0,
        isRightNextButton:
          offset?.current! + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
      });
    }
  }, [totalWidth]);
};
