import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { useGridMatchPath } from '../../hooks/useGridMatchPath/useGridMatchPath';

/**
 * This component is for comparing id's and force updating Grid component if prev id and current are not equal
 *
 * Please note:
 * It's only Hook component.
 */

interface Props {
  setForceUpdate(val: any): void;
  path: string;
}

function GridUpdatingHook({ setForceUpdate, path }: Props) {
  const location = useLocation();
  const { railId } = useGridMatchPath(path);
  const prevId = useRef(railId);

  useEffect(() => {
    if (prevId.current !== railId) {
      prevId.current = railId;

      setForceUpdate((val: boolean) => {
        return !val;
      });
    }
  }, [location]);

  return null;
}

export default GridUpdatingHook;
