import { useCallback, useEffect, useRef } from 'react';
import { DivNode } from 'types';

import { applyStyle } from 'utils';

export const useHandleRef = () => {
  const ref = useRef<DivNode>(null);

  useEffect(() => {
    if (ref.current) {
      const parent = ref.current.closest('a');
      if (parent) {
        applyStyle(parent, '--textBlockHeight', `${Math.ceil(ref.current.getBoundingClientRect().height)}px`);
      }
    }
  }, [ref.current]);

  return useCallback((node: DivNode) => {
    if (node) {
      ref.current = node;
    }
  }, []);
};
