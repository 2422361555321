import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { Program } from '@kaltura-ott/tvpil-shared';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { LAST_SCROLL_Y_POSITION, SEARCH_STRING } from 'consts';
import { useDetectLocation, useRootStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { getUserActionAdditionalParams, resolveLinkForMd, resolvePathToDetailsPage, userActionEvents } from 'utils';

import { useAssetCardContext } from '../../../../AssetCardContext/AssetCardContext';

export const useHandleClickInfo = () => {
  const { search } = useRootStore();

  const navigate = useNavigate();
  const { isSearchPage } = useDetectLocation();
  const location = useLocation();
  const { state } = location;
  const {
    listStore,
    resolvedProps: {
      isNpvr,
      currentAsset,
      isSuppressedEpisode,
      isAggregatedSeriesRecord,
      isGroupedSeries,
      isProgramRecord,
      seriesId,
      mediaTypeParam,
      isFutureProgram,
      isMdCondition,
      isShowedOnlyInfo,
      isNoData,
    },
  } = useAssetCardContext();
  const { linearId, record } = currentAsset as Program;
  const { assetId, mediaType: mediaTypeForUserEvent } = getUserActionAdditionalParams(currentAsset);
  const { onCardClick: onClick } = useRailHorisontalDataContext();
  const isSingleRecord = isNpvr && !!record?.isSingleRecord;
  const isPreventCondition = (!isNpvr || isSingleRecord) && !isNoData;

  const handleClickInfo = useCallback(
    async (e: React.MouseEvent) => {
      if (isPreventCondition) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (isShowedOnlyInfo || isNoData) return;

      if (onClick) {
        onClick(linearId);
      }

      if (isSearchPage) {
        await search!.addToSearchHistory(currentAsset);
        sessionStorage.setItem(SEARCH_STRING, location.search);
      }

      if (!isSuppressedEpisode) {
        globalStorage.setDetailsAsset(currentAsset);
      }

      userActionEvents(UserActionType.details, assetId, mediaTypeForUserEvent);

      let link = resolvePathToDetailsPage({
        isAggregatedSeriesRecord,
        isGroupedSeries,
        isProgramRecord,
        seriesId,
        subtype: listStore.subtype,
        id: assetId,
        mediaTypeParam,
        isFuture: isFutureProgram, // for avoid the bugs with future recording
        isSuppressedEpisode,
      });

      link += resolveLinkForMd(isMdCondition, link, listStore.subtype); // to avoid displaying MD components in the player

      navigate(link, { state: { goBackUrl: state?.goBackUrl } });

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());
    },
    [isShowedOnlyInfo, assetId, location.search], // eslint-disable-line
  );

  return { handleClickInfo };
};
