import { useEffect } from 'react';

import useRouteChange from '../useRouteChange/useRouteChange';

interface Props {
  isShowHistory: boolean;
  isSearchPage: boolean;
  setIsShowHistory: (value: boolean) => void;
}

export const useCloseSearchHistory = ({ isShowHistory, isSearchPage, setIsShowHistory }: Props) => {
  const hasRouteChanged = useRouteChange();

  useEffect(() => {
    if (hasRouteChanged() && isShowHistory && isSearchPage) {
      setIsShowHistory(false);
    }
  }, [hasRouteChanged, isShowHistory]);
};
