import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { Program } from '@kaltura-ott/tvpil-shared';

import { RecordIcon, SvgView } from 'components/presenters/icons';

import { calculateOffset } from '../../../../../../../../utils';

import { ellipsis } from './constants';
import { useProgramHooksCombiner } from './hooks';

import styles from './ProgramCard.module.scss';

interface Props {
  program: Program;
}

function ProgramCard({ program }: Props) {
  const {
    isLoading,
    clickHandler,
    epgParams,
    width,
    duration,
    startDate,
    isFutureDate,
    title,
    isDescriptionHidden,
    time,
    isOnlyMd,
    isWithoutMd,
    isCatchUpWithNoData,
    isRestrictedCatchup,
    isRecordBtnCondition,
    isMdIconCondition,
    isSeriesRecord,
    iconMDUrl,
    isTitleEllipsis,
    isTimeEllipsis,
    isAdultCathUpPlaybackEnabled,
  } = useProgramHooksCombiner(program);

  return (
    <div
      className={cn(styles.wrapper, { [styles.loading]: isLoading })}
      style={{
        width,
        minWidth: width,
        left: calculateOffset(startDate, epgParams, width, duration),
      }}
    >
      <button
        className={cn(styles.programCard, {
          [styles.next]: isFutureDate,
          [styles.titleEllipsis]: isTitleEllipsis,
          [styles.timeEllipsis]: isTimeEllipsis,
          [styles.empty]: isDescriptionHidden,
          [styles.onlyMd]: isOnlyMd, // To display the MD icon with a short card length
          [styles.withoutMd]: isWithoutMd, // To hide the MD icon with a short card length
          [styles.noData]: isCatchUpWithNoData,
        })}
        onClick={clickHandler}
        type='button'
      >
        <h5>{title}</h5>
        <span className={cn(styles.ellipsis, styles.title)}>{ellipsis}</span>

        <div className={styles.timeWrapper}>
          {((!isCatchUpWithNoData && !isRestrictedCatchup) || isAdultCathUpPlaybackEnabled) && (
            <>
              <span className={styles.time}>{time}</span>
              {!isRecordBtnCondition && <span className={cn(styles.ellipsis, styles.time)}>{ellipsis}</span>}
            </>
          )}
          {isRecordBtnCondition && <RecordIcon active className={styles.recordIcon} multiple={!!isSeriesRecord} />}
          {isMdIconCondition && (
            <span className={styles.mdIcon}>
              <SvgView url={iconMDUrl} />
            </span>
          )}
        </div>
      </button>
    </div>
  );
}

export default observer(ProgramCard);
