import { useEffect } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { PerformanceContextType, PerformanceEvents } from 'interface';

interface Props {
  store: RailStoreV2;
  isHomePageFirstRail: boolean;
  isImagesLoading: boolean;
  performanceEventsContext: PerformanceContextType;
}

export const useAppStartAndHomePageReady = ({
  store,
  isImagesLoading,
  isHomePageFirstRail,
  performanceEventsContext,
}: Props) => {
  useEffect(() => {
    const isPerformanceEvent = isHomePageFirstRail && store.isReady && !isImagesLoading;
    if (isPerformanceEvent) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.appStart });
      performanceEventsContext.setEndTime({ value: PerformanceEvents.homePageReady });
    }
  }, [isHomePageFirstRail, isImagesLoading, store.isReady, performanceEventsContext]);
};
