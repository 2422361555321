import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import MoreButton from '../MoreButton/MoreButton';

import styles from './Title.module.scss';

interface Props {
  isLogoRail: boolean;
  store: RailStoreV2;
  forwardHeaderRef: any;
}

const Title = ({ forwardHeaderRef, isLogoRail, store }: Props) => {
  const { name: title, subtype, presentShowMore } = store;
  const isShowingTitle = !!(title && !isLogoRail && subtype !== UIDAPresentationTypeEnum.hero);

  return (
    <>
      {isShowingTitle ? (
        <header className={styles.header} ref={forwardHeaderRef}>
          <h3>{title}</h3>
          {presentShowMore && <MoreButton />}
        </header>
      ) : null}
    </>
  );
};

export default React.memo(Title);
