import { UAParser } from 'ua-parser-js';

import { getIPad13, isTabletType } from 'utils';

export function useDeviceCheck() {
  const { userAgent } = window.navigator;
  const UserAgentInstance = new UAParser(userAgent);
  const device = UserAgentInstance.getDevice();

  return {
    isTablet: isTabletType(device) || getIPad13(),
  };
}
