import { useRootStore } from 'hooks';

function useDataFromRootStore() {
  const rootStore = useRootStore();

  const { firstEpisode, mosaicAsset } = rootStore?.detailsStore!;

  return { firstEpisode, mosaicAsset };
}

export { useDataFromRootStore };
