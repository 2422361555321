import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

import { contextStorage, playerStorage } from '../../storages';

export async function handlePlaybackEnded() {
  const {
    routerContext: { goBack },
  } = contextStorage.getContext();
  const {
    autoNextEpisode,
    isMovie,
    isVodEpisode,
    type,
    showSeriesPage,
    smartNextEpisode,
    autoplayNextProgram,
  } = playoutStoreDataResolver();
  const isBingeWatching = isVodEpisode && (autoNextEpisode || smartNextEpisode) && !showSeriesPage;
  const isGoBack =
    type === PlayoutStoreV2Type.NPVR ||
    (isMovie && !isBingeWatching && !autoplayNextProgram) ||
    (isVodEpisode && showSeriesPage);

  if (type === PlayoutStoreV2Type.VOD) {
    playerStorage.setIsPlayBackPaused(true);
  }

  if (isGoBack) {
    return goBack();
  }
}
