import React, { useCallback } from 'react';
import cn from 'classnames';

import { KalturaCategoryMeta } from '@kaltura-ott/tvpil';

import { applyStyle } from 'utils';

import styles from './ContentMarker.module.scss';

interface ContentMarkerType {
  text: KalturaCategoryMeta | string;
  background?: KalturaCategoryMeta | string;
  color?: KalturaCategoryMeta | string;
}

export function ContentMarker({ className, contentMarker }: { className?: string; contentMarker: ContentMarkerType }) {
  const { background, color, text } = contentMarker;

  const handleRef = useCallback((node: HTMLElement) => {
    if (background) {
      applyStyle(node, '--background-color', background as string);
    }
    if (color) {
      applyStyle(node, '--text-color', color as string);
    }
  }, []);

  return (
    <span ref={handleRef} className={cn(styles.contentMarker, className)}>
      {text as string}
    </span>
  );
}
