import routes from 'routesPaths';

/*
 * This function takes an original path, and a modifier path.
 * It iterates over the routes and checks if the original path includes any of them.
 * If a matching route is found, it modifies the original path by replacing the route with the modifier path.
 *
 * Most affected scenario is going back on a details page with any asset cards in the background (grids/rails)
 */
const modifyPathForDetails = (originalPath: string, modifierPath: string) => {
  const pathsForCheck = [routes.details.pathDetailsInfo.path, routes.details.pathDetailsSeries.path];

  const foundPath = pathsForCheck.find((path) => originalPath.includes(path));

  if (foundPath) {
    const index = originalPath.indexOf(foundPath);
    return `${originalPath.slice(0, index)}${modifierPath}`;
  }
};

export { modifyPathForDetails };
