import React, { useEffect, useRef, useState } from 'react';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  transitionClassNames: {
    enterActive?: string;
    enter?: string;
    exitActive?: string;
    exit?: string;
  };
  className?: string;
  timeout?: number;
  unmountOnTransitionExit?: boolean;
}

export function Transition({
  children,
  className = '',
  isLoading,
  timeout = 0,
  transitionClassNames,
  unmountOnTransitionExit = false,
}: Props) {
  const [render, setRender] = useState(true);
  const prevIsLoading = useRef(isLoading);
  const ref = useRef<HTMLDivElement>(null);
  const timerId = useRef<number | undefined>();
  const { enterActive = '', enter = '', exitActive = '', exit = '' } = transitionClassNames;

  function updateClassName(baseClassName: string, activeClassName: string) {
    ref.current!.className = `${className} ${baseClassName}`;

    window.setTimeout(() => {
      if (ref.current) {
        ref.current.className = `${className} ${activeClassName}`;
      }
    }, timeout);
  }

  useEffect(() => {
    if (ref?.current! && isLoading !== prevIsLoading.current) {
      prevIsLoading.current = isLoading;

      if (isLoading && enter) {
        updateClassName(enter, enterActive);
      }

      if (!isLoading && exit) {
        updateClassName(exit, exitActive);
      }
    }

    return () => {
      window.clearTimeout(timerId.current);
    };
  }, [isLoading]);

  function onTransitionEnd() {
    if (!isLoading && unmountOnTransitionExit) {
      setRender(false);
    }
  }

  return render ? (
    <div ref={ref} className={className} onTransitionEnd={onTransitionEnd}>
      {children}
    </div>
  ) : null;
}
