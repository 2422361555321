import React, { useState } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useInitializationHook } from '../../hooks';

function RailGridInitializer({ store }: { store: RailStoreV2 }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isImagesLoading, setImagesLoadingForward] = useState(false);

  useInitializationHook({ setImagesLoadingForward, store });

  return null;
}
export default React.memo(RailGridInitializer);
