import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { LinkAsAsset, PageStore } from '@kaltura-ott/tvpil-shared';

import { useRootStore } from 'hooks';

import MenuItem from '../MenuItem/MenuItem';

interface Props {
  isNpvrEnabled: boolean;
  items: LinkAsAsset[];
}

const MenuItems = ({ items, isNpvrEnabled }: Props) => {
  const rootStore = useRootStore();

  return (
    <ul>
      {items.map((item: LinkAsAsset) => {
        const page = rootStore?.internalPages?.find(
          (innerPage: PageStore) => innerPage.referenceId === item?.data.linkItem?.inAppLink,
        );

        if (page?.subtype === UIDAPresentationTypeEnum.npvr && !isNpvrEnabled) {
          return null;
        }

        return <MenuItem key={item.id} menuItem={item} subtype={page?.subtype} />;
      })}
    </ul>
  );
};

export default React.memo(MenuItems);
