import { useEffect } from 'react';

import { Program } from '@kaltura-ott/tvpil-shared';

import { useEpgStore } from 'hooks';

export function useVisibleProgram(program: Program) {
  const { epgStore } = useEpgStore();

  useEffect(() => {
    epgStore.setVisibleProgram(program);

    return () => {
      epgStore.setInvisibleProgram(program);
    };
  }, [epgStore, program]);
}
