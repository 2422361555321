import React, { useCallback } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';
import { useParentalControlStore } from 'hooks';

interface Props {
  onDeclineChangePin: () => void;
  onValidatePinChange: (pin: string) => { message: string; state: string };
  onSuccessChangedPin: (type: string) => Promise<void>;
  onValidatePinConfirm: (pin: string) => { message: string; state: string } | undefined;
}

export function useOnChangeAndConfirmPin({
  onDeclineChangePin,
  onValidatePinChange,
  onSuccessChangedPin,
  onValidatePinConfirm,
}: Props) {
  const { parentalControlStore } = useParentalControlStore();
  const modalContext = useModalContext();

  const onConfirmPin = useCallback((type: string) => {
    const enumKeyConfirm = `Confirm${type}` as keyof typeof PinCodeModalTypes;

    modalContext.openModal(
      <PinCodeModal
        asset={parentalControlStore}
        callback={async (success: boolean) => {
          if (success) {
            setTimeout(() => {
              onSuccessChangedPin(type);
            }, 500); // delay for UX and modal popup bug fix
          } else {
            setTimeout(() => {
              onChangePin(type, true);
            }, 500); // delay for UX and modal popup bug fix
          }
        }}
        onDecline={onDeclineChangePin}
        type={PinCodeModalTypes[enumKeyConfirm]}
        validatePin={onValidatePinConfirm}
        context={UIDAPresentationTypeEnum.parental_control}
      />,
    );
  }, []);

  function onChangePin(type: string, isConfirmErrorMessage?: boolean) {
    const enumKeyUpdate = `Update${type}` as keyof typeof PinCodeModalTypes;

    modalContext.openModal(
      <PinCodeModal
        asset={parentalControlStore}
        callback={async (success: boolean) => {
          if (success) {
            setTimeout(() => {
              onConfirmPin(type);
            }, 500);
          }
        }}
        onDecline={onDeclineChangePin}
        type={PinCodeModalTypes[enumKeyUpdate]}
        validatePin={onValidatePinChange}
        isConfirmErrorMessage={isConfirmErrorMessage}
        context={UIDAPresentationTypeEnum.parental_control}
      />,
    );
  }

  return { onChangePin };
}
