import { useCallback } from 'react';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';

export const useMouseLeaveHandle = () => {
  const { onCardHover: onHover } = useRailHorisontalDataContext();
  return useCallback(
    () => onHover && onHover(false),
    [], // eslint-disable-line
  );
};
