import find from 'lodash/fp/find';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import {
  FavoritesStore,
  SelfServiceStore,
  SubpageStore,
  SubscriptionStore,
  VideoStore,
} from '@kaltura-ott/tvpil-shared';

// TODO findSubpageStore and findSubscriptionStore should be added tests
export const findSubpageStore = <T = SubpageStore>(type: UIDAPresentationTypeEnum) => find<T>(['subtype', type]);

export const findDevicesStore = findSubpageStore<SubpageStore>(UIDAPresentationTypeEnum.devices);

export const findFavoritesStore = findSubpageStore<FavoritesStore>(UIDAPresentationTypeEnum.favorites);

export const findLanguagesStore = findSubpageStore<SubpageStore>(UIDAPresentationTypeEnum.language);

export const findSelfServiceStore = findSubpageStore<SelfServiceStore>(UIDAPresentationTypeEnum.link_list);

export const findSubscriptionStore = findSubpageStore<SubscriptionStore>(UIDAPresentationTypeEnum.subscription);

export const findVideoStore = findSubpageStore<VideoStore>(UIDAPresentationTypeEnum.preferred_video_quality);
