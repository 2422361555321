import React from 'react';
import SVG from 'react-inlinesvg';

import { SVG_ADDITIONAL_PARAM } from 'consts';

export interface Props {
  className?: string;
  url: string;
  active?: boolean;
}

export function SvgView({ className, active, url }: Props) {
  const isExternalIcon = !!url.includes('https');
  const resolvedSrc = isExternalIcon ? `${url}${SVG_ADDITIONAL_PARAM}` : url;

  return <SVG className={className} data-target={active ? 'activeRecordIcon' : 'recordIcon'} src={resolvedSrc} />;
}
