import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import { LAST_SCROLL_Y_POSITION } from 'consts';
import { globalStorage } from 'services/globalStorage';
import { resolveLinkForMd, resolvePathToDetailsPageV2, userActionEvents } from 'utils';

interface Props {
  data: any;
  isProgramRecord: boolean;
  isShowedOnlyInfo: boolean;
  mediaTypeParam: string;
  collectionType: string | undefined;
  isFutureProgram: boolean;
  isSuppressedEpisode: boolean;
  isTypeOfSeries: boolean;
  isMdCondition: boolean;
}

export const useHandleClickInfo = ({
  data,
  isProgramRecord,
  isShowedOnlyInfo,
  collectionType,
  mediaTypeParam,
  isFutureProgram,
  isSuppressedEpisode,
  isTypeOfSeries,
  isMdCondition,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const handleClickInfo = useCallback(
    async (e: React.MouseEvent) => {
      // TODO: (onClick(linearId)) must be done in the next iteration
      if (isShowedOnlyInfo) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      // TODO: (addToSearchHistory, sessionStorage.setItem(SEARCH_STRING, location.search)) must be done in the next iteration for search page

      // TODO: (globalStorage.setDetailsAsset(currentAsset);) must be done in the next iteration for SuppressedEpisode

      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      userActionEvents(UserActionType.details, data?.id, mediaTypeParam);

      let link = resolvePathToDetailsPageV2({
        isTypeOfSeries,
        isProgramRecord,
        seriesId: data.seriesId,
        collectionType,
        id: data?.id!,
        mediaTypeParam,
        isFutureProgram, // for avoid the bugs with future recording
        isSuppressedEpisode,
      });

      link += resolveLinkForMd(isMdCondition, link, collectionType); // to avoid displaying MD components in the player
      navigate(link, { state: { goBackUrl: state?.goBackUrl } });

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());
    },
    [isShowedOnlyInfo, data?.id, location.search],
  );

  return { handleClickInfo };
};
