import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { playerStorage } from '../../storages';
import { isLive } from '../../utils/isLive/isLive';
import { loadPresets } from '../../utils/loadPresets/loadPresets';

export async function handleLoadPlayer(hideUI: boolean, muted: boolean, assetId: number) {
  const playerService = playerServiceManagement.getPlayerService();
  const presets = await loadPresets();

  await playerService.mountPlayer({ presets, hideUI, isLive: isLive() });
  // To access the player in all parts of the application
  await playerStorage.setPlayer(playerService.getPlayerInstance(), hideUI, muted, assetId);
}
