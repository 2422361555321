import { useEffect } from 'react';

import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { setScrollPosition } from 'utils';

export const useSetScrollPosition = (isLoading: boolean) => {
  const { location, navType } = useStableReactRouter();

  useEffect(() => {
    setScrollPosition({
      historyAction: navType,
      location,
      isRecordPage: false,
    });
  }, [isLoading]);
};
