import { useEffect, useRef } from 'react';

import { globalStorage } from 'services/globalStorage';

import { useEpgStore } from './useEpgStore';

export const useScrollDirection = () => {
  const { epgStore } = useEpgStore();
  const { isOnBoarding } = globalStorage;
  const scrollDirection = useRef<string | null>(null);
  let header: HTMLElement | null = null;
  const prevScrollY = useRef(0);
  const down = 'down';
  const up = 'up';

  const toggleScrollDirection = () => {
    const isEpg = window.location.pathname.includes(epgStore?.referenceId);
    const { scrollY } = window;

    if (scrollY === 0) {
      scrollDirection.current = null;
    } else if (scrollY > prevScrollY.current && scrollDirection.current !== down) {
      scrollDirection.current = down;
    } else if (scrollY < prevScrollY.current && scrollDirection.current !== up) {
      scrollDirection.current = up;
    }

    prevScrollY.current = scrollY;

    if (header) {
      header.setAttribute('scrollUp', `${isEpg && scrollDirection.current === up}`);
      header.setAttribute('scrollDown', `${isEpg && scrollDirection.current === down}`);
      header.setAttribute('onBoarding', `${isOnBoarding}`);
      header.setAttribute('scrollBackground', `${scrollDirection.current === up || scrollDirection.current === down}`);
    }
  };

  useEffect(() => {
    header = document.getElementById('header');
    window.addEventListener('scroll', toggleScrollDirection);

    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  }, []);
};
