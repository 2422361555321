import React from 'react';

import Days from './components/Days/Days';
import Time from './components/Time/Time';
import { useDateViewCombiner } from './hooks';

function DateView() {
  useDateViewCombiner();

  return (
    <>
      <Days />
      <Time />
    </>
  );
}

export default React.memo(DateView);
