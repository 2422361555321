import React, { createContext, useContext, useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { SeriesItemType, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { ParentalPinStoreFacade } from '@kaltura-ott/tvpil-shared/lib/facades/parental-pin-store-facade';

import { ContextType as ContextModalType } from 'context/modal/interface';
import { useModalContext } from 'context/modal/modal';
import { useParentalPinStore } from 'hooks';

interface Props {
  children: React.ReactNode;
  firstEpisode: SeriesItemType | undefined;
  mosaicAsset: ViewableEntitiesType | undefined;
  program: ViewableEntitiesType;
  isOverlay: boolean | undefined;
  closeOverlay?: () => void;
  switchToLive?: () => void;
}

export interface ContextType {
  firstEpisode: SeriesItemType | undefined;
  mosaicAsset: ViewableEntitiesType | undefined;
  program: ViewableEntitiesType;
  isOverlay: boolean | undefined;
  closeOverlay?: () => void;
  switchToLive?: () => void;
  navigate: NavigateFunction;
  modalContext: ContextModalType;
  parentalPinStore: ParentalPinStoreFacade;
}

const VideoContentContext = createContext<ContextType>({
  firstEpisode: {} as SeriesItemType,
  mosaicAsset: {} as ViewableEntitiesType,
  program: {} as ViewableEntitiesType,
  isOverlay: false,
  closeOverlay: undefined,
  switchToLive: undefined,
  navigate: () => {},
  modalContext: {} as ContextModalType,
  parentalPinStore: {} as ParentalPinStoreFacade,
});

const VideoContextProvider = ({
  children,
  firstEpisode,
  mosaicAsset,
  program,
  isOverlay,
  closeOverlay,
  switchToLive,
}: Props) => {
  const navigate = useNavigate();
  const modalContext = useModalContext();
  const parentalPinStore = useParentalPinStore();

  const contextValue = useMemo(() => {
    return {
      firstEpisode,
      mosaicAsset,
      program,
      navigate,
      modalContext,
      parentalPinStore,
      isOverlay,
      closeOverlay,
      switchToLive,
    };
  }, [firstEpisode, mosaicAsset, program, navigate, isOverlay, closeOverlay, switchToLive]);

  return <VideoContentContext.Provider value={contextValue}>{children}</VideoContentContext.Provider>;
};

const useVideoContext = () => useContext(VideoContentContext);

export { VideoContextProvider, useVideoContext };
