import { IntlShape } from 'react-intl';

import { KalturaCategoryMetas, KuxCategoryMetas } from '@kaltura-ott/tvpil/lib/utils';

export function getSeasonEpisodeTitle(
  intl: IntlShape,
  metas?: KuxCategoryMetas | KalturaCategoryMetas,
  delimiter = ':',
  withEpisodeName = true,
  episodeName?: string,
) {
  let text = '';
  const SeasonNumber = metas?.SeasonNumber;
  const EpisodeNumber = metas?.EpisodeNumber;
  const SeriesID = metas?.SeriesID;
  const IsSeries = metas?.IsSeries;

  if (IsSeries && IsSeries.toString() === 'false' && !SeriesID) {
    return '';
  }

  const season =
    SeasonNumber &&
    `${intl.formatMessage({ defaultMessage: 'Season', id: 'VideoDetails.season' }).slice(0, 1)}${SeasonNumber}`;
  const episode =
    EpisodeNumber &&
    `${intl.formatMessage({ defaultMessage: 'Episode', id: 'VideoDetails.episode' }).slice(0, 1)}${EpisodeNumber}`;

  if (season && episode) {
    text = `${season}${delimiter}${episode}`;
  } else if (season) {
    text = season;
  } else if (episode) {
    text = episode;
  }

  if (episodeName && withEpisodeName) {
    text += ` ${episodeName}`;
  }

  return text;
}
