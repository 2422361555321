import React from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

interface Props {
  value: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
  icon?: JSX.Element;
  toggleIcon?: JSX.Element;
  isShowError?: boolean;
  placeholder?: string;
  type?: string;
}

export default function Input({
  value,
  name,
  onBlur,
  onChange,
  error,
  icon,
  toggleIcon,
  isShowError,
  placeholder,
  type,
  disabled = false,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.inputWrapper, {
          [styles.error]: isShowError,
          [styles.withIcon]: icon,
          [styles.withToggleIcon]: toggleIcon,
        })}
      >
        {icon}
        <input
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          type={type || 'text'}
          value={value}
        />
        {toggleIcon}
      </div>
      {isShowError && <div className={styles.errorText}>{error}</div>}
    </div>
  );
}
