import { appStore } from '@kaltura-ott/tvpil-shared';

import { playoutStoreDataResolver } from 'utils';

export function getContentOptionsYoubora() {
  const userId = appStore?.userStore?.userData?.externalId;
  const { partnerId } = appStore?.params?.dmsSetting!;
  const youboraAccountCode = appStore?.dms?.playerSettings?.youbora?.accountCode;
  const {
    seasonNumber,
    episodeName,
    channel,
    contentType,
    imdbId,
    genre: genreArray,
    subtitleLanguage,
    title,
  } = playoutStoreDataResolver();
  const subtitles = subtitleLanguage?.join(', ');
  const genre = genreArray?.join(', ');

  return {
    options: {
      accountCode: youboraAccountCode,
      'user.name': userId,
      'app.releaseVersion': appStore?.params?.dmsSetting?.clientVersion!,
      'app.name': appStore?.params?.dmsSetting?.applicationName!,
      'content.customDimension.1': partnerId,
      'content.season': seasonNumber,
      'content.episodeTitle': episodeName,
      'content.channel': channel,
      'content.type': contentType,
      'content.imdbId': imdbId,
      'content.genre': genre,
      'content.subtitles': subtitles,
      'content.title': title,
      'content.program': title,
    },
  };
}
