import { useRef } from 'react';

import {
  CollectionAsset,
  isCollectionAssetPurchasable,
  isCollectionChannelAsset,
  isCollectionLinkAsset,
  isCollectionLinkAssetValid,
  isCollectionProgramAsset,
  isCollectionSeriesAsset,
  useLinears,
} from '@kaltura-ott/tvpil-shared';

import { useCardLink } from 'components/widgets/Card/hooks';
import { useMouseEnterHandle } from 'components/widgets/CardV2/hooks/useMouseEnterHandle/useMouseEnterHandle';
import { useMouseLeaveHandle } from 'components/widgets/CardV2/hooks/useMouseLeaveHandle/useMouseLeaveHandle';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';

import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useHandleClickInfo } from '../useHandleClickInfo';
import { useRecommendationCardImages } from '../useRecommendationCardImages';
import { useRecommendationCardRating } from '../useRecommendationCardRating/useRecommendationCardRating';
import { useRecommendationCardRef } from '../useRecommendationCardRef';
import { useRecommendationChannelInfo } from '../useRecommendationChannelInfo/useRecommendationChannelInfo';

interface Props {
  item: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function useRecommendationCard({ item, itemWidth, imageHeight }: Props) {
  const { linears } = useLinears(); // TODO: must be replaced by useChannelsDictionary
  const isLinkAsAsset = isCollectionLinkAsset(item);
  const isValidLinkAsAsset = isLinkAsAsset && isCollectionLinkAssetValid(item);
  const isProgramAsset = isCollectionProgramAsset(item);
  const isCatchupSeries = isProgramAsset && Boolean(item.seriesId);
  const isFutureProgram = isProgramAsset && item.startDate > Date.now() && !isCatchupSeries;
  const isPurchasable = isCollectionAssetPurchasable(item, linears);
  const isTypeOfSeries = isCollectionSeriesAsset(item);
  const isAsPlayAsset = !isTypeOfSeries && !isFutureProgram && !isPurchasable && !isLinkAsAsset;
  const isChannelAsset = isCollectionChannelAsset(item);

  // using version v2 of the rail provider
  const {
    onHover,
    nextButtons,
    settings,
    settings: { shouldShowRating },
    type: collectionType,
  } = useRailContext();
  const { link } = useCardLink({
    data: item,
    isSingleVod: false,
    isFutureProgram,
    collectionType,
    isLinkAsAsset,
    isAsPlayAsset,
    isChannelAsset,
  });

  // adults
  const isAdult = !!item?.isAdult;
  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useRecommendationCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandler = useClickHandler({
    data: item,
    isLinkAsAsset,
    isValidLinkAsAsset,
    isChannelHasNoProgram: false,
    mediaTypeParam: item.type,
    isPurchasable,
    imageHeight,
    link,
  });
  const { handleClickInfo } = useHandleClickInfo({
    id: item?.id!,
    externalId: undefined,
    mediaTypeParam: item.type,
    isChannelHasNoProgram: false,
  });
  // rating
  const { isDisplayRating, assetRating } = useRecommendationCardRating({ shouldShowRating, item });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useRecommendationCardImages({
    item,
    imageType: settings.imageType,
    imageWidth: settings.imageWidth!,
    imageHeight,
  });
  // title and channelName
  const { title, channelName } = useRecommendationChannelInfo(item);
  // collectionProgramAsset type checking for AirTime presentation
  const collectionProgramAsset = isProgramAsset ? item : undefined;
  // checking for seriesId
  const seriesId = isProgramAsset ? item.seriesId : undefined;

  return {
    id: item.id,
    link,
    title,
    isAdult,
    seriesId,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
    collectionProgramAsset,
  };
}

export { useRecommendationCard };
