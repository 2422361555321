import { useMemo } from 'react';

import { QuotaStatusEnum, TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import { LinkAsAsset } from '@kaltura-ott/tvpil-shared';

import { FROM_REFERENCE_ID, IMAGE_PARAM } from 'consts';
import { useMenuContext } from 'context/menuContext/menuContext';
import { useDetectLocation, usePageId, useRecordRepository, useSearchParam } from 'hooks';
import { findMenuItems } from 'utils';

export const useHeadingLinksCombiner = () => {
  const { isLogin } = useDetectLocation();
  const { isOnBoarding, isNotFound, store } = useMenuContext();
  const recordRepository = useRecordRepository();
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const logo = store?.items?.find(
    (item: LinkAsAsset, index: number) =>
      item.data?.linkItem?.typeOfAppLink === TypeOfApplicationLink.page && item.data?.linkItem?.image && index === 0,
  );
  const isOnBoardingWihoutLogo = isOnBoarding && !logo;
  const isLogo = isOnBoarding && !!logo;
  const logoSource = logo && `${logo.data.linkItem?.image}${IMAGE_PARAM}`;
  const fromReferenceId = useSearchParam(FROM_REFERENCE_ID);
  const pageId = usePageId();
  const { items } = findMenuItems(store, fromReferenceId || pageId);
  const isOnBoardingView = isOnBoarding || isLogin;

  return useMemo(
    () => ({
      logo,
      items,
      isLogo,
      isNotFound,
      logoSource,
      isNpvrEnabled,
      isOnBoardingView,
      isOnBoardingWihoutLogo,
    }),
    [isOnBoardingView],
  );
};
