import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';

import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsHeroRail(rail: RailStoreV2) {
  const isEnabledHeroRail = featureEnabler.variation(FEATURES.NEW_HERO_RAIL_ENABLED, false);

  return rail?.subtype === UIDAPresentationTypeEnum.hero && !checkIsLogoRail(rail) && isEnabledHeroRail;
}
