import React from 'react';
import cn from 'classnames';

import { Footer } from 'components/widgets/Footer/Footer';

import styles from './OnBoardingLayout.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  refElement?: React.Ref<HTMLDivElement>;
}

const OnBoardingLayout: React.FC<Props> = ({ children, className, refElement }) => {
  return (
    <div ref={refElement} className={cn(styles.wrapper, className)}>
      <main>{children}</main>
      <Footer className={styles.footer} />
    </div>
  );
};

export default OnBoardingLayout;
