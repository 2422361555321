import { epgLocalTimeStampStorage } from '../../storages';

export const onHandleMouseWheel = (event: WheelEvent) => {
  const { offsetWidth, scrollWidth } = document.documentElement;
  const { deltaX } = event;
  const maxX = scrollWidth - offsetWidth;

  if (epgLocalTimeStampStorage.scrollLeft + deltaX < 0 || epgLocalTimeStampStorage.scrollLeft + deltaX > maxX) {
    event.preventDefault();

    epgLocalTimeStampStorage.setScrollLeft(Math.max(0, Math.min(maxX, epgLocalTimeStampStorage.scrollLeft + deltaX)));
  }
};
