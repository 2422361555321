import React from 'react';

import { useEpgContext } from 'context/epgContext/epgContext';
import { useEpgStore } from 'hooks';

import ProgramsInner from './components/ProgramsInner/ProgramsInner';

import styles from './Programs.module.scss';

function Programs() {
  const {
    epgStore: { tvChannels },
  } = useEpgStore();
  const {
    epgParams: { epgWidth },
  } = useEpgContext();

  return (
    <ul
      className={styles.list}
      style={{
        width: epgWidth,
      }}
    >
      <>
        {tvChannels.map((channel, i) => (
          <ProgramsInner key={channel.id} index={i} channel={channel} />
        ))}
      </>
    </ul>
  );
}

export default Programs;
