import { MS_IN_SEC } from 'consts';

const THREE_SECONDS = 3 * MS_IN_SEC;

export function awake(callback: any) {
  let then = Date.now();
  const interval = setInterval(tick, MS_IN_SEC);
  return clear;

  function clear() {
    clearInterval(interval);
  }

  function tick() {
    const now = Date.now();

    if (now - then > THREE_SECONDS) {
      callback();
    }

    then = now;
  }
}
