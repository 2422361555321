import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { handleCheckIsStartOverOutsidePlayer } from '../../handlers/handleCheckIsStartOverOutsidePlayer/handleCheckIsStartOverOutsidePlayer';
import { handleCheckMute } from '../../handlers/handleCheckMute/handleCheckMute';
import { handleProgramUpdate } from '../../handlers/handleProgramUpdate/handleProgramUpdate';
import { ContextType } from '../../services/consts';
import { playerStorage } from '../../storages';
import { KalturaPlayerProps } from '../../types';

export function updateActions(prevProps: KalturaPlayerProps, props: KalturaPlayerProps) {
  const { muted, isMaxi } = props;

  playerStorage.updateMuted(muted);

  const { currentAsset, id, type } = playoutStoreDataResolver();
  const { player } = playerStorage.getPlayer();

  handleCheckMute(prevProps);
  handleCheckIsStartOverOutsidePlayer();

  if (player) {
    const isProgramUpdated = player.sources.id && player.sources.id !== currentAsset?.id;
    const { contextType, epgId } = player.sources.metadata;
    const isLivePlayback = type === PlayoutStoreV2Type.LIVE && contextType === ContextType.PLAYBACK;

    // update epgId for analytics if live program changed
    if (isLivePlayback && id && epgId !== id) {
      player.setSourcesMetadata({ epgId: id });
    }

    // To update the player after switching to another program
    if (isProgramUpdated) {
      handleProgramUpdate(isMaxi);
    }

    player.dispatchEvent({ type: CUSTOM_PLAYER_EVENTS.UPDATE });
  }
}
