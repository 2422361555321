import { useEffect } from 'react';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { PerformanceEvents } from 'interface';

export const usePlaybackStartPerformance = () => {
  const performanceEventsContext = usePerformanceEventsContext();

  useEffect(() => {
    performanceEventsContext.setStartTime(PerformanceEvents.playbackStart);
  }, [performanceEventsContext]);
};
