import { useRef, useState } from 'react';

import { getImageByRatio, RhinoImage, useMaxiPlayerSettings } from '@kaltura-ott/tvpil-shared';

import { globalStorage } from 'services/globalStorage';

export const useMaxiPlayerPosterHook = (pageReferenceId: string) => {
  const [liveProgramPosterImages, setLiveProgramPosterImages] = useState<RhinoImage[] | undefined>(undefined);
  const assetId = globalStorage?.focusAssetV2?.id;
  const { height } = window.screen;
  const { isCanPlayFired } = globalStorage;
  const isLogoPoster = useRef(false);
  const maxiPlayerPosterUrl = useRef<string | undefined>();
  const settings = useMaxiPlayerSettings({ referenceId: pageReferenceId });

  if (liveProgramPosterImages || globalStorage?.focusAssetV2?.images) {
    const poster = getImageByRatio(liveProgramPosterImages || globalStorage?.focusAssetV2?.images!, {
      imageHeight: height,
      imageRatio: settings?.channelIconRatio!,
      imageType: settings?.channelIconType!,
    });

    maxiPlayerPosterUrl.current = poster?.url;
  }

  isLogoPoster.current = !liveProgramPosterImages;

  const isPosterHidden = isCanPlayFired || !maxiPlayerPosterUrl.current;

  return {
    isPosterHidden,
    isLogoPoster: isLogoPoster.current,
    maxiPlayerPosterUrl: maxiPlayerPosterUrl.current,
    setLiveProgramPosterImages,
    assetId,
  };
};
