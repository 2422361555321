import { KuxBookmark } from '@kaltura-ott/tvpil';
import { Ppv, Program, Series, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { Episode, ProgramStateV2, VodAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities';
import { SelectedSubscription } from '@kaltura-ott/tvpil-shared/lib/stores/entities/entities.service';

function detailsStoreDataResolver(asset: ViewableEntitiesType) {
  const mediaType = asset?.data?.mediaType;
  const mediaTypeParam = mediaType?.toLowerCase();
  const id = asset?.id;
  const contentMarker = asset?.contentMarker;
  const description = asset?.data?.description;
  const isPurchased = asset?.isPurchased;
  const isPurchasable = asset?.isPurchasable;
  const parentalRating = asset?.data?.parentalRating;
  const metas = asset?.data?.metas;

  // eslint-disable-next-line camelcase
  let originalName = asset?.data?.metas?.original_name;
  let title = asset?.data?.title;
  let adult = asset?.isAdult;
  let bookmark: KuxBookmark | undefined;
  let rents: Ppv[] = []; // TODO should be added a type
  let isSingleRent = false;
  let isSvod = false;
  let isTvod = false;
  let catchupAvailableTime;
  let catchupExpirationTime;
  let recordAvailableTime;
  let linear;
  let rules;
  let isPurchasePending: boolean | undefined;
  let duration: number | undefined;
  let enableCatchUp = false;
  let enableStartOver = false;
  let endDate: number | undefined;
  let isRecordable = false;
  let mediaFileId: number | undefined;
  let startDate;
  let record;
  let seriesRecord;
  let recordBookmark;
  let isPlayable;
  let episodeNumber: number | undefined;
  let episodeName: string | undefined;
  let isRented = false;
  let programState;
  let isFuture = false;
  let isLive = false;
  let isPast = false;
  let selectedSubscription: SelectedSubscription | undefined;
  let seriesId: string | undefined;
  let isRecordedAsset = false;
  let isProviderAsset = false;
  let isVodSingle = false;
  let isVodSeries = false;
  let isExternalProviderAsset = false;
  let providerLogoPath: string | undefined;
  let mosaicInfo;
  let isProgramRecord: boolean | undefined;

  if (!(asset instanceof Series)) {
    bookmark = asset?.bookmark;
    rules = asset?.rules;
    isPurchasePending = asset?.isPurchasePending;
    duration = asset?.data?.duration;
    mediaFileId = asset?.mediaFileId;
    isPlayable = asset?.isPlayable;
    selectedSubscription = asset?.selectedSubscription;
  }

  if (asset instanceof Program) {
    adult = asset?.isAdult || asset?.linear?.isAdult;
    catchupExpirationTime = asset?.catchupExpirationTime;
    catchupAvailableTime = asset?.catchupAvailableTime;
    recordAvailableTime = asset?.recordAvailableTime;
    linear = asset?.linear;
    enableCatchUp = asset?.data?.enableCatchUp;
    enableStartOver = asset?.data?.enableStartOver;
    endDate = asset?.data?.endDate;
    startDate = asset?.data?.startDate;
    isRecordable = asset?.isRecordable;
    programState = asset?.programState;
    isFuture = programState === ProgramStateV2.FUTURE;
    isLive = programState === ProgramStateV2.LIVE;
    isPast = programState === ProgramStateV2.PAST;
    record = asset?.record;
    seriesRecord = asset?.seriesRecord;
    recordBookmark = asset?.recordBookmark;
    isRecordedAsset = !!asset.record || !!asset.seriesRecord;
    mosaicInfo = asset?.data?.mosaicInfo;
    isProgramRecord = asset.isProgramRecord;
  }

  if (asset instanceof Program || asset instanceof Episode) {
    episodeNumber = asset?.data?.episodeNumber;
    episodeName = asset?.data?.episodeName;
    seriesId = asset?.data?.seriesId;
  }

  if (asset instanceof VodAsset) {
    rents = asset?.rents;
    isTvod = asset?.isTvod;
    isSingleRent = asset?.isSingleRentAvailable;
    isRented = asset?.isRented;
    isProviderAsset = asset?.isProviderAsset;
    isVodSingle = true;
    isExternalProviderAsset = isProviderAsset && !!asset.isAssetPlayOnExternalApp;
    providerLogoPath = asset?.providerLogo;
    originalName = asset?.data?.metas?.OriginalName;
  }

  if (asset instanceof Episode) {
    isSvod = asset?.isSvod;
    seriesId = asset?.data?.seriesId;
    title = asset?.data?.tags?.SeriesName?.length ? asset?.data?.tags?.SeriesName[0] : title;
  }

  if (asset instanceof Series) {
    isProviderAsset = asset?.isProviderAsset;
    providerLogoPath = asset?.providerLogo;
    isVodSeries = true;
  }

  if (seriesId) {
    title = asset?.data?.tags?.SeriesName?.length ? asset?.data?.tags?.SeriesName[0] : title;
  }

  return {
    id,
    bookmark,
    contentMarker,
    rules,
    isPurchasePending,
    duration,
    catchupExpirationTime,
    catchupAvailableTime,
    recordAvailableTime,
    linear,
    enableCatchUp,
    enableStartOver,
    endDate,
    startDate,
    mediaType,
    mediaTypeParam,
    isRecordable,
    mediaFileId,
    description,
    episodeNumber,
    episodeName,
    title,
    originalName,
    isPurchased,
    isPurchasable,
    isRented,
    adult,
    record,
    parentalRating,
    programState,
    isFuture,
    isLive,
    isPast,
    seriesRecord,
    recordBookmark,
    isPlayable,
    rents,
    isSvod,
    isTvod,
    isSingleRent,
    isVodSingle,
    isVodSeries,
    selectedSubscription,
    seriesId,
    isRecordedAsset,
    isProviderAsset,
    isExternalProviderAsset,
    providerLogoPath,
    mosaicInfo,
    metas,
    isProgramRecord,
  };
}

export default detailsStoreDataResolver;
