import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { appStore } from '@kaltura-ott/tvpil-shared';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useServicesStore } from 'hooks';
import { PerformanceEvents } from 'interface';
import routes from 'routesPaths';

import { checkIsServiceList } from '../../utils/checkIsServiceList';

export const useOnSetLoginSuccessEndTime = () => {
  const {
    servicesStore,
    servicesStore: { selectService },
  } = useServicesStore();
  const navigate = useNavigate();
  const performanceEventsContext = usePerformanceEventsContext();

  return useCallback(async () => {
    const isMultipleServiceList = checkIsServiceList(servicesStore);

    if (isMultipleServiceList) {
      // calc and save first part of loginSuccess time
      performanceEventsContext.setEndTime({
        value: PerformanceEvents.loginSuccesses,
        isSendingAllowed: false,
      });
    }

    // if there is only one service we do not show ServiceList component and choose this service automatically
    if (servicesStore.servicesList?.length === 1) {
      await selectService(servicesStore.servicesList[0].id);
      performanceEventsContext.setEndTime({ value: PerformanceEvents.loginSuccesses });
      const resolvePathToRedirect = appStore.applicationRoute || routes.home.path;
      navigate(resolvePathToRedirect);
    }
  }, []);
};
