import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { SubscriptionStore } from '@kaltura-ott/tvpil-shared';

import { useModalContext } from 'context/modal/modal';

import Card from '../../Card/Card';
import { COMPONENT_NAME } from '../constants';

import { ActivationModal, ActivationModalForNotConfigured, ActivationModalForUnPurchased } from './components';

function AdditionPlans({ subscriptionStore }: { subscriptionStore: SubscriptionStore }) {
  const intl = useIntl();
  const modalContext = useModalContext();
  const { additionPlans } = subscriptionStore;

  function activateAdditionPlan(title: string, isPurchased: boolean, provider: string = '') {
    return () => {
      const { fullActivationUrl, isConfigured, shortActivationUrl, code } = subscriptionStore.providerSettings![
        provider
      ];

      if (!isPurchased) {
        modalContext.openModal(<ActivationModalForUnPurchased provider={provider} title={title} />);
        return;
      }

      if (!isConfigured) {
        modalContext.openModal(<ActivationModalForNotConfigured provider={provider} title={title} />);
        return;
      }

      modalContext.openModal(
        <ActivationModal
          code={code}
          fullActivationUrl={fullActivationUrl!}
          provider={provider}
          shortActivationUrl={shortActivationUrl!}
          title={title}
        />,
      );
    };
  }

  return (
    <>
      {additionPlans.map(({ packageData, isPurchased, data }) => (
        <Card
          key={packageData.id}
          name={intl.formatMessage(
            {
              id: `${COMPONENT_NAME}.cardTitleActivateTitle.${data.provider}`,
              defaultMessage: `Activate ${packageData.title}`,
            },
            { title: packageData.title },
          )}
          onHandleClick={activateAdditionPlan(packageData.title, isPurchased, data.provider)}
        />
      ))}
    </>
  );
}

export default observer(AdditionPlans);
