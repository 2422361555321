import { useLocation, useNavigate } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import routes from 'routesPaths';
import { saveAssetToGlobalStorage } from 'utils';

export const useRedirectToPlayer = (asset: ViewableEntitiesType) => {
  const { id, mediaTypeParam } = detailsStoreDataResolver(asset);
  const navigate = useNavigate();
  const location = useLocation();

  return () => {
    saveAssetToGlobalStorage(asset);

    navigate(routes.vod.link(id, mediaTypeParam), {
      state: {
        goBackUrl: `${location.pathname}${location.search}`,
      },
    });
  };
};
