import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import info from 'assets/images/maxi-player/info.svg';
import play from 'assets/images/maxi-player/play.svg';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';
import { resolvePathToDetailsPage } from 'utils';

import { COMPONENT_NAME } from '../../constans';
import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import MuteButton from './components/MuteButton/MuteButton';

import styles from './Buttons.module.scss';

const Buttons: React.FC = () => {
  const {
    asset,
    isLinear,
    program,
    muted,
    muteHandler,
    watchNowHandler,
    handleInfoButton,
    isContentRestricted,
    isContentGeoBlocking,
  } = useVideoPlayoutContext();

  const mediaType = program?.data?.mediaType?.toLowerCase();
  const detailsPath = resolvePathToDetailsPage({
    id: asset?.currentProgram?.data?.id!,
    mediaTypeParam: mediaType,
  }) as string;
  const isShowedInfoButton = isLinear && !asset?.currentProgram?.data?.notFound;
  const isShowedMuteButton = !isContentRestricted && !isContentGeoBlocking;

  return (
    <div className={styles.buttonGroup}>
      <ActionButton
        className={styles.actionButton}
        icon={<SVG data-target='playIcon' src={play} />}
        isLarge
        onClick={watchNowHandler}
        type={ActionButtonTypesEnum.primary}
      >
        <FormattedMessage defaultMessage='Watch' id={`${COMPONENT_NAME}.buttonTitleWatch`} />
      </ActionButton>
      {isShowedInfoButton && (
        <ActionButton
          className={styles.actionButton}
          icon={<SVG data-target='infoIcon' src={info} />}
          isLarge
          onClick={handleInfoButton}
          to={detailsPath}
          type={ActionButtonTypesEnum.secondary}
        >
          <FormattedMessage defaultMessage='Info' id={`${COMPONENT_NAME}.buttonTitleInfo`} />
        </ActionButton>
      )}
      {isShowedMuteButton && <MuteButton className={styles.muteButton} muted={muted} onClick={muteHandler} />}
    </div>
  );
};

export default Buttons;
