// @ts-nocheck
import { KuxFilteredCategoriesDTO } from '@kaltura-ott/tvpil';

import { HALF_DIVIDER } from 'consts';

const mapProps = {
  backgroundColor: '--background-color',
  fontSize: '--font-size',
  fontType: '--font-type',
  focusColor: '--focus-color',
  selectedColor: '--selected-color',
  textColor: '--text-color',
};

const mapButtonProps = {
  btnFontSize: '--btn-font-size',
  btnBackgroundColor: '--btn-background-color',
  btnBackgroundColorFocus: '--btn-background-focus-color',
  btnTextColor: '--btn-text-color',
  btnTextColorFocus: '--btn-text-focus-color',
};

/**
 *  Apply OTC styles for element
 * @param element
 * @param uiDesignElements
 * @param isAllProps
 */

export function applyStyles(
  element: HTMLElement,
  uiDesignElements: KuxFilteredCategoriesDTO['uiDesignElements'],
  isAllProps?: boolean,
  withBodyBackground?: boolean,
  map: Object = isAllProps ? { ...mapProps, ...mapButtonProps } : mapProps,
): void {
  Object.keys(uiDesignElements).forEach((style) => {
    element.style.setProperty(
      map[style],
      typeof uiDesignElements[style] === 'number' ? `${uiDesignElements[style]}px` : uiDesignElements[style],
    );
  });

  if (withBodyBackground) {
    document.body.style.backgroundColor = uiDesignElements.backgroundColor;
  }
}

export function applyStyle(element: HTMLElement, name: string, value: string) {
  if (!element) return;

  element.style.setProperty(name, value);
}

export function applyStyleHexToHSL(element: HTMLElement, hex: any, isButton?: boolean) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return;
  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  let l = (max + min) / HALF_DIVIDER;

  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  const colorHsl = { h, s: `${s}%`, l: `${l}%` };

  Object.keys(colorHsl).forEach((value) =>
    element.style.setProperty(`${mapProps.backgroundColor}${isButton ? '-btn' : ''}-${value}`, colorHsl[value]),
  );
}
