import { useRef } from 'react';
import { useContainerPosition, useInfiniteLoader, usePositioner, useResizeObserver } from 'masonic';

import { useGridCollection } from '@kaltura-ott/tvpil-shared';

import { useGridCardsViewItems } from 'components/pages/Grids/common/hooks/useGridCardsViewItems/useGridCardsViewItems';
import { PAGE_SIZE } from 'components/pages/Grids/constants';
import { useWidthForMasonryWrapper } from 'components/widgets/Rail/RailGrid/hooks';
import { checkTotalGridHeight } from 'components/widgets/Rail/utils';
import routes from 'routesPaths';

import { useGridMatchPath } from '../../../../../common/hooks/useGridMatchPath/useGridMatchPath';
import { useGridCollectionCardsSizeChecker } from '../useGridCollectionCardsSizeChecker/useGridCollectionCardsSizeChecker';

function useGridCollectionCardsViewCombiner() {
  const stopIndexRef = useRef(0);
  const { type, railId } = useGridMatchPath(routes.collectionGrid.path);
  const { items, isLoading, hasNextPage, fetchNextPage } = useGridCollection({
    gridReferenceId: type!,
    railReferenceId: railId!,
    pageSize: PAGE_SIZE,
  });
  const isFullLoaded = !!items.length && !hasNextPage;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { columnCount, windowWidth, windowHeight, columnWidth, defaultHeight } = useGridCollectionCardsSizeChecker();
  const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
  const widthForMasonryWrapper = useWidthForMasonryWrapper({ width, columnCount, columnWidth });
  const { gridItems } = useGridCardsViewItems({ items, columnCount, isFullLoaded });
  const positioner = usePositioner(
    {
      width: widthForMasonryWrapper,
      columnWidth,
    },
    [railId, isFullLoaded],
  );
  const resizeObserver = useResizeObserver(positioner);
  const nextPageHandle = useInfiniteLoader(async (preIndex, stopIndex) => {
    if (hasNextPage && !isLoading && stopIndex !== stopIndexRef.current) {
      stopIndexRef.current = stopIndex;

      fetchNextPage();
    }
  });

  // masonic does not allow to remove items
  // to not count skeleton items height recalculate grid height if all items have been loaded
  checkTotalGridHeight(containerRef, isFullLoaded, items.length, columnCount, defaultHeight);

  const totalGridHeight = Math.ceil(items.length / columnCount) * defaultHeight;

  return {
    containerRef,
    windowHeight,
    columnWidth,
    defaultHeight,
    positioner,
    offset,
    gridItems,
    resizeObserver,
    nextPageHandle,
    isFullLoaded,
    isLoading,
    totalGridHeight,
  };
}

export { useGridCollectionCardsViewCombiner };
