import { useRef, useState } from 'react';

import { DEFAULT_HEIGHT } from 'consts';

import { useRailContext } from '../../../RailProviderV2/RailProviderV2';
import { useArrowsClickHook } from '../useArrowsClickHook/useArrowsClickHook';
import { useNextButtonsDuringResize } from '../useNextButtonsDuringResize/useNextButtonsDuringResize';
import { useNextButtonsDuringTotalWidth } from '../useNextButtonsDuringTotalWidth/useNextButtonsDuringTotalWidth';

function useNextButtonsCombiner(width: number) {
  const {
    setNextButtons,
    refElementInner,
    totalWidth,
    offset,
    setRailCardsListRefScrollTo,
    data: { items },
    railCardsListRef,
    settings,
  } = useRailContext();
  const [state, setState] = useState({
    isLeftNextButton: offset?.current! > 0,
    isRightNextButton:
      offset?.current! + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });
  const scrolling = useRef(false);
  const prevWidth = useRef(width);

  useNextButtonsDuringResize({ width, offset, scrolling, prevWidth, totalWidth, refElementInner, setState });

  useNextButtonsDuringTotalWidth({ scrolling, offset, totalWidth, refElementInner, setState });

  const scrollHandle = (newOffset: number, blockWidtht: number) => {
    scrolling.current = true;
    const buttonsView = {
      isLeftNextButton: newOffset > 0,
      isRightNextButton: newOffset + blockWidtht < totalWidth,
    };
    // set value for RailContext
    setNextButtons({ ...buttonsView });
    // set value for local state
    setState({ ...buttonsView });
    scrolling.current = false;
  };

  const clickHandle = useArrowsClickHook({
    scrollHandle,
    setRailCardsListRefScrollTo,
    items,
    totalWidth,
    offset,
    railCardsListRef,
    refElementInner,
    railSettings: settings,
  });
  const { isLeftNextButton, isRightNextButton } = state;

  return { isLeftNextButton, isRightNextButton, clickHandle, imageHeight: settings.imageHeight || DEFAULT_HEIGHT };
}

export { useNextButtonsCombiner };
