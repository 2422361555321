import { appStore } from '@kaltura-ott/tvpil-shared';

import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { playerStorage } from '../../storages';
import { PlayerEvent } from '../../types';

export async function handleCancelRecord({ payload }: PlayerEvent) {
  const { recordRepository } = appStore;
  const { player } = playerStorage.getPlayer();
  const { recordId, type } = payload;

  await recordRepository.removeRecord(recordId, type);

  player.dispatchEvent({ type: CUSTOM_PLAYER_EVENTS.UPDATE });
}
