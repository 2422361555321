import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useIsComponentMounted } from './useIsComponentMounted';

/**
 * This hook is to use useState and don't worry about updating state after component is unmounted
 *
 * Please note:
 * If you use setNewState function in useCallback or useEffect - linter will show warning about list of dependencies.
 * setNewState function need to be added to list of dependencies,
 * because linter see this function is not setter from useState.
 * It's only wrapper.
 */
export function useCleanupState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
  const alive = useIsComponentMounted();
  const [state, setState] = useState(initialState);

  const setNewState = useCallback(
    (newValue: any) => {
      if (alive.current) {
        setState(newValue);
      }
    },
    [alive],
  );

  return [state, setNewState];
}
