import React from 'react';
import cn from 'classnames';

import styles from './MuteButton.module.scss';

interface Props {
  className?: string;
  muted: Boolean;
  onClick: React.MouseEventHandler;
}

const MuteButton: React.FC<Props> = ({ className, muted, onClick }) => {
  const classes = cn(className, styles.muteButton, muted ? styles.muted : styles.unmuted);

  return <button aria-label='Mute button' className={classes} onClick={onClick} type='button' />;
};

export default MuteButton;
