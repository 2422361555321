const routes = {
  home: {
    path: '/',
    alias: '/home',
  },
  auth: {
    path: '/auth/',
  },
  grid: {
    path: '/grid/:type/:id',
    link: (link: string | number) => `${link}`,
  },
  collectionGrid: {
    path: '/grid/collection/:type/:id',
    link: (link: string | number) => `${link}`,
  },
  seriesCatchUpGrid: {
    path: '/grid/series-catch-up/:type/:id',
    link: (link: string | number) => `${link}`,
  },
  gridTheme: {
    path: '/grid/:type/:idRail/:idGrid',
    link: (type: string, railId: string | number, gridId: string | number) => `/grid/${type}/${railId}/${gridId}`,
  },
  settings: {
    path: '/settings',
    pathSubPage: 'subpage/:id',
    link: (link: string) => `/settings${link}`,
  },
  purchase: {
    path: '/purchase',
    pathPackage: '/purchase/:packageId',
  },
  subscriptions: {
    path: '/subscriptions',
  },
  page: {
    path: '/page/:id',
    link: (link: string) => `/page/${link}`,
  },
  live: {
    commonPath: '/live',
    path: '/live/:id/:mediaType/:externalId?',
    link: (id: string | number, mediaType: string, externalId: string | number = '') =>
      `/live/${id}/${mediaType}${externalId ? '/' : ''}${externalId}`,
  },
  startOver: {
    path: '/startOver/:id/:mediaType',
    link: (id: string | number, mediaType: string, externalId: string | number = '') =>
      `/startOver/${id}/${mediaType}${externalId ? '/' : ''}${externalId}`,
  },
  program: {
    path: '/program/:id/:mediaType',
    link: (epgId: number | string, mediaType: string) => `/program/${epgId}/${mediaType}`,
  },
  details: {
    commonPath: '/details',
    pathDetailsInfo: { path: '/details-info/' },
    pathDetailsSeries: { path: '/details-series/' },
  },
  detailsInfo: {
    path: '/details-info/:programId/:mediaType/:externalId?',
    link: (programId: number | string, mediaType: string = '', externalId: string = '') =>
      `/details-info/${programId}${mediaType ? '/' : ''}${mediaType}${externalId ? '/' : ''}${externalId}`,
  },
  detailsSeries: {
    path: '/details-series/:programId/:mediaType',
    link: (seriesId: number | string, mediaType: string = '') =>
      `/details-series/${seriesId}${mediaType ? '/' : ''}${mediaType}`,
  },
  vod: {
    path: '/vod/:id/:mediaType',
    link: (itemId: number, mediaType: string) => `/vod/${itemId}/${mediaType}`,
  },
  catchUp: {
    path: '/catchUp/:id/:mediaType/:externalId?',
    link: (itemId: number, mediaType: string, externalId: string = '') =>
      `/catchUp/${itemId}/${mediaType}${externalId ? '/' : ''}${externalId}`,
  },
  favorites: {
    path: '/favorites',
  },
  login: {
    path: '/login',
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  household: {
    path: '/household',
  },
  search: {
    path: '/page/search',
    link: (name: string = '') => `/page/search?name=${name}`,
  },
  notFound: {
    path: '/not-found',
  },
};

export default routes;
