import React from 'react';

import Modal from 'components/widgets/Modal/Modal';

import styles from './ModalRouter.module.scss';

const ModalRouter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Modal
      afterOpenClassName={styles.afterOpen}
      beforeCloseClassName={styles.beforeClose}
      className={styles.content}
      closeTimeoutMS={600}
      id='routerModal'
      overlayClassName={styles.overlay}
      shouldReturnFocusAfterClose={false}
    >
      {children}
    </Modal>
  );
};

export default ModalRouter;
