import { useCallback } from 'react';

export const useHandleClick = (closeHandler: () => void) => {
  return useCallback((event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.hasAttribute('role')) {
      closeHandler();
    }
  }, []);
};
