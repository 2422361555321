import { appStore } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

export async function handleSwitchToCatchUp({ detail }: any) {
  await appStore?.playoutStoreV2.init({
    playableAsset: detail.value,
    type: PlayoutStoreV2Type.CATCHUP,
    autoplayNextProgram: true,
    saveLastWatched: true,
  });
}
