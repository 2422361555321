import { RhinoEPGProgram } from '@kaltura-ott/tvpil-shared';

import { epgLocalTimeStampStorage } from 'components/pages/EpgV2/storages';

export const filterVisiblePrograms = (programs: RhinoEPGProgram[]) => {
  const startScreenTime = epgLocalTimeStampStorage.getStartDate();
  const endScreenTime = epgLocalTimeStampStorage.getEndDate();

  return programs.filter((pr) => pr.endDate >= startScreenTime && pr.startDate <= endScreenTime);
};
