import { useEffect, useRef, useState } from 'react';

import { RailItem, RailProviderData } from '../../components/RailProviderV2/types';

const useRailEmptyCheck = (data: RailProviderData<RailItem>) => {
  const [isRailEmpty, setIsRailEmpty] = useState(false);
  const isPrevLoadFirst = useRef(data.isLoading);

  useEffect(() => {
    // after first loading items is empty or after application navigation(items received from cache)
    // in Rentals rail case client has totalCount(it's adult), but items = 0
    const isRailShouldBeEmpty =
      (!data.isLoading && !data.items.length && (isPrevLoadFirst.current || data.totalCount)) ||
      (!data.isLoading && !data.items.length && !isPrevLoadFirst.current && !data.totalCount);

    if (isRailShouldBeEmpty) {
      isPrevLoadFirst.current = false;
      setIsRailEmpty(true);
    }
  }, [data.isLoading]);

  return isRailEmpty;
};

export { useRailEmptyCheck };
