import { useCallback, useEffect } from 'react';
import { Node } from 'types';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { APP_PATH, FEATURE_MULTILANGUAGE, IMAGE_PARAM, PLATFORM } from 'consts';
import { useCheckPlatform, useDeviceCheck, useRootStore } from 'hooks';
import { applyStyles } from 'utils';

export const useDownloadPage = () => {
  const store = useRootStore();
  const platform = useCheckPlatform();
  const { isTablet } = useDeviceCheck();
  const isChangingLangEnabled = featureEnabler.variation(FEATURE_MULTILANGUAGE, true);
  const { androidFallbackPageUrl, appleFallbackPageUrl, branding, images } =
    store.getDownloadMobileAppConfiguration() || {};
  const urls = { [PLATFORM.IOS]: appleFallbackPageUrl, [PLATFORM.ANDROID]: androidFallbackPageUrl };
  const iconUrl = images && `${images[AssetPresentationRatioEnum['1x1']]?.url}${IMAGE_PARAM}`;
  const bannerUrlPortrait = images && `${images[AssetPresentationRatioEnum['2x3']]?.url}${IMAGE_PARAM}`;
  const bannerUrlLandScape = isTablet && images && `${images[AssetPresentationRatioEnum['16x9']]?.url}${IMAGE_PARAM}`;
  const to = platform && urls[platform];
  const isAppDownloadRequired = !!platform && store?.getDownloadMobileAppConfiguration();
  const isAutoRedirect = !!(isAppDownloadRequired && document.location.pathname === APP_PATH && to);

  useEffect(() => {
    if (branding) {
      document.body.style.backgroundColor = branding.backgroundColor;
    }
  }, []);

  const handleRef = useCallback((node: Node) => {
    if (branding && node) {
      applyStyles(node, branding, false, true);
    }
  }, []);

  return {
    to,
    isTablet,
    isAutoRedirect,
    isAppDownloadRequired,
    isChangingLangEnabled,
    actionFrameIconUrl: iconUrl,
    bannerUrl: isTablet ? bannerUrlLandScape : bannerUrlPortrait,
    handleRef,
  };
};
