import { useEffect, useMemo, useState } from 'react';

import { DataRailV2 } from '@kaltura-ott/tvpil-shared';

import { DEFAULT_HEIGHT, DEFAULT_WIDTH, MARGIN_BOTTOM, MARGIN_HORIZONTAL, MARGIN_RIGHT } from 'consts';

export function useRailSkeletonSizer(store: DataRailV2) {
  const [windowSize, setWindowSize] = useState({
    windowWidth: document.body.clientWidth,
    windowHeight: document.body.clientHeight,
  });
  const defaultHeight = (store.railHeight || DEFAULT_HEIGHT) + MARGIN_BOTTOM;

  function resizeHandler(event: Event) {
    setWindowSize({
      windowWidth: (event.currentTarget as Window).innerWidth,
      windowHeight: (event.currentTarget as Window).innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return useMemo(() => {
    const columnWidth = Math.round(store.itemDefaultWidth || DEFAULT_WIDTH);
    const scrollBarWidth = window.innerWidth - document.body.clientWidth;
    // add scrollbar width to have constant containerWidth during lazy loading
    const containerWidth = document.body.clientWidth - MARGIN_HORIZONTAL + scrollBarWidth;
    const columnCount = Math.floor(containerWidth / (columnWidth + MARGIN_RIGHT)) || 1;

    return {
      windowWidth: windowSize.windowWidth,
      windowHeight: windowSize.windowHeight,
      containerWidth,
      columnCount,
      columnWidth,
      defaultHeight,
    };
    // eslint-disable-next-line
  }, [windowSize.windowWidth, windowSize.windowHeight, defaultHeight]);
}
