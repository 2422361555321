import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppStore } from 'hooks/common/useAppStore';

export function useAppCheckCookieConfirmation() {
  const location = useLocation();

  const {
    appStore: { cookieStore },
  } = useAppStore();

  useEffect(() => {
    cookieStore.checkCookieConfirmation();
  }, [location.pathname, cookieStore]);
}
