import { action, observable } from 'mobx';

// Storage for seek bar props
// TODO: Must be split to separate storages

class SeekStorage {
  @observable props: any = {};

  @observable isKeyDownLiveSeek: boolean = false;

  @observable isFakeRewind: boolean = false;

  @observable seekBarElement: HTMLElement | any = null;

  @observable framePreviewElement: HTMLElement | any = null;

  @observable timeBubbleElement: HTMLElement | any = null;

  @action
  setIsFakeRewind(value: boolean = false) {
    this.isFakeRewind = value;
  }

  @action
  getIsFakeRewind() {
    return this.isFakeRewind;
  }

  @action
  getIsKeyDownLiveSeek() {
    return this.isKeyDownLiveSeek;
  }

  @action
  setIsKeyDownLiveSeek(value: boolean = false) {
    this.isKeyDownLiveSeek = value;
  }

  @action
  getSeekProps() {
    return this.props;
  }

  @action
  setSeekProps(props: any) {
    this.props = props;
  }

  @action
  getSeekBarElement() {
    return this.seekBarElement;
  }

  @action
  setSeekBarElement(seekBarElement: HTMLDivElement) {
    this.seekBarElement = seekBarElement;
  }

  @action
  getFramePreviewElement() {
    return this.framePreviewElement;
  }

  @action
  setFramePreviewElement(framePreviewElement: HTMLDivElement) {
    this.framePreviewElement = framePreviewElement;
  }

  @action
  setTimeBubbleElement(timeBubbleElement: HTMLElement) {
    this.timeBubbleElement = timeBubbleElement;
  }

  @action
  getTimeBubbleElement() {
    return this.timeBubbleElement;
  }

  @action
  resetSeekProps() {
    this.props = {};
    this.seekBarElement = null;
    this.framePreviewElement = null;
    this.timeBubbleElement = null;
    this.isKeyDownLiveSeek = false;
    this.isFakeRewind = false;
  }
}

const seekStorage = new SeekStorage();

export { seekStorage };
