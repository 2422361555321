import { useEffect } from 'react';

import { LAST_DRAG_X_POSITION, LAST_RAIL_X_POSITION, LAST_SCROLL_Y_POSITION, LAST_VIEW_SEASON_NUMBER } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';

export function useAppBeforeUnLoad() {
  const {
    appStore: { isReady: appStoreIsReady },
  } = useAppStore();

  const handleUnload = () => {
    sessionStorage.removeItem(LAST_DRAG_X_POSITION);
    sessionStorage.setItem(LAST_SCROLL_Y_POSITION, '0');
    sessionStorage.removeItem(LAST_VIEW_SEASON_NUMBER);
    sessionStorage.removeItem(LAST_RAIL_X_POSITION);
  };

  useEffect(() => {
    // clear session storage data on page reload or app reboot
    if (!appStoreIsReady) {
      handleUnload();
    }
  }, [appStoreIsReady]);
}
