import { UAParser } from 'ua-parser-js';

const SAFARI = 'Safari';
const EDGE = 'Edge';
const EdgeHTML = 'EdgeHTML';
const IPAD = 'iPad';
const MAC_INTEL = 'MacIntel';
const MACINTOSH = 'Macintosh';
const DeviceTypes = {
  Tablet: 'tablet',
};

export const uaParser = new UAParser();
export const MIN_WIDTH = 1440;

export function widthLessThan(width: number): boolean {
  return window.innerWidth < width;
}

export function isOldEdge(): boolean {
  return uaParser.getEngine().name === EdgeHTML && uaParser.getBrowser().name === EDGE;
}

export function isEdge() {
  return uaParser.getBrowser().name === EDGE;
}

export function isSafari(): boolean {
  return uaParser.getBrowser().name === SAFARI;
}

export function isShowNotification() {
  return isOldEdge() || widthLessThan(MIN_WIDTH);
}

export function getBrowserData() {
  return uaParser.getBrowser();
}

export function isIOS13Check(type: string) {
  const { navigator } = window;

  return !!(
    navigator &&
    navigator.platform &&
    (navigator.platform.indexOf(type) !== -1 ||
      ([MACINTOSH, MAC_INTEL].includes(navigator.platform) && navigator.maxTouchPoints > 1))
  );
}

export function isTabletType({ type }: IUAParser.IDevice) {
  return type === DeviceTypes.Tablet;
}

export function getIPad13() {
  return isIOS13Check(IPAD);
}
