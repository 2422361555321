import React from 'react';

import { Channel } from '@kaltura-ott/tvpil-shared';

import CardBubbleInfo from 'components/widgets/CardV2/components/CardBubbleInfo/CardBubbleInfo';
import CardTitle from 'components/widgets/CardV2/components/CardTitle/CardTitle';
import Card from 'components/widgets/RailV2/components/Card/Card';
import CardInner from 'components/widgets/RailV2/components/CardInner/CardInner';

import HeroCardContent from '../HeroCardContent/HeroCardContent';

import { useHeroCard } from './hooks/useHeroCard/useHeroCard';

interface Props {
  channel: Channel;
  itemWidth: number;
  imageHeight: number;
  timestamp: number;
}

function HeroCard({ channel, itemWidth, imageHeight, timestamp }: Props) {
  const {
    imageUrl,
    liveProgram,
    link,
    title,
    defaultImageUrl,
    channelLogoUrl,
    seasonEpisodeTitle,
    isAdult,
    isChannelHasNoProgram,
    handleLinkRef,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleRef,
    handleClickInfo,
  } = useHeroCard({ channel, itemWidth, imageHeight, timestamp });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={!!liveProgram?.hasMosaic}
    >
      <CardInner
        cardType='hero'
        url={imageUrl}
        name={liveProgram?.name}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={isChannelHasNoProgram}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <HeroCardContent
            isAdult={isAdult}
            channelHasProgram={!isChannelHasNoProgram}
            channelLogoUrl={channelLogoUrl!}
            channelTitle={channel?.title}
            shouldDisplayCartIcon={!channel.isEntitled}
            shouldDisplayPlayButton={channel.isEntitled}
            shouldDisplayFavoriteIcon={channel.isEntitled && channel.isFavorite}
            startDate={liveProgram?.startDate!}
            endDate={liveProgram?.endDate!}
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram={!isChannelHasNoProgram}
            isAdult={isAdult}
            seasonEpisodeTitle={seasonEpisodeTitle}
            title={title}
            isMosaicProgram={!!liveProgram?.hasMosaic}
            handleClickInfo={handleClickInfo}
            programId={liveProgram?.id}
            seriesId={liveProgram?.seriesId}
          />
        }
      />
      <CardTitle
        isAdult={isAdult}
        isStaticInfo
        title={title}
        isMosaicProgram={!!liveProgram?.hasMosaic}
        programId={liveProgram?.id}
        seriesId={liveProgram?.seriesId}
        seasonEpisodeTitle={seasonEpisodeTitle}
      />
    </Card>
  );
}

export default HeroCard;
