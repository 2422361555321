import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import AirTimeV2 from 'components/presenters/AirTimeV2/AirTimeV2';
import { InfoButton } from 'components/presenters/InfoButton/InfoButton';
import { useContentRestriction } from 'components/widgets/RailV2/HeroRail/components/HeroCard/hooks/useContentRestriction';

import { CardChannelName } from '../CardChannelName/CardChannelName';
import CardTitle from '../CardTitle/CardTitle';

import { useHandleRef } from './hooks/useRefHook/useRefHook';

import styles from './CardBubbleInfo.module.scss';

function CardBubbleInfo({
  seasonEpisodeTitle,
  title,
  isMosaicProgram,
  handleClickInfo,
  channelHasProgram,
  channelName,
  isAdult,
  programId,
  seriesId,
  collectionProgramAsset,
}: {
  isAdult: boolean;
  seasonEpisodeTitle?: string;
  title: string;
  isMosaicProgram: boolean;
  handleClickInfo: (e: React.MouseEvent) => void;
  channelHasProgram: boolean;
  programId?: number;
  seriesId?: string;
  channelName?: string;
  collectionProgramAsset?: CollectionProgramAsset;
}) {
  const ref = useHandleRef();
  // for avoiding wrapping the whole parent component with observer
  const isContentRestricted = useContentRestriction(isAdult);

  return (
    <div
      ref={ref}
      data-type='cardBubbleInfo'
      onClick={isContentRestricted ? undefined : handleClickInfo}
      role='presentation'
      className={cn(styles.wrapper)}
    >
      <div>
        <CardTitle
          isAdult={isAdult}
          seasonEpisodeTitle={seasonEpisodeTitle}
          title={title}
          isMosaicProgram={isMosaicProgram}
          programId={programId}
          seriesId={seriesId}
        />
        {!isContentRestricted && (
          <>
            {!!seasonEpisodeTitle && <p className={styles.description}>{seasonEpisodeTitle}</p>}
            {channelName && (
              <div className={styles.channel}>
                <CardChannelName channelName={channelName} />
              </div>
            )}
            {collectionProgramAsset && <AirTimeV2 className={styles.airTime} isInfo data={collectionProgramAsset} />}
            {channelHasProgram && <InfoButton className={styles.infoButton} />}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(CardBubbleInfo);
