import React from 'react';
import cn from 'classnames';

import { RecordIcon } from 'components/presenters/icons';

import { Props } from '../../types';

import styles from './CardTitleBubbleView.module.scss';

function CardTitleBubbleView({ title, isRecord, isWrapperWithIcon, isWithSeriesRecord, isSeriesType }: Props) {
  return (
    <div
      className={cn(
        styles.wrapper,
        { [styles.singleRecord]: isWrapperWithIcon },
        { [styles.seriesRecord]: isWithSeriesRecord },
      )}
    >
      <h6>
        {title}
        {isRecord && (
          <span className={styles.recordIcon}>
            <RecordIcon active multiple={isSeriesType} />
          </span>
        )}
      </h6>
    </div>
  );
}

export default CardTitleBubbleView;
