import { PlayerService } from './PlayerService';

class PlayerServiceManagement {
  // @ts-ignore
  playerService: PlayerService;

  setPlayerService(playerService: any) {
    this.playerService = playerService;
  }

  getPlayerService() {
    const { playerService } = this;

    return playerService;
  }
}

const playerServiceManagement = new PlayerServiceManagement();

export { playerServiceManagement };
