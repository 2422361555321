import React from 'react';
import cn from 'classnames';
import { MasonryScroller } from 'masonic';

import SeriesCatchUpGridCardResolveRendering from '../SeriesCatchUpGridCardResolveRendering/SeriesCatchUpGridCardResolveRendering';

import { useSeriesCatchUpGridCardsViewCombiner } from './hooks/useSeriesCatchUpGridCardsViewCombiner/useSeriesCatchUpGridCardsViewCombiner';

import styles from './SeriesCathUpGridCardsView.module.scss';

interface Props {
  className?: string;
}

function SeriesCathUpGridCardsView({ className }: Props) {
  const {
    containerRef,
    windowHeight,
    columnWidth,
    defaultHeight,
    positioner,
    offset,
    gridItems,
    resizeObserver,
    nextPageHandle,
    isFullLoaded,
    totalGridHeight,
  } = useSeriesCatchUpGridCardsViewCombiner();
  return (
    <div className={cn(styles.wrapper, className, { [styles.fullLoadded]: isFullLoaded })}>
      <MasonryScroller
        className={styles.grid}
        containerRef={containerRef}
        height={isFullLoaded ? totalGridHeight : windowHeight}
        items={gridItems}
        itemStyle={{ width: columnWidth, height: defaultHeight }}
        offset={offset}
        positioner={positioner}
        itemKey={(data, index) => data?.id || index}
        render={SeriesCatchUpGridCardResolveRendering}
        onRender={nextPageHandle}
        resizeObserver={resizeObserver}
        overscanBy={3}
        itemHeightEstimate={defaultHeight}
      />
    </div>
  );
}

export default SeriesCathUpGridCardsView;
