import React from 'react';

import MainLayout from 'components/presenters/layouts/main/MainLayout';
import { useEpgContext } from 'context/epgContext/epgContext';

import DateView from '../DateView/DateView';
import Grid from '../Grid/Grid';

import styles from './Epginner.module.scss';

function EpgInner() {
  const { handleRef, setUserActive } = useEpgContext();

  return (
    <MainLayout forwardRef={handleRef} className={styles.layout}>
      <div role='presentation' onClick={setUserActive} className={styles.wrappper}>
        <DateView />
        <Grid />
      </div>
    </MainLayout>
  );
}

export default EpgInner;
