import { playerStorage } from '../../storages';

export function handlePausePlay() {
  const { player } = playerStorage.getPlayer();

  // KUXEXT-22399 - play/pause icon is flickering during playback start
  // to avoid this we check player play/pause state based on variable playerStorage.isPlayBackPaused
  if (playerStorage.isPlayBackPaused && !player.paused) {
    player.pause();
  }

  if (!playerStorage.isPlayBackPaused && player.paused) {
    player.play();
  }
}
