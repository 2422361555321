import { useCallback } from 'react';

import { CUSTOM_RAIL_EVENTS } from 'components/widgets/Rail/constants';

interface Props {
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
  offset: React.MutableRefObject<number>;
  totalWidth: number;
  setRailCardsListRefScrollTo: (value: number) => void;
}

export const useCheckResizeHook = ({ setRailCardsListRefScrollTo, refElementInner, offset, totalWidth }: Props) => {
  return useCallback(() => {
    if (refElementInner.current) {
      const blockWidth = refElementInner?.current?.clientWidth || document.body.clientWidth;
      let newOffset = offset.current;

      if (blockWidth > totalWidth) {
        newOffset = 0;
      }

      setRailCardsListRefScrollTo(newOffset);
      refElementInner.current.dispatchEvent(
        new CustomEvent(CUSTOM_RAIL_EVENTS.SCROLL, {
          detail: {
            newOffset,
            newBlockWidth: blockWidth,
            newTotalWidth: totalWidth,
          },
        }),
      );
    }
  }, [offset.current, refElementInner?.current?.clientWidth, document.body.clientWidth, totalWidth]); // eslint-disable-line
};
