import React from 'react';
import { FormattedMessage } from 'react-intl';

import { KalturaPurchaseStatus } from '@kaltura-ott/ott-client-types/api/types';

import NotAllowedPurchase from 'components/presenters/NotAllowedPurchase/NotAllowedPurchase';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useAppStore, useTransactionStore } from 'hooks';
import { ActionButtonTypesEnum } from 'interface';
import { DateFormatStyle, formatDate } from 'utils';

import { COMPONENT_NAME } from '../../constants';
import { usePackageDetailsContent } from '../PackageDetailsProvider/PackageDetailsProvider';

import styles from '../../PackageDetails.module.scss';

function PackageDetailsStateDefaultView() {
  const {
    isSubscription,
    purchaseStatus,
    purchaseDate,
    description,
    disclaimer,
    priceResolved,
    onBuy,
  } = usePackageDetailsContent();
  const {
    appStore: {
      localizationStore: { displayLanguage },
    },
  } = useAppStore();
  const { isPurchaseAllowed, lastDigits, subpageProperty } = useTransactionStore();
  const { hidePrices } = subpageProperty!;
  const displayPrice = !isSubscription || !hidePrices;

  function resolveContent() {
    if (purchaseStatus === KalturaPurchaseStatus.subscriptionPurchased) {
      return (
        <>
          {purchaseDate && isSubscription && (
            <p className={styles.date}>
              <FormattedMessage
                defaultMessage='Subscribed since {date}'
                id={`${COMPONENT_NAME}.purchaseSubscribedSince`}
                values={{ date: formatDate(purchaseDate, DateFormatStyle.WithFullYear, displayLanguage) }}
              />
            </p>
          )}
          {/* TODO: must be completed in future stories */}
          {/* {!!allowCancellation && (
            <div>
              <ActionButton className={styles.buttonCancel} onClick={onCancelSubscription} type={Types.danger}>
                <FormattedMessage defaultMessage="Cancel" id={`${COMPONENT_NAME}.purchaseButtonCancel`} />
              </ActionButton>
            </div>
          )} */}
        </>
      );
    }

    if (!isPurchaseAllowed) {
      return <NotAllowedPurchase className={styles.warningMessage} componentName={COMPONENT_NAME} />;
    }

    return (
      <>
        {displayPrice && <span className={styles.priceBig}>{priceResolved}</span>}
        <div>
          <ActionButton onClick={onBuy} type={ActionButtonTypesEnum.primary}>
            {lastDigits ? (
              <FormattedMessage
                defaultMessage={`Purchase with xxxx-${lastDigits}`}
                id={`${COMPONENT_NAME}.purchaseButtonBuyWithDigits`}
                values={{ value: lastDigits }}
              />
            ) : (
              <FormattedMessage defaultMessage='Buy' id={`${COMPONENT_NAME}.purchaseButtonBuy`} />
            )}
          </ActionButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.description}>{description.substr(0, 155)}</div>
      {purchaseStatus && resolveContent()}
      {isPurchaseAllowed && disclaimer && <div className={styles.disclaimer}>{disclaimer.substr(0, 150)}</div>}
    </>
  );
}

export default PackageDetailsStateDefaultView;
