import React from 'react';
import { useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';

import { COMPONENT_NAME } from '../../../constants';

import styles from '../../AdditionPlans.module.scss';

interface Props {
  provider: string;
  title: string;
}

const defaultDescriptionMessage =
  'It seems that your activation code was not provided yet. You need it for the fist registration.<br/>Please write email to <strong>ott@o2.cz</strong> or try later.';

export function ActivationModalForNotConfigured({ provider, title }: Props) {
  const intl = useIntl();

  return (
    <DefaultModal
      className={styles.modal}
      description={intl.formatMessage({
        id: `${COMPONENT_NAME}.additionPlanNotConfiguredDescription.${provider}`,
        defaultMessage: defaultDescriptionMessage,
      })}
      title={intl.formatMessage({
        id: `${COMPONENT_NAME}.additionPlanNotConfiguredTitle.${provider}`,
        defaultMessage: title,
      })}
    />
  );
}
