import { useEffect } from 'react';

interface Props {
  setShowSearchInput: (value: boolean) => void;
  setIsShowHistory: (value: boolean) => void;
  isSearchPage: boolean;
  searchParam: string;
  location: {
    state:
      | {
          isInputOpen?: boolean;
        }
      | undefined;
  };
}

export const useCloseSearchWidget = ({
  setShowSearchInput,
  setIsShowHistory,
  isSearchPage,
  searchParam,
  location,
}: Props) => {
  const state = location.state as {
    isInputOpen?: boolean;
  };

  useEffect(() => {
    if (!searchParam && !isSearchPage && !state) {
      setShowSearchInput(false);
      setIsShowHistory(false);
    }
  }, [location]);
};
