import React from 'react';
import { FormattedMessage } from 'react-intl';

import { COMPONENT_NAME } from '../../constants';

import styles from '../../PackageDetails.module.scss';

interface Props {
  title: string;
  priceResolved: JSX.Element;
}

function PinCodeInner({ title, priceResolved }: Props) {
  return (
    <div className={styles.pinCodeModal}>
      <p className={styles.textUpperCase}>
        <FormattedMessage id={`${COMPONENT_NAME}.purchasePinCodeModalSubTitle`} />
      </p>
      <p className={styles.infoMiddle}>
        <FormattedMessage
          id={`${COMPONENT_NAME}.purchaseYearlySubscription`}
          values={{
            title,
            strong: (text: React.ReactNode) => <b>{text}</b>,
          }}
        />
      </p>
      <p className={styles.infoMiddle}>{priceResolved}</p>
    </div>
  );
}

export default PinCodeInner;
