import { useMemo } from 'react';

import { MARGIN_RIGHT } from 'consts';

interface Props {
  width: number;
  columnCount: number;
  columnWidth: number;
}

export const useWidthForMasonryWrapper = ({ width, columnCount, columnWidth }: Props) => {
  return useMemo(() => {
    let result = columnCount * (columnWidth + MARGIN_RIGHT); // To avoid centering elements by the library

    if (result <= 0) {
      result = width;
    }

    return result;
  }, [columnWidth, columnCount]); // eslint-disable-line
};
