import React from 'react';
import cn from 'classnames';

import { CLOSE_BTN_AREA } from 'components/widgets/Modal/constants';
import { ButtonProps } from 'components/widgets/Modal/defaultModalButtonProps';

import { DefaultModalActionButton } from '../DefaultModalActionButton/DefaultModalActionButton';

import styles from '../../DefaultModal.module.scss';

export interface DefaultModalButtonsProps {
  buttonsArray?: ButtonProps[];
  buttonProps?: ButtonProps;
  handleDecline: () => void;
  loading?: boolean;
}
export const DefaultModalButtons = React.memo(
  ({ buttonsArray, buttonProps, handleDecline, loading }: DefaultModalButtonsProps) => {
    const isAnyActionButton = !!buttonsArray?.find((btn) => btn.onAccept) || buttonProps?.onAccept;

    return (
      <div
        className={cn(styles.buttons, {
          [styles.withTopBorder]: isAnyActionButton,
        })}
      >
        <button aria-label={CLOSE_BTN_AREA} className={styles.closeBtn} onClick={handleDecline} type='button' />
        {buttonsArray?.map((props, index) => (
          <DefaultModalActionButton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            loading={loading}
            onAccept={props.onAccept}
            acceptButtonText={props.acceptButtonText}
            acceptButtonType={props.acceptButtonType}
          />
        ))}
        {buttonProps && (
          <DefaultModalActionButton
            loading={loading}
            onAccept={buttonProps.onAccept}
            acceptButtonText={buttonProps.acceptButtonText}
            acceptButtonType={buttonProps.acceptButtonType}
          />
        )}
      </div>
    );
  },
);
