interface Props {
  isSearchPage: boolean;
  setShowSearchInput: (value: boolean) => void;
  setIsShowHistory: (value: boolean) => void;
}

export const useHandleClickOutsideEmptySearch = ({ isSearchPage, setShowSearchInput, setIsShowHistory }: Props) => {
  return () => {
    if (!isSearchPage) {
      const value = (document.getElementById('searchInput') as HTMLInputElement)?.value;

      // close input if value is empty
      if (value?.length === 0) {
        setShowSearchInput(false);
        setIsShowHistory(false);
      }
    }
  };
};
