import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LAST_URL_PLAY_BACK } from 'consts';

export const useSetLastPlaybackUrl = () => {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem(LAST_URL_PLAY_BACK, location.pathname);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
