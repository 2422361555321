import { useEffect } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { HeroRailStoreV2, RailStoreV2 } from '@kaltura-ott/tvpil-shared';

export const useOnInvisible = (store: RailStoreV2) => {
  useEffect(() => {
    return () => {
      if (store.subtype === UIDAPresentationTypeEnum.hero) {
        ((store as unknown) as HeroRailStoreV2).onInvisible(); // to avoid hero rail interval updating on tvpil
      }
    };
  }, []); // eslint-disable-line
};
