import { AssetPresentationRatioEnum, getWidthByHeight } from '@kaltura-ott/tvpil';

import { DEFAULT_HEIGHT } from 'consts';

import { RailSettings } from '../../components/RailProviderV2/types';
import {
  ADDITIONAL_EPISODE_PADDING,
  ADDITIONAL_HEIGHT,
  CARD_PADDING,
  INITIAL_OFFSET,
  TITLE_BLOCK_MARGIN,
} from '../../constants';

export function calculateItemHeight(railSettings: RailSettings, isLogoRail: boolean) {
  const { imageHeight, branding } = railSettings;
  const titleBlockHeight = branding?.fontSize ?? 0 + TITLE_BLOCK_MARGIN;
  let componentHeight = imageHeight || DEFAULT_HEIGHT;

  if (!isLogoRail) {
    componentHeight += ADDITIONAL_HEIGHT + titleBlockHeight + ADDITIONAL_EPISODE_PADDING;
  }

  // TODO: must be added the logic for railType === RAIL_TYPE.CW

  return componentHeight;
}

export function calculateTotalWidth(itemLength: number, settings: RailSettings) {
  let width = 0;
  // get width by height for default landcape ratio if imageWidth is undefined
  const fallbackItemWidth = getWidthByHeight(
    settings.imageHeight || DEFAULT_HEIGHT,
    AssetPresentationRatioEnum['16x9'],
  );

  for (let i = 0; i < itemLength; i += 1) {
    width += settings.imageWidth ? settings.imageWidth + CARD_PADDING : fallbackItemWidth + CARD_PADDING || 0;
  }

  return Math.ceil(width) + INITIAL_OFFSET;
}
