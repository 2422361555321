export const FOUR_COLUMNS_RESOLUTION = 1483;
export const MAX_GRID_WIDTH_FOR_DEFAULT_CARD_SIZE = 984;
export const DEFAULT_HEIGHT_PLUS_MARGIN = 72;
export const SETTINGS_TOP_PADDING = 31;
export const DEFAULT_ITEMS_LENGTH = 20;
export const HEADER_HEIGHT = 120;
export const DEFAULT_WIDTH = 240;
export const DEFAULT_MIN_WIDTH = 225;
export const DEFAULT_HEIGHT = 60;
export const COLUMN_GUTTER = 8;
export const ROW_GUTTER = 20;
export const MENU_HEIGHT = 85;
export const COLUMN = 6;

export const LOGO_HEIGHT = 38;
export const ADD_TO_FAVORITE = 'AddFavorite';
export const REMOVE_FROM_FAVORITE = 'RemoveFavorite';
export const RESPONSE = { SUCCESS: 'Success', ERROR: 'Error' };
export const FAVORITES = 'favorites';
