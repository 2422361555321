import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router-dom';
import { observer } from 'mobx-react';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import Img from 'components/presenters/Img/Img';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import Loader from 'components/presenters/Loader/Loader';
import RailGrid from 'components/widgets/Rail/RailGrid/RailGrid';
import { useErrorScreenContext } from 'context/error/error';
import { useAppStore } from 'hooks';
import routes from 'routesPaths';
import { APPLICATION_SPINNER_EVENT_NAME, applyStyleHexToHSL, applyStyles } from 'utils';

import styles from './Grid.module.scss';

const COMPONENT_NAME = 'GridPage';

function Grid() {
  const { pathname } = window.location;
  const { id: railId, type } = matchPath({ path: routes.grid.path, end: false }, pathname)?.params || {};
  const {
    appStore: { gridPageStoreV2 },
  } = useAppStore();
  const wrapperRef = useRef(null);
  const errorScreenContext = useErrorScreenContext();

  useEffect(() => {
    async function initGridPageStore() {
      await gridPageStoreV2.init(type!, railId!);

      if (!gridPageStoreV2.railStore) {
        // temporary solution for the error content
        errorScreenContext.setError({
          error: {
            titleId: `${COMPONENT_NAME}.errorTitle`,
            titleDefault: 'Rail not found',
            messageId: `${COMPONENT_NAME}.errorMessage`,
            messageDefault: 'Please check id',
          },
          goBackButton: {
            translationId: 'GeoError.playerButtonText',
            defaultMessage: 'Go Back',
          },
        });
      }

      const node = wrapperRef?.current;
      const uiDesignElements = gridPageStoreV2?.railStore?.uiDesignElements;

      if (node && uiDesignElements) {
        applyStyles(node, uiDesignElements, false, true);
        applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
      }
    }

    if (gridPageStoreV2) {
      initGridPageStore();
    }
  }, [type, railId]);

  if (!gridPageStoreV2) {
    return null;
  }

  return (
    <MainLayout>
      <div ref={wrapperRef} className={styles.wrapper}>
        {!!gridPageStoreV2.providerLogo && (
          <Img alt='provider logo' className={styles.providerLogo} srcList={gridPageStoreV2.providerLogo} />
        )}
        {gridPageStoreV2.railStore?.isReady ? (
          <>
            <FormattedMessage
              defaultMessage={gridPageStoreV2.railStore.name}
              id={`${COMPONENT_NAME}.title.${gridPageStoreV2.railStore.name}`}
              tagName='h3'
            />
            <RailGrid className={styles.grid} store={gridPageStoreV2.railStore} />
          </>
        ) : (
          <div className={styles.loaderWrapper}>
            <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.GRID_PAGE} component={<Loader />} />
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default observer(Grid);
