import { OverlayAction, PlaybackAction } from '@kaltura-ott/tvpil-analytics';
import { appStore, featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import { ContextType } from 'context/combinedContext/combinedContext';
import { IS_BOOKMARK_SENT } from 'services/analytics/PlaybackBaseEvent';
import { PlaybackErrorEvent } from 'services/analytics/PlaybackErrorEvent';
import { PlaybackActionEvent } from 'services/analytics/PlaybackEvent';
import { PlaybackOverlayEvent } from 'services/analytics/PlaybackOverlayEvent';
import { globalStorage } from 'services/globalStorage';

import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { playerEventsStorage } from '../../storages';
import { PlayerEvent } from '../../types';

export enum Types {
  action = 'action',
  error = 'error',
  overlay = 'overlay',
}

export class PlaybackEventService {
  readonly context: ContextType;

  readonly player: any;

  constructor(context: ContextType, player: any) {
    this.context = context;
    this.player = player;
  }

  checkIsAnalyticsEnabled(eventType: Types) {
    const isAllowDevicePlaybackActionEvent = !!appStore?.dms?.analytics?.events?.allowDevicePlaybackActionEvent;
    const isAnalyticsEnabled = featureEnabler.variation(FEATURES.ANALYTICS, false);

    if (eventType === Types.error) {
      const isAllowDevicePlaybackErrorEvent = !!appStore?.dms?.analytics?.events?.allowDevicePlaybackErrorEvent;
      return isAnalyticsEnabled && isAllowDevicePlaybackErrorEvent;
    }

    if (eventType === Types.overlay) {
      const isAllowDevicePlaybackOverlayEvent = !!appStore?.dms?.analytics?.events?.allowDevicePlaybackOverlayEvent;
      return isAnalyticsEnabled && isAllowDevicePlaybackOverlayEvent;
    }
    return isAnalyticsEnabled && isAllowDevicePlaybackActionEvent;
  }

  addEventListeners() {
    if (this.checkIsAnalyticsEnabled(Types.action)) {
      this.player.addEventListener(this.player.Event.PLAYBACK_START, () => {
        const isFirstPlayEventFired = playerEventsStorage.getIsFirstPlayEventFired();
        if (!isFirstPlayEventFired) {
          playerEventsStorage.setIsFirstPlay(true);
        }
      });
      this.player.addEventListener(this.player.Event.MEDIA_LOADED, () => {
        const isFirstPlay = playerEventsStorage.getIsFirstPlay();

        if (isFirstPlay) {
          new PlaybackActionEvent({ player: this.player }, PlaybackAction.firstPlay).sendEvent();
          playerEventsStorage.setIsFirstPlay(false);
          playerEventsStorage.setIsFirstPlayEventFired(true);
          sessionStorage.setItem(IS_BOOKMARK_SENT, '1');
        }

        globalStorage.setIsKsErrorHandled(false);
      });
      this.player.addEventListener(this.player.Event.PLAY, () => {
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.play).sendEvent();
      });
      this.player.addEventListener(this.player.Event.PAUSE, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.pause).sendEvent(),
      );
      this.player.addEventListener(CUSTOM_PLAYER_EVENTS.SEEK, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.seek).sendEvent(),
      );
      this.player.addEventListener(this.player.Event.UI.USER_CLICKED_FORWARD, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.ff).sendEvent(),
      );
      this.player.addEventListener(this.player.Event.UI.USER_CLICKED_REWIND, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.rw).sendEvent(),
      );
      this.player.addEventListener(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED, () => {
        if (!playerEventsStorage.getIsPlayerPlaybackEnded()) {
          new PlaybackActionEvent({ player: this.player }, PlaybackAction.finish).sendEvent();
          playerEventsStorage.setIsPlayerPlaybackEnded(true);
        }
      });
      this.player.addEventListener(this.player.Event.PLAYER_DESTROY, () => {
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.stop).sendEvent();
        sessionStorage.removeItem(IS_BOOKMARK_SENT);
      });
    }
    if (this.checkIsAnalyticsEnabled(Types.error)) {
      this.player.addEventListener(this.player.Event.PLAYBACK_START, () =>
        playerEventsStorage.clearIsPlayerErrorEventFired(),
      );
      this.player.addEventListener(this.player.Event.ERROR, (e: PlayerEvent) => {
        const isPlayerErrorFired = playerEventsStorage.getIsPlayerErrorEventFired();

        if (!isPlayerErrorFired) {
          new PlaybackErrorEvent({ player: this.player }, e.payload).sendEvent();
          playerEventsStorage.setIsPlayerErrorEventFired(true);
        }
      });
    }
    if (this.checkIsAnalyticsEnabled(Types.overlay)) {
      this.player.addEventListener(this.player.Event.TEXT_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.subtitleChange).sendEvent(),
      );
      this.player.addEventListener(this.player.Event.AUDIO_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.audioChange).sendEvent(),
      );
      this.player.addEventListener(this.player.Event.VIDEO_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.videoBitrateChange).sendEvent(),
      );
      this.player.addEventListener(CUSTOM_PLAYER_EVENTS.CONCURRENCY_LIMIT, (payload: any) =>
        new PlaybackOverlayEvent(
          { player: this.player },
          OverlayAction.reachedConcurrencyHhLimit,
          payload.code,
        ).sendEvent(),
      );
      this.player.addEventListener(CUSTOM_PLAYER_EVENTS.NEXT_EPISODE, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.nextEpisode).sendEvent(),
      );
      this.player.addEventListener(CUSTOM_PLAYER_EVENTS.SKIP_INTRO, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.skipIntro).sendEvent(),
      );
    }
  }

  removeEventListeners() {
    if (this.checkIsAnalyticsEnabled(Types.action)) {
      this.player.removeEventListener(this.player.Event.PLAYBACK_START, () => {
        const isFirstPlayEventFired = playerEventsStorage.getIsFirstPlayEventFired();
        if (!isFirstPlayEventFired) {
          playerEventsStorage.setIsFirstPlay(true);
        }
      });
      this.player.removeEventListener(this.player.Event.MEDIA_LOADED, () => {
        const isFirstPlay = playerEventsStorage.getIsFirstPlay();
        if (isFirstPlay) {
          new PlaybackActionEvent({ player: this.player }, PlaybackAction.firstPlay).sendEvent();
          playerEventsStorage.setIsFirstPlay(false);
          playerEventsStorage.setIsFirstPlayEventFired(true);
          sessionStorage.setItem(IS_BOOKMARK_SENT, '1');
        }
      });
      this.player.removeEventListener(this.player.Event.PLAY, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.play).sendEvent(),
      );
      this.player.removeEventListener(this.player.Event.PAUSE, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.pause).sendEvent(),
      );
      this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.SEEK, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.seek).sendEvent(),
      );
      this.player.removeEventListener(this.player.Event.UI.USER_CLICKED_FORWARD, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.seek).sendEvent(),
      );
      this.player.removeEventListener(this.player.Event.UI.USER_CLICKED_REWIND, () =>
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.seek).sendEvent(),
      );
      this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED, () => {
        if (!playerEventsStorage.getIsPlayerPlaybackEnded()) {
          new PlaybackActionEvent({ player: this.player }, PlaybackAction.finish).sendEvent();
          playerEventsStorage.setIsPlayerPlaybackEnded(true);
        }
      });
      this.player.removeEventListener(this.player.Event.PLAYER_DESTROY, () => {
        new PlaybackActionEvent({ player: this.player }, PlaybackAction.stop).sendEvent();
        sessionStorage.setItem(IS_BOOKMARK_SENT, '1');
      });
    }
    if (this.checkIsAnalyticsEnabled(Types.error)) {
      this.player.removeEventListener(this.player.Event.PLAYBACK_START, () =>
        playerEventsStorage.clearIsPlayerErrorEventFired(),
      );
      this.player.removeEventListener(this.player.Event.ERROR, (e: PlayerEvent) => {
        const isPlayerErrorFired = playerEventsStorage.getIsPlayerErrorEventFired();

        if (!isPlayerErrorFired) {
          new PlaybackErrorEvent({ player: this.player }, e.payload).sendEvent();
          playerEventsStorage.setIsPlayerErrorEventFired(true);
        }
      });
    }
    if (this.checkIsAnalyticsEnabled(Types.overlay)) {
      this.player.removeEventListener(this.player.Event.TEXT_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.subtitleChange).sendEvent(),
      );
      this.player.removeEventListener(this.player.Event.AUDIO_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.audioChange).sendEvent(),
      );
      this.player.removeEventListener(this.player.Event.VIDEO_TRACK_CHANGED, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.videoBitrateChange).sendEvent(),
      );
      this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.CONCURRENCY_LIMIT, (payload: any) =>
        new PlaybackOverlayEvent(
          { player: this.player },
          OverlayAction.reachedConcurrencyHhLimit,
          payload.code,
        ).sendEvent(),
      );
      this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.NEXT_EPISODE, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.nextEpisode).sendEvent(),
      );
      this.player.removeEventListener(CUSTOM_PLAYER_EVENTS.SKIP_INTRO, () =>
        new PlaybackOverlayEvent({ player: this.player }, OverlayAction.skipIntro).sendEvent(),
      );
    }
  }
}
