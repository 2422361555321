import { useCallback, useRef } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';

import { RailCardContainerProps, RailData } from '../RailProviderV2/types';
import RailViewer from '../RailViewer/RailViewer';

interface Props<T extends { id: number }> {
  railData: RailData<T>;
  children: ({ imageHeight, itemWidth, item }: RailCardContainerProps<T>) => React.ReactNode;
}

function DynamicRailViewer<T extends { id: number }>({ railData, children }: Props<T>) {
  const stopIndexRef = useRef(0);
  const { totalCount, items, isLoading, hasNextPage, fetchNextPage } = railData || {};

  const nextPageHandle = useCallback(
    (_: number, stopIndex: number) => {
      if (fetchNextPage && hasNextPage && !isLoading && stopIndex !== stopIndexRef.current) {
        stopIndexRef.current = stopIndex;

        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isLoading],
  );

  return (
    <InfiniteLoader itemCount={totalCount} isItemLoaded={(i) => i < items.length} loadMoreItems={nextPageHandle}>
      {({ onItemsRendered }) => (
        <RailViewer<T> onItemsRendered={onItemsRendered}>
          {({ imageHeight, itemWidth, item }) => {
            return children({ imageHeight, itemWidth, item });
          }}
        </RailViewer>
      )}
    </InfiniteLoader>
  );
}

export default DynamicRailViewer;
