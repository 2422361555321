import React from 'react';
import cn from 'classnames';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';

import styles from './CardWithActionButton.module.scss';

type ButtonParams = {
  text: string;
  link?: string;
  blank?: boolean;
};

interface Props {
  name: string;
  className?: string;
  buttonParams: ButtonParams;
  title?: string;
  onHandleClick?: Function;
}

export default function CardWithActionButton({ name, buttonParams, title, onHandleClick, className }: Props) {
  const { blank } = buttonParams;

  return (
    <div className={cn(styles.card, className)}>
      <div>
        <h5>{name}</h5>
      </div>
      <ActionButton
        className={styles.actionButton}
        onClick={onHandleClick}
        target={blank ? '_blank' : undefined}
        title={title}
        to={buttonParams.link}
        type={ActionButtonTypesEnum.primary}
      >
        {buttonParams.text}
      </ActionButton>
    </div>
  );
}
