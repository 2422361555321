import { SetStateAction, useCallback, useState } from 'react';

import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';

import { useAppStartAndHomePageReady } from '../useAppStartAndHomePageReady/useAppStartAndHomePageReady';
import { useClearAppStartAndHomePageReady } from '../useClearAppStartAndHomePageReady/useClearAppStartAndHomePageReady';
import { useHandleSkeletonRef } from '../useHandleSkeletonRef/useHandleSkeletonRef';
import { usePerformanceSpinerHook } from '../usePerformanceSpinerHook/usePerformanceSpinerHook';

function useRailWrapperCombiner(railSettings: RailSettings, items: CollectionAsset[], railType: string, index: number) {
  const [isSkeletonCondition, setIsSkeletonCondition] = useState(true);
  const setIsSkeletonConditionForward = useCallback(
    (value: SetStateAction<boolean>) => setIsSkeletonCondition(value),
    [],
  ); // eslint-disable-line
  const handleSkeletonRef = useHandleSkeletonRef(setIsSkeletonConditionForward, items, railSettings);
  const performanceEventsContext = usePerformanceEventsContext();
  const isHomePageFirstRail = index === 0;

  usePerformanceSpinerHook({ railSettings, isSkeletonCondition, performanceEventsContext, railType, items });
  useAppStartAndHomePageReady({ isSkeletonCondition, isHomePageFirstRail, performanceEventsContext });
  useClearAppStartAndHomePageReady({ isHomePageFirstRail, performanceEventsContext });

  return { isSkeletonCondition, handleSkeletonRef };
}

export { useRailWrapperCombiner };
