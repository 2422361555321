import { useCallback } from 'react';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { useRailContext } from 'components/widgets/Rail/RailHorisontal/components/RailProvider/RailProvider';
import { applyStyle } from 'utils';

import { reCalculateCardZoom } from '../../../helpers/reCalculateCardZoom';
import { useAssetCardBodyResolveData } from '../useAssetCardBodyResolveData/useAssetCardBodyResolveData';

interface Props {
  ref: React.MutableRefObject<any>;
}

export const useMouseEnterHandle = ({ ref }: Props) => {
  const { height, width } = useAssetCardBodyResolveData();
  const { onCardHover, nextButtons } = useRailHorisontalDataContext();
  const { isGrid } = useRailContext();

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onCardHover && !isGrid) {
        e.stopPropagation();

        const parent = ref.current.parentNode;
        const textBlockHeight = ref.current
          .closest('a')
          .style.getPropertyValue('--textBlockHeight')
          .replace(/[^0-9]/g, '');

        onCardHover(true, +textBlockHeight);

        const { zoomToLeft, zoomToRight } = reCalculateCardZoom({
          height,
          width,
          nextButtons,
          bounding: parent.getBoundingClientRect(),
        });

        if (zoomToLeft) {
          applyStyle(parent, '--zoom-to-left', `${zoomToLeft}px`);
        }
        if (zoomToRight) {
          applyStyle(parent, '--zoom-to-right', `${zoomToRight}px`);
        }
      }
    },
    [], // eslint-disable-line
  );
};
