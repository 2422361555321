import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { RhinoImage, useChannelLiveProgram } from '@kaltura-ott/tvpil-shared';

interface Props {
  assetId: number;
  setLiveProgramPosterImages: (value: RhinoImage[]) => void;
}

function MaxiPlayerPosterHook({ setLiveProgramPosterImages, assetId }: Props) {
  const { liveProgram } = useChannelLiveProgram({ enabled: true, channelId: assetId, timestamp: Date.now() });

  useEffect(() => {
    if (liveProgram && liveProgram.images) {
      setLiveProgramPosterImages(liveProgram.images);
    }
  }, [liveProgram, setLiveProgramPosterImages]);

  return null;
}

export default observer(MaxiPlayerPosterHook);
