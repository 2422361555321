import React from 'react';

import { Record, SeriesRecord } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { useVideoContext } from 'context/videoContext/videoContext';
import { checkIsNotStringIdentity } from 'utils';

import { Specifications } from '../Specifications';
import { Tags } from '../Tags';
import { Title } from '../Title';

import styles from '../../VideoContent.module.scss';

interface Props {
  isCatchupSeries: boolean;
  record: Record;
  seriesRecord: SeriesRecord | undefined;
}

export function TitleInfo({ isCatchupSeries, record, seriesRecord }: Props) {
  const { program } = useVideoContext();
  const { title, originalName, isRented, contentMarker, isVodSeries, isVodSingle } = detailsStoreDataResolver(program);
  const isOriginalName = checkIsNotStringIdentity(title, originalName as any);
  const isDisplayContentMarker = Boolean((isVodSeries || isVodSingle) && !isRented && contentMarker);

  return (
    <div className={styles.titleInfo}>
      <Tags isCatchupSeries={isCatchupSeries} />
      <Title title={title} record={record} seriesRecord={seriesRecord} />
      {isOriginalName && <h3 className={styles.subTitle}>{String(originalName)}</h3>}

      <Specifications isRented={isRented} isDisplayContentMarker={isDisplayContentMarker} />
    </div>
  );
}
