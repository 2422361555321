import { useEffect } from 'react';

import { useEpgContext } from 'context/epgContext/epgContext';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { PerformanceEvents } from 'interface';

export function useGridPerformance() {
  const { isReadyTvChannel } = useEpgContext();

  const performanceEventsContext = usePerformanceEventsContext();

  useEffect(() => {
    if (isReadyTvChannel) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.epgReady });
    }
  }, [isReadyTvChannel, performanceEventsContext]);

  useEffect(() => {
    return () => {
      performanceEventsContext.clearEvent(PerformanceEvents.epgReady);
    };
  }, [performanceEventsContext]);
}
