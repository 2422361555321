import { IntlShape } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { Linear } from '@kaltura-ott/tvpil-shared';

import { ContextType } from 'context/toast/toast';
import { ApplicationError } from 'interface';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';
import { userActionEvents } from 'utils';

import { ADD_TO_FAVORITE, REMOVE_FROM_FAVORITE, RESPONSE } from '../constants';

import { getResultMessage } from './getResultMessage';

export const setFavoriteResultsHandler = (
  channel: Linear,
  isToggleSuccess: boolean,
  intl: IntlShape,
  toastMessageContext: ContextType,
) => {
  if (isToggleSuccess) {
    const actionType = channel.isFavorite ? ADD_TO_FAVORITE : REMOVE_FROM_FAVORITE;
    const userActionType = channel.isFavorite ? UserActionType.favoriteAdd : UserActionType.favoriteRemove;

    userActionEvents(userActionType, channel?.id);
    toastMessageContext.showToast({
      success: true,
      title: getResultMessage(actionType, RESPONSE.SUCCESS, intl),
    });
  } else {
    const actionType = channel.isFavorite ? REMOVE_FROM_FAVORITE : ADD_TO_FAVORITE;

    toastMessageContext.showToast({
      error: true,
      title: getResultMessage(actionType, RESPONSE.ERROR, intl),
    });
    sendApplicationErrorEvent({
      context: UIDAPresentationTypeEnum.favorites,
      message: actionType,
      additionalInfo: ApplicationError.Favorites,
    });
  }
};
