import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getImageByHeightV2, Linear } from '@kaltura-ott/tvpil-shared';

const LOGO_HEIGHT = 40;

function useChannelCombiner(channel: Linear) {
  const {
    data: { images },
  } = channel;
  const originImage = getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['16x9'], images);

  return { originImage };
}

export { useChannelCombiner };
