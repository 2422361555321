import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCollectionGridSettings, useProviderLogo } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/Grids/constants';
import routes from 'routesPaths';
import { applyStyleHexToHSL, applyStyles } from 'utils';

import { useGridMatchPath } from '../../../common/hooks/useGridMatchPath/useGridMatchPath';
import { GridSettingsFetchDataTypes } from '../../../types';

function useCollectionGridHooksCombiner() {
  const { type, railId } = useGridMatchPath(routes.collectionGrid.path);
  const [, setForceUpdate] = useState(false); // For force updating of component
  const gridSettings = useCollectionGridSettings({ gridReferenceId: type!, railReferenceId: railId! });
  const providerLogo = useProviderLogo(gridSettings.settings?.providerName);
  const wrapperRef = useRef(null);
  const isNoContent = !gridSettings.settings && !gridSettings.isLoading;
  const intl = useIntl();
  const notValidPageTitle = intl.formatMessage({
    id: `${COMPONENT_NAME}.notValidPage`,
    defaultMessage: 'Not a valid page',
  });

  useEffect(() => {
    async function initGridPage() {
      const node = wrapperRef?.current;

      if (node && gridSettings.settings?.branding!) {
        applyStyles(node, gridSettings.settings.branding, false, true);
        applyStyleHexToHSL(node, gridSettings.settings.branding.backgroundColor);
      }
    }

    if (gridSettings && !gridSettings.error) {
      initGridPage();
    }
  }, [type, railId, gridSettings.isLoading]);

  return ({
    gridSettings: gridSettings.settings,
    isLoading: gridSettings.isLoading,
    wrapperRef,
    providerLogo,
    setForceUpdate,
    isNoContent,
    notValidPageTitle,
  } as unknown) as GridSettingsFetchDataTypes;
}

export { useCollectionGridHooksCombiner };
