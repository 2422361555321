import { FUTURE_RECORDINGS, MD_UI_DISABLE, PLAYER_MOUNT_NODE, SERIES_ID, UI_MODE } from 'consts';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { useEpgStore } from './useEpgStore';
import { useSearchParam } from './useSearchParam';

const GRID = '/grid';

function useDetectLocation() {
  const { location } = window; // for avoiding redundant re-renders and cyclic dependencies with the router.tsx file
  const { epgStore } = useEpgStore();
  const isPlayerInstance = () => !!document.getElementById(PLAYER_MOUNT_NODE);
  const { isFullPlayer, isUrlToFullPlayer } = globalStorage;
  const isLiveVideoPage = location.pathname.includes(routes.live.commonPath);
  const isModal =
    location.pathname.includes(routes.details.pathDetailsInfo.path) ||
    location.pathname.includes(routes.details.pathDetailsSeries.path);
  const isFutureNpvr = useSearchParam('type') === FUTURE_RECORDINGS;
  const seriesId = useSearchParam(SERIES_ID) || undefined;
  const isSearchPageWithDetailsInfo = location.pathname.includes(
    `${routes.search.path}${routes.details.pathDetailsInfo.path}`,
  );
  const isSearchPageWithDetailsSeries = location.pathname.includes(
    `${routes.search.path}${routes.details.pathDetailsSeries.path}`,
  );
  const isDetailsSeries = location.pathname.includes(routes.details.pathDetailsSeries.path);
  const isDetailsPage = location.pathname.includes(routes.details.commonPath);
  const isDetailsInfo = location.pathname.includes(routes.details.pathDetailsInfo.path);
  const isSearchPage = location.pathname.includes(routes.search.path);
  const isEpg = location.pathname.includes(epgStore?.referenceId);
  const isMdUiDisable =
    useSearchParam(UI_MODE) === MD_UI_DISABLE || location.pathname.includes(`${UI_MODE}=${MD_UI_DISABLE}`);
  const isHomePage = location.pathname.includes(routes.home.alias) || location.pathname === routes.home.path;
  const isGrid = location.pathname.includes(GRID);
  const isSettings = location.pathname.includes(routes.settings.path);
  const isLogin = location.pathname.includes(routes.login.path);

  return {
    isSearchPageWithDetailsSeries,
    isPlayerInstance,
    isSearchPageWithDetailsInfo,
    isDetailsSeries,
    isDetailsInfo,
    isDetailsPage,
    isSearchPage,
    seriesId,
    isModal,
    isFutureNpvr,
    isEpg,
    isFullPlayer,
    isMdUiDisable,
    isUrlToFullPlayer,
    isHomePage,
    isGrid,
    isLiveVideoPage,
    isSettings,
    isLogin,
  };
}

export { useDetectLocation };
