import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Node } from 'types';

import OnBoardingLayout from 'components/presenters/layouts/onboarding/OnBoardingLayout';
import { useErrorScreenContext } from 'context/error/error';
import { Form, useLoginPageStore, useLoginStore, useValidationForm } from 'hooks';
import { ActionButtonTypesEnum } from 'interface';
import { applyStyles, validateEmail } from 'utils';

import ActionButton from '../../ActionButton/ActionButton';
import Input from '../../common/Input/Input';
import findLoginItems from '../findLoginItems';

import ConfirmationEmail from './ConfirmationEmail';
import { COMPONENT_NAME } from './constants';

import styles from './ForgotPassword.module.scss';

let savedEmail = '';

function ForgotPassword() {
  const { ssoResetPassword } = useLoginStore();
  const intl = useIntl();
  const [form, setForm, defaultInputError] = useValidationForm();
  const [email, setEmail] = useState('');
  const loginPageStore = useLoginPageStore();
  const { clearError } = useErrorScreenContext();
  const [link, setLink] = useState<string | undefined>();

  useEffect(() => {
    async function init() {
      await loginPageStore.init();
      const { signUp } = findLoginItems(loginPageStore?.items!);
      setLink(signUp?.inAppLink);
    }

    if (loginPageStore && !link) {
      init();
    }
    // eslint-disable-next-line
  }, []);

  const handleRef = useCallback((node: Node) => {
    const { uiDesignElements } = loginPageStore!;

    if (node !== null && uiDesignElements) {
      applyStyles(node, uiDesignElements, false, true);
    }
  }, []); // eslint-disable-line

  const validateOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const emailIsValid = validateEmail(name, value);
    const updatedForm: Form = {
      ...form,
      [name]: {
        ...form[name],
        value,
        isFailedValidation: !emailIsValid,
        errorMessage: defaultInputError[name],
      },
    };

    setForm(updatedForm);
  }, []); // eslint-disable-line

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedForm: Form = {
      ...form,
      [name]: {
        ...form[name],
        value,
        isFailedValidation: false,
      },
    };

    setForm(updatedForm);
  }, []);

  async function handleResetPassword() {
    savedEmail = form.email.value;

    setEmail(savedEmail);
    clearError();

    await ssoResetPassword(savedEmail);

    savedEmail = '';
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent<EventTarget>) => {
      e.preventDefault();
      const emailIsValid = validateEmail('email', form.email.value);
      const updatedForm = {
        ...form,
        email: {
          ...form.email,
          isFailedValidation: !emailIsValid,
          errorMessage: defaultInputError.email,
        },
      };

      setForm(updatedForm);

      if (!emailIsValid) {
        return;
      }

      await handleResetPassword();
    },
    [form.email.value], // eslint-disable-line
  );

  return (
    <OnBoardingLayout refElement={handleRef}>
      <div className={styles.forgotPassword}>
        {savedEmail ? (
          <ConfirmationEmail email={email || savedEmail} />
        ) : (
          <>
            <FormattedMessage
              tagName={(props: any) => <div {...props} className={styles.title} />}
              defaultMessage='Restore Your Password'
              id={`${COMPONENT_NAME}.title`}
            />
            <FormattedMessage
              tagName={(props: any) => <div {...props} className={styles.description} />}
              defaultMessage='Enter your email Address and we will send you a link to restore you password'
              id={`${COMPONENT_NAME}.description`}
            />

            <form className={styles.form} onSubmit={handleSubmit}>
              <Input
                error={form.email.errorMessage}
                isShowError={form.email.isFailedValidation}
                name='email'
                onBlur={validateOnBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({
                  id: `${COMPONENT_NAME}.placeholder`,
                  defaultMessage: 'Your email address',
                })}
                type='text'
                value={form.email.value}
              />
              <ActionButton
                type={ActionButtonTypesEnum.primary}
                className={styles.sendEmailButton}
                onClick={handleSubmit}
              >
                <FormattedMessage defaultMessage='Send Email' id={`${COMPONENT_NAME}.sendEmail`} />
              </ActionButton>
            </form>

            <div className={styles.notMember}>
              <FormattedMessage defaultMessage='Not a member yet?' id={`${COMPONENT_NAME}.notMember`} />
              <a href={link} rel='noopener noreferrer' target='_blank'>
                <FormattedMessage defaultMessage='Sign up' id='Forms.SignIn.signUp' />
              </a>
            </div>
          </>
        )}
      </div>
    </OnBoardingLayout>
  );
}

export default ForgotPassword;
