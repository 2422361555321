import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { ParentalPinStoreFacade } from '@kaltura-ott/tvpil-shared/lib/facades/parental-pin-store-facade';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { ContextType } from 'context/modal/interface';
import { saveAssetToGlobalStorage } from 'utils';

interface Props {
  asset: ViewableEntitiesType;
  isLive: boolean;
  navigate: NavigateFunction;
  modalContext: ContextType;
  parentalPinStore: ParentalPinStoreFacade;
}

export function checkParentalRestriction(
  event: React.MouseEvent<typeof Link>,
  { asset, isLive = false, navigate, modalContext, parentalPinStore }: Props,
) {
  const { parentalRating } = detailsStoreDataResolver(asset);

  if (parentalPinStore.isBlockByParentRating(asset)) {
    event.preventDefault();
    // @ts-ignore
    const { pathname } = event.currentTarget;

    modalContext.openModal(
      <PinCodeModal
        callback={(success: boolean) => {
          if (success) {
            saveAssetToGlobalStorage(asset, isLive);
            navigate(pathname);
          }
        }}
        asset={asset}
        context={UIDAPresentationTypeEnum.asset_details_page}
        description={parentalRating ? <DescriptionWithAge age={parentalRating} /> : undefined}
        type={PinCodeModalTypes.RestrictedContentParental}
      />,
    );

    return false;
  }

  return true;
}
