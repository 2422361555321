import React, { createContext, useContext, useMemo } from 'react';

import { CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

import { DEFAULT_WIDTH } from 'consts';

interface Props {
  gridSettingsData: CollectionGridSettings;
  children: React.ReactNode;
}

interface SeriesCatchUpGridSettingsContextType {
  gridSettingsData: CollectionGridSettings;
  itemWidth: number;
}

const SeriesCatchUpGridSettingsContext = createContext<SeriesCatchUpGridSettingsContextType>({
  gridSettingsData: {} as CollectionGridSettings,
  itemWidth: 0,
});

const SeriesCatchUpGridSettingsProvider = ({ gridSettingsData, children }: Props) => {
  const itemWidth = gridSettingsData.imageWidth || DEFAULT_WIDTH;

  const contextValue = useMemo(() => {
    return {
      gridSettingsData,
      itemWidth,
    };
  }, []);

  return (
    <SeriesCatchUpGridSettingsContext.Provider value={contextValue}>
      {children}
    </SeriesCatchUpGridSettingsContext.Provider>
  );
};

const useSeriesCatchUpGridSettingsContext = () => useContext(SeriesCatchUpGridSettingsContext);

export { SeriesCatchUpGridSettingsProvider, useSeriesCatchUpGridSettingsContext };
