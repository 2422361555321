import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { PurchaseIcon } from 'components/presenters/icons';
import { FavoriteIcon } from 'components/presenters/icons/Favorite/Favorite';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';
import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';
import TimeProgressBar from 'components/widgets/ProgressBar/TimeProgressBar';

import { useContentRestriction } from '../HeroCard/hooks/useContentRestriction';

import styles from './HeroCardContent.module.scss';

function HeroCardContent({
  shouldDisplayCartIcon,
  channelTitle,
  channelLogoUrl,
  shouldDisplayFavoriteIcon,
  shouldDisplayPlayButton,
  endDate,
  startDate,
  channelHasProgram,
  isAdult,
}: {
  channelTitle?: string;
  shouldDisplayPlayButton: boolean;
  channelLogoUrl: string;
  shouldDisplayCartIcon: boolean;
  isAdult: boolean;
  shouldDisplayFavoriteIcon: boolean;
  endDate: number;
  startDate: number;
  channelHasProgram: boolean;
}) {
  // for avoiding wrapping the whole parent component with observer
  const isContentRestricted = useContentRestriction(isAdult);
  const showProgramContent = !isContentRestricted && channelHasProgram;

  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      {shouldDisplayCartIcon && <PurchaseIcon className={styles.cart} isBadge />}
      {showProgramContent && (
        <>
          {shouldDisplayPlayButton && <PlayButton data-type='playButton' className={styles.playBtn} />}
          {endDate && startDate && (
            <TimeProgressBar data-type='progress-bar' endDate={endDate} startDate={startDate}>
              {(percent: number) => {
                return <ProgressBar className={styles.progressBar} percent={percent} />;
              }}
            </TimeProgressBar>
          )}
        </>
      )}

      <div className={styles.channelLarge}>
        {channelHasProgram && <img data-type='logo' alt={channelTitle} src={channelLogoUrl} />}
        {shouldDisplayFavoriteIcon && <FavoriteIcon className={styles.favoriteChannel} />}
      </div>
    </div>
  );
}

export default observer(HeroCardContent);
