import { ORIENTATION } from 'consts';

export function resolveImgClass(ratio: string | undefined) {
  let result;

  switch (ratio) {
    case '16:9': {
      result = ORIENTATION.LANDSCAPE;
      break;
    }
    case '1:1': {
      result = 'default';
      break;
    }
    case '2:3': {
      result = ORIENTATION.PORTRAIT;
      break;
    }
    default: {
      result = 'default';
      break;
    }
  }

  return result;
}
