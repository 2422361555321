import { ASSET_NOT_PROVIDED, NO_EXTERNAL_ERRORS, NO_INTERNAL_ERRORS } from '../constants/constants';
import { FormatOutcomeParams } from '../types/types';

/**
 * Formats and returns a log message based on asset and error information.
 *
 * @returns {string} A formatted string in JSON format representing the outcome, including internal and external error information, and asset details.
 */
export const formatOutcomeLog = ({ errorData, apiError, currentAsset }: FormatOutcomeParams): string => {
  const assetInfoExist = errorData?.playerErrorPayload && currentAsset?.data;

  return JSON.stringify(
    {
      internalIncome: errorData ?? NO_INTERNAL_ERRORS,
      externalIncome: apiError ?? NO_EXTERNAL_ERRORS,
      assetInfo: assetInfoExist ? currentAsset.data : ASSET_NOT_PROVIDED,
    },
    null,
    2,
  );
};
