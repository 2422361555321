import { KuxMenuItem, TypeOfApplicationLink } from '@kaltura-ott/tvpil';

import routes from 'routesPaths';

function resolveMenuLinks(item: KuxMenuItem): string {
  if (item.typeOfAppLink === TypeOfApplicationLink.grid) {
    return routes.grid.link(item.link!);
  }
  return routes.page.link(item.inAppLink!);
}

export default resolveMenuLinks;
