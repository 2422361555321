import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';

import Devices from '../Devices/Devices';
import Favorites from '../Favorites/Favorites';
import Languages from '../Languages/Languages';
import ParentalControl from '../ParentalControl/ParentalControl';
import RhinoFavorites from '../RhinoFavorites/Favorites';
import SelfService from '../SelfService/SelfService';
import Subscriptions from '../Subscriptions/Subscriptions';
import VideoSettings from '../VideoSettings/VideoSettings';

interface Props {
  subtype: string;
  referenceId: string;
}

export default function SettingsComponentResolver({ subtype, referenceId }: Props) {
  switch (subtype) {
    case UIDAPresentationTypeEnum.subscription:
      return <Subscriptions />;
    case UIDAPresentationTypeEnum.parental_control:
      return <ParentalControl />;
    case UIDAPresentationTypeEnum.language:
      return <Languages />;
    case UIDAPresentationTypeEnum.devices:
      return <Devices />;
    case UIDAPresentationTypeEnum.link_list:
      return <SelfService />;
    case UIDAPresentationTypeEnum.preferred_video_quality:
      return <VideoSettings />;
    case UIDAPresentationTypeEnum.favorites: {
      const isRhinoFavoritesEnabled = featureEnabler.variation(FEATURES.NEW_MY_FAVOURITE_CHANNELS_ENABLED, true);

      return isRhinoFavoritesEnabled ? <RhinoFavorites referenceId={referenceId} /> : <Favorites />;
    }
    default:
      return <h2>No subpage yet</h2>;
  }
}
