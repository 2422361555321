export function removeSymbolAndTransformToCamelCase(str: string) {
  const reg = /(^|[^a-z0-9]+?)[a-z0-9]/gi;

  if (!str.includes('_') || !str.includes('')) {
    return str;
  }

  return str.toLowerCase().replace(reg, (match) => {
    if (match.length === 1) {
      // the 1st char
      return match.toLowerCase();
    }
    // char after special char
    return `${match[1].toUpperCase()}`;
  });
}
