import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';

import { FROM_REFERENCE_ID } from 'consts';
import { useMenuContext } from 'context/menuContext/menuContext';
import { useDetectLocation, usePageId, useSearchParam } from 'hooks';
import routes from 'routesPaths';
import { findMenuItems } from 'utils';

interface Props {
  isActive: React.MutableRefObject<string | undefined>;
  callback: () => void;
}

function MenuItemsCombiner({ isActive, callback }: Props) {
  const location = useLocation();
  const { store } = useMenuContext();
  const { isSettings } = useDetectLocation();
  const fromReferenceId = useSearchParam(FROM_REFERENCE_ID);
  const pageId = usePageId();

  useEffect(() => {
    const { itemById } = findMenuItems(store, fromReferenceId || pageId); // get active item

    if (itemById && itemById.data.linkItem) {
      const { typeOfAppLink, inAppLink, link } = itemById.data.linkItem;
      isActive.current = typeOfAppLink === TypeOfApplicationLink.page ? routes.page.link(inAppLink) : link;

      callback();
    } else if (isSettings) {
      isActive.current = undefined;
      callback();
    }
  }, [location.pathname, pageId]);

  return null;
}

export default React.memo(MenuItemsCombiner);
