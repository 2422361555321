import { useCallback, useEffect, useRef } from 'react';

const down = 'down';
const up = 'up';

export const useDateViewScrollDirection = () => {
  let time: HTMLElement | null = null;
  let days: HTMLElement | null = null;
  const prevScrollY = useRef(0);
  const scrollDirection = useRef<string | null>();

  const changeState = useCallback((timeTop = '', daysTop = '') => {
    if (time) {
      time.style.top = timeTop;
    }
    if (days) {
      days.style.top = daysTop;
    }
  }, []);

  const toggleScrollDirection = useCallback(() => {
    const { scrollY } = window;
    if (scrollY === 0) {
      scrollDirection.current = null;
      changeState();
    } else if (scrollY > prevScrollY.current && scrollDirection.current !== down) {
      scrollDirection.current = down;
      changeState();
    } else if (scrollY < prevScrollY.current && scrollDirection.current !== up) {
      scrollDirection.current = up;
      changeState('145px', '85px');
    }

    prevScrollY.current = scrollY;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection);

    time = document.getElementById('time');
    days = document.getElementById('days');

    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  }, []);
};
