import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDetectLocation, useOnClickOutside, useSearchParam } from 'hooks';

import { useCloseSearchHistory } from '../useCloseSearchHistory/useCloseSearchHistory';
import { useCloseSearchWidget } from '../useCloseSearchWidget/useCloseSearchWidget';
import { useFocusOnSearchInput } from '../useFocusOnSearchInput/useFocusOnSearchInput';
import { useHandleClickOutsideEmptySearch } from '../useHandleClickOutsideEmptySearch/useHandleClickOutsideEmptySearch';
import { useHandleRef } from '../useHandleRef/useHandleRef';
import { useOpenSearchInputAndHistory } from '../useOpenSearchInputAndHistory/useOpenSearchInputAndHistory';

export interface StateProps {
  isInputOpen?: boolean;
}

export const useSearchWidgetCombiner = () => {
  const [isShowSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [isShowHistory, setIsShowHistory] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const { isSearchPageWithDetailsInfo, isSearchPage, isSearchPageWithDetailsSeries } = useDetectLocation();
  const searchParam = useSearchParam('name') || '';
  const location = useLocation();
  const state = location.state as StateProps;

  const handleClickOutsideEmptySearch = useHandleClickOutsideEmptySearch({
    isSearchPage,
    setIsShowHistory,
    setShowSearchInput,
  });
  const handleRef = useHandleRef();

  useOpenSearchInputAndHistory({
    setShowSearchInput,
    setIsShowHistory,
    isSearchPage,
    isSearchPageWithDetailsInfo,
    isSearchPageWithDetailsSeries,
    searchParam,
    state,
  });

  useCloseSearchHistory({ isSearchPage, isShowHistory, setIsShowHistory });

  useFocusOnSearchInput({ isShowSearchInput, searchRef });

  // to close searchWidget when user clicked by another root page
  useCloseSearchWidget({ isSearchPage, searchParam, location, setIsShowHistory, setShowSearchInput });

  useOnClickOutside(searchRef, handleClickOutsideEmptySearch);

  return { searchRef, searchParam, isShowSearchInput, isShowHistory, handleRef, setShowSearchInput, setIsShowHistory };
};
