import { useEffect } from 'react';

import { useParentalPinStore } from 'hooks';

export const useParentalControlSetAgeThreshold = (setAgeThreshold: (value: React.SetStateAction<string>) => void) => {
  const { ageThreshold } = useParentalPinStore();

  useEffect(() => {
    if (ageThreshold) {
      setAgeThreshold(String(ageThreshold));
    }
  }, [ageThreshold]);
};
