import React from 'react';

import { useSearchWidgetCombiner } from './hooks/useSearchWidgetCombiner/useSearchWidgetCombiner';
import { SearchButton, SearchHistory, SearchInput } from './index';

import styles from './SeacrhWidget.module.scss';

function SearchWidget() {
  const {
    handleRef,
    setShowSearchInput,
    setIsShowHistory,
    searchRef,
    searchParam,
    isShowSearchInput,
    isShowHistory,
  } = useSearchWidgetCombiner();

  return (
    <div ref={handleRef}>
      <div ref={searchRef} className={styles.searchWidget}>
        <SearchButton
          setShowSearchInput={setShowSearchInput}
          isShowSearchInput={isShowSearchInput}
          setIsShowHistory={setIsShowHistory}
        />
        {isShowSearchInput && (
          <SearchInput searchParam={searchParam} setIsShowHistory={setIsShowHistory} isShowHistory={isShowHistory} />
        )}
        {isShowHistory && <SearchHistory />}
      </div>
    </div>
  );
}

export default SearchWidget;
