import { appStore, PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';

import { contextStorage, playerStorage, startOverInfoStorage } from 'components/widgets/Player/storages';
import { getKS } from 'components/widgets/Player/utils/getKS/getKS';
import { isLive } from 'components/widgets/Player/utils/isLive/isLive';
import { playoutStoreDataResolver } from 'utils';

export async function resetError() {
  const { player } = playerStorage.getPlayer();
  const { type } = playoutStoreDataResolver();
  const isLiveType = type === PlayoutStoreV2Type.LIVE;
  const { isStartOver } = startOverInfoStorage.getStartOver();
  const savedCurrentTime = isLiveType && !isStartOver ? -1 : playerStorage.getSavedCurrentTime();

  if (!player) {
    return;
  }

  const {
    errorContext: { clearError },
    performanceContext,
  } = contextStorage.getContext();

  const config = {
    ui: { disable: false },
    provider: { ks: getKS() },
  };

  if (isLive()) {
    // @ts-ignore
    config.sources = { dvr: true };
  }

  player.configure(config);
  performanceContext.resetEventsStartTime();
  const mediaInfo = player.getMediaInfo();

  // we are forcing ks to loadMedia method because mediaInfo will be updated only after triggering player events
  await player.loadMedia({ ...mediaInfo, ks: getKS() }, { startTime: savedCurrentTime });

  await new Promise((resolve) => {
    player.addEventListener(
      player.Event.CAN_PLAY,
      async () => {
        clearError();

        if (!playerStorage.isPlayBackPaused) {
          player.play();
        }

        resolve(true);
      },
      { once: true },
    );
  });

  // request pin code again after connection restored
  appStore.restoreAfterConnectionError();
}
