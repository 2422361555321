import React from 'react';
import { useIntl } from 'react-intl';

import { appStore } from '@kaltura-ott/tvpil-shared';

import { SvgView } from 'components/presenters/icons/SvgView/SvgView';
import { COMPONENT_NAME } from 'components/widgets/VideoPlayout/constans';
import { MD_ICON } from 'consts';
import { getMDIconPath, getSeasonEpisodeTitle } from 'utils';

import { useVideoPlayoutContext } from '../../../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from '../../Description.module.scss';

const DescriptionViewTitle = () => {
  const intl = useIntl();
  const { program, isContentBlockedAsAdult, programTitle, isMosaicProgram } = useVideoPlayoutContext();

  return (
    <>
      <h5 className={styles.title}>
        <span className={styles.titleText}>
          {!isContentBlockedAsAdult
            ? programTitle
            : intl.formatMessage({
                defaultMessage: 'Restricted content',
                id: `${COMPONENT_NAME}.RestrictedContent`,
              })}
        </span>
        {isMosaicProgram && (
          <span className={styles.mosaicIcon}>
            <SvgView url={`${appStore?.staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />
          </span>
        )}
      </h5>
      {!isContentBlockedAsAdult && (
        <p className={styles.episode}>
          {isMosaicProgram
            ? program.data.title
            : getSeasonEpisodeTitle(intl, program.data?.metas, ':', true, program.data?.episodeName)}
        </p>
      )}
    </>
  );
};

export default React.memo(DescriptionViewTitle);
