import { millisecondsInMinute, minutesInHour } from 'date-fns';
import curry from 'lodash/curry';

import { ProgramStateV2 } from '@kaltura-ott/tvpil-shared';

import { paramImageResolveParamForLandscapeByHeigh } from 'utils';

import { LOGO_HEIGHT, SYNOPSIS_LENGTH, SYNOPSIS_VISIBLE_LENGTH } from '../constants';

export const wasInPast = (state: ProgramStateV2) => state === ProgramStateV2.PAST;
export const willInFuture = (state: ProgramStateV2) => state === ProgramStateV2.FUTURE;
export const imageUrlParam = paramImageResolveParamForLandscapeByHeigh(LOGO_HEIGHT);
export const getSynopsis = (desc: string = '') => () =>
  desc.length >= SYNOPSIS_LENGTH ? `${desc.substring(0, SYNOPSIS_VISIBLE_LENGTH)}...` : desc;
export const isFullSynopsis = (description: string = '') => () => description.length <= SYNOPSIS_LENGTH;
export const getPartialItems = curry((count: number, items: Array<any>): Array<any> => items.slice(0, count));

export const resolveMillisecondsToMinutes = (duration: number) => `${Math.round(duration / millisecondsInMinute)}m`;

export const resolveMillisecondsToHoursAndMinutes = (duration: number) => {
  const durationInMinutes = Math.round(duration / millisecondsInMinute);

  return {
    hours: Math.trunc(durationInMinutes / minutesInHour),
    minutes: durationInMinutes % minutesInHour,
  };
};

export const getAssetExpiration = (expirationTime: number | undefined) =>
  !!expirationTime && expirationTime < Date.now();
