import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { PurchaseStates, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { Subscription } from '@kaltura-ott/tvpil-shared';

import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { useModalContext } from 'context/modal/modal';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useParentalPinStore, usePurchase } from 'hooks';
import { PerformanceEvents } from 'interface';
import { priceResolve } from 'utils';

import PinCodeDisclaimer from '../components/PinCodeDisclaimer/PinCodeDisclaimer';
import PinCodeInner from '../components/PinCodeInner/PinCodeInner';
import UnsubscribeModal from '../UnsubscribeModal/UnsubscribeModal';

interface Props {
  setLoading(value: boolean): void;
  setPurchaseState(value: string): void;
  setPurchaseAction(value: string): void;
  setError(value: any): void;
  details: Subscription | undefined;
}

export function useHandlePurchase({ setLoading, setPurchaseState, setPurchaseAction, setError, details }: Props) {
  const modalContext = useModalContext();
  const performanceEventsContext = usePerformanceEventsContext();
  const { state } = useLocation();
  const redirectLink = state?.redirectLink;
  const goBackUrl = state?.goBackUrl;
  const navigate = useNavigate();
  const intl = useIntl();
  const parentalPinStore = useParentalPinStore();
  const { price } = details! || {};
  const { title = '', disclaimer, priceDescription = '' } = details?.packageData! || {};
  const priceResolved = priceResolve(price, priceDescription);

  function onCancelSubscription() {
    return modalContext.openModal(
      <UnsubscribeModal
        callback={(success: boolean, error?: string) => {
          if (!success && error) {
            setPurchaseState(PurchaseStates.error);
          } else {
            setPurchaseState(PurchaseStates.ok);
            setPurchaseAction('cancelSubscription');
          }

          modalContext.closeModal();
        }}
        store={details!}
        title={title}
      />,
    );
  }

  function onError(error: { message: string; code: string }) {
    setPurchaseState(PurchaseStates.error);
    setError({
      errorMessage: error.message,
      errorCode: error.code,
    });
  }

  function onPending() {
    setPurchaseState(PurchaseStates.pending);
  }

  function onSuccess() {
    setPurchaseState(PurchaseStates.ok);

    if (redirectLink) {
      navigate(redirectLink, {
        state: {
          goBackUrl,
        },
      });
    } else {
      // To clear startTime for performance event purchase if the transition to the player is not expected
      performanceEventsContext.clearEvent(PerformanceEvents.purchase);
    }
  }

  const { onPurchase: onPurchaseAfterPin } = usePurchase({
    callbackError: onError,
    callbackPending: onPending,
    callbackSuccess: onSuccess,
    errorContext: UIDAPresentationTypeEnum.transaction_history,
    purchase: details?.buyAfterPinEntered.bind(details),
    setIsLoading: setLoading,
  });

  function onEnterPin() {
    modalContext.openModal(
      <PinCodeModal
        asset={details!}
        afterPinCodeContent={<PinCodeDisclaimer disclaimer={disclaimer} />}
        beforePinCodeContent={<PinCodeInner title={title} priceResolved={priceResolved} />}
        callback={async (success: boolean) => {
          if (!success) {
            return;
          }
          setPurchaseState('');
          onPurchaseAfterPin();
        }}
        type={PinCodeModalTypes.Purchase}
        validatePin={parentalPinStore.validatePurchasePin}
        context={UIDAPresentationTypeEnum.transaction_history}
        title={intl.formatMessage({ id: 'Purchase.PinCodeModal.title', defaultMessage: 'Enter PIN Code' })}
      />,
    );
  }

  const { onPurchase } = usePurchase({
    callbackError: onError,
    callbackPending: onPending,
    callbackPin: onEnterPin,
    callbackSuccess: onSuccess,
    errorContext: UIDAPresentationTypeEnum.transaction_history,
    purchase: details?.buy.bind(details),
    setIsLoading: setLoading,
  });

  return { onPurchaseSubscription: onPurchase, onCancelSubscription };
}
