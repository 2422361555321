import { action, observable } from 'mobx';

const DEFAULT_VIDEO_TRACK_INFO_VALUE = {
  videoTracks: [],
  minBitrateFromVideoTracks: 0,
};

class PlayerStorage {
  @observable savedCurrent: number = 0;

  @observable assetId: number = 0;

  @observable isPlayBackPaused = false;

  @observable liveServerDelay: number = 0;

  @observable isMdUiDisable = false;

  @observable storage: any = {};

  @observable player: any;

  @observable hideUI: boolean = false;

  @observable muted: boolean = false;

  @observable videoTracks: any = [];

  @observable videoTrackInfo = DEFAULT_VIDEO_TRACK_INFO_VALUE;

  @observable minBitrateFromVideoTracks: number = 0;

  @observable isRestricted = false;

  @observable isMouseOverPlayerBottomBar = false;

  @action
  setIsMouseOverPlayerBottomBar(value: boolean) {
    this.isMouseOverPlayerBottomBar = value;
  }

  @action
  getIsMouseOverPlayerBottomBar() {
    return this.isMouseOverPlayerBottomBar;
  }

  @action
  setIsMdUiDisable(value = false) {
    this.isMdUiDisable = value;
  }

  @action
  getIsMdUiDisable() {
    return this.isMdUiDisable;
  }

  @action
  setPlayer(player: any, hideUI: boolean, muted: boolean, assetId: number) {
    this.player = player;
    this.hideUI = hideUI;
    this.muted = muted;
    this.assetId = assetId;
  }

  @action
  getPlayer() {
    const { player, hideUI, muted, assetId } = this;

    return { player, hideUI, muted, assetId };
  }

  @action
  clearPlayer() {
    this.player = undefined;
    this.hideUI = false;
    this.muted = false;
    this.isMdUiDisable = false;
    this.assetId = 0;
  }

  @action
  updateMuted(value: boolean) {
    this.muted = value;
  }

  @action
  setVideoTrackInfo(videoTracks: [], minBitrateFromVideoTracks: number) {
    this.videoTrackInfo = {
      videoTracks,
      minBitrateFromVideoTracks,
    };
  }

  @action
  getVideoTrackInfo() {
    return this.videoTrackInfo;
  }

  @action
  clearVideoTrackInfo() {
    this.videoTrackInfo = DEFAULT_VIDEO_TRACK_INFO_VALUE;
  }

  @action
  setSavedCurrentTime(currentTime: number) {
    this.savedCurrent = currentTime;
  }

  @action
  getSavedCurrentTime() {
    return this.savedCurrent;
  }

  @action
  setIsPlayBackPaused(isPlayBackPaused: boolean) {
    this.isPlayBackPaused = isPlayBackPaused;
  }

  @action
  getIsPlayBackPaused() {
    return this.isPlayBackPaused;
  }

  @action
  clearIsPlayBackPausedAndSavedCurrentTime() {
    this.isPlayBackPaused = false;
    this.savedCurrent = 0;
  }

  @action
  getCurrentAsset() {
    return this.storage.asset;
  }

  @action
  setCurrentAsset(asset: any) {
    this.storage.asset = {
      ...this.storage.asset,
      ...asset,
    };
  }

  @action
  getLiveServerDelay() {
    return this.liveServerDelay;
  }

  @action
  setLiveServerDelay(value: number) {
    this.liveServerDelay = value;
  }

  @action
  removeCurrentAsset() {
    delete this.storage.asset;
  }

  @action
  clear() {
    this.clearIsPlayBackPausedAndSavedCurrentTime();
    this.removeCurrentAsset();
    this.clearPlayer();
    this.clearVideoTrackInfo();
  }
}

const playerStorage = new PlayerStorage();

export { playerStorage };
