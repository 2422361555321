import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { SubscriptionType } from '@kaltura-ott/tvpil-shared/lib/stores/subscriptionsRepository';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { PurchaseIcon } from 'components/presenters/icons';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useRootStore, useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { onMoveToPurchase, openLink } from 'utils';

export interface BuyPackageButtonProps {
  asset: ViewableEntitiesType;
  className: string;
  programNotAvailable: boolean;
}

export function BuyPackageButton({ asset, className, programNotAvailable }: BuyPackageButtonProps) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const {
    detailsStore: { externalPortalUrl },
  } = useRootStore();

  const { settingsStore } = useSettingsStore();

  const {
    id,
    isPurchasePending,
    linear,
    mediaTypeParam,
    isPast,
    isVodSingle,
    selectedSubscription,
  } = detailsStoreDataResolver(asset);
  const { amount, packageId, type } = selectedSubscription!;
  const channelId = linear?.id || 0;
  const isPurchaseButtonDisabled = isPurchasePending || programNotAvailable;
  const isPackageAvailable = type === SubscriptionType.addonForPurchasedBase;
  const defaultMessage = isPackageAvailable ? 'Buy package' : 'Change tariff';
  const translationId = isPackageAvailable ? 'purchaseButton' : 'changeTariffButton';

  const handleMoveToPurchase = async () => {
    if (!isPackageAvailable) {
      if (externalPortalUrl) {
        openLink(externalPortalUrl);
      }
      return;
    }

    let redirectLink;

    if (isVodSingle) {
      redirectLink = routes.vod.link(id, mediaTypeParam);
    } else {
      redirectLink = isPast ? routes.catchUp.link(id, mediaTypeParam) : routes.live.link(channelId, mediaTypeParam);
    }

    await onMoveToPurchase({
      settingsStore,
      navigate,
      redirectLink,
      goBackUrl: `${pathname}${search}`,
      isSingleSubscriptionAvailable: amount === 1,
      singleSubscriptionId: packageId,
    });
  };

  return (
    <ActionButton
      className={className}
      data-disabled={isPurchaseButtonDisabled ? 'disabled' : null}
      disabled={isPurchaseButtonDisabled}
      icon={<PurchaseIcon />}
      isLarge
      onClick={handleMoveToPurchase}
    >
      <FormattedMessage defaultMessage={defaultMessage} id={`${COMPONENT_NAME}.VideoContent.${translationId}`} />
    </ActionButton>
  );
}
