import { useCallback } from 'react';

import { useEpgContext } from 'context/epgContext/epgContext';

import { epgLocalDrugStorage } from '../../../../storages';

export const useOnHandleDragEnd = () => {
  const { forwardGridMovement } = useEpgContext();
  return useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (epgLocalDrugStorage.dragStatus === 'dragging') {
      e.stopPropagation();
    }

    epgLocalDrugStorage.setDragStatus('end');

    if (forwardGridMovement.current) {
      forwardGridMovement.current.style.zIndex = '0';
    }
  }, []);
};
