import { matchRoutes, To } from 'react-router-dom';

import routes from 'routesPaths';

export function useIsUrlToFullPlayer(link: string | To) {
  if (!link) {
    return false;
  }

  const url = typeof link === 'string' ? link : link?.pathname;

  return !!matchRoutes([routes.live, routes.catchUp, routes.startOver, routes.program, routes.vod], url!);
}
