export function animate(
  setIsAnimation: (val: boolean) => void,
  railCardsListRef: React.RefObject<any>,
  delta: number,
  time: number,
  callback: () => void,
) {
  setIsAnimation(true);

  if (railCardsListRef.current) {
    const outerRef = railCardsListRef.current.props.outerRef as React.RefObject<any>;
    const ul = outerRef!.current.children[0] as HTMLElement;

    ul.style.transition = `all ${time}ms`;
    ul.style.transform = `translateX(${-delta}px)`;
    ul.style.pointerEvents = 'none';

    const timeout = setTimeout(() => {
      callback();

      ul.style.transition = 'unset';
      ul.style.transform = '';
      ul.style.pointerEvents = '';

      setIsAnimation(false);
      clearTimeout(timeout);
    }, time);
  }
}
