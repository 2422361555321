import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PurchaseStates } from '@kaltura-ott/tvpil';

import { COMPONENT_NAME } from '../../constants';
import { usePackageDetailsContent } from '../PackageDetailsProvider/PackageDetailsProvider';

import styles from '../../PackageDetails.module.scss';

function PackageDetailsStateStatusView() {
  const {
    purchaseState,
    priceResolved,
    error: { errorMessage, errorCode },
    purchaseAction: type,
    isPurchasePending,
  } = usePackageDetailsContent();
  const isError = purchaseState === PurchaseStates.error;

  const renderPurchaseContent = () => {
    if (isError) {
      return (
        <>
          <div className={styles.statusPurchasedError}>
            <FormattedMessage
              defaultMessage={errorMessage}
              id={`${COMPONENT_NAME}.tvpilSubscriptionError.${errorCode}`}
              tagName='p'
            />
          </div>
          {errorCode && (
            <div className={styles.statusPurchasedErrorCode}>
              <FormattedMessage
                defaultMessage={`Error Code: ${errorCode}`}
                id={`${COMPONENT_NAME}.errorCode`}
                tagName='p'
                values={{ errorCode }}
              />
            </div>
          )}
        </>
      );
    }

    if (isPurchasePending) {
      return (
        <div className={styles.statusPurchasedPending}>
          <FormattedMessage
            defaultMessage='Purchase is pending confirmation'
            id={`${COMPONENT_NAME}.pendingPurchase`}
            tagName='p'
          />
        </div>
      );
    }

    return (
      <div className={styles.statusPurchasedSuccess}>
        {type === 'cancelSubscription' ? (
          <FormattedMessage
            defaultMessage='You’ve successfully unsubscribed from package'
            id={`${COMPONENT_NAME}.purchaseStatusUnsubscribed`}
            tagName='p'
          />
        ) : (
          <FormattedMessage
            defaultMessage='Purchase was completed successfully'
            id={`${COMPONENT_NAME}.purchaseStatusSuccess`}
            tagName='p'
          />
        )}
      </div>
    );
  };

  return (
    <>
      {!isError && <span className={styles.priceMiddle}>{priceResolved}</span>}
      {renderPurchaseContent()}
    </>
  );
}

export default PackageDetailsStateStatusView;
