import { useRailContext } from '../../../RailProvider/RailProvider';
import { useApplyStyles } from '../useApplyStyles/useApplyStyles';

function useRailViewCombiner() {
  const {
    railData: { totalCount, items, hasNextPage },
    wrapperRef,
    refElementInner,
    headerRef,
    isLogoRail,
    componentHeight,
    railSettings,
    railType,
    checkResize,
  } = useRailContext();

  const handleRef = useApplyStyles(railSettings);

  return {
    handleRef,
    wrapperRef,
    refElementInner,
    isLogoRail,
    checkResize,
    componentHeight,
    headerRef,
    railSettings,
    railType,
    totalCount,
    itemsLength: items.length,
    items,
    hasNextPage,
  };
}

export { useRailViewCombiner };
