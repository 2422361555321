import { appStore } from '@kaltura-ott/tvpil-shared';

import { ERROR_CODES } from 'consts';
import { globalStorage } from 'services/globalStorage';

import { resetError } from '../resetError';

/**
 * Handles the 'KS Expired' error by refreshing the Kaltura Session token.
 * It checks the passed payload for a specific error code and, if matched,
 * performs necessary actions to get a new KS token and refresh media.
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the
 * KS expired error was handled, otherwise `false`.
 */
export async function handleKsExpiredError(payload: any) {
  // Retrieve the error code from the payload, if available. (Ks expired error payload has another object structure than other errors)
  const errorCode = payload?.data?.data?.results ? payload?.data?.data?.results[0]?.error?.code : null;

  if (errorCode !== ERROR_CODES.KS_EXPIRED) {
    return false;
  }

  await appStore.loginStore.originalStore.getNewKs();

  globalStorage.setIsKsErrorHandled(true);

  await resetError();

  return true;
}
