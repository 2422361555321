import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './RentedBadge.module.scss';

export function RentedBadge({ className, translationId }: { className?: string; translationId: string }) {
  return (
    <span className={cn(styles.rentedBadge, className)}>
      <FormattedMessage defaultMessage='Rented' id={`${translationId}.rentedBadge`} />
    </span>
  );
}
