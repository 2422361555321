import { globalStorage } from 'services/globalStorage';

import { PLAYER_SESSION_STORAGE_AUDIO_KEY, PLAYER_SESSION_STORAGE_SUBTITLE_KEY } from '../../constants';
import { PLAYER_STORAGE_SUBTITLE_KEY, SUBTITLE_OFF } from '../../services/consts';
import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import {
  bingeWatchingStorage,
  contextStorage,
  intlStorage,
  overlayStorage,
  playerEventsStorage,
  playerStorage,
  startOverInfoStorage,
} from '../../storages';
import { clearHoverTimeout } from '../osdHelpers/helpers';

export function unmountActions() {
  clearHoverTimeout();

  const playerService = playerServiceManagement.getPlayerService();
  const { player, hideUI } = playerStorage.getPlayer();

  playerStorage.setIsPlayBackPaused(false);
  playerStorage.setCurrentAsset({ error: null });
  playerService.unmountPlayer();
  playerStorage.clear();
  contextStorage.resetContext();
  intlStorage.resetIntl();
  playerEventsStorage.clearPlayerEventsStorage();
  // For disable full screen mode
  if (player?.isFullscreen()) {
    player.exitFullscreen(); // should be moved to playerStorage.clearPlayer afetr refactoring
  }
  // For reset startOver info not only maxiPlayer
  if (!hideUI) {
    startOverInfoStorage.resetStartOverInfo();
  }
  bingeWatchingStorage.resetSettings();
  overlayStorage.setShowOverlay(false);
  globalStorage.clearIsFullPlayer();
  globalStorage.clearIsCanPlayFired();
  window.sessionStorage.removeItem(PLAYER_SESSION_STORAGE_SUBTITLE_KEY);
  window.sessionStorage.removeItem(PLAYER_SESSION_STORAGE_AUDIO_KEY);
  localStorage.setItem(PLAYER_STORAGE_SUBTITLE_KEY, SUBTITLE_OFF);
}
