import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import startOverIcon from 'assets/images/player/start-over.svg';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { startOverInfoStorage } from 'components/widgets/Player/storages';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useDetectLocation, useIsNpvr } from 'hooks';
import { PerformanceEvents } from 'interface';
import routes from 'routesPaths';
import { resolveLinkForMd } from 'utils';

import { handleClickDetailsButton } from '../../handlers';

export interface StartOverButtonProps {
  asset: ViewableEntitiesType;
  className: string;
  isLive: boolean;
  programNotAvailable: boolean;
}

export function StartOverButton({ asset, className, isLive, programNotAvailable }: StartOverButtonProps) {
  const isNpvr = useIsNpvr();
  const { isMdUiDisable } = useDetectLocation();
  const { pathname, search } = useLocation();
  const performanceContext = usePerformanceEventsContext();
  const { adult, enableStartOver, id, mediaTypeParam } = detailsStoreDataResolver(asset);
  const { navigate, modalContext, parentalPinStore, isOverlay, closeOverlay } = useVideoContext();
  const isStartOverBtnDisabled = !enableStartOver || (adult && isLive) || (!isNpvr && programNotAvailable);

  function setStartOver() {
    startOverInfoStorage.setStartOver(true, true, 0);
  }

  const handleStartOver = useCallback((e: React.MouseEvent<typeof Link>) => {
    performanceContext.setStartTime(PerformanceEvents.playbackStart);

    handleClickDetailsButton({
      e,
      asset,
      callback: setStartOver,
      closeOverlay,
      navigate,
      modalContext,
      parentalPinStore,
    });
  }, []);

  function resolveStartOverPathProps() {
    if (isOverlay) {
      return null;
    }

    let link = routes.catchUp.link(id, mediaTypeParam);
    const goBackUrl = `${pathname}${search}`;

    if (isNpvr) {
      link = routes.program.link(id, mediaTypeParam);
    }

    if (isLive) {
      link = routes.startOver.link(id, mediaTypeParam);
    }

    link += resolveLinkForMd(isMdUiDisable, link); // to avoid displaying MD components in the player

    // no need VOD check due to startOver button ONLY for Program
    return { to: link, state: { goBackUrl } };
  }

  return (
    <ActionButton
      data-disabled={isStartOverBtnDisabled ? 'disabled' : null}
      className={className}
      disabled={isStartOverBtnDisabled}
      icon={<SVG data-target='startOverIcon' src={startOverIcon} />}
      isLarge
      onClick={handleStartOver}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...resolveStartOverPathProps()}
    >
      <FormattedMessage defaultMessage='StartOver' id={`${COMPONENT_NAME}.VideoContent.buttonStartOver`} />
    </ActionButton>
  );
}
