import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { UIDAPresentationTypeEnum, UIDAStructureTypeEnum } from '@kaltura-ott/tvpil';
import { AppStore, PageStore } from '@kaltura-ott/tvpil-shared';

function useSettingsStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  const { rootStore } = appStore;
  const settingsStore = rootStore?.children.find(
    ({ type, subtype }) => type === UIDAStructureTypeEnum.page && subtype === UIDAPresentationTypeEnum.settings,
  ) as PageStore;

  return { settingsStore };
}

export { useSettingsStore };
