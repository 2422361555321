import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import RailCardsList from 'components/widgets/Rail/components/RailCardsList/RailCardsList';

interface Props {
  checkResize: () => void;
}

const RailCardsWrapper = ({ checkResize }: Props) => {
  return (
    <AutoSizer onResize={checkResize}>
      {({ height = 0, width = 0 }) => <RailCardsList height={height} width={width} />}
    </AutoSizer>
  );
};

export default React.memo(RailCardsWrapper);
