import { useMemo } from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/collection/types';

interface Props {
  items: CollectionAsset[];
  columnCount: number;
  isFullLoaded: boolean;
}

export const useGridCardsViewItems = ({ items, columnCount, isFullLoaded }: Props) => {
  const skeletonItemsCount = useMemo(() => {
    let result = columnCount * 2;

    if (items.length) {
      result -= items.length % columnCount;
    }
    return result;
  }, [items.length]);
  const fakeItems = columnCount && skeletonItemsCount > 0 ? new Array(skeletonItemsCount).fill({}) : [];
  const gridItems = items.concat(fakeItems);

  return { gridItems: isFullLoaded ? items : gridItems };
};
