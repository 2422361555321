import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useDetailsNavigationLogic } from 'components/pages/VideoDetails/hooks/useDetailsNavigationLogic';
import { useErrorScreenContext } from 'context/error/error';
import { useAppStore, useDetectLocation } from 'hooks';
import { useRootStore } from 'hooks/common/useRootStore';
import { globalStorage } from 'services/globalStorage';
import { getDefaultImagePath } from 'utils';

import { useAddidionalSeriesRailSetup } from '../useAddidionalSeriesRailSetup/useAddidionalSeriesRailSetup';
import { useCloseHandler } from '../useCloseHandler/useCloseHandler';
import { useVideoDetailsImage } from '../useDetailsImage/useVideoDetailsImage';
import { useHandleClick } from '../useHandleClick/useHandleClick';
import { useHandleRef } from '../useHandleRef/useHandleRef';
import { useVideoDetailsInit } from '../useVideoDetailsInit/useVideoDetailsInit';

export const useVideoDetailsCombiner = () => {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const rootStore = useRootStore();
  const { detailsStore } = rootStore!;
  const errorScreenContext = useErrorScreenContext();
  const { isDetailsSeries } = useDetectLocation();
  const { asset: originalAsset, seriesAdditionalRail, scheduledRecordingSeriesRail } = detailsStore;
  const isEpisodes = isDetailsSeries && !!seriesAdditionalRail?.isReady && seriesAdditionalRail.items.length > 0;
  const asset: any = globalStorage.detailsAsset ?? originalAsset; // TODO: the type any should be changed
  const closeHandler = useCloseHandler();
  const handleClick = useHandleClick(closeHandler);
  const handleRef = useHandleRef();

  const setError = () => {
    errorScreenContext.setError({
      error: {
        titleId: `${COMPONENT_NAME}.errorTitle`,
        titleDefault: 'Content Cannot Be Displayed',
        messageId: `${COMPONENT_NAME}.errorMessage`,
        messageDefault: 'The content is not available',
      },
      goBackButton: {
        translationId: 'GeoError.playerButtonText',
        defaultMessage: 'Go Back',
      },
    });
  };

  const { posterUrl, isLandScapeView } = useVideoDetailsImage();

  const landScapeViewDefined = isLandScapeView !== undefined;
  /**
   * hook with details page initialization logic
   */
  const detailsInfo = useVideoDetailsInit();

  const defaultImage = `${staticAssetsUrl}${getDefaultImagePath(isLandScapeView)}`;

  /**
   * hook with addidional series rail setup logic
   */
  useAddidionalSeriesRailSetup();
  /**
   * hook with episodes details page back navigation logic
   */
  useDetailsNavigationLogic(asset, isDetailsSeries);

  return {
    asset,
    posterUrl,
    isEpisodes,
    detailsInfo,
    defaultImage,
    isLandScapeView,
    landScapeViewDefined,
    seriesAdditionalRail,
    scheduledRecordingSeriesRail,
    setError,
    handleRef,
    handleClick,
    closeHandler,
  };
};
