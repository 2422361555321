import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore } from '@kaltura-ott/tvpil-shared';

function useConcurrencyStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  return appStore.concurrencyStore;
}

export { useConcurrencyStore };
