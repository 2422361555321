import React from 'react';
import cn from 'classnames';

import styles from './InfoButton.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export function InfoButton({ className, ...props }: ButtonProps) {
  return (
    <button className={cn(styles.infoBtn, className)} {...props} type='button'>
      i
    </button>
  );
}
