import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { CategoryStore, RhinoEPGProgram, SubpageStore, Subscription } from '@kaltura-ott/tvpil-shared';
import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';

import { defaultPropsError } from 'components/widgets/Modal/constants';
import { useParentalPinStore } from 'hooks/common/useParentalPinStore';

import { PinCodeModalTypes } from '../../PinCodeModalTypesEnum';
import { calculatePinLimitTime } from '../../utils/calculatePinLimitTime/calculatePinLimitTime';
import { getPinLimitError } from '../../utils/getPinLimitError/getPinLimitError';
import { usePinCodeModalSubmit } from '../usePinCodeModalSubmit/usePinCodeModalSubmit';

interface Props {
  type: PinCodeModalTypes;
  asset: MediaAsset | CategoryStore | SubpageStore | Subscription | RhinoEPGProgram;
  context?: UIDAPresentationTypeEnum;
  rhinoParentalRating?: number;
  validatePin?: (pin: string) => Promise<any> | Object | undefined;
  callback?: (success: boolean) => void;
}

export const usePinCodeModalCombiner = ({
  type,
  asset,
  context,
  rhinoParentalRating,
  validatePin,
  callback,
}: Props) => {
  const intl = useIntl();
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState(defaultPropsError);
  const [loading, setLoading] = useState(false);
  const inputWrapper = useRef<HTMLDivElement>(null);
  const parentalPinStore = useParentalPinStore();
  const { isParentalPinLocked, parentalPinTimeout, isPurchasePinLocked, purchasePinTimeout } = parentalPinStore;
  const isRestrictedLock =
    [
      PinCodeModalTypes.RestrictedContent,
      PinCodeModalTypes.RestrictedContentParental,
      PinCodeModalTypes.EnterParental,
      PinCodeModalTypes.ParentalRhino,
    ].includes(type) && isParentalPinLocked;
  const isPurchaseLock = PinCodeModalTypes.Purchase === type && isPurchasePinLocked;
  let timeoutValue = 0;

  if (isRestrictedLock) {
    timeoutValue = parentalPinTimeout;
  } else if (isPurchaseLock) {
    timeoutValue = purchasePinTimeout;
  }

  const pinTimeout = calculatePinLimitTime(timeoutValue);
  const isPinLocked = isRestrictedLock || isPurchaseLock;
  const isResetError = !isParentalPinLocked && !isPurchasePinLocked;
  const pinLimitError = isPinLocked ? getPinLimitError(pinTimeout) : undefined;
  const submit = usePinCodeModalSubmit({
    asset,
    type,
    context,
    pinCode,
    rhinoParentalRating,
    pinLimitError,
    setLoading,
    validatePin,
    callback,
    setPinCode,
    setError,
  });

  return {
    intl,
    error,
    pinCode,
    loading,
    pinTimeout,
    inputWrapper,
    isResetError,
    pinLimitError,
    isPurchasePinLocked,
    submit,
    setError,
    setPinCode,
  };
};
