import React from 'react';
import { observer } from 'mobx-react';

import { DayProps } from 'components/pages/EpgV2/interface';

import Day from './components/Day/Day';
import { useDaysHooksCombiner } from './hooks';

import styles from './Days.module.scss';

function Days() {
  const {
    days,
    blockRef,
    onMouseDownHandler,
    onMouseMoveHandler,
    onMouseUpHandler,
    primeTime,
  } = useDaysHooksCombiner();

  return (
    <div
      ref={blockRef}
      id='days'
      className={styles.wrapper}
      onMouseDown={onMouseDownHandler}
      onMouseLeave={onMouseUpHandler}
      onMouseMove={onMouseMoveHandler}
      onMouseUp={onMouseUpHandler}
      role='presentation'
    >
      {days.map((day: DayProps) => (
        <Day key={day.delta} day={day} primeTime={primeTime} />
      ))}
    </div>
  );
}

export default observer(Days);
