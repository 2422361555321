import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

interface Props {
  defaultMessage: string;
  translationId: string;
  onChange(checked: boolean): void;
  value?: boolean;
}

function Checkbox({ defaultMessage, translationId, onChange, value = false }: Props) {
  const [checked, setChecked] = useState(value);

  function handleChange() {
    onChange(!checked);
    setChecked(!checked);
  }

  return (
    <label htmlFor={translationId}>
      <input id={translationId} checked={checked} type='checkbox' onChange={handleChange} />
      <span className={cn(styles.checkbox, { [styles.active]: checked })} aria-hidden='true' />
      <FormattedMessage defaultMessage={defaultMessage} id={translationId} />
    </label>
  );
}

export default Checkbox;
