import { useEffect } from 'react';

import { useParentalPinStore } from 'hooks';

export const useParentalControlInit = () => {
  const parentalPinStore = useParentalPinStore();
  useEffect(() => {
    parentalPinStore.init();
  }, []);
};
