import { useEffect } from 'react';

import { ROOT } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';
import { applyStyle, applyStyleHexToHSL, applyStyles } from 'utils';
import { isYettelEnvGroup } from 'utils/checkEnvironmentGroup';

export function useAppBranding() {
  const {
    appStore: { params, rootStore },
  } = useAppStore();

  useEffect(() => {
    if (!rootStore) return;

    const isYettelEnv = isYettelEnvGroup(params?.dmsSetting?.partnerId!);
    const fontFamily = isYettelEnv ? 'Yettel' : 'Open Sans';
    const rootEl = document.getElementById(ROOT) as HTMLElement;

    applyStyle(document.body, '--font-family', fontFamily);
    applyStyles(rootEl, rootStore.uiDesignElements!, true);
    applyStyleHexToHSL(rootEl, rootStore.uiDesignElements?.backgroundColor!);
    applyStyleHexToHSL(rootEl, rootStore.uiDesignElements?.btnBackgroundColor!, true);

    if (isYettelEnv) {
      rootEl.setAttribute('style-mode', 'yettelEnvGroup');
    }
  }, [rootStore]);
}
