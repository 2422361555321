import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SelectedSubscription } from '@kaltura-ott/tvpil-shared/lib/stores/entities/entities.service';
import { SubscriptionType } from '@kaltura-ott/tvpil-shared/lib/stores/subscriptionsRepository';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';

export function getPackageDescription(subscription?: SelectedSubscription) {
  const componentName = `${COMPONENT_NAME}.VideoContent`;
  const { type, amount = 0, addonTitle, baseTitle } = subscription || {};
  let defaultMessage = '';
  let id = '';

  switch (true) {
    case type === SubscriptionType.addonForPurchasedBase && amount === 1:
      defaultMessage = `This content is a part of ${addonTitle}`;
      id = `${componentName}.purchasePackageDescription`;
      break;
    case type === SubscriptionType.addonForPurchasedBase && amount > 1:
      defaultMessage = 'This content is a part of Multiple Packages';
      id = `${componentName}.purchasePackageDescriptionMany`;
      break;
    case type === SubscriptionType.base && amount === 1:
      defaultMessage = `This content is a part of ${baseTitle} tariff.`;
      id = `${componentName}.changeTariffDescription`;
      break;
    case type === SubscriptionType.base && amount > 1:
      defaultMessage = `This content is a part of ${baseTitle} tariff or higher.`;
      id = `${componentName}.changeTariffDescriptionFromMany`;
      break;
    case type === SubscriptionType.addonForOtherBase && amount === 1:
      defaultMessage = `This content is a part of ${addonTitle} package of ${baseTitle} tariff.`;
      id = `${componentName}.changeTariffAndPackageDescription`;
      break;
    case type === SubscriptionType.addonForOtherBase && amount > 1:
      defaultMessage = `This content is a part of ${addonTitle} package of ${baseTitle} tariff or higher.`;
      id = `${componentName}.changeTariffAndPackageDescriptionFromMany`;
      break;
    default:
      break;
  }

  return id ? (
    <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ packageName: addonTitle, tariff: baseTitle }} />
  ) : null;
}
