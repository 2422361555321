import { useNavigate } from 'react-router-dom';

import { useErrorScreenContext } from 'context/error/error';
import { playoutStoreDataResolver } from 'utils';

export const useSetGeoError = () => {
  const navigate = useNavigate();
  const { setError } = useErrorScreenContext();
  const { geoBlockingProperties } = playoutStoreDataResolver();

  return () => {
    setError({
      error: {
        titleDefault: geoBlockingProperties?.name || 'Content Cannot Be Played',
        messageDefault:
          geoBlockingProperties?.description || 'The content  you  requested is not available in you country',
        code: 554,
      },
      goBackButton: {
        goBackLink: () => {
          navigate(-1);
        },
        translationId: 'GeoError.playerButtonText',
        defaultMessage: 'Go Back',
      },
    });
  };
};
