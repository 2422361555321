import React from 'react';
import cn from 'classnames';

import { RecordIcon, SvgView } from 'components/presenters/icons';
import { MD_ICON } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';
import { getMDIconPath } from 'utils';

import { Props } from '../../types';

import styles from './CardTitleBubbleView.module.scss';

function CardTitleBubbleView({ title, isRecord, isMosaicProgram, isWithSeriesRecord, isSeriesType }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const presentRecordIcon = isRecord && !isMosaicProgram;

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.seriesRecord]: isWithSeriesRecord,
        [styles.singleRecord]: isRecord,
        [styles.mosaicTitle]: isMosaicProgram,
      })}
    >
      <h6>
        {title}
        {presentRecordIcon && (
          <span className={styles.recordIcon}>
            <RecordIcon active multiple={isSeriesType} />
          </span>
        )}
        {isMosaicProgram && (
          <span className={styles.mosaicIcon}>
            <SvgView url={`${staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />
          </span>
        )}
      </h6>
    </div>
  );
}

export default CardTitleBubbleView;
