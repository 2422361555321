import { playerStorage } from '../../storages';

export function focusOnPlayerNode() {
  const { player, hideUI } = playerStorage.getPlayer();
  const playerContainer = document.getElementById(player?.config?.ui?.targetId);

  if (playerContainer && !hideUI) {
    playerContainer.focus();
  }
}
