import React from 'react';

import AssetCardBody from './AssetCardBody/AssetCardBody';
import AssetCardContent from './AssetCardContent/AssetCardContent';

function AssetCard() {
  return (
    <AssetCardBody>
      <AssetCardContent />
    </AssetCardBody>
  );
}

export default AssetCard;
