import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { setCookie } from '@kaltura-ott/tvpil-shared';

import { ONE_YEAR, WARNING_ICON } from 'consts';
import { useErrorScreenContext } from 'context/error/error';
import { useToastMessageContext } from 'context/toast/toast';
import { ApplicationError } from 'interface';
import routes from 'routesPaths';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';
import { getErrorParams, getLegacyAppPath, getWarningIcon } from 'utils';

import { useAppStore } from '../../common/useAppStore';
import { useLoginStore } from '../../common/useLoginStore';

import { resolveError } from './utils/resolveError';

const COMPONENT_NAME = 'AppErrorReaction';

export function useAppErrorReaction() {
  const { appStore } = useAppStore();
  const intl = useIntl();
  const errorScreenContext = useErrorScreenContext();
  const toastMessageContext = useToastMessageContext();
  const loginStore = useLoginStore();
  const legacyAppPath = getLegacyAppPath(appStore?.params?.dmsSetting?.endpoint);
  const warningIcon = `${appStore.staticAssetsUrl}${getWarningIcon(WARNING_ICON)}`;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!appStore.apiError) {
      errorScreenContext.clearError();
      toastMessageContext.clearToast();
      return;
    }

    const {
      code,
      title,
      message,
      actionName,
      onRetry,
      onCancel,
      isToastError,
      isMigrationError,
      isFullScreenError,
    } = resolveError(location);

    if (isMigrationError) {
      const redirectUrl = loginStore.userMigrationUri;

      setCookie('migo2tv', 0, {
        domain: legacyAppPath,
        'max-age': ONE_YEAR,
      });

      sendApplicationErrorEvent({
        context: UIDAPresentationTypeEnum.login,
        message,
        additionalInfo: ApplicationError.SelectServices,
      });

      errorScreenContext.setError({
        error: {
          icon: 'banContent',
          titleId: `${COMPONENT_NAME}.errorHouseholdMigration.title`,
          titleDefault: 'You Can’t Use this Version Yet',
          messageId: `${COMPONENT_NAME}.errorHouseholdMigration.message`,
          messageDefault: 'We hope to let you in very soon',
        },
        goBackButton: {
          goBackLink: async () => {
            await loginStore.logoutUser();
            navigate(routes.home.path);
          },
          translationId: `${COMPONENT_NAME}.errorHouseholdMigration.logOut`,
          defaultMessage: 'Log Out',
        },
        onClear: onCancel,
        retryButton: {
          onRetry: async () => {
            if (redirectUrl) {
              await loginStore.logoutUser();
              if (window.location.origin === redirectUrl) {
                window.location.reload();
              } else {
                window.location.href = redirectUrl;
              }
              errorScreenContext.stopLoadingError();
            }
          },
          translationId: `${COMPONENT_NAME}.errorHouseholdMigration.goToO2`,
          defaultMessage: 'Go to O2TV App',
        },
        isErrorFromTvpil: true,
      });
    } else if (isFullScreenError) {
      const errorParams = getErrorParams({
        actionName,
        keyForTranslation: COMPONENT_NAME,
        onRetry,
        code,
        title,
        message,
        onClear: onCancel,
        stopLoader: errorScreenContext.stopLoadingError,
        isErrorFromTvpil: true,
      });
      errorScreenContext.setError({ ...errorParams });
    } else if (isToastError) {
      toastMessageContext.showToast({
        title: intl.formatMessage({
          id: `AppErrorReaction.mediumError`,
          defaultMessage: 'Sorry, this action cannot be completed.',
        }),
        icon: warningIcon,
        onClear: () => appStore.clearAllApiError(),
      });
    } else {
      console.warn(`ApiError: ${code} - ${title} (${message})`);
    }
  }, [appStore.apiError]);
}
