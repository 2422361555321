import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './NoContent.module.scss';

function NoContent({ title, className }: { title: string; className: string }) {
  return (
    <div className={cn(styles.noContent, className)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>
        <FormattedMessage defaultMessage='There is no content connected to this page' id='Base.noContent' />
      </div>
    </div>
  );
}

export default NoContent;
