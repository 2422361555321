import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import { useToastMessageContext } from 'context/toast/toast';
import { userActionEvents } from 'utils';

import { RESPONSE } from '../constants';
import { Action } from '../enum';
import { getResultMessage } from '../helper/getResultMessage';

export const useSuccessNotice = () => {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();

  return useCallback((action: Action, channelId: number) => {
    const userActionType = action === Action.AddFavorite ? UserActionType.favoriteAdd : UserActionType.favoriteRemove;

    userActionEvents(userActionType, channelId);

    toastMessageContext.showToast({
      success: true,
      title: getResultMessage(action, RESPONSE.SUCCESS, intl),
    });
  }, []);
};
