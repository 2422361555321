import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import styles from '../RecordingTabs.module.scss';

type Props = {
  type: string;
  active: boolean;
  tabMessage: string;
  onClick: (type: string) => () => void;
};

function Tab({ active, onClick, tabMessage, type }: Props) {
  return (
    <button
      type='button'
      className={cn(styles.tab, {
        [styles.active]: active,
      })}
      onClick={onClick(type)}
    >
      {tabMessage}
    </button>
  );
}
export default observer(Tab);
