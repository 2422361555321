import React from 'react';
import { observer } from 'mobx-react';

import { useEpgContext } from 'context/epgContext/epgContext';

import NowLineTime from './components/NowLineTime/NowLineTime';
import { useNowLineCombiner } from './hooks';

import styles from './NowLine.module.scss';

function NowLine() {
  const { nowLineRef, isReadyTvChannel } = useEpgContext();
  const { isNowLine } = useNowLineCombiner({ isReadyTvChannel: Boolean(isReadyTvChannel) });

  if (!isNowLine) {
    return null;
  }

  return (
    <div ref={nowLineRef} className={styles.wrapper}>
      <NowLineTime />
    </div>
  );
}

export default observer(NowLine);
