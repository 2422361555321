import React, { useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { BODY_OPEN_CLASS_NAME } from 'consts';

import { useRailContext } from '../RailProviderV2/RailProviderV2';

interface ChildProps {
  width: number;
  height: number;
}

interface Props {
  children: ({ width, height }: ChildProps) => React.ReactNode;
}

/**
 * A wrapper component that provides its children with a stable width and height dimensions,
 * using the `react-virtualized-auto-sizer` for dynamic sizing while preventing unnecessary
 * re-renders when body scrolling is blocked.
 *
 * @param children - A render prop function that returns the child component.
 */
function StableAutoSizer({ children }: Props) {
  const { checkResize } = useRailContext();
  const resolvedWidth = useRef<number>(0);

  return (
    <AutoSizer onResize={checkResize}>
      {({ height = 0, width = 0 }) => {
        // For avoiding unnecessary rerendering of the RailCardsList component due to a change in width after adding the "open" class to the body element
        const isBlockedBodyScrolling = document.body.classList.contains(BODY_OPEN_CLASS_NAME);

        if (!isBlockedBodyScrolling) {
          resolvedWidth.current = width;
        }

        return children({ width: resolvedWidth.current! || width, height });
      }}
    </AutoSizer>
  );
}

export default React.memo(StableAutoSizer);
