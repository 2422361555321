import { useMemo } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

interface Props {
  store: RailStoreV2;
  columnCount: number;
}

export const useGridItems = ({ store, columnCount }: Props) => {
  const items = useMemo(() => store?.items || [], [store?.items]); // eslint-disable-line
  const skeletonItemsCount = useMemo(() => columnCount * 2 - (items.length % columnCount), [items.length]);
  const fakeItems = columnCount && skeletonItemsCount > 0 ? new Array(skeletonItemsCount).fill({}) : [];
  const gridItems = items.concat(fakeItems);

  return { gridItems, items };
};
