/* eslint-disable no-shadow */

import React, { useState } from 'react';
import cn from 'classnames';
import config from 'config';
import { observer } from 'mobx-react';

import { DebugMenuStore, featureEnabler, Subscription, tvpilVersion } from '@kaltura-ott/tvpil-shared';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useAppStore, useCheckPlatform } from 'hooks';

import styles from './DebugMenu.module.scss';

const DebugMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [rootCategoryId, setRootCategoryId] = useState(DebugMenuStore.rootCategoryId);

  const { appStore } = useAppStore();
  const isMobile = !!useCheckPlatform();

  const store = appStore.debugMenuStore as DebugMenuStore;
  // for testing with debug menu
  // @ts-ignore
  window.appStore = appStore;
  // @ts-ignore
  window.DebugMenuStore = DebugMenuStore;
  // @ts-ignore
  window.Subscription = Subscription;
  // @ts-ignore
  window.featureEnabler = featureEnabler;
  // @ts-ignore
  const playerVersion = window?.KalturaPlayer?.VERSION;

  function onCategoryChange(event: any) {
    const { target } = event;

    DebugMenuStore.setRootCategoryId(Number(target.value));
    setRootCategoryId(target.value);

    event.stopPropagation();
    event.preventDefault();
  }

  function renderEnv({ envs, title, partnerId, name }: any, index: any) {
    return (
      <div className={styles.envs}>
        <h3>{title}</h3>
        <br />
        <span>partnerId: {partnerId}</span>
        <br />
        <span>applicationName: {name}</span>

        <div>
          {envs.map(({ name: envName, url: envUrl }: any, index2: any) => {
            return (
              <div key={envName} className={styles.envItem}>
                <h5>{envName}</h5>
                <a href={envUrl} rel='noopener noreferrer' target='_blank'>
                  {envUrl}
                </a>

                <ActionButton
                  onClick={() => {
                    store.switchEnv(index, index2);
                    window.location.reload();
                  }}
                >
                  Switch
                </ActionButton>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const { partnerId, endpoint, applicationName } = appStore?.params?.dmsSetting!;

  return (
    <div className={cn(styles.wrapper, { [styles.mobile]: isMobile })}>
      <button onClick={() => setOpen(!open)} type='button'>
        {open ? '-' : '+'}
      </button>
      {open && (
        <div>
          <p>
            Current env: <br />
            {partnerId} <br />
            {applicationName} <br />
            <a href={endpoint}>{endpoint}</a>
          </p>
          <p>
            Current dms/app version:
            <span> {import.meta.env.VITE_VERSION}</span>
          </p>
          <p>
            Current version of the player:
            <span> {playerVersion}</span>
          </p>
          <p>
            TVPIL version:
            <span> {tvpilVersion}</span>
          </p>
          <p>
            Custom rootCategoryId:
            <input onChange={onCategoryChange} type='text' value={rootCategoryId} />
            <button type='button' onClick={() => window.location.reload()}>
              Reload page
            </button>
          </p>
          {config.debugMenu &&
            store.items.map((item, index) => {
              return <div key={item.name}>{renderEnv(item, index)}</div>;
            })}
        </div>
      )}
    </div>
  );
};

export default observer(DebugMenu);
