import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES, SENTRY_DEFAULT_VALUES } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';

export function useAppSentryInit() {
  const {
    appStore: { dms, params },
    appStore,
  } = useAppStore();
  const { id: userID } = appStore?.userStore.userData || {};

  useEffect(() => {
    if (!dms) return;

    const { dsn } = dms.sentry || {};
    const frp = 'frp1';
    const isFRP = dms.endpointUrl.includes(frp);
    const isSentryReadyForInit =
      dsn && import.meta.env.NODE_ENV === 'production' && !Sentry.getCurrentHub().getClient();

    const tracesSampleRate =
      import.meta.env.NODE_ENV === 'production'
        ? featureEnabler.variation(FEATURES.SENTRY_TRACES_SAMPLE_RATE, SENTRY_DEFAULT_VALUES.TRACES_SAMPLE_RATE)
        : SENTRY_DEFAULT_VALUES.TRACES_SAMPLE_RATE;

    const sampleRate = featureEnabler.variation(FEATURES.SENTRY_SAMPLE_RATE, SENTRY_DEFAULT_VALUES.SAMPLE_RATE);
    const { matches } = featureEnabler.variation(FEATURES.SENTRY_IGNORE_ERRORS, {});

    if (isSentryReadyForInit) {
      const ignoreErrors = matches?.length ? matches.map((pattern: any) => new RegExp(pattern, 'ig')) : undefined;

      Sentry.init({
        environment: isFRP ? 'production' : 'development',
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        release: `${import.meta.env.VITE_APP_NAME}@${import.meta.env.VITE_VERSION}`,
        tracesSampleRate,
        sampleRate,
        // @ts-ignore
        initialScope: {
          tags: { groupId: params?.dmsSetting?.partnerId },
        },
        ignoreErrors,
      });
    }
  }, [dms, params]);

  useEffect(() => {
    if (!Sentry.getCurrentHub().getClient()) {
      return;
    }

    Sentry.setUser(userID ? { id: userID } : null);
  }, [userID]);
}
