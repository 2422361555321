import { useMemo } from 'react';

import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { checkIsServiceList } from 'components/widgets/Login/LoginForm/utils/checkIsServiceList';
import { FEATURE_MULTILANGUAGE } from 'consts';
import { useMenuContext } from 'context/menuContext/menuContext';
import { useRootStore, useServicesStore } from 'hooks';

export const useMenuCombiner = () => {
  const { store, isOnBoarding } = useMenuContext();
  const { isReady } = store;
  const rootStore = useRootStore();
  const { servicesStore } = useServicesStore();
  const isChangingLangEnabled =
    featureEnabler.variation(FEATURE_MULTILANGUAGE, true) && !checkIsServiceList(servicesStore);
  const isShowSearchInput = rootStore.search !== undefined;

  return useMemo(
    () => ({
      isReady,
      isOnBoarding,
      isChangingLangEnabled,
      isShowSearchInput,
    }),
    [isReady],
  );
};
