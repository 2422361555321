import { useEffect } from 'react';

import { defaultPropsError } from 'components/widgets/Modal/constants';

export const useResetErrorMessage = (
  setError: React.Dispatch<
    React.SetStateAction<{
      errorMessage: string;
      errorCode: string;
    }>
  >,
  isResetError: boolean,
) => {
  useEffect(() => {
    if (isResetError) {
      setError(defaultPropsError);
    }
  }, [isResetError]);
};
