import { useCallback } from 'react';

import { useFavorites } from '@kaltura-ott/tvpil-shared';

import { Action } from '../enum';

import { useErrorNotice } from './useErrorNotice';
import { useSuccessNotice } from './useSuccessNotice';

export const useFavoriteHandlers = () => {
  const errorNotice = useErrorNotice();
  const successNotice = useSuccessNotice();
  const { deleteFavoriteChannel, addFavoriteChannel } = useFavorites();

  const deleteFavorite = useCallback(async (channelId: number) => {
    try {
      await deleteFavoriteChannel({ channelId });

      return successNotice(Action.RemoveFavorite, channelId);
    } catch (e) {
      return errorNotice(Action.RemoveFavorite);
    }
  }, []);

  const addFavorite = useCallback(async (channelId: number) => {
    try {
      await addFavoriteChannel({ channelId });

      return successNotice(Action.AddFavorite, channelId);
    } catch (e) {
      return errorNotice(Action.AddFavorite);
    }
  }, []);

  return { addFavorite, deleteFavorite };
};
