import { NavigateFunction } from 'react-router-dom';

import { PageStore, Subscription } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';

interface Props {
  navigate: NavigateFunction;
  settingsStore: PageStore;
  singleSubscriptionId?: number | undefined;
  isSingleSubscriptionAvailable?: boolean;
  redirectLink: string;
  goBackUrl?: string;
  lastScrollYPosition?: number | string;
  linearId?: number;
}

export async function onMoveToPurchase({
  navigate,
  settingsStore,
  redirectLink,
  linearId,
  goBackUrl = '/',
  isSingleSubscriptionAvailable,
  singleSubscriptionId,
}: Props) {
  function redirect(pathname: string) {
    navigate(pathname, {
      state: {
        redirectLink,
        goBackUrl,
      },
    });
  }

  if (linearId) {
    const result = await Subscription.findAddonsByLinearId(linearId);

    if (result && result.length === 1) {
      redirect(`${routes.purchase.path}/${result[0].packageData.id}`);
      return;
    }
  } else if (isSingleSubscriptionAvailable) {
    redirect(`${routes.purchase.path}/${singleSubscriptionId}`);
    return;
  }

  const { link } = settingsStore.children.find(({ subtype }: any) => subtype === 'subscription') as PageStore;
  navigate(`${routes.settings.path}${link}`);
}
