import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { AppStore, EpgStore } from '@kaltura-ott/tvpil-shared';

function useEpgStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  const { rootStore } = appStore;
  const epgStore = rootStore?.children.find((store) => store.subtype === UIDAPresentationTypeEnum.epg) as EpgStore;

  return { epgStore };
}

export { useEpgStore };
