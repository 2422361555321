import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { SubscriptionType } from '@kaltura-ott/tvpil-shared/lib/stores/subscriptionsRepository';

import { getPackageDescription } from 'components/pages/VideoDetails/components/VideoContent/utils';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { resolveMillisecondsToHoursAndMinutes } from 'components/pages/VideoDetails/utils';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { CLOCK, PENDING_PURCHASE_SMALL } from 'consts';
import { useAppStore, useRootStore } from 'hooks';
import { getPurchaseIcon } from 'utils';

import styles from './ButtonsDescription.module.scss';

export interface ButtonsDescriptionProps {
  asset: ViewableEntitiesType;
  isPpv: boolean;
}

export function ButtonsDescription({ asset, isPpv }: ButtonsDescriptionProps) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const {
    detailsStore: { expirationTime },
  } = useRootStore();
  const { isPurchased, isPurchasePending, selectedSubscription } = detailsStoreDataResolver(asset);
  const packageDescription = getPackageDescription(selectedSubscription);
  const isShowPackageDetails = !isPurchased && !isPurchasePending && !isPpv && !!packageDescription;
  const isShowExpirationTime = expirationTime && expirationTime > 0;
  return (
    <>
      {isPurchasePending && (
        <div className={styles.pendingPurchase}>
          <img alt='purchase' src={`${staticAssetsUrl}${getPurchaseIcon(PENDING_PURCHASE_SMALL)}`} />
          <FormattedMessage
            defaultMessage='Pending Purchase Confirmation'
            id={`${COMPONENT_NAME}.VideoContent.pendingPurchase`}
          />
        </div>
      )}

      {isShowPackageDetails && (
        <p className={styles.packageName}>
          <span>{packageDescription}</span>{' '}
          {selectedSubscription!.type !== SubscriptionType.addonForPurchasedBase && (
            <FormattedMessage
              defaultMessage='You can easily change your tariff'
              id={`${COMPONENT_NAME}.VideoContent.changeTariff`}
              tagName='span'
            />
          )}
        </p>
      )}

      {isShowExpirationTime && ( // to show when rented TVOD expires
        <div className={styles.expirationBadge}>
          <img alt='expiration' src={`${staticAssetsUrl}${getPurchaseIcon(CLOCK)}`} />
          <FormattedMessage
            defaultMessage='{hours}h {minutes}m left'
            id={`${COMPONENT_NAME}.VideoContent.expirationTime`}
            values={resolveMillisecondsToHoursAndMinutes(expirationTime)}
          />
        </div>
      )}
    </>
  );
}
