import { useCardContext } from 'context/сardContext/сardContext';
import { useParentalPinStore } from 'hooks';

import { useHandleClickInfo } from '../useHandleClickInfo/useHandleClickInfo';
import { useHandleRef } from '../useRefHook/useRefHook';

function useCardBubbleInfoCombiner() {
  const ref = useHandleRef();
  const {
    data,
    resolvedCardProps: {
      adult,
      isExternalProviderAsset,
      isLinkAsAsset,
      isAsPlayAsset,
      isShowAirTime,
      isTypeOfSeries,
      seasonEpisodeTitle,
      isProgramRecord,
      collectionType,
      mediaTypeParam,
      isFutureProgram,
      isSuppressedEpisode,
      isHero,
      isContinueWatching,
      isMdCondition,
      isChannelAsset,
    },
  } = useCardContext();
  const parentalPinStore = useParentalPinStore();
  const isBlockByAdult = !parentalPinStore.isAdultPinEntered;
  const isContentRestricted = !!(adult && isBlockByAdult && !isExternalProviderAsset);
  const isShowedOnlyInfo = isChannelAsset || isContentRestricted || isLinkAsAsset;
  const { handleClickInfo } = useHandleClickInfo({
    data,
    isProgramRecord,
    isShowedOnlyInfo,
    collectionType,
    mediaTypeParam,
    isFutureProgram,
    isSuppressedEpisode,
    isTypeOfSeries,
    isMdCondition,
  });
  const isNotHeroAndContinueWatching = !isHero && !isContinueWatching;
  const isChannelInfo = isNotHeroAndContinueWatching;
  const isChannelAndAirTimeInfoCondition = (isChannelInfo && !isTypeOfSeries) || isShowAirTime;

  return {
    isChannelInfo,
    isShowAirTime,
    isChannelAndAirTimeInfoCondition,
    isShowedOnlyInfo,
    seasonEpisodeTitle,
    isAsPlayAsset,
    handleClickInfo,
    ref,
    data,
  };
}

export { useCardBubbleInfoCombiner };
