import { useCallback } from 'react';
import { Link, NavigateFunction } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { ParentalPinStoreFacade } from '@kaltura-ott/tvpil-shared/lib/facades/parental-pin-store-facade';

import { ContextType } from 'context/modal/interface';

import { handleClickDetailsButton } from '../handleClickDetailsButton/handleClickDetailsButton';

interface Props {
  asset: ViewableEntitiesType;
  switchToLive?: () => void;
  closeOverlay?: (() => void) | undefined;
  navigate: NavigateFunction;
  modalContext: ContextType;
  parentalPinStore: ParentalPinStoreFacade;
}

export const useHandleDisableStartOver = ({
  asset,
  switchToLive,
  closeOverlay,
  navigate,
  modalContext,
  parentalPinStore,
}: Props) => {
  return useCallback((e: React.MouseEvent<typeof Link>) => {
    if (switchToLive) {
      switchToLive();
    }

    handleClickDetailsButton({ e, asset, isLive: true, closeOverlay, navigate, modalContext, parentalPinStore });
  }, []);
};
