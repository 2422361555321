import { useCallback } from 'react';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { resolvePathToDetailsPageV2, userActionEvents } from 'utils';

interface Props {
  channelId: number;
  id: number;
  mediaTypeParam: string;
  externalId: string | undefined;
  isChannelHasNoProgram: boolean;
}

export const useHandleClickInfo = ({ id, mediaTypeParam, externalId, isChannelHasNoProgram, channelId }: Props) => {
  const { navigate, location } = useStableReactRouter();
  const { onCardClick } = useRailContext();
  const { state } = location;
  // handle click info button on hero card to navigate to details page
  const handleClickInfo = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (isChannelHasNoProgram) {
        return;
      }

      if (onCardClick) {
        onCardClick(channelId);
      }

      userActionEvents(UserActionType.details, id, mediaTypeParam);

      const link = resolvePathToDetailsPageV2({
        id,
        mediaTypeParam,
        externalId,
      });

      navigate(link, { state: { goBackUrl: state?.goBackUrl } });

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());
    },
    [id],
  );

  return { handleClickInfo };
};
