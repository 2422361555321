export const CHANNEL_COLUMN_WIDTH = 150;
export const Y_DRAG_MULTIPLIER = 2.5;
export const MINUTES_IN_HOUR = 60;
export const MARGIN_BOTTOM = 3;
export const MAX_TIME = 24;
export const EVENING_START_HOUR = 20;
export const MAX_HOURS = 24;
export const MAX_TIME_FOR_SWITCHING_DAY = 21;
export const DEFAULT_MAX_EPG_DAYS_FORWARD = 7;
export const DEFAULT_MAX_EPG_DAYS_BACKWARD = 7;
export const DEFAULT_LANDSCAPE_RATIO = '16x9';
