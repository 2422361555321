import React from 'react';
import SVG from 'react-inlinesvg';

import { FAVORITE_ICON_PATH } from 'consts';
import { useAppStore } from 'hooks';
import { getFavoriteChannelIcon } from 'utils';

interface Props {
  className?: string;
}

export function FavoriteIcon({ className = '' }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  return (
    <SVG
      className={className}
      data-type='favorite'
      src={`${staticAssetsUrl}${getFavoriteChannelIcon(FAVORITE_ICON_PATH)}`}
    />
  );
}
