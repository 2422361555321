import { useEffect } from 'react';

import { handleHideInfoPopup } from 'components/widgets/Player/handlers/handleHideInfoPopup/handleHideInfoPopup';
import { CUSTOM_APP_EVENTS } from 'consts';
import { playoutStoreDataResolver } from 'utils';

export const useCloseOverlayOnPlaybackEnded = (loaded: boolean) => {
  const { id } = playoutStoreDataResolver();

  useEffect(() => {
    if (loaded) {
      handleHideInfoPopup();
      document.dispatchEvent(new CustomEvent(CUSTOM_APP_EVENTS.SHOW_OSD));
    }
  }, [id]);
};
