import { removeSymbolAndTransformToCamelCase } from 'utils';

export function parseUrl(url: string | null | undefined) {
  if (!url) return '';

  const resultObj: any = {};

  url.split('&').forEach((item: string) => {
    const [key, value] = item.split('=');

    resultObj[removeSymbolAndTransformToCamelCase(key)] = value;
  });

  return resultObj;
}
