import { useCallback } from 'react';

interface Props {
  drag: React.MutableRefObject<boolean>;
  blockRef: React.MutableRefObject<HTMLDivElement | null>;
  startX: React.MutableRefObject<number>;
  scrollLeft: React.MutableRefObject<number>;
}

export const useMouseDownHandler = ({ drag, blockRef, startX, scrollLeft }: Props) =>
  useCallback((e: MouseEvent) => {
    drag.current = true;
    startX.current = e.pageX - blockRef.current!.offsetLeft;
    scrollLeft.current = blockRef.current!.scrollLeft;
  }, []);
