import React from 'react';
import cn from 'classnames';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import airTimeStyles from 'components/presenters/AirTime/AirTime.module.scss';
import AirTimeV2 from 'components/presenters/AirTimeV2/AirTimeV2';
import { ContentMarker, ExpiresBadge, NewEpisodeBadge, PurchaseIcon, RentedBadge } from 'components/presenters/icons';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';

import CardRating from '../CardRating/CardRating';

import { useCardContentCombiner } from './hooks/useCardContentCombiner/useCardContentCombiner';

import styles from './CardContent.module.scss';

const COMPONENT_NAME = 'Rail.RailCard';

function CardContent() {
  const {
    isProgramAsset,
    isDisplayContentMarker,
    contentMarker,
    isFutureProgram,
    isLinearAsset,
    isRecordExpires,
    recordingExpirationInDays,
    isAsPlayAsset,
    isDisplayCartIcon,
    isNewEpisode,
    isRented,
    isContentRestricted,
    data,
  } = useCardContentCombiner();

  return (
    <div
      className={cn(styles.inner, { [styles.programAsset]: isProgramAsset }, { [styles.linearAsset]: isLinearAsset })}
    >
      {isDisplayCartIcon && <PurchaseIcon className={styles.cart} isBadge />}
      {isDisplayContentMarker && <ContentMarker className={styles.contentMarker} contentMarker={contentMarker!} />}
      {isNewEpisode && <NewEpisodeBadge translationId={COMPONENT_NAME} className={styles.newEpisodeBadge} />}
      {isRented && <RentedBadge className={styles.rentedBadge} translationId={COMPONENT_NAME} />}
      {!isContentRestricted && (
        <>
          {isFutureProgram && (
            <div data-type='futureProgram' className={airTimeStyles.futureProgram}>
              <AirTimeV2 className={styles.airTime} data={data as CollectionProgramAsset} />
            </div>
          )}

          <CardRating />

          {isAsPlayAsset && <PlayButton className={styles.playBtn} />}
          {/* TODO: (AssetCardProgressBar) must be added in the next iteration */}

          {isRecordExpires && (
            <ExpiresBadge
              className={styles.expires}
              translationId={COMPONENT_NAME}
              expiredInDays={recordingExpirationInDays!}
            />
          )}
        </>
      )}
      {/* TODO: (FavoriteIcon, AssetCardProgramChannelInfo) must be added in the next iteration */}
    </div>
  );
}

export default React.memo(CardContent);
