import { useCallback } from 'react';

import { epgLocalDrugStorage } from 'components/pages/Epg/storages';

import { handleDragDelta } from '../../utils/handleDragDelta';

const zIndex = '12';

export const useOnHandleDragContinue = (forwardGridMovement: React.MutableRefObject<HTMLDivElement | null>) =>
  useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (handleDragDelta(e)) return;

    if (['clicked'].includes(epgLocalDrugStorage.dragStatus)) {
      if (forwardGridMovement.current) {
        forwardGridMovement.current.style.zIndex = zIndex;
      }
    }
  }, []);
