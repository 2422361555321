import React from 'react';

import RailSkeleton from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';

import RailHorisontalWrapper from '../RailHorisontalWrapper/RailHorisontalWrapper';
import { useRailContext } from '../RailProvider/RailProvider';

const DEFAULT_ITEMS = 10;

const RailHorisontalResolveRendering = () => {
  const { store, isSkeletonCondition, handleSkeletonRef } = useRailContext();

  if (isSkeletonCondition) {
    return <RailSkeleton handleRef={handleSkeletonRef} items={DEFAULT_ITEMS} store={store} />;
  }

  if (store.isRailEmpty) {
    return null;
  }

  return <RailHorisontalWrapper />;
};

export default RailHorisontalResolveRendering;
