import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import FormWrapper from './components/FormWrapper/FormWrapper';
import { useLoginFormCombiner } from './hooks/useLoginFormCombiner/useLoginFormCombiner';

import styles from './LoginForm.module.scss';

interface Props {
  isOttLogin: boolean;
  loginAssets: { inAppLink: string; title: string; type: string }[];
}

function LoginForm({ isOttLogin, loginAssets }: Props) {
  const {
    form,
    error,
    signUp,
    isPending,
    isVisiblePassword,
    handleSubmit,
    validateOnBlur,
    handleChange,
    handleStateChange,
  } = useLoginFormCombiner({ loginAssets, isOttLogin });

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h3>
          <FormattedMessage defaultMessage='Sign In' id='Forms.SignIn.title' />
        </h3>
        {error && <ErrorMessage error={error} />}
        <FormWrapper
          handleSubmit={handleSubmit}
          validateOnBlur={validateOnBlur}
          handleChange={handleChange}
          handleStateChange={handleStateChange}
          isPending={isPending}
          isVisiblePassword={isVisiblePassword}
          form={form}
        />
      </div>
      <p className={styles.footer}>
        <span>
          <FormattedMessage defaultMessage='Not a member yet?' id='Forms.SignIn.notMember' />
        </span>
        <a href={signUp?.inAppLink} rel='noopener noreferrer' target='_blank'>
          <FormattedMessage defaultMessage='Sign up' id='Forms.SignIn.signUp' />
        </a>
      </p>
    </div>
  );
}

export default observer(LoginForm);
