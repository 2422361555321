import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import LivePlayer from 'components/widgets/Player/LivePlayer';
import { useAppStore, useCleanupState, useDeviceStore, useRootStore } from 'hooks';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import FullPlayerLoadingScreen from './FullPlayerLoadingScreen';
import { useCheckMdUiDisable } from './hooks';

import styles from './VideoPage.module.scss';

interface Props {
  startOver?: boolean;
}

const LiveVideoPage: React.FC<Props> = ({ startOver = false }) => {
  const {
    appStore: { playoutStoreV2, lastWatchedLinear },
  } = useAppStore();
  const rootStore = useRootStore();
  const { householdDevice } = useDeviceStore();
  const isHouseholdDeviceNotEmpty = !!householdDevice?.householdId;
  const [error, setError] = useCleanupState(false);
  const [programId, setProgramId] = useState<null | number>(null);
  const { id, externalId } = useParams();
  const location = useLocation();
  const isShowStreams = location.state?.isShowStreams;
  const paramId = Number(id);
  const isPlayerCondition = programId && isHouseholdDeviceNotEmpty;
  const mainHeroRail = lastWatchedLinear?.mainHeroRail;
  const lastWatchedSubLinearExternalId = mainHeroRail?.lastWatchedSubLinearExternalId;
  const initType = startOver ? PlayoutStoreV2Type.START_OVER : PlayoutStoreV2Type.LIVE;
  const playableAsset = globalStorage.focusAsset;

  useEffect(() => {
    async function liveEffect() {
      if (playoutStoreV2) {
        const initParams = playableAsset ? { playableAsset } : { id: paramId };

        await playoutStoreV2.init({
          ...initParams,
          type: initType,
          autoplayNextProgram: true,
          saveLastWatched: true,
          mosaic: {
            externalId: externalId || lastWatchedSubLinearExternalId,
            loadFirstSubStream: !externalId,
          },
        });
      }

      // @ts-ignore
      setProgramId(playoutStoreV2?.currentAsset?.currentProgram?.data?.id || playoutStoreV2?.currentAsset?.data?.id);
      setError(!playoutStoreV2?.currentAsset);
      globalStorage.clearFocusAsset();
    }

    if (paramId) {
      liveEffect();
    }
  }, [playoutStoreV2, setError, paramId]);

  useEffect(() => {
    return () => {
      playoutStoreV2.stopPlayback();
    };
  }, [playoutStoreV2]);

  useEffect(() => {
    if (mainHeroRail) {
      // to reset loading mosaic asset for hero rail after switching reg to md program for live
      mainHeroRail.onInvisible();
    }
  }, [mainHeroRail]);

  useEffect(() => {
    const { uiDesignElements } = rootStore!;

    if (uiDesignElements) {
      document.body.style.backgroundColor = uiDesignElements.backgroundColor;
    }
  }, []);

  useCheckMdUiDisable();

  // TODO: handle error
  if (error) {
    return <Navigate to={routes.notFound.path} />;
  }

  return (
    <div className={styles.videoPage}>
      <FullPlayerLoadingScreen />
      {isPlayerCondition && <LivePlayer assetId={paramId} isShowStreams={isShowStreams} />}
    </div>
  );
};

export default observer(LiveVideoPage);
