import { useCallback } from 'react';

import { useCleanupState } from 'hooks';

export const useMuteHook = () => {
  const [muted, setMuted] = useCleanupState(() => true);

  const muteHandler = useCallback(() => {
    setMuted((mute) => !mute);
  }, [setMuted]);

  return { muted, muteHandler };
};
