import { useParentalPinStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { playoutStoreDataResolver } from 'utils';

export const useBlockContentResolver = () => {
  const { geoBlockingProperties, loadingNextProgram, currentEntity: asset } = playoutStoreDataResolver();
  let { isGeoBlocking } = playoutStoreDataResolver();
  const parentalPinStore = useParentalPinStore();
  const { isPlayerError } = globalStorage;

  if (loadingNextProgram) {
    isGeoBlocking = false;
  }

  const parentalRating = asset?.data?.parentalRating;
  const isContentBlockedAsAdult = !!asset && parentalPinStore.isBlockByAdult(asset);
  const isContentBlockedByAge = !!parentalRating && parentalPinStore.isBlockByParentRating(asset);
  const isContentRestricted = !loadingNextProgram && (isContentBlockedAsAdult || isContentBlockedByAge);
  const isNotPurchased = asset?.isPurchased === false;
  const isPlaybackAllowed = !isContentRestricted && !isGeoBlocking && !isNotPurchased;

  return {
    isContentBlockedAsAdult,
    geoBlockingProperties,
    isContentRestricted,
    isPlaybackAllowed,
    parentalPinStore,
    parentalRating,
    isNotPurchased,
    isGeoBlocking,
    isPlayerError,
    asset,
  };
};
