import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { useToastMessageContext } from 'context/toast/toast';
import { ApplicationError } from 'interface';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

import { RESPONSE } from '../constants';
import { Action } from '../enum';
import { getResultMessage } from '../helper/getResultMessage';

export const useErrorNotice = () => {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();

  return useCallback((action: Action) => {
    toastMessageContext.showToast({
      error: true,
      title: getResultMessage(action, RESPONSE.ERROR, intl),
    });

    sendApplicationErrorEvent({
      context: UIDAPresentationTypeEnum.favorites,
      message: action,
      additionalInfo: ApplicationError.Favorites,
    });
  }, []);
};
