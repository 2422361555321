import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Program } from '@kaltura-ott/tvpil-shared';

interface Props {
  program: Program;
  isRestrictedByAdult: boolean | undefined;
  isCatchUp: boolean | undefined;
}

export function useResolveTitle({ isRestrictedByAdult, isCatchUp, program }: Props) {
  const intl = useIntl();
  const {
    data: { notFound, seriesId, tags },
  } = program;

  return useMemo(() => {
    let title = seriesId && tags?.SeriesName?.length ? tags.SeriesName[0] : program.data.title;

    if (isRestrictedByAdult) {
      title = intl.formatMessage({ id: 'Epg.ProgramCard.restrictedContent', defaultMessage: 'Restricted Content' });
    }

    if (notFound) {
      if (!isRestrictedByAdult) {
        title = intl.formatMessage({
          id: 'Epg.ProgramCard.noDataAvailable',
          defaultMessage: 'Program cannot be played or no data available',
        });
      }

      if (isCatchUp) {
        title = intl.formatMessage({
          id: 'Epg.ProgramCard.noDataCatchUp',
          defaultMessage: 'This Channel Does Not Show Past Programs',
        });
      }
    }

    return title;
  }, [program.id, isRestrictedByAdult]);
}
