import { appStore } from '@kaltura-ott/tvpil-shared';
// TODO: cover the tests
class NetworkService {
  private requestURL = '';

  init() {
    this.requestURL = appStore?.dms?.pingServerUrl || '';
  }

  isConnection = false;

  setNetworkConnection(value: any) {
    this.isConnection = value;
  }

  getResult() {
    return this.isConnection;
  }

  isNetworkConnection = async () => {
    if (this.requestURL) {
      await new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', this.requestURL, true);

        xhr.onload = () => {
          if (xhr.status) {
            this.setNetworkConnection(true);
          } else {
            this.setNetworkConnection(false);
          }
          resolve(xhr.response);
        };

        xhr.onerror = () => {
          this.setNetworkConnection(false);
          resolve(xhr.response);
        };

        try {
          xhr.send();
        } catch (e) {
          xhr.abort();
          this.setNetworkConnection(false);
        }
      });

      return this.getResult();
    }
    this.setNetworkConnection(window.navigator.onLine); // To avoid the situation where requestURL is not defined
    return this.getResult();
  };
}

const networkService = new NetworkService();

export default networkService;
