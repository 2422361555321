import React, { createContext, useContext, useMemo } from 'react';
import { EpgParamsType } from 'types';

import { Channel, EpgSettings } from '@kaltura-ott/tvpil-shared';

export interface ContextType {
  epgParams: EpgParamsType;
  refTime: React.MutableRefObject<HTMLDivElement | null>;
  nowLineRef: React.MutableRefObject<HTMLDivElement | null>;
  forwardGridMovement: React.MutableRefObject<HTMLDivElement | null>;
  epgRef: React.MutableRefObject<HTMLDivElement | null>;
  userActive: React.MutableRefObject<boolean>;
  gridStartYPosition: React.MutableRefObject<number>;
  setUserActive: () => void;
  handleRef: (node: any) => void;
  setFirstVisibleIndex: (id: number) => void;
  settings?: EpgSettings;
  channels?: Channel[];
  isReadyTvChannel?: boolean;
}

interface Props extends ContextType {
  children: React.ReactNode;
}

const EpgContentContext = createContext<ContextType>({
  epgRef: { current: null },
  refTime: { current: null },
  nowLineRef: { current: null },
  forwardGridMovement: { current: null },
  userActive: { current: false },
  gridStartYPosition: { current: 0 },
  epgParams: {
    channelWidth: 0,
    epgStartTimestamp: 0,
    epgEndTimestamp: 0,
    epgStartEndDifference: 0,
    epgWidth: 0,
    ratio: 0,
  },
  setUserActive: () => {},
  handleRef: () => {},
  setFirstVisibleIndex: () => {},
  settings: {} as EpgSettings,
  channels: [] as Channel[],
  isReadyTvChannel: false,
});

const EpgContextProvider = ({
  epgParams,
  epgRef,
  nowLineRef,
  forwardGridMovement,
  refTime,
  userActive,
  gridStartYPosition,
  setUserActive,
  handleRef,
  setFirstVisibleIndex,
  children,
  settings,
  channels,
  isReadyTvChannel,
}: Props) => {
  const contextValue = useMemo(() => {
    return {
      channels,
      isReadyTvChannel,
      epgRef,
      settings,
      refTime,
      nowLineRef,
      forwardGridMovement,
      userActive,
      gridStartYPosition,
      epgParams,
      handleRef,
      setUserActive,
      setFirstVisibleIndex,
    };
  }, [
    isReadyTvChannel,
    channels,
    settings,
    epgParams,
    epgRef,
    nowLineRef,
    forwardGridMovement,
    refTime,
    userActive,
    gridStartYPosition,
    handleRef,
    setUserActive,
    setFirstVisibleIndex,
  ]);

  return <EpgContentContext.Provider value={contextValue}>{children}</EpgContentContext.Provider>;
};

const useEpgContext = () => useContext(EpgContentContext);

export { EpgContextProvider, useEpgContext };
export default EpgContextProvider;
