import React from 'react';
import { FormattedMessage } from 'react-intl';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useVideoContext } from 'context/videoContext/videoContext';

import styles from '../../VideoContent.module.scss';

export const Cast = () => {
  const { program } = useVideoContext();
  const directors = program.getDirectors();
  const actors = program.getActors();

  return (
    <div className={styles.cast}>
      {directors.length > 0 && (
        <p>
          <FormattedMessage
            defaultMessage={`Directed by: ${directors.join(', ')}`}
            id={`${COMPONENT_NAME}.VideoContent.directedByText`}
            values={{ value: directors.join(', '), strong: (text: React.ReactNode) => <b>{text}</b> }}
          />
        </p>
      )}
      {actors?.length > 0 && (
        <p>
          <FormattedMessage
            defaultMessage={`Cast: ${actors.join(', ')}`}
            id={`${COMPONENT_NAME}.VideoContent.castText`}
            values={{ value: actors.join(', '), strong: (text: React.ReactNode) => <b>{text}</b> }}
          />
        </p>
      )}
    </div>
  );
};
