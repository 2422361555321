import { useEffect } from 'react';

import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import { PerformanceContextType, PerformanceEvents } from 'interface';
import { APPLICATION_SPINNER_EVENT_NAME, checkLoadingLifecycle } from 'utils';

interface Props {
  railSettings: RailSettings;
  isSkeletonCondition: boolean;
  performanceEventsContext: PerformanceContextType;
  railType: string;
  items: CollectionAsset[];
}

export const usePerformanceSpinerHook = ({
  railSettings,
  isSkeletonCondition,
  performanceEventsContext,
  railType,
  items,
}: Props) => {
  useEffect(() => {
    if (items.length) {
      const hashKeyForSpinerEvent = `${
        APPLICATION_SPINNER_EVENT_NAME.RAIL
      }-id:${railSettings?.id!}-name:${railType}`.toLowerCase();

      if (isSkeletonCondition) {
        performanceEventsContext.setStartTime(PerformanceEvents.spinner, hashKeyForSpinerEvent);
      } else {
        checkLoadingLifecycle(hashKeyForSpinerEvent, performanceEventsContext);
      }
    }
  }, [items.length, isSkeletonCondition]); // eslint-disable-line
};
