import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import {
  CollectionLinkAsset,
  createCollectionAssetLink,
  isCollectionLinkAssetValid,
  MenuStore,
} from '@kaltura-ott/tvpil-shared';

import { FROM_REFERENCE_ID } from 'consts';
import { findMenuItems, resolvePathToDetailsPage } from 'utils';

interface Props {
  data: CollectionLinkAsset;
  menuStore: MenuStore;
  referenceId: string | undefined;
}

export function resolveLinkAsAsset({ data, menuStore, referenceId }: Props) {
  const isValidLinkAsAsset = isCollectionLinkAssetValid(data);
  let link = '';

  if (data?.linkType! === TypeOfApplicationLink.asset && data?.inAppLink) {
    link = resolvePathToDetailsPage({ id: data?.inAppLink! });
  } else if (data?.linkType === TypeOfApplicationLink.external && data?.inAppLink) {
    link = data?.inAppLink;
  } else if (data?.linkType && data?.inAppLink) {
    const isItemInMenu = !!findMenuItems(menuStore, data?.inAppLink).itemById;

    if (isValidLinkAsAsset) {
      link = createCollectionAssetLink(data) || '';
    }

    if (referenceId && !isItemInMenu && isValidLinkAsAsset) {
      link += `?${FROM_REFERENCE_ID}=${referenceId}`;
    }
  }

  return link;
}
