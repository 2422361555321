import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import Tab from './Tab/Tab';

import styles from './RecordingTabs.module.scss';

export const RECORD_TYPE = {
  past: 'past',
  future: 'future',
};

type Props = {
  selectedTab: string;
  clickTabsHandler: (type: string) => () => void;
  resetDefaultTab: () => void;
};

function RecordingTabs({ selectedTab, clickTabsHandler, resetDefaultTab }: Props) {
  const intl = useIntl();

  const tabs = [
    {
      type: RECORD_TYPE.past,
      active: selectedTab === RECORD_TYPE.past,
      id: 'RecordingTabs.Recordings',
      defaultMessage: 'Recordings',
    },
    {
      type: RECORD_TYPE.future,
      active: selectedTab === RECORD_TYPE.future,
      id: 'RecordingTabs.ScheduledRecordings',
      defaultMessage: 'Scheduled Recordings',
    },
  ];

  useEffect(() => {
    return () => {
      resetDefaultTab();
    };
  }, []); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            type={tab.type}
            active={tab.active}
            onClick={clickTabsHandler}
            tabMessage={intl.formatMessage({ id: tab.id, defaultMessage: tab.defaultMessage })}
          />
        ))}
      </div>
    </div>
  );
}
export default observer(RecordingTabs);
