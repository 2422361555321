import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import LocalTime from 'components/presenters/LocalTime/LocalTime';
import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useAppStore, useConcurrencyStore, useDeviceStore } from 'hooks';
import { DateFormatStyle, formatDate } from 'utils';

import styles from './DeviceRemovalFrequencyModal.module.scss';

const COMPONENT_NAME = 'DeviceList.DeviceRemovalFrequencyModal.DefaultModal';

function DeviceRemovalFrequencyModal() {
  const { getDeviceList } = useDeviceStore();
  const {
    appStore: {
      localizationStore: { displayLanguage },
    },
  } = useAppStore();
  const concurrencyStore = useConcurrencyStore();
  const intl = useIntl();
  const { frequencyNextDeviceAction } = concurrencyStore.household!;
  const date = formatDate(frequencyNextDeviceAction, DateFormatStyle.WithFullYear, displayLanguage);
  const defaultDescription = `You can not logout from your connected devices right now. Logout will be possible at ${date}`;

  return (
    <DefaultModal
      className={styles.frequencyModal}
      title={intl.formatMessage({ defaultMessage: 'Can Not Logout', id: `${COMPONENT_NAME}.title` })}
      onDecline={getDeviceList}
    >
      <p>
        <FormattedMessage defaultMessage={defaultDescription} id={`${COMPONENT_NAME}.description`} values={{ date }} />{' '}
        <LocalTime date={frequencyNextDeviceAction} />
      </p>
    </DefaultModal>
  );
}

export default DeviceRemovalFrequencyModal;
