import React from 'react';

import { Linear } from '@kaltura-ott/tvpil-shared';

import { config } from 'components/pages/Epg/config';

import ProgramsList from './components/ProgramsList/ProgramsList';
import { usePrrogramsLineCombiner } from './hooks';

import styles from './ProgramsInner.module.scss';

interface Props {
  channel: Linear;
  index: number;
}

function ProgramsInner({ channel, index }: Props) {
  const { visible, ref } = usePrrogramsLineCombiner();

  return (
    <li ref={ref} data-target={channel.data.title} className={styles.item} style={{ height: config.channelLineHeight }}>
      {visible && <ProgramsList index={index} channel={channel} />}
    </li>
  );
}

export default ProgramsInner;
