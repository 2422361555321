import React from 'react';
import cn from 'classnames';

import { RailSettings as RailSettingsTVPIL } from '@kaltura-ott/tvpil-shared';

import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from 'consts';

import { RAIL_TYPE } from '../../constants';
import { RailSettings } from '../RailProviderV2/types';

import styles from './RailSkeleton.module.scss';

interface Props {
  handleRef?: (node: HTMLDivElement) => void;
  settings: RailSettingsTVPIL | RailSettings;
  railType: string;
  items: number;
}

function RailSkeleton({ handleRef, settings, items, railType }: Props) {
  const isLogo = railType === RAIL_TYPE.LOGO;
  const isHero = railType === RAIL_TYPE.HERO;
  const isShowHeader = !isLogo && !isHero;
  const skeletonItemsNum = isLogo ? 1 : items;
  const imageHeight = settings?.imageHeight! || DEFAULT_HEIGHT;
  const imageWidth = settings?.imageWidth! || DEFAULT_WIDTH;

  return (
    <div ref={handleRef} className={cn(styles.railSkeleton, { [styles.logo]: isLogo })}>
      {isShowHeader && (
        <header
          style={{
            width: imageWidth,
          }}
        />
      )}
      <div>
        {new Array(skeletonItemsNum).fill(0).map((_, i) => {
          /* eslint-disable react/no-array-index-key */
          return <RailCardSkeleton key={i} height={imageHeight ?? 0} width={imageWidth} />;
        })}
      </div>
    </div>
  );
}

export function RailCardSkeleton({ height, width }: { height: number; width: number }) {
  return (
    <div data-type='skeletonAsset' className={styles.railSkeletonAsset}>
      <div
        style={{
          height,
          width,
        }}
      />
      <div />
    </div>
  );
}

export default RailSkeleton;
