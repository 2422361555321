import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { overlayStorage, playerStorage } from '../../storages';
import { handleLoadChosenStream } from '../handleLoadChosenStream/handleLoadChosenStream';
import { handleShowInfoPopup } from '../handleShowInfoPopup/handleShowInfoPopup';

export async function handleLoadMedia() {
  const playerService = playerServiceManagement.getPlayerService();
  const isShowOverlay = overlayStorage.getShowOverlay();
  const { muted } = playerStorage.getPlayer();

  if (!playerService.getPlayerInstance()) {
    return;
  }

  await handleLoadChosenStream();

  const isMuted = !!muted;

  playerService.setMuted(isMuted);

  if (isShowOverlay) {
    handleShowInfoPopup();
  }
}
