import React, { useEffect, useState } from 'react';
import { secondsToMilliseconds } from 'date-fns';

interface Props {
  startDate: number;
  endDate: number;
  children: any;
  gapInSec?: number;
}

const TimeProgressBar: React.FC<Props> = ({ startDate, endDate, children, gapInSec = 1 }) => {
  function calculate() {
    return ((Date.now() - startDate) * 100) / (endDate - startDate);
  }

  const [percent, setPercent] = useState(calculate());
  const gap = secondsToMilliseconds(gapInSec);

  useEffect(() => {
    const interval = window.setInterval(() => {
      const p = calculate();

      setPercent(p);
    }, gap); // every 30 sec

    return () => window.clearInterval(interval);
  }, [startDate, endDate, gapInSec]); // eslint-disable-line

  return children(percent);
};

export default TimeProgressBar;
