import { useEffect, useRef } from 'react';

/**
 * Hook to be sure component is mounted or unmounted
 */
export function useIsComponentMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
