import { useCallback } from 'react';
import { Node } from 'types';

import { featureEnabler, LOGIN_METHOD } from '@kaltura-ott/tvpil-shared';

import { FEATURES, LOGIN_TYPE } from 'consts';
import { useLoginPageStore, useLoginStore, useServicesStore } from 'hooks';
import { applyStyle, applyStyles } from 'utils';

import { checkIsServiceList } from '../../LoginForm/utils/checkIsServiceList';
import { useLoginInitializationHook } from '../useLoginInitializationHook/useLoginInitializationHook';
import { useResetServiceList } from '../useResetServiceList/useResetServiceList';

export const useLoginCombinerHook = () => {
  const loginPageStore = useLoginPageStore();
  const { uiDesignElements, image, isReady, items } = loginPageStore;

  const { isAnonymous, isPostLoginProcessing, ks } = useLoginStore();
  const {
    servicesStore,
    servicesStore: { serviceListRequiredToPresent },
  } = useServicesStore();

  const isLoginForm =
    featureEnabler.variation(FEATURES.LOGIN_METHOD, LOGIN_METHOD.LOGIN_FORM) === LOGIN_METHOD.LOGIN_FORM;
  const isOttLogin = featureEnabler.variation(FEATURES.LOGIN_FORM_TYPE, LOGIN_TYPE.OTT) === LOGIN_TYPE.OTT;
  const showServiceList = checkIsServiceList(servicesStore);
  const isShowServicesView = isLoginForm && showServiceList;
  const isShowLoginForm = isLoginForm && !showServiceList;
  const isAlreadyAuthorized = !isAnonymous && !!ks && !serviceListRequiredToPresent && !isPostLoginProcessing;

  const handleRef = useCallback((node: Node) => {
    if (node !== null) {
      if (uiDesignElements) {
        applyStyles(node, uiDesignElements, false, true);
      }
      if (image) {
        applyStyle(node, '--background-image', `url(${image})`);
      }
    }
  }, []); // eslint-disable-line

  useLoginInitializationHook();

  // to reset isShowServicesView prop after browser back navigation on service list page
  useResetServiceList();

  return {
    isReady,
    showServiceList,
    isShowServicesView,
    isShowLoginForm,
    isOttLogin,
    items,
    isLoginForm,
    isAlreadyAuthorized,
    loginPageStore,
    handleRef,
  };
};
