/**
 * @param originalString - The original string to be processed.
 * @param substring - The substring to search for within the original string.
 * @returns The original string cut up to the last slash before the specified substring.
 *          Returns '/' if the substring is at the beginning, or the original string
 *          if the substring is not found.
 */
export function cutStringUpTo(originalString: string, substring: string): string {
  if (substring === '') {
    return originalString;
  }

  const indexOfSubstring = originalString.indexOf(substring);

  if (indexOfSubstring !== -1) {
    if (indexOfSubstring === 1) return '/';
    // Find the last slash before the substring to ensure it's not included in the result
    const lastSlashBeforeSubstring = originalString.lastIndexOf('/', indexOfSubstring);
    return originalString.slice(0, lastSlashBeforeSubstring);
  }

  return originalString;
}
