import React from 'react';

import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';
import { RecordsErrorCodeEnum } from '@kaltura-ott/tvpil';
import { Record, SeriesRecord } from '@kaltura-ott/tvpil-shared';

import NoSpaceModal from 'components/widgets/Modal/NoSpaceModal/NoSpaceModal';
import { useModalContext } from 'context/modal/modal';
import { useRecordRepository } from 'hooks';

import { getCorrectSeriesId, isNotRecorded } from '../../utils/utils';

interface Props {
  setPending: Function;
  setIsMenuOpen: Function;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

function useRecord({ setPending, setIsMenuOpen, ref }: Props) {
  const recordRepository = useRecordRepository();
  const modalContext = useModalContext();

  async function addRecord(type: KalturaRecordingType, assetId: number, seriesId: any) {
    setPending(true);
    setIsMenuOpen(false);

    const result = await recordRepository.addRecord({
      assetId,
      seriesId: getCorrectSeriesId(type, seriesId),
    });

    if (result?.error === RecordsErrorCodeEnum.ExceededQuota) {
      modalContext.openModal(<NoSpaceModal />);
    }

    setPending(false);
  }

  async function removeRecord(
    type: KalturaRecordingType,
    record: Record,
    seriesRecord: SeriesRecord,
    assetId: number,
    seriesId: any,
  ) {
    if (isNotRecorded(record, seriesRecord)) {
      return;
    }

    setPending(true);
    setIsMenuOpen(false);

    await recordRepository.removeRecord({ assetId, seriesId: getCorrectSeriesId(type, seriesId) }, type);

    setPending(false);
    ref.current?.blur();
  }

  return { addRecord, removeRecord };
}

export { useRecord };
