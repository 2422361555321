import { PurchaseStates, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { TransactionResult } from '@kaltura-ott/tvpil-shared';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { ApplicationError, PerformanceEvents } from 'interface';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';
import { openLink } from 'utils';

interface Props {
  callbackGeo?(): void;
  callbackError(error?: any): void;
  callbackSuccess(): void;
  callbackPending(): void;
  errorContext: UIDAPresentationTypeEnum;
  purchase?(): Promise<TransactionResult>;
  setIsLoading(value: boolean): void;
  callbackPin?(): void;
}

export function usePurchase({
  callbackGeo,
  callbackError,
  callbackSuccess,
  callbackPending,
  callbackPin,
  errorContext,
  purchase,
  setIsLoading,
}: Props) {
  const performanceEventsContext = usePerformanceEventsContext();

  function handleError(error: any) {
    // To clear startTime for performance event purchase if the response has error
    performanceEventsContext.clearEvent(PerformanceEvents.purchase);

    callbackError(error);
    setIsLoading(false);
    sendApplicationErrorEvent({
      context: errorContext,
      message: error.message,
      code: error.code,
      additionalInfo: ApplicationError.Purchase,
    });
  }

  async function onPurchase() {
    if (!purchase) return;

    setIsLoading(true);
    // To set startTime for performance event purchase
    performanceEventsContext.setStartTime(PerformanceEvents.purchase);

    try {
      const result = await purchase();

      if (result.state === PurchaseStates.redirect && result.redirectUrl) {
        openLink(result.redirectUrl);
      } else if (result.state === PurchaseStates.ok) {
        callbackSuccess();
        setIsLoading(false);
        return;
      } else if (result.state === PurchaseStates.pending) {
        callbackPending();
      } else if (result.state === PurchaseStates.geoBlock && callbackGeo) {
        callbackGeo();
      } else if (result.state === PurchaseStates.enterPin && callbackPin) {
        callbackPin();
      } else if (result.state === PurchaseStates.error) {
        handleError(result);
        return;
      } else {
        throw new Error(PurchaseStates.error);
      }

      setIsLoading(false);
      // To clear startTime for performance event purchase if the transition to the player is not expected
      performanceEventsContext.clearEvent(PerformanceEvents.purchase);
    } catch (error) {
      handleError(error);
    }
  }

  return { onPurchase };
}
