import React, { useCallback, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { AssetPresentationRatioEnum, getWidthByHeight } from '@kaltura-ott/tvpil';

import {
  CARD_PADDING,
  DEFAULT_ANIMATION_TIME,
  INITIAL_OFFSET,
  NEXT_BUTTON_WIDTH,
} from 'components/widgets/RailV2/constants';
import { animate } from 'components/widgets/RailV2/utils';
import { DEFAULT_HEIGHT } from 'consts';

import { RailSettings } from '../../../RailProviderV2/types';

interface Props {
  setRailCardsListRefScrollTo: (value: number) => void;
  items: any[];
  totalWidth: number;
  offset: React.MutableRefObject<any> | undefined;
  railCardsListRef: React.MutableRefObject<List | null>;
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
  railSettings: RailSettings;
  scrollHandle: (newOffset: number, blockWidth: number) => void;
}

export const useArrowsClickHook = ({
  scrollHandle,
  setRailCardsListRefScrollTo,
  items,
  totalWidth,
  offset,
  railCardsListRef,
  refElementInner,
  railSettings,
}: Props) => {
  const isAnimationActive = useRef(false);
  const setIsAnimation = useCallback((value: boolean) => {
    isAnimationActive.current = value;
  }, []);
  // get width by height for default landcape ratio if imageWidth is undefined
  const fallbackItemWidth = getWidthByHeight(
    railSettings.imageHeight || DEFAULT_HEIGHT,
    AssetPresentationRatioEnum['16x9'],
  );

  return useCallback(
    (increase: boolean) => {
      if (isAnimationActive.current) {
        return;
      }

      const blockWidth = refElementInner?.current?.clientWidth || document.body.clientWidth;
      let newOffset = 0; // start rail from full card when navigating
      let totalAssetsWidth = 0;

      for (let i = 0; i < items.length; i += 1) {
        const width = railSettings.imageWidth
          ? railSettings.imageWidth + CARD_PADDING
          : fallbackItemWidth + CARD_PADDING || 0;
        // i element overlow to right
        if (increase && totalAssetsWidth + width + INITIAL_OFFSET > offset?.current + blockWidth) {
          newOffset = totalAssetsWidth - CARD_PADDING;
        } else if (!increase && totalAssetsWidth + width > totalWidth - offset?.current) {
          newOffset = totalWidth - totalAssetsWidth - blockWidth + NEXT_BUTTON_WIDTH;
          // in some situations small part of space may stay, and we will have left arrow button
          if (newOffset <= NEXT_BUTTON_WIDTH) {
            newOffset = 0;
          }
        } else {
          totalAssetsWidth += width;
        }
      }

      if (newOffset < 0) {
        newOffset = 0;
      }

      let delta = newOffset - offset?.current;

      if (newOffset + blockWidth > totalWidth) {
        newOffset = totalWidth - blockWidth;
        delta = totalWidth - offset?.current - blockWidth;
      }

      const animationTime = (Math.abs(delta) / blockWidth) * DEFAULT_ANIMATION_TIME;

      animate(setIsAnimation, railCardsListRef, delta, animationTime, () => {
        scrollHandle(newOffset, blockWidth);
        setRailCardsListRefScrollTo(newOffset);
      });
    },
    [isAnimationActive.current, totalWidth, items.length, offset?.current],
  );
};
