import { useEffect } from 'react';

import { useCleanupState } from 'hooks';

export const useTimeRange = (program: any) => {
  const [timeRange, setTimeRange] = useCleanupState(() => ({
    startTime: 0,
    endTime: 0,
  }));

  useEffect(() => {
    if (program?.data) {
      setTimeRange({ startTime: program.data?.startDate || 0, endTime: program.data?.endDate || 0 });
    }
  }, [program, setTimeRange]);

  return { timeRange };
};
