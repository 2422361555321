import { useState } from 'react';

import { AppApiError, appStore } from '@kaltura-ott/tvpil-shared';

import findLoginItems from 'components/widgets/Login/findLoginItems';
import { useValidationForm } from 'hooks';
import { getLegacyAppPath } from 'utils';

import {
  useOnCallSignIn,
  useOnHandleChange,
  useOnHandleError,
  useOnHandleStateChange,
  useOnHandleSubmit,
  useOnSetLoginSuccessEndTime,
  useOnValidateOnBlur,
} from '../../handlers';
import { useLoginFormErrors } from '../useLoginFormErrors/useLoginFormErrors';

interface Props {
  loginAssets: { inAppLink: string; title: string; type: string }[];
  isOttLogin: boolean;
}

export const useLoginFormCombiner = ({ loginAssets, isOttLogin }: Props) => {
  const [form, setForm, defaultInputError] = useValidationForm();
  const { signUp } = findLoginItems(loginAssets);
  const [error, setError] = useState<null | AppApiError>(null);
  const [isPending, setIsPending] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const legacyAppPath = getLegacyAppPath(appStore?.params?.dmsSetting?.endpoint);

  const setLoginSuccessEndTime = useOnSetLoginSuccessEndTime();
  const callSignIn = useOnCallSignIn({ isOttLogin, form, legacyAppPath, setLoginSuccessEndTime });
  const handleSubmit = useOnHandleSubmit({ form, defaultInputError, setForm, setIsPending, callSignIn });
  const handleChange = useOnHandleChange({ form, error, setError, setForm });
  const validateOnBlur = useOnValidateOnBlur({ form, defaultInputError, setForm });
  const handleStateChange = useOnHandleStateChange(setIsVisiblePassword);
  const handleError = useOnHandleError({ setError, setIsPending });

  useLoginFormErrors({ loginForm: form, setLoginForm: setForm, setLoginFormError: handleError });

  return {
    form,
    error,
    signUp,
    isPending,
    isVisiblePassword,
    handleSubmit,
    validateOnBlur,
    handleChange,
    handleStateChange,
  };
};
