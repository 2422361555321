import React from 'react';
import { observer } from 'mobx-react';

import BlockContentChecker from 'components/pages/Video/BlockContentChecker';

import KalturaPlayer from './KalturaPlayer';

interface Props {
  assetId: number;
}

const VodPlayer: React.FC<Props> = ({ assetId }) => {
  return (
    <BlockContentChecker>{(isAllowed) => (isAllowed ? <KalturaPlayer assetId={assetId} /> : null)}</BlockContentChecker>
  );
};

export default observer(VodPlayer);
