import { KuxBookmark } from '@kaltura-ott/tvpil';
import { Program } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

export function getBookmarkPosition() {
  const { currentAsset, type } = playoutStoreDataResolver();
  let bookmark: KuxBookmark | undefined = currentAsset?.bookmark;
  let bookmarkPosition = 0;

  if (type === PlayoutStoreV2Type.NPVR) {
    bookmark = (currentAsset as Program)?.recordBookmark;
  }

  if (!bookmark?.finishedWatching && bookmark?.position) {
    bookmarkPosition = bookmark!.position;
  }

  if (!bookmarkPosition && type === PlayoutStoreV2Type.START_OVER) {
    bookmarkPosition = 1;
  }

  return bookmarkPosition;
}
