import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { AppApiError } from '@kaltura-ott/tvpil-shared';

import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

import { isDetailsInitError, isRecordingTypeError } from './checkErrorActionName';
import { getApplicationErrorEventsAdditionalParams } from './getApplicationErrorEventsAdditionalParams';

interface Props {
  isFullPlayer: boolean;
  isDetailsPage: boolean;
  isSearchPage: boolean;
  isNpvr: boolean;
  isEpg: boolean;
}

export const appErrorEventSender = (
  error: AppApiError,
  { isFullPlayer, isDetailsPage, isSearchPage, isNpvr, isEpg }: Props,
) => {
  const { message, code, actionName } = error;

  const isRecordingErrorType = isRecordingTypeError(actionName);
  const isDetailsInitErrorType = isDetailsInitError(actionName);

  const { recordingOrDetailsContextType, otherContextType, additionalInfo } = getApplicationErrorEventsAdditionalParams(
    {
      error,
      isDetailsPage,
      isSearchPage,
      isFullPlayer,
      isNpvr,
      isEpg,
    },
  );

  if (isRecordingErrorType || isDetailsInitErrorType) {
    sendApplicationErrorEvent({
      context: recordingOrDetailsContextType as UIDAPresentationTypeEnum,
      message,
      code: code?.toString(),
      additionalInfo,
    });
  }

  if (otherContextType) {
    sendApplicationErrorEvent({
      context: otherContextType as UIDAPresentationTypeEnum,
      message,
      code: code?.toString(),
      additionalInfo,
    });
  }
};
