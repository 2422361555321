import { useCallback, useRef } from 'react';

import { changeState } from '../../helper/changeState/changeState';

const down = 'down';
const up = 'up';

export const useToggleScrollDirection = () => {
  const prevScrollY = useRef(0);
  const scrollDirection = useRef<string | null>();

  return useCallback(() => {
    const { scrollY } = window;

    if (scrollY === 0) {
      scrollDirection.current = null;
      changeState();
    } else if (scrollY > prevScrollY.current && scrollDirection.current !== down) {
      scrollDirection.current = down;
      changeState();
    } else if (scrollY < prevScrollY.current && scrollDirection.current !== up) {
      scrollDirection.current = up;
      changeState('145px', '85px');
    }

    prevScrollY.current = scrollY;
  }, []);
};
