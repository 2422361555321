import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import Img from 'components/presenters/Img/Img';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import Loader from 'components/presenters/Loader/Loader';
import NoContent from 'components/widgets/NoContent/NoContent';
import { SeriesCatchUpGridSettingsProvider } from 'context/seriesCatchUpGridSettingsContext/seriesCatchUpGridSettingsContext';
import routes from 'routesPaths';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils/getAdditionalEventParam';

import GridUpdatingHook from '../common/components/GridUpdatingHook/GridUpdatingHook';
import { COMPONENT_NAME } from '../constants';

import SeriesCathUpGridCardsView from './components/SeriesCathUpGridCardsView/SeriesCathUpGridCardsView';
import { useSeriesCatchUpGridHooksCombiner } from './hooks/useSeriesCatchUpGridHooksCombiner/useSeriesCatchUpGridHooksCombiner';

import styles from './SeriesCatchUpGrid.module.scss';

function SeriesCatchUpGrid() {
  const {
    isLoading,
    gridSettings,
    isNoContent,
    wrapperRef,
    providerLogo,
    notValidPageTitle,
    setForceUpdate,
  } = useSeriesCatchUpGridHooksCombiner();

  return (
    <MainLayout>
      {isNoContent ? (
        <NoContent className={styles.noContent} title={notValidPageTitle} />
      ) : (
        <>
          <GridUpdatingHook setForceUpdate={setForceUpdate} path={routes.seriesCatchUpGrid.path} />
          <div ref={wrapperRef} className={styles.wrapper}>
            {!!providerLogo && <Img alt='provider logo' className={styles.providerLogo} srcList={providerLogo} />}
            {isLoading ? (
              <div className={styles.loaderWrapper}>
                <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.GRID_PAGE} component={<Loader />} />
              </div>
            ) : (
              <>
                <FormattedMessage
                  defaultMessage={gridSettings.title}
                  id={`${COMPONENT_NAME}.title.${gridSettings.title}`}
                  tagName='h3'
                />
                <SeriesCatchUpGridSettingsProvider gridSettingsData={gridSettings}>
                  <SeriesCathUpGridCardsView />
                </SeriesCatchUpGridSettingsProvider>
              </>
            )}
          </div>
        </>
      )}
    </MainLayout>
  );
}

export default React.memo(SeriesCatchUpGrid);
