import { action, observable } from 'mobx';

class ContextStorage {
  @observable context: any = {};

  @action
  getContext() {
    return this.context;
  }

  @action
  setContext(context: any) {
    this.context = context;
  }

  @action
  resetContext() {
    this.context = {};
  }
}

const contextStorage = new ContextStorage();

export { contextStorage };
