import { action, observable } from 'mobx';

class IntlStorage {
  @observable intl: any = {};

  @action
  getIntl() {
    return this.intl;
  }

  @action
  setIntl(intl: any) {
    this.intl = intl;
  }

  @action
  resetIntl() {
    this.intl = {};
  }
}

const intlStorage = new IntlStorage();

export { intlStorage };
