import React from 'react';
import cn from 'classnames';

import { useAppStore } from 'hooks';

import styles from './Loader.module.scss';

interface Props {
  center?: boolean;
}

function Loader({ center }: Props) {
  const {
    appStore: { dms },
  } = useAppStore();

  if (!dms) {
    return null; // TODO: page may jumping, need to test
  }

  const { loadingAnimationUrl } = dms || {};

  return (
    <div className={cn(styles.wrapper, { [styles.center]: center })}>
      <img alt='Loader' src={loadingAnimationUrl} />
    </div>
  );
}

export default Loader;
