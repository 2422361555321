import { ErrorEventArgs } from '@kaltura-ott/tvpil-analytics/lib/interfaces/error-event';
import { appStore } from '@kaltura-ott/tvpil-shared';

import { ErrorSource } from 'interface';
import { checkNetworkErrorFormat } from 'utils/checkNetworkErrorFormat';

interface Props {
  context: string;
  message: string;
  code?: string;
  additionalInfo?: string;
}

export const sendApplicationErrorEvent = ({ context, message, code, additionalInfo }: Props) => {
  if (appStore.dms?.analytics?.events?.allowDeviceApplicationErrorEvents) {
    const errorCode = checkNetworkErrorFormat(code);
    appStore.kuxAnalytics.send(new ErrorEventArgs(context, message, errorCode, additionalInfo, ErrorSource.BE));
  }
};
