import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import Card from 'components/widgets/Card/Card';
import { CardProvider } from 'context/сardContext/сardContext';

import { useRailCardLogicCombiner } from './hooks/useRailCardLogicCombiner/useRailCardLogicCombiner';

interface Props {
  asset: CollectionAsset;
  itemWidth: number;
}

function RailCardLogic({ asset, itemWidth }: Props) {
  const railCardResolvedLogic = useRailCardLogicCombiner({ data: asset });

  return (
    <CardProvider resolvedCardProps={railCardResolvedLogic} itemWidth={itemWidth} data={asset}>
      <Card />
    </CardProvider>
  );
}

export { RailCardLogic };
