import { useEffect } from 'react';

import { useEpgStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';

import { epgLocalDrugStorage, epgLocalTimeStampStorage } from '../../storages';
import { calculateEpgParams } from '../../utils';

export const useInitializationHook = () => {
  const {
    epgStore,
    epgStore: { maxEpgDateBackward, maxEpgDateForward },
  } = useEpgStore();

  useEffect(() => {
    epgStore.init({});
    epgLocalTimeStampStorage.init();

    return () => {
      epgStore.onInvisible();
      epgLocalDrugStorage.clear();
      epgLocalTimeStampStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (globalStorage.isAwakened) {
      epgStore.resetStore();
      epgStore.init({});
      epgLocalTimeStampStorage.init();
    }
  }, [globalStorage.isAwakened]);

  return { epgParams: calculateEpgParams(maxEpgDateBackward, maxEpgDateForward) };
};
