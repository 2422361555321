import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Program, SeriesAdditionalRailStoreV2, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';
import { RecordAdditionalRailStoreV2 } from '@kaltura-ott/tvpil-shared/lib/stores/category/widgets/rails/record-rail/record-rail-additional-store';

import { LAST_VIEW_SEASON_NUMBER } from 'consts';
import { EpisodesContextProvider } from 'context/episodesContext/episodesContext';

import EpisodesList from './EpisodesList/EpisodesList';
import RecordingTabs, { RECORD_TYPE } from './RecordingTabs/RecordingTabs';
import SelectEpisode from './SelectEpisode';

import styles from './Episodes.module.scss';

type Props = {
  program: ViewableEntitiesType;
  seriesStore: SeriesAdditionalRailStoreV2;
  scheduledStore?: RecordAdditionalRailStoreV2;
};

function Episodes({ seriesStore, scheduledStore, program }: Props) {
  const { seriesRecord } = program as Program;
  const [selectedTab, setSelectedTab] = useState(RECORD_TYPE.past);
  const isPastTab = selectedTab === RECORD_TYPE.past;
  const isTabs = seriesStore.isReady && scheduledStore?.isReady && !!scheduledStore.items.length && !!seriesRecord;
  const isScheduledTab = selectedTab === RECORD_TYPE.future && !!scheduledStore && !!scheduledStore.items.length;
  const store = isScheduledTab ? scheduledStore : seriesStore;

  const handleClickTabsHandler = (type: string) => {
    return () => {
      if (selectedTab === type) {
        return;
      }
      setSelectedTab(type);
    };
  };

  const resetDefaultTab = () => {
    setSelectedTab(RECORD_TYPE.past);
  };

  const setupSeason = async (seasonNumber: number) => {
    sessionStorage.setItem(LAST_VIEW_SEASON_NUMBER, seasonNumber.toString());
    await seriesStore.setupSeasonNumber(seasonNumber);
  };

  return (
    <EpisodesContextProvider episodesStore={store}>
      <div className={styles.wrapper}>
        {isTabs && (
          <RecordingTabs
            selectedTab={selectedTab}
            clickTabsHandler={handleClickTabsHandler}
            resetDefaultTab={resetDefaultTab}
          />
        )}
        {isPastTab && (
          <header>
            <SelectEpisode seriesStore={seriesStore} setupSeason={setupSeason} />
          </header>
        )}
        {store?.items.length && <EpisodesList />}
      </div>
    </EpisodesContextProvider>
  );
}

export default observer(Episodes);
