import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { ParentalControlContextProvider } from 'context/parentalControlContext/parentalControlContext';
import { useParentalPinStore } from 'hooks';

import SettingsContentWrapper from '../SettingsContentWrapper/SettingsContentWrapper';

import ParentalBlock from './components/ParentalBlock/ParentalBlock';
import PurchaseProtection from './components/PurchaseProtection/PurchaseProtection';
import { useParentalControlCombiner } from './hooks/useParentalControlCombiner/useParentalControlCombiner';
import { COMPONENT_NAME, FULL_COMPONENT_NAME } from './constans';

function ParentalControl() {
  const parentalPinStore = useParentalPinStore();
  const intl = useIntl();
  const [ageThreshold, setAgeThreshold] = useState(parentalPinStore.noThresholdAge);
  const {
    handleClick,
    onChangeAgeRange,
    useParentalControlInit,
    useToastMessage,
    useParentalControlSetAgeThreshold,
  } = useParentalControlCombiner(setAgeThreshold);

  useParentalControlInit();
  useToastMessage(COMPONENT_NAME);
  useParentalControlSetAgeThreshold(setAgeThreshold);

  return (
    <SettingsContentWrapper
      description={intl.formatMessage({
        defaultMessage: 'Protect your Kids from unwanted content or purchases',
        id: `${FULL_COMPONENT_NAME}.SettingsContentWrapper.description`,
      })}
      title={intl.formatMessage({
        defaultMessage: 'Parental Control',
        id: `${FULL_COMPONENT_NAME}.SettingsContentWrapper.title`,
      })}
    >
      <ParentalControlContextProvider
        ageThreshold={ageThreshold}
        onChangeAgeRange={onChangeAgeRange}
        onChangePin={handleClick}
      >
        <ParentalBlock />
        <PurchaseProtection />
      </ParentalControlContextProvider>
    </SettingsContentWrapper>
  );
}

export default observer(ParentalControl);
