import { useCallback, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import { RailFetchData } from 'types';

import { RailSettings } from '@kaltura-ott/tvpil-shared';

import { RAIL_TYPE } from 'components/widgets/RailV2/constants';

import { calculateItemHeight, calculateTotalWidth } from '../../../../utils';
import { useApplyIncreaseHeightHook } from '../useApplyIncreaseHeightHook/useApplyIncreaseHeightHook';
import { useCheckResizeHook } from '../useCheckResizeHook/useCheckResizeHook';
import { useOnCardClickHook } from '../useOnCardClickHook/useOnCardClickHook';
import { useOnHoverHook } from '../useOnHoverHook/useOnHoverHook';
import { useRailLastXPosition } from '../useRailLastXPosition/useRailLastXPosition';

function useRailProviderCombiner(railSettings: RailSettings, railData: RailFetchData, railType: string) {
  const startOffset = useRailLastXPosition(railSettings.id, railType);
  const offset = useRef(startOffset);
  const isLogoRail = railType === RAIL_TYPE.LOGO;
  const headerRef = useRef<HTMLElement | null>(null);
  const railCardsListRef = useRef<List | null>(null);
  const refElementInner = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const setRailCardsListRefScrollTo = useCallback((value: number) => {
    if (railCardsListRef.current) {
      railCardsListRef.current.scrollTo(value);
      offset.current = value;
    }
  }, []);
  const componentHeight = calculateItemHeight(railSettings, isLogoRail);
  const totalWidth = calculateTotalWidth(railData.items, railSettings);
  const checkResize = useCheckResizeHook({
    setRailCardsListRefScrollTo,
    refElementInner,
    offset,
    totalWidth,
  });
  const onHover = useOnHoverHook({ headerRef, refElementInner, componentHeight });
  const onCardClick = useOnCardClickHook({ id: railSettings.id, offset });
  const nextButtons = useRef({
    isLeftNextButton: offset.current > 0,
    isRightNextButton:
      // @ts-ignore
      offset.current + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });
  const setNextButtons = useCallback((value: any) => {
    nextButtons.current = value;
  }, []);

  useApplyIncreaseHeightHook({ refElementInner, componentHeight });

  return {
    checkResize,
    onHover,
    onCardClick,
    wrapperRef,
    totalWidth,
    componentHeight,
    headerRef,
    refElementInner,
    railCardsListRef,
    offset,
    setNextButtons,
    setRailCardsListRefScrollTo,
    nextButtons,
    isLogoRail,
  };
}

export { useRailProviderCombiner };
