import { useMemo } from 'react';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';
import { Linear, Program, ProgramPlaceholder } from '@kaltura-ott/tvpil-shared';

import { config } from 'components/pages/Epg/config';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { MD_ICON, MS_IN_SEC, SEC_IN_HOUR } from 'consts';
import { useEpgContext } from 'context/epgContext/epgContext';
import { useAppStore, useEpgStore, useParentalPinStore, useRecordRepository } from 'hooks';
import { checkInstanceOf, formatLocalTime, getMDIconPath } from 'utils';

import { textStyles } from '../../constants';

export function useConditionsResolver(program: Program) {
  const {
    appStore: {
      staticAssetsUrl,
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();

  const recordRepository = useRecordRepository();

  const parentalPinStore = useParentalPinStore();
  const { epgStore } = useEpgStore();
  const { epgParams } = useEpgContext();
  const {
    data: { startDate, endDate, notFound, mosaicInfo, adult },
    linear,
    record,
    seriesRecord,
  } = program;

  const isRestrictedByAdult =
    (linear && parentalPinStore.isBlockByAdult(linear)) || parentalPinStore.isBlockByAdult(program);

  return useMemo(() => {
    const channel = program.linear as Linear;
    const isFutureDate = startDate > epgLocalTimeStampStorage.currentTimestamp;
    const isSeriesRecord = (record && record.isSeriesRecord) || seriesRecord;
    const isMdIconCondition = !!mosaicInfo?.mosaicChannelsInfo?.length;
    const iconMDUrl = `${staticAssetsUrl}${getMDIconPath(MD_ICON)}`;
    const duration = endDate - startDate;
    const width = (config.hourScale * duration) / (SEC_IN_HOUR * MS_IN_SEC);
    const time = formatLocalTime({ date: startDate, locale: latinLanguage || displayLanguage });
    const isCatchUp =
      startDate < epgLocalTimeStampStorage.currentTimestamp && endDate < epgLocalTimeStampStorage.currentTimestamp;
    const isCatchUpWithNoData = isCatchUp && notFound;
    const isRestrictedCatchup = isRestrictedByAdult && isCatchUp;
    const isAdultCathUpPlaybackEnabled = !!(adult && isCatchUp && linear?.data.enableCatchUp);
    const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
    const isRecordBtnCondition = (record || seriesRecord) && isNpvrEnabled;
    const isLive =
      startDate <= epgLocalTimeStampStorage.currentTimestamp && endDate >= epgLocalTimeStampStorage.currentTimestamp;
    const isPlaceHolder = checkInstanceOf(program, ProgramPlaceholder);
    const isLoading = !epgStore.isReadyTvChannel || isPlaceHolder;
    const isDescriptionHidden = width < textStyles.title.padding;
    const widthForTimeEllipsis = isRecordBtnCondition || isMdIconCondition ? width - 20 : width;
    const isOnlyMd = isRecordBtnCondition && isMdIconCondition && !!(width < config.minWidthForRecorddAndMd);
    const isWithoutMd = isMdIconCondition && width < config.minWidthForMdIcon;

    return {
      channel,
      isRestrictedByAdult,
      isFutureDate,
      isSeriesRecord,
      isMdIconCondition,
      iconMDUrl,
      duration,
      width,
      time,
      isCatchUp,
      isCatchUpWithNoData,
      isRestrictedCatchup,
      isRecordBtnCondition,
      isLive,
      isLoading,
      isDescriptionHidden,
      epgParams,
      widthForTimeEllipsis,
      isOnlyMd,
      isWithoutMd,
      isAdultCathUpPlaybackEnabled,
    };
  }, [record, seriesRecord, isRestrictedByAdult]);
}
