import { appStore } from '@kaltura-ott/tvpil-shared';

import { isSafari, playoutStoreDataResolver } from 'utils';

import { PLAYER_SESSION_STORAGE_SUBTITLE_KEY } from '../../constants';
import { playerStorage } from '../../storages/PlayerStorage';
import { getKS } from '../../utils/getKS/getKS';
import { ADAPTER_DATA, ContextType, DRM_SUPPORTED, StreamFormat, SUBTITLE_OFF, VOD_SUBTITLES_STYLE } from '../consts';

export async function mediaRequest(
  assetId: number,
  contextType = ContextType.PLAYBACK,
  mediaType = 'media',
  assetReferenceType = 'media',
  config?: any,
): Promise<any> {
  const { player } = playerStorage.getPlayer();

  if (!player) return;

  const { epgId, id, isVod } = playoutStoreDataResolver();
  player.isMediaLoaded = false;

  // TODO: fix for correctly set entryId by player
  player.reset();

  let isDrmSupportedLocal = sessionStorage.getItem(DRM_SUPPORTED);

  if (!isDrmSupportedLocal) {
    const { isDRMSupported } = await player.getMediaCapabilities(); // getMediaCapabilities returnes isDRMSupported = 1 or 0

    isDrmSupportedLocal = isDRMSupported.toString();
    sessionStorage.setItem(DRM_SUPPORTED, isDRMSupported.toString());
  }

  const { isDrmDeny, drmCapable } = appStore?.rootStore?.playerStore || {};
  const isDrmCapable = !!(isDrmSupportedLocal === '1' && drmCapable && !isDrmDeny);

  const adapterData = {
    codec: {
      value: ADAPTER_DATA.CODEC.AVC,
    },
    DRM: {
      value: String(isDrmCapable),
    },
    quality: {
      value: ADAPTER_DATA.QUALITY.UHD,
    },
  };

  let programId;

  if (contextType === ContextType.PLAYBACK && mediaType === 'media') {
    programId = id;
  } else {
    programId = epgId;
  }

  const playerConfig = {
    plugins: { ottAnalytics: { entryId: assetId, experimentalEnableLiveMediaHit: true } },
  } as any;
  const storagePlayerSubKey = sessionStorage.getItem(PLAYER_SESSION_STORAGE_SUBTITLE_KEY);

  if (!isVod && isSafari()) {
    playerConfig.playback = { textLanguage: SUBTITLE_OFF };
  } else if (storagePlayerSubKey) {
    playerConfig.playback = { textLanguage: storagePlayerSubKey };
  }

  const textConfig = {
    useNativeTextTrack: true,
    useShakaTextTrackDisplay: true,
  } as any;

  if (isVod) {
    textConfig.useNativeTextTrack = false;
    textConfig.useShakaTextTrackDisplay = false;
    textConfig.textStyle = VOD_SUBTITLES_STYLE;
  }

  playerConfig.text = textConfig;
  player.configure(playerConfig);

  let formats;

  if (!isSafari()) {
    formats = isVod ? appStore.dms.filesFormat.VOD[0] : appStore.dms.filesFormat.LIVE[0];
  }

  const streamerType = isSafari() ? StreamFormat.HLS : StreamFormat.DASH;
  const response = await player.loadMedia(
    {
      entryId: assetId,
      ks: getKS(),
      urlType: 'DIRECT',
      streamerType,
      contextType,
      mediaType,
      assetReferenceType,
      adapterData,
      formats,
    },
    { ...config, metadata: { epgId: programId } },
  );

  player.isMediaLoaded = true;

  return response;
}
