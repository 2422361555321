import { appStore } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { playerStorage, startOverInfoStorage } from '../../storages';
import { unpause } from '../../utils/unpause/unpause';

export async function handleSwitchToLive() {
  const { player } = playerStorage.getPlayer();
  const playerService = playerServiceManagement.getPlayerService();
  const { currentAsset, type, endDate } = playoutStoreDataResolver();
  const { isStartOver } = startOverInfoStorage.getStartOver();
  // @ts-ignore
  const id = currentAsset?.linearId;

  if (id) {
    if (type === PlayoutStoreV2Type.LIVE) {
      // for seeking to live without loadmedia or swithing to actual live
      if (Date.now() < endDate && player?.paused) {
        player.seekToLiveEdge();
      } else if (isStartOver) {
        await playerService.loadMedia(id);
      }
      unpause();
    } else {
      playerStorage.setIsPlayBackPaused(false);
      await appStore?.playoutStoreV2.init({
        id,
        type: PlayoutStoreV2Type.LIVE,
        autoplayNextProgram: false,
      });
    }
  }

  startOverInfoStorage.setStartOver(false, false);
}
