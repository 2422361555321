import { useEffect } from 'react';

import { MS_IN_SEC } from 'consts';
import { globalStorage } from 'services/globalStorage';
import { awake } from 'utils';

export const useAppAwakened = () => {
  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    const clearInterval = awake(() => {
      globalStorage.setIsAwakened(true);

      timeout = setTimeout(() => {
        globalStorage.clearIsAwakened();
      }, MS_IN_SEC);
    });

    return () => {
      clearInterval();
      clearTimeout(timeout);
    };
  }, []);
};
