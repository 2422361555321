import { matchPath } from 'react-router';

function useGridMatchPath(path: string) {
  const { pathname } = window.location;
  const { id: railId, type } = matchPath({ path, end: false }, pathname)?.params || {};

  return { railId, type };
}

export { useGridMatchPath };
