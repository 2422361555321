import { MosaicChannelsInfo } from '@kaltura-ott/tvpil';

import { playerStorage } from 'components/widgets/Player/storages';

export type MosaicInfoType = {
  isMosaicEvent?: '0' | '1';
  mosaicChannelsInfo?: MosaicChannelsInfo[];
  type?: 'hidden' | 'mosaic' | 'video';
};

export function checkIsMosaicProgram(mosaicInfo?: MosaicInfoType) {
  const isMdUiDisable = playerStorage.getIsMdUiDisable();

  if (isMdUiDisable) return false;

  return mosaicInfo?.isMosaicEvent === '1' && mosaicInfo.type === 'mosaic';
}

export function getMosaicStreamDetails(mosaicInfo?: MosaicInfoType, id?: string) {
  const mosaicChannels = mosaicInfo?.mosaicChannelsInfo;
  const currentStream = mosaicChannels?.find(({ programExternalId }: MosaicChannelsInfo) => id === programExternalId);

  return { streamOrder: currentStream?.order, totalStreams: mosaicChannels?.length };
}
