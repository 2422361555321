import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from '../PinCodeModal.module.scss';

export function DescriptionWithAge({ age }: { age: number }) {
  return (
    <>
      <FormattedMessage
        defaultMessage={`By entering PIN code I confirm my age is ${age}`}
        id='Modals.PinCode.confirmAge'
        values={{
          age: <span className={styles.ageBadge}>{age}+</span>,
        }}
      />
    </>
  );
}
