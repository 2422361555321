import { useEffect } from 'react';
import { getMinutes, millisecondsInHour, minutesInHour } from 'date-fns';
import { EpgParamsType } from 'types';

import { config } from 'components/pages/Epg/config';
import { CHANNEL_COLUMN_WIDTH } from 'components/pages/Epg/constans';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { calculateOffset } from 'components/pages/Epg/utils';
import { useEpgStore } from 'hooks';
import { applyStyle } from 'utils';

interface Props {
  epgRef: React.MutableRefObject<HTMLDivElement | null>;
  refTime: React.MutableRefObject<HTMLDivElement | null>;
  nowLineRef: React.MutableRefObject<HTMLDivElement | null>;
  epgParams: EpgParamsType;
}

export const useScrollTo = ({ refTime, epgRef, nowLineRef, epgParams }: Props) => {
  const { epgStore } = useEpgStore();

  useEffect(() => {
    if (!epgLocalTimeStampStorage.viewTimestamp) return;
    epgLocalTimeStampStorage.setViewTimestampState(epgLocalTimeStampStorage.viewTimestamp);
    // set left position for the NowLine component
    if (nowLineRef?.current) {
      const leftResolve =
        ((epgLocalTimeStampStorage.currentTimestamp - epgLocalTimeStampStorage.viewTimestamp) / millisecondsInHour) *
        config.hourScale;
      // TODO: maybe we could fix it by css
      if (leftResolve < CHANNEL_COLUMN_WIDTH) {
        applyStyle(nowLineRef.current, '--color-line', 'transparent');
      } else {
        applyStyle(nowLineRef.current, '--color-line', '');
      }

      applyStyle(nowLineRef.current, 'left', `${leftResolve}px`);
    }
    // set translate style for the Time component
    if (refTime?.current) {
      const minutesShift = getMinutes(epgLocalTimeStampStorage.viewTimestamp) * (config.hourScale / minutesInHour);

      applyStyle(refTime.current, 'transform', `translate(${-minutesShift}px, 0)`);
    }
    // scroll left for the Grid component
    if (epgRef.current) {
      epgRef.current.scrollTo(calculateOffset(epgLocalTimeStampStorage.viewTimestamp, epgParams), 0);
    }
  }, [epgLocalTimeStampStorage.currentTimestamp, epgLocalTimeStampStorage.viewTimestamp, epgStore.tvChannels]);
};
