import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { useAppStore, useSettingsStore } from 'hooks';
import { APPLICATION_SPINNER_EVENT_NAME, DateFormatStyle, formatDate, userActionEvents } from 'utils';

import Card from '../Card/Card';
import SettingsContentWrapper from '../SettingsContentWrapper/SettingsContentWrapper';
import { findSelfServiceStore } from '../utils';

import SkeletonView from './SkeletonView/SkeletonView';

import styles from './SelfService.module.scss';

function SelfService() {
  const [isLoading, setLoading] = useState(true);
  const {
    appStore: {
      localizationStore: { displayLanguage },
    },
  } = useAppStore();
  const { settingsStore } = useSettingsStore();
  const intl = useIntl();

  const selfServiceStore = findSelfServiceStore(settingsStore.children)!;

  useEffect(() => {
    async function effect() {
      userActionEvents(UserActionType.selfSrv);

      await selfServiceStore.init();

      setLoading(false);
    }

    effect();
  }, []);

  if (isLoading) {
    return <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.SELFSERVICE} component={<SkeletonView />} />;
  }

  if (!selfServiceStore.purchaseHistory) return null;

  return (
    <SettingsContentWrapper
      title={intl.formatMessage({
        id: 'Settings.SelfService.SettingsContentWrapper.title',
        defaultMessage: 'Self Service',
      })}
    >
      <ul className={styles.links}>
        {selfServiceStore?.links?.map(({ link, name, description }) => (
          <li key={link}>
            <Card blank link={link} name={name} title={description} />
          </li>
        ))}
      </ul>

      <h2 className={styles.subtitle}>
        <FormattedMessage id='Settings.SelfService.subTitle' defaultMessage='Purchase history' />
      </h2>
      <div className={styles.history}>
        {selfServiceStore?.purchaseHistory?.map(({ purchasedItemName, actionDate }) => (
          <Card
            key={actionDate}
            name={purchasedItemName}
            text={formatDate(actionDate, DateFormatStyle.WithFullYear, displayLanguage)}
          />
        ))}
        {!selfServiceStore.purchaseHistory.length && (
          <p>
            <FormattedMessage id='Settings.SelfService.noHistory' defaultMessage='No history' />
          </p>
        )}
      </div>
    </SettingsContentWrapper>
  );
}

export default observer(SelfService);
