import React from 'react';

import { Channel } from '@kaltura-ott/tvpil-shared';

import { RailCardSkeleton } from 'components/widgets/RailV2/components/RailSkeleton/RailSkeleton';

import HeroCard from '../HeroCard/HeroCard';

interface Props {
  channel: Channel;
  itemWidth: number;
  imageHeight: number;
  timestamp: number;
}

function HeroCardContainer({ channel, itemWidth, imageHeight, timestamp }: Props) {
  if (!channel?.id) {
    return <RailCardSkeleton height={imageHeight} width={itemWidth} />;
  }
  return <HeroCard imageHeight={imageHeight} itemWidth={itemWidth} channel={channel} timestamp={timestamp} />;
}

export default React.memo(HeroCardContainer);
