import { useEffect } from 'react';

import { DefaultError } from 'components/widgets/Modal/constants';
import { useParentalPinStore } from 'hooks/common/useParentalPinStore';

export const useConfirmationErrorMsg = (
  isConfirmErrorMessage: boolean,
  setError: React.Dispatch<
    React.SetStateAction<{
      errorMessage: string;
      errorCode: string;
    }>
  >,
) => {
  const parentalPinStore = useParentalPinStore();

  useEffect(() => {
    if (isConfirmErrorMessage) {
      setError({ ...DefaultError });
    }

    parentalPinStore.initModal();
  }, []); // eslint-disable-line
};
