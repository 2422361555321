import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import checkIcon from 'assets/images/check.svg';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';

import styles from './Stream.module.scss';

interface Props {
  index: number;
  isActive: boolean;
  isShowingPlayIcon: boolean;
  onClick: (index: number) => () => void;
  order: number;
  title: string;
  totalStreamsNum: number;
  translationId: string;
}

export const Stream = ({
  index,
  isActive,
  isShowingPlayIcon,
  onClick,
  order,
  title,
  totalStreamsNum,
  translationId,
}: Props) => {
  return (
    <li>
      <button type='button' className={styles.stream} onClick={onClick(index)}>
        {isActive && <SVG className={styles.checkIcon} src={checkIcon} />}
        {isShowingPlayIcon && <PlayButton className={styles.play} />}
        <span className={styles.title}>{title}</span>&nbsp;
        <FormattedMessage
          defaultMessage={`(Stream ${order}/${totalStreamsNum})`}
          id={`${translationId}.streamNumber`}
          tagName='span'
          values={{ number: order, total: totalStreamsNum }}
        />
      </button>
    </li>
  );
};
