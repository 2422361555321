export function throttle(fn: any, wait: number) {
  let time = Date.now();

  return (event: Event) => {
    if (time + wait - Date.now() < 0) {
      fn(event);
      time = Date.now();
    }
  };
}
