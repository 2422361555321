import React, { useCallback } from 'react';

import { MediaType, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { RhinoEPGProgram, RhinoProgram, useLinears } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useParentalPinStore, useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { onMoveToPurchase } from 'utils';

import { getUserActionEventMediaType } from '../../utils';
import { useOpenDetailsPage } from '../useOpenDetailsPage/useOpenDetailsPage';

interface Props {
  isLoading: boolean;
  program: RhinoEPGProgram;
  isLive: boolean;
  isNotFound: boolean | undefined;
  isRestrictedByAdult: boolean | undefined;
  isFutureDate: boolean;
  isAdultCatchUp: boolean;
  isAdultCathUpPlaybackEnabled: boolean;
  mosaicExternalId: string | undefined;
}

export function useClickHandler({
  isLive,
  program,
  isLoading,
  isNotFound,
  isFutureDate,
  isAdultCatchUp,
  mosaicExternalId,
  isRestrictedByAdult,
  isAdultCathUpPlaybackEnabled,
}: Props) {
  const parentalPinStore = useParentalPinStore();
  const { settingsStore } = useSettingsStore();
  const { navigate, location } = useStableReactRouter();
  const modalContext = useModalContext();
  const { linears } = useLinears();
  const channel = linears.get((program as RhinoProgram).channelId);
  const { isPurchasable } = channel || {};
  const mediaType = (program as RhinoProgram).type || MediaType.Program.toLowerCase();
  const { mediaType: mediaTypeForUserEvent } = getUserActionEventMediaType(isLive, isFutureDate);
  const openDetailsPage = useOpenDetailsPage({ mediaType, mediaTypeForUserEvent, program, navigate, mosaicExternalId });

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isLoading) {
        return;
      }

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      // get parental from channel if not found program
      const parentalRating = isNotFound && channel ? channel.parentalRating : (program as RhinoProgram).parentalRating;
      const linearId = (program as RhinoProgram).channelId;
      const isLiveNotFoundProgram = !!(isLive && linearId && isNotFound);
      const isLiveNotFoundParental =
        isLiveNotFoundProgram &&
        parentalRating !== undefined &&
        parentalPinStore.isBlockedByParentalRating(+parentalRating);
      const isDisabledClick = (isNotFound || isAdultCatchUp) && !isLive && !isAdultCathUpPlaybackEnabled;

      if (e.button) return;

      if (isDisabledClick) {
        return;
      }

      if (isRestrictedByAdult) {
        modalContext.openModal(
          <PinCodeModal
            asset={program}
            context={UIDAPresentationTypeEnum.epg}
            type={PinCodeModalTypes.RestrictedContent}
            validatePin={parentalPinStore.validateAdultPin}
          />,
        );
        return;
      }

      if (isPurchasable) {
        if (isNotFound && linearId) {
          onMoveToPurchase({
            settingsStore,
            navigate,
            linearId,
            redirectLink: routes.live.link(linearId, mediaType),
            goBackUrl: location.pathname,
          });
        } else {
          openDetailsPage();
        }
        return;
      }

      // check parental before move to player
      if (isLiveNotFoundParental) {
        modalContext.openModal(
          <PinCodeModal
            callback={(success: boolean) => {
              if (success) {
                clickHandler(e);
              }
            }}
            asset={program}
            context={UIDAPresentationTypeEnum.epg}
            description={parentalRating ? <DescriptionWithAge age={+parentalRating} /> : undefined}
            type={PinCodeModalTypes.ParentalRhino}
            rhinoParentalRating={+parentalRating}
          />,
        );
        return;
      }

      if (isLiveNotFoundProgram) {
        navigate(routes.live.link(linearId!, mediaType), {
          state: {
            goBackUrl: location.pathname,
          },
        });
        return;
      }

      openDetailsPage();
    },
    [
      isLoading,
      program,
      isLive,
      isNotFound,
      isRestrictedByAdult,
      isPurchasable,
      parentalPinStore,
      modalContext,
      settingsStore,
      mediaType,
    ],
  );

  return clickHandler;
}
