import { OverlayAction, PlaybackOverlayEventArgs } from '@kaltura-ott/tvpil-analytics';
import { appStore } from '@kaltura-ott/tvpil-shared';

import { DataAnalyticsType, PlaybackBaseEvent } from './PlaybackBaseEvent';

export class PlaybackOverlayEvent extends PlaybackBaseEvent {
  readonly overlayAction: OverlayAction;

  readonly errorId: string | undefined;

  constructor(data: DataAnalyticsType, action: OverlayAction, errorId?: number) {
    super(data);
    this.overlayAction = action;
    this.errorId = errorId?.toString();
  }

  getParam(overlayAction: OverlayAction) {
    const tracks = this.getActiveTracks();
    let languageId;
    let errorCode;

    if (overlayAction === OverlayAction.audioChange) {
      languageId = tracks.audio?.language;
    }
    if (overlayAction === OverlayAction.subtitleChange) {
      languageId = tracks.text?.language;
    }
    if (overlayAction === OverlayAction.reachedConcurrencyHhLimit) {
      errorCode = this.errorId?.toString();
    }

    return { languageId, errorCode };
  }

  getPlaybackOverlayData() {
    this.getPlaybackBaseEventData();

    const { languageId, errorCode } = this.getParam(this.overlayAction);
    const playbackOverlayEventData = {
      overlayAction: this.overlayAction,
      languageId,
      errorCode,
      ...this.playbackBaseEventData,
    } as PlaybackOverlayEventArgs;

    return playbackOverlayEventData;
  }

  async sendEvent() {
    const playbackOverlayEventData = this.getPlaybackOverlayData();
    if (playbackOverlayEventData.epgId) {
      await appStore.kuxAnalytics.send(new PlaybackOverlayEventArgs(playbackOverlayEventData));
    }
  }
}
