import React, { createContext, useContext, useMemo } from 'react';

import { SeriesAdditionalRailStoreV2 } from '@kaltura-ott/tvpil-shared';
import { RecordAdditionalRailStoreV2 } from '@kaltura-ott/tvpil-shared/lib/stores/category/widgets/rails/record-rail/record-rail-additional-store';

interface Props {
  children: React.ReactNode;
  episodesStore: SeriesAdditionalRailStoreV2 | RecordAdditionalRailStoreV2 | undefined;
}

export interface ContextType {
  episodesStore: SeriesAdditionalRailStoreV2 | RecordAdditionalRailStoreV2 | undefined;
}

const EpisodesContext = createContext<ContextType>({
  episodesStore: undefined,
});

const EpisodesContextProvider = ({ children, episodesStore }: Props) => {
  const contextValue = useMemo(() => {
    return { episodesStore };
  }, [episodesStore]);

  return <EpisodesContext.Provider value={contextValue}>{children}</EpisodesContext.Provider>;
};

const useEpisodesContext = () => useContext(EpisodesContext);

export { EpisodesContextProvider, useEpisodesContext };
