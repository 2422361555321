import { action, observable } from 'mobx';

interface Settings {
  timerInProgress?: boolean;
  watchCredits?: boolean;
  watchNow?: boolean;
}

export const DEFAULT_SETTINGS = {
  timerInProgress: false,
  watchCredits: false,
  watchNow: false,
};

class BingeWatchingStorage {
  @observable settings: Settings = { ...DEFAULT_SETTINGS };

  @action
  setSettings(settings: Settings) {
    this.settings = {
      ...DEFAULT_SETTINGS,
      ...settings,
    };
  }

  @action
  resetSettings() {
    this.settings = {
      ...DEFAULT_SETTINGS,
    };
  }
}

const bingeWatchingStorage = new BingeWatchingStorage();

export { bingeWatchingStorage };
