import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import LocalTime from 'components/presenters/LocalTime/LocalTime';

import styles from './NowLineTime.module.scss';

function NowLineTime() {
  return (
    <>
      <div className={styles.time}>
        <FormattedMessage defaultMessage='LIVE' id='Epg.Time.NowLine.text' />{' '}
        <LocalTime date={epgLocalTimeStampStorage.currentTimestamp} />
      </div>
    </>
  );
}

export default observer(NowLineTime);
