import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { appStore, CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { LAST_SCROLL_Y_POSITION, WARNING_ICON } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useToastMessageContext } from 'context/toast/toast';
import { useIsUrlToFullPlayer, useParentalPinStore, useSettingsStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { getWarningIcon, resolvePathToDetailsPage, userActionEvents } from 'utils';

interface Props {
  data: CollectionAsset;
  link: string;
  mediaTypeParam: string;
  imageHeight: number;
  isPurchasable: boolean;
  mosaicExternalId?: string;
  isChannelHasNoProgram: boolean;
  isLinkAsAsset: boolean;
  isValidLinkAsAsset: boolean;
}

export const useClickHandler = ({
  data,
  link,
  mediaTypeParam,
  imageHeight,
  isPurchasable,
  isChannelHasNoProgram,
  mosaicExternalId,
  isLinkAsAsset,
  isValidLinkAsAsset,
}: Props) => {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();
  const { onCardClick } = useRailContext();
  const { navigate } = useStableReactRouter();
  const { settingsStore } = useSettingsStore();
  const parentalPinStore = useParentalPinStore();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(link);
  const modalContext = useModalContext();
  // needs for parental rating check in case of url to full player and parental rating is blocked
  const parentalRating = Number(data?.parentalRating);
  const warningIcon = `${appStore.staticAssetsUrl}${getWarningIcon(WARNING_ICON)}`;

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isChannelHasNoProgram) {
        e.preventDefault();

        return;
      }

      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      if (onCardClick) {
        onCardClick(data?.id!);
      }

      if (isLinkAsAsset) {
        if (!isValidLinkAsAsset) {
          toastMessageContext.showToast({
            title: intl.formatMessage({
              id: `AppErrorReaction.mediumError`,
              defaultMessage: 'Sorry, this action cannot be completed.',
            }),
            icon: warningIcon,
          });

          return;
        }
      }

      if (isPurchasable) {
        e.preventDefault();

        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);

        navigate(
          resolvePathToDetailsPage({
            id: data?.id!,
            mediaTypeParam: mediaTypeParam.toLowerCase(),
            externalId: mosaicExternalId ?? '',
          }),
          {
            state: {
              lastScrollYPosition: window.scrollY + imageHeight,
            },
          },
        );
        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockedByParentalRating(parentalRating!)) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      if (link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link.includes('details')) {
        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);
      }
    },
    [modalContext, link, parentalRating, settingsStore],
  );
};
