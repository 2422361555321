import { useEffect } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import { BACK_NAVIGATION_TRY, LAST_URL, PREV_LOCATION_KEY } from 'consts';
import routes from 'routesPaths';

import { useDetectLocation } from './useDetectLocation';

export function useDetailsPageBackNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const navType = useNavigationType();
  const { isDetailsPage } = useDetectLocation();

  useEffect(() => {
    const backNavTry = Number(sessionStorage.getItem(BACK_NAVIGATION_TRY));
    const prevLocationKey = sessionStorage.getItem(PREV_LOCATION_KEY);
    const currentLocationKey = location.key;

    if (navType === 'POP' && prevLocationKey !== currentLocationKey) {
      switch (backNavTry) {
        case 2:
          if (isDetailsPage) {
            // first back navigation try to details page
            sessionStorage.setItem(BACK_NAVIGATION_TRY, '1');
          } else {
            // first back navigation try to any(not details) page
            sessionStorage.removeItem(BACK_NAVIGATION_TRY);
          }
          break;
        case 1:
          if (isDetailsPage) {
            // second back navigation try to details page
            const prevUrl = sessionStorage.getItem(LAST_URL) || '';
            if (prevUrl) {
              navigate(prevUrl, { replace: true });
            } else {
              navigate(routes.home.path);
            }
            sessionStorage.removeItem(BACK_NAVIGATION_TRY);
          } else {
            // second back navigation try to any(not details) page
            sessionStorage.removeItem(BACK_NAVIGATION_TRY);
          }
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(BACK_NAVIGATION_TRY);
      sessionStorage.removeItem(PREV_LOCATION_KEY);
    };
  }, []);
}
