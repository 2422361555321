import { CollectionGridMediaAsset } from 'types';

import { EntityRating } from '@kaltura-ott/tvpil-shared';

import { useCardRecordInfo } from 'components/widgets/Card/hooks';
import { useCardContext } from 'context/сardContext/сardContext';

function useCardContentCombiner() {
  const {
    data,
    resolvedCardProps: {
      isProgramAsset,
      shouldShowRating,
      isFuture,
      isFutureProgram,
      isLinearAsset,
      isNoData,
      isHero,
      isShowedProgressBar,
      isAsPlayAsset,
      catchUpAirDate,
      isDisplayCartIcon,
      isDisplayContentMarker,
      contentMarker,
      isNewEpisode,
      isRented,
      isContentRestricted,
      isTypeOfSeries,
      isCollectionEpisode,
    },
  } = useCardContext();
  const { isRecordExpires, recordingExpirationInDays } = useCardRecordInfo({
    data,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
  });
  const rating = (data as CollectionGridMediaAsset)?.rating as EntityRating;
  const isShowRating = shouldShowRating && rating?.value;

  return {
    data,
    isProgramAsset,
    isFuture,
    isFutureProgram,
    isLinearAsset,
    isNoData,
    isHero,
    isRecordExpires,
    recordingExpirationInDays,
    isShowedProgressBar,
    isAsPlayAsset,
    catchUpAirDate,
    isDisplayCartIcon,
    isDisplayContentMarker,
    contentMarker,
    isNewEpisode,
    isRented,
    isContentRestricted,
    isShowRating,
    rating,
  };
}

export { useCardContentCombiner };
