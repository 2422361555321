import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { BACK_NAVIGATION_TRY, PREV_LOCATION_KEY } from 'consts';
import { useIsNpvr } from 'hooks';

import detailsStoreDataResolver from '../utils/detailsStoreDataResolver';

export function useDetailsNavigationLogic(asset: ViewableEntitiesType | undefined, isDetailsSeries: boolean) {
  const action = useNavigationType();
  const location = useLocation();
  const isNpvr = useIsNpvr();
  const { seriesId } = detailsStoreDataResolver(asset!);

  useEffect(() => {
    const prevLocationKey = sessionStorage.getItem(PREV_LOCATION_KEY);
    const currentLocationKey = location.key;

    const backNavTry = sessionStorage.getItem(BACK_NAVIGATION_TRY);
    const isDetailsOrContainsEpisodeBtn =
      (action === 'PUSH' && isDetailsSeries) ||
      (action === 'PUSH' && seriesId) ||
      (action === 'POP' && prevLocationKey === currentLocationKey);

    const isSetBackNavTryCondition =
      (seriesId && !backNavTry) || (isDetailsSeries && !backNavTry) || isDetailsOrContainsEpisodeBtn;

    // user go to details series page or page with episode btn for the first time or go to details series page or page with episode btn
    if (isSetBackNavTryCondition && !isNpvr) {
      sessionStorage.setItem(BACK_NAVIGATION_TRY, '2');
      sessionStorage.setItem(PREV_LOCATION_KEY, `${currentLocationKey}`);
    }

    // eslint-disable-next-line
  }, [location, asset]);
}
