import { useState } from 'react';
import { useIntl } from 'react-intl';

export interface Form {
  [index: string]: any;
  email: {
    value: string;
    isFailedValidation: boolean;
    errorMessage: string;
  };
  password: {
    value: string;
    isFailedValidation: boolean;
    errorMessage: string;
  };
}

export interface InputError {
  [index: string]: any;
  email: string;
  password: string;
}

export function useValidationForm() {
  const intl = useIntl();

  const defaultInputError: InputError = {
    email: intl.formatMessage({
      id: 'Errors.Email.notValid',
      defaultMessage: 'Please enter valid email',
    }),
    password: intl.formatMessage({
      id: 'Errors.Password.empty',
      defaultMessage: 'Please enter a password',
    }),
  };

  const [form, setFormState] = useState<Form>({
    email: {
      value: '',
      isFailedValidation: false,
      errorMessage: defaultInputError.email,
    },
    password: {
      value: '',
      isFailedValidation: false,
      errorMessage: defaultInputError.password,
    },
  });

  const setForm = (updatedForm: Form) => {
    setFormState(updatedForm);
  };

  return [form, setForm, defaultInputError] as const;
}
