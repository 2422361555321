import { useMemo } from 'react';
import { useNavigationType } from 'react-router-dom';

import { PresentationAdditionalTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { appStore } from '@kaltura-ott/tvpil-shared';

import { LAST_RAIL_X_POSITION } from 'consts';

export function useRailLastXPosition(
  railId: number,
  subtype: UIDAPresentationTypeEnum | PresentationAdditionalTypeEnum,
) {
  const navigationType = useNavigationType();

  return useMemo(() => {
    let railPosition = 0;
    const lastRailXPosition = sessionStorage.getItem(LAST_RAIL_X_POSITION);

    if (lastRailXPosition && subtype !== UIDAPresentationTypeEnum.cw && navigationType !== 'PUSH') {
      const { id, position, linearId } = JSON.parse(lastRailXPosition);
      const firstItemInHeroRail = appStore.lastWatchedLinear.mainHeroRail?.items[0];

      if (railId === id && !(subtype === UIDAPresentationTypeEnum.hero && firstItemInHeroRail?.linearId === linearId)) {
        railPosition = position;
      }
    }

    return railPosition;
  }, [railId, subtype]);
}
