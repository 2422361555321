import { useEffect } from 'react';

export function useScrollToCenter(blockRef: React.MutableRefObject<HTMLDivElement | null>) {
  useEffect(() => {
    // to center screen element "now"
    if (blockRef.current) {
      const target = Array.from(blockRef.current.children).find(
        ({ attributes }) => attributes.getNamedItem('data-target')?.nodeValue === '0',
      );

      if (target) {
        const scrollMove =
          target.getBoundingClientRect().left + target.clientWidth / 2 - blockRef.current.clientWidth / 2;
        blockRef.current.scrollLeft = scrollMove;
      }
    }
  }, []);
}
