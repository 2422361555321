import { MediaType, UserActionType } from '@kaltura-ott/tvpil-analytics';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver, userActionEvents } from 'utils';

import { contextStorage, overlayStorage, playerStorage } from '../../storages';
import { changeLocalState } from '../../utils/customEvents/customEventChangeLocalState';

export async function handleShowInfoPopup() {
  const { id, type } = playoutStoreDataResolver();
  const { player, assetId } = playerStorage.getPlayer();
  const {
    toastContext: { clearToast },
  } = contextStorage.getContext();
  let idForEvent = id;
  let mediaTypeForUserEvent = MediaType.live;

  if (type === PlayoutStoreV2Type.CATCHUP) {
    mediaTypeForUserEvent = MediaType.catchup;
    idForEvent = assetId;
  } else if (type === PlayoutStoreV2Type.NPVR) {
    mediaTypeForUserEvent = MediaType.recording;
    idForEvent = assetId;
  }

  player.configure({ ui: { disable: true } });
  changeLocalState({ isOpen: true });
  overlayStorage.setShowOverlay(true);
  clearToast();

  userActionEvents(UserActionType.details, idForEvent, mediaTypeForUserEvent);
}
