import React, { useCallback, useMemo, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import { observer } from 'mobx-react';

import { QuotaStatusEnum, SpecialWidgetTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { CollectionRailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useRecordRepository } from 'hooks';

import { RailHorisontalDataProvider } from '../../../components/RailHorisontalDataProvider/RailHorisontalDataProvider';
import { useRailLastXPosition } from '../../../hooks/useRailLastXPosition/useRailLastXPosition';
import { calculateItemHeight, calculateTotalWidth } from '../../../utils';
import RailHorisontalView from '../RailHorisontalView/RailHorisontalView';
import { useRailContext } from '../RailProvider/RailProvider';

import {
  useApplyIncreaseHeightHook,
  useArrowsClickHook,
  useCheckResizeHook,
  useOnCardClickHook,
  useOnHoverHook,
} from './hooks';

const RailHorisontalWrapper = () => {
  const { store } = useRailContext();
  const isLogoRail = useMemo(
    () => (store as CollectionRailStoreV2).specialWidgetType === SpecialWidgetTypeEnum.logo,
    [(store as CollectionRailStoreV2).specialWidgetType], // eslint-disable-line
  ); // collection rail can has specialWidgetType
  const recordRepository = useRecordRepository();
  const { id, items, subtype } = store;
  const startOffset = useRailLastXPosition(id, subtype);
  const offset = useRef(startOffset);
  const headerRef = useRef<HTMLElement | null>(null);
  const railCardsListRef = useRef<List | null>(null);
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const setRailCardsListRefScrollTo = useCallback((value: number) => {
    if (railCardsListRef.current) {
      railCardsListRef.current.scrollTo(value);
      offset.current = value;
    }
  }, []);
  const refElementInner = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const componentHeight = calculateItemHeight(store);
  const totalWidth = calculateTotalWidth(store);
  const handleClickNavigationButton = useArrowsClickHook({
    items,
    totalWidth,
    offset,
    store,
    railCardsListRef,
    setRailCardsListRefScrollTo,
    refElementInner,
  });
  const checkResize = useCheckResizeHook({
    setRailCardsListRefScrollTo,
    refElementInner,
    offset,
    totalWidth,
  });
  const onHover = useOnHoverHook({ headerRef, refElementInner, componentHeight });
  const onCardClick = useOnCardClickHook({ id, offset });
  useApplyIncreaseHeightHook({ refElementInner, componentHeight });

  if (store.subtype === UIDAPresentationTypeEnum.npvr && !isNpvrEnabled) {
    return null;
  }

  return (
    <RailHorisontalDataProvider
      offset={offset.current}
      totalWidth={totalWidth}
      isLogoRail={isLogoRail}
      onCardHover={onHover}
      componentHeight={componentHeight}
      handleClickNavigationButton={handleClickNavigationButton}
      forwardHeaderRef={headerRef}
      forwardWrapperRef={wrapperRef}
      forwardElementInnerRef={refElementInner}
      forwardRailCardsListRef={railCardsListRef}
      onCardClick={onCardClick}
      checkResize={checkResize}
    >
      <RailHorisontalView />
    </RailHorisontalDataProvider>
  );
};

export default observer(RailHorisontalWrapper);
