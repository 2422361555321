import { useCallback } from 'react';
import { Node } from 'types';

import { useRootStore } from 'hooks';
import { applyStyleHexToHSL, applyStyles } from 'utils';

export const useHandleRef = () => {
  const rootStore = useRootStore();
  const { search } = rootStore!;

  return useCallback((node: Node) => {
    if (search) {
      const { uiDesignElements } = search;

      if (uiDesignElements && node !== null) {
        applyStyles(node, uiDesignElements);
        applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
      }
    }
  }, []);
};
