import React from 'react';
import { observer } from 'mobx-react';

import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import VideoPlayout from 'components/widgets/VideoPlayout/VideoPlayout';
import VideoPlayoutV2 from 'components/widgets/VideoPlayoutV2/VideoPlayoutV2';
import { FEATURES } from 'consts';
import { globalStorage } from 'services/globalStorage';

function MaxiPlayerViewResolver({ pageReferenceId }: { pageReferenceId: string }) {
  const isEnabledHeroRail = featureEnabler.variation(FEATURES.NEW_HERO_RAIL_ENABLED, false);

  if (globalStorage.isDisableMaxiPlayer) {
    return null;
  }

  return <>{isEnabledHeroRail ? <VideoPlayoutV2 pageReferenceId={pageReferenceId} /> : <VideoPlayout />}</>;
}

export default observer(MaxiPlayerViewResolver);
