import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { ToastMessage } from '@kaltura-ott/tvpil-shared';

import { LAST_CHANGED_LANGUAGE } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { ToastProps, useToastMessageContext } from 'context/toast/toast';
import { ApplicationError } from 'interface';
import routes from 'routesPaths';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

import { useToastStore } from './useToastStore';

export const settingTypes = {
  languages: 'Languages',
  deviceList: 'DeviceList',
  videoSettings: 'VideoSettings',
  parentalControl: 'ParentalControl',
};

export const useToastMessage = (settingType: string) => {
  const intl = useIntl();
  const { location } = useStableReactRouter();
  const toastStore = useToastStore();
  const list = toastStore.list as ToastMessage[];
  const toastMessageContext = useToastMessageContext();

  useEffect(() => {
    if (list.length) {
      let index = 0;
      let toast;
      if (list.length > 1) {
        index = 1;
      }
      const toastType = list[index].status ? { success: true } : { error: true };
      let context;
      let message = list[index].label;
      let additionalInfo = ApplicationError.Household;

      if (settingType === settingTypes.languages) {
        message = sessionStorage.getItem(LAST_CHANGED_LANGUAGE)!;
        context = UIDAPresentationTypeEnum.language;
      } else if (settingType === settingTypes.deviceList) {
        context = UIDAPresentationTypeEnum.devices;
      } else if (settingType === settingTypes.videoSettings) {
        context = UIDAPresentationTypeEnum.preferred_video_quality;
      } else if (settingType === settingTypes.parentalControl) {
        context = UIDAPresentationTypeEnum.parental_control;
      }
      // TODO: reduce this switch
      switch (settingType) {
        case settingTypes.languages: {
          const lastChangedLanguage = sessionStorage.getItem(LAST_CHANGED_LANGUAGE);
          if (list[index].status) {
            toast = {
              ...toastType,
              title: intl.formatMessage(
                {
                  defaultMessage: '{type} Language saved successfully',
                  id: `Settings.Language.${list[index].label}_success`,
                },
                { type: lastChangedLanguage },
              ),
            };
          } else {
            toast = {
              ...toastType,
              title: intl.formatMessage(
                {
                  defaultMessage: "{type} Language wasn't saved",
                  id: `Settings.Language.${list[index].label}_error`,
                },
                { type: lastChangedLanguage },
              ),
            };
          }
          break;
        }
        case settingTypes.deviceList: {
          if (list[index].status) {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: 'Device deleted successfully',
                id: `Settings.DeviceList.${list[index].label}_success`,
              }),
            };
          } else {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: "Device wasn't deleted",
                id: `Settings.DeviceList.${list[index].label}_error`,
              }),
            };
          }
          break;
        }
        case settingTypes.videoSettings: {
          if (list[index].status) {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: 'Video settings changed successfully',
                id: `Settings.VideoSettings.${list[index].label}_success`,
              }),
            };
          } else {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: "Video settings wasn't changed",
                id: `Settings.VideoSettings.${list[index].label}_error`,
              }),
            };
          }
          break;
        }
        case settingTypes.parentalControl: {
          if (list[index].status) {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: 'Parental control settings saved successfully',
                id: `Settings.ParentalControl.${list[index].label}_success`,
              }),
            };
          } else {
            toast = {
              ...toastType,
              title: intl.formatMessage({
                defaultMessage: "Parental control settings wasn't saved",
                id: `Settings.ParentalControl.${list[index].label}_error`,
              }),
            };
          }
          break;
        }
        default:
          break;
      }

      if (location.pathname !== routes.household.path) {
        additionalInfo = ApplicationError.Settings;

        toastMessageContext.clearToast();
        toastMessageContext.showToast(toast as ToastProps);
      }
      if (toastType.error) {
        sendApplicationErrorEvent({
          context: context as UIDAPresentationTypeEnum,
          message,
          additionalInfo,
        });
      }
    }
  }, [list.length]); // eslint-disable-line

  useEffect(() => {
    return () => {
      toastMessageContext.clearToast();
    };
  }, []); // eslint-disable-line
};
