import React from 'react';
import cn from 'classnames';

import { Footer } from 'components/widgets/Footer/Footer';

import styles from './MainLayout.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  pageId?: string;
  forwardRef?: any;
}

const MainLayout: React.FC<Props> = ({ children, className, pageId = '', forwardRef }: Props) => {
  return (
    <div ref={forwardRef} className={cn(styles.mainLayout, className)}>
      {children}
      <Footer pageId={pageId} />
    </div>
  );
};

export default MainLayout;
