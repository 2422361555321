import { useCallback, useEffect, useState } from 'react';

import { config } from 'components/pages/EpgV2/config';
import { MARGIN_BOTTOM } from 'components/pages/EpgV2/constans';

function usePrrogramsLineCombiner() {
  const [visible, setVissible] = useState(false);
  const channelObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        setVissible(entry.isIntersecting);
      });
    },
    {
      rootMargin: `${(config.channelLineHeight + MARGIN_BOTTOM) * 2}px 0px`,
    },
  );

  const ref = useCallback((node: HTMLLIElement) => {
    if (node) {
      channelObserver.observe(node);
    }
  }, []);

  useEffect(() => {
    return () => {
      channelObserver.disconnect();
    };
  }, []);

  return { visible, ref };
}

export { usePrrogramsLineCombiner };
