import { EntityRating, useRatingProviders } from '@kaltura-ott/tvpil-shared';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

interface Props {
  rentalItem: RentalAsset;
  shouldShowRating: boolean | undefined;
}

export const useRentalCardRating = ({ rentalItem, shouldShowRating }: Props) => {
  const { ratingProviders } = useRatingProviders();
  const ratingName = rentalItem.rating?.name;
  const assetRating = ratingName
    ? ({ ...ratingProviders.get(ratingName), value: rentalItem.rating?.value } as EntityRating)
    : undefined;
  const isDisplayRating = !!(shouldShowRating && assetRating);

  return { isDisplayRating, assetRating };
};
