import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

export async function preloadAssetsImage(
  cleanupFunction: React.MutableRefObject<boolean>,
  setImagesLoading: Function,
  store: RailStoreV2,
) {
  const promises: Promise<void>[] = [];

  store.items.forEach((item) => {
    const image = store.itemImage(item.data);
    if (image?.imageUrl) {
      promises.push(
        new Promise((resolve) => {
          const img = new Image();

          img.src = image.imageUrl!;
          img.onload = resolve as any;
          img.onerror = resolve as any;
        }),
      );
    }
  });

  if (!cleanupFunction.current) {
    setImagesLoading(true);
  }

  await Promise.all(promises);

  if (!cleanupFunction.current) {
    setImagesLoading(false);
  }
}
