import { useIntl } from 'react-intl';

import { Subscription } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/Settings/Subscriptions/constants';
import { findSubscriptionStore } from 'components/pages/Settings/utils';
import { useSettingsStore } from 'hooks/common/useSettingsStore';

function useTariffPlansData() {
  const { formatMessage } = useIntl();
  const { settingsStore } = useSettingsStore();
  const subscriptionStore = findSubscriptionStore(settingsStore.children)!;

  const { externalPortalUrl } = subscriptionStore.subpageProperty;
  const plans = subscriptionStore?.plans.length
    ? subscriptionStore?.plans
    : [
        {
          packageData: {
            id: 0,
            title: formatMessage({
              id: `${COMPONENT_NAME}.noPlan.title`,
              defaultMessage: 'No Plan',
            }),
          },
        } as Subscription,
      ];

  const buttonParamsText = subscriptionStore?.plans.length
    ? formatMessage({ id: `${COMPONENT_NAME}.cardButtonUpgradePlan`, defaultMessage: 'Change plan' })
    : formatMessage({ id: `${COMPONENT_NAME}.noPlan.changePlan`, defaultMessage: 'Change plan' });

  return {
    externalPortalUrl,
    buttonParamsText,
    plans,
  };
}

export { useTariffPlansData };
