import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { appStore } from '@kaltura-ott/tvpil-shared';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import ExpandedErrorLogModal from 'components/widgets/Modal/ExpandedErrorLogModal/ExpandedErrorLogModal';
import { NETWORK_ERROR, NETWORK_ERROR_FOR_DISPLAY, NETWORK_ERROR_GEO } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { ActionButtonTypesEnum } from 'interface';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { checkExtendedErrorLogActivity } from './helpers/checkExtendedErrorLogActivity';
import { ContextType, PropsValue } from './types';

import styles from './error.module.scss';

const defaultProps = {
  error: { titleId: '', messageId: '', code: 0, titleDefault: '', messageDefault: '' },
  retryButton: {
    onRetry: () => {},
    translationId: '',
    defaultMessage: '',
  },
  goBackButton: {
    goBackLink: undefined,
    translationId: '',
    defaultMessage: '',
  },
  onClear: () => {},
  playerErrorPayload: {},
};

const ErrorScreenContext = createContext<ContextType>({
  setError: () => {},
  clearError: () => {},
  stopLoadingError: () => {},
});

interface Props {
  children: React.ReactNode;
  apiErrorRetrying?: boolean;
}

const ErrorScreenProvider = ({ children, apiErrorRetrying }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorValue, setErrorValue] = useState<PropsValue>(defaultProps);
  const [isPendingRetry, setIsPendingRetry] = useState(false);
  const [isPendingBack, setIsPendingBack] = useState(false);

  const setError = useCallback(
    ({ goBackButton, retryButton, error, onClear, isErrorFromTvpil, playerErrorPayload }: PropsValue) => {
      setErrorValue({ goBackButton, retryButton, error, onClear, isErrorFromTvpil, playerErrorPayload });
    },
    [],
  );

  const clearError = useCallback(() => {
    setErrorValue(defaultProps);
    setIsPendingBack(false);
    setIsPendingRetry(false);
  }, []);

  const stopLoadingError = useCallback(() => {
    setIsPendingBack(false);
    setIsPendingRetry(false);
  }, []);

  const {
    error: { titleId, messageId, code, icon, titleDefault, messageDefault },
    retryButton: { onRetry, translationId: retryTranslationId, defaultMessage: retryDefaultMessage } = {},
    goBackButton: { goBackLink, translationId: goBackTranslationId, defaultMessage: gaBackDefaultMessage } = {},
    onClear,
    isErrorFromTvpil,
  } = errorValue;

  const modalContext = useModalContext();

  const isError = !!(titleId || code);
  const isErrorCode = code && code !== NETWORK_ERROR_GEO;
  const isShowChild = (isError && globalStorage.isPlayerError) || !isError;
  const isShowingMessage = !!(messageId || code);
  const isRetry = isPendingRetry || apiErrorRetrying;
  const isDisabled = isRetry || isPendingBack;
  const isExtendedErrorLogActive = checkExtendedErrorLogActivity();

  // Сlearing the error on changing routing
  useEffect(() => {
    if (isError) {
      if (onClear) {
        onClear();
      }

      clearError();
    }
  }, [location.pathname]);

  const contextValue = useMemo(() => {
    return { setError, clearError, stopLoadingError };
  }, [setError, clearError, stopLoadingError]);

  const resolveBack = useCallback(() => {
    if (goBackLink) {
      setIsPendingBack(true);
      goBackLink();
    } else {
      navigate(routes.home.path);
    }
  }, [goBackLink, navigate]);

  const handleRetry = useCallback(async () => {
    if (onRetry) {
      setIsPendingRetry(true);
      await onRetry();

      if (!appStore.apiError && isErrorFromTvpil) {
        clearError();
      }
    } else {
      window.location.reload();
    }
  }, [clearError, isErrorFromTvpil, onRetry]);

  const handleOpenModal = () => {
    modalContext.openModal(<ExpandedErrorLogModal errorData={errorValue} />);
  };

  return (
    <ErrorScreenContext.Provider value={contextValue}>
      {isError && (
        <div className={cn(styles.wrapper, styles[`${icon}`])}>
          <FormattedMessage defaultMessage={titleDefault} id={titleId || `Errors.Api.${code}`} tagName='h3' />
          {isShowingMessage && (
            <FormattedMessage
              defaultMessage={messageDefault}
              id={messageId || `Errors.Api.${code}.description`}
              tagName='p'
              values={{ br: <br /> }}
            />
          )}
          {retryTranslationId && (
            <ActionButton
              isPending={isRetry}
              onClick={handleRetry}
              type={ActionButtonTypesEnum.primary}
              disabled={isDisabled}
            >
              <FormattedMessage defaultMessage={retryDefaultMessage} id={retryTranslationId} />
            </ActionButton>
          )}
          {goBackTranslationId && (
            <ActionButton isPending={isPendingBack} onClick={resolveBack} disabled={isDisabled}>
              <FormattedMessage defaultMessage={gaBackDefaultMessage} id={goBackTranslationId} />
            </ActionButton>
          )}

          {isExtendedErrorLogActive && (
            <ActionButton onClick={handleOpenModal}>
              <FormattedMessage defaultMessage='Expand log' id='expandLogId' />
            </ActionButton>
          )}

          {isErrorCode && (
            <span className={styles.code}>
              {code === NETWORK_ERROR ? (
                <FormattedMessage
                  defaultMessage={`Error Code: ${NETWORK_ERROR_FOR_DISPLAY}`}
                  id='ApiError.errorCode.networkError'
                />
              ) : (
                <FormattedMessage defaultMessage='Error Code: {code}' id='ApiError.errorCode' values={{ code }} />
              )}
            </span>
          )}
        </div>
      )}
      {isShowChild && children}
    </ErrorScreenContext.Provider>
  );
};
// TODO In the future better to use in components useErrorScreenContext instead ErrorScreenContext
const useErrorScreenContext = () => useContext(ErrorScreenContext);

export { ErrorScreenContext, ErrorScreenProvider, useErrorScreenContext };
