import React from 'react';

import { createSkeletonCollection } from 'utils';

import styles from './SkeletonView.module.scss';

const SKELETON_ITEMS_NUMBER = 5;
const BLOCK_SKELETON_ITEMS_PREFIX = 'block-skeleton';
const HISTORY_BLOCK_SKELETON_ITEMS_PREFIX = 'history-block-skeleton';

function SkeletonView() {
  const blocks = createSkeletonCollection(SKELETON_ITEMS_NUMBER, BLOCK_SKELETON_ITEMS_PREFIX);
  const historyBlocks = createSkeletonCollection(SKELETON_ITEMS_NUMBER, HISTORY_BLOCK_SKELETON_ITEMS_PREFIX);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title} />
      {blocks.map((block) => (
        <div key={block.id} className={styles.block} />
      ))}
      <div className={styles.subtitle} />
      {historyBlocks.map((block) => (
        <div key={block.id} className={styles.historyBlock} />
      ))}
    </div>
  );
}
export default SkeletonView;
