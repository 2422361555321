import { action, observable } from 'mobx';

class OverlayStorage {
  @observable showOverlay: boolean = false;

  @observable showErrorOverlay = false;

  @action
  setShowOverlay(value: boolean) {
    this.showOverlay = value;
  }

  @action
  getShowOverlay() {
    return this.showOverlay;
  }

  @action
  setShowErrorOverlay(value: boolean) {
    this.showErrorOverlay = value;
  }

  @action
  getShowErrorOverlay() {
    return this.showErrorOverlay;
  }
}

const overlayStorage = new OverlayStorage();

export { overlayStorage };
