import { useEffect } from 'react';

import { globalStorage } from 'services/globalStorage';

import { epgLocalDrugStorage, epgLocalTimeStampStorage } from '../../storages';

export const useInitializationHook = () => {
  useEffect(() => {
    epgLocalTimeStampStorage.init();

    return () => {
      epgLocalDrugStorage.clear();
      epgLocalTimeStampStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (globalStorage.isAwakened) {
      epgLocalTimeStampStorage.init();
    }
  }, [globalStorage.isAwakened]);
};
