import React from 'react';

import { KalturaRecordingType } from '@kaltura-ott/ott-client-types';
import { RecordsErrorCodeEnum } from '@kaltura-ott/tvpil';
import { appStore } from '@kaltura-ott/tvpil-shared';

import NoSpaceModal from 'components/widgets/Modal/NoSpaceModal/NoSpaceModal';

import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { contextStorage, playerStorage } from '../../storages';
import { PlayerEvent } from '../../types';

export async function handleRecord({ payload }: PlayerEvent) {
  const { recordRepository } = appStore;
  const { assetId, type, seriesId } = payload;
  const { player } = playerStorage.getPlayer();
  const {
    modalContext: { openModal },
  } = contextStorage.getContext();

  const result = await recordRepository.addRecord({
    assetId,
    seriesId: type === KalturaRecordingType.series ? seriesId : undefined,
  });
  player.dispatchEvent({ type: CUSTOM_PLAYER_EVENTS.UPDATE });

  if (result?.error === RecordsErrorCodeEnum.ExceededQuota) {
    openModal(<NoSpaceModal />);
  }
}
