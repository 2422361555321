import { useCallback } from 'react';
import { Node } from 'types';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { applyStyles } from 'utils';

export const useApplyStyles = (store: RailStoreV2) =>
  useCallback((node: Node) => {
    const { uiDesignElements } = store;

    if (uiDesignElements && node) {
      applyStyles(node, uiDesignElements);
    }
  }, []); // eslint-disable-line
