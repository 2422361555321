import React, { useCallback } from 'react';
import { Node } from 'types';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import MainLayout from 'components/presenters/layouts/main/MainLayout';
import NoContent from 'components/widgets/NoContent/NoContent';
import RailHorisontal from 'components/widgets/Rail/RailHorisontal/RailHorisontal';
import CollectionRail from 'components/widgets/RailV2/CollectionRail/CollectionRail';
import HeroRail from 'components/widgets/RailV2/HeroRail/HeroRail';
import RecommendationRail from 'components/widgets/RailV2/RecommendationRail/RecommendationRail';
import RentalsRail from 'components/widgets/RailV2/RentalsRail/RentalsRail';
import SeriesCatchUpRail from 'components/widgets/RailV2/SeriesCatchUpRail/SeriesCatchUpRail';
import { BASE } from 'consts';
import { useRootStore } from 'hooks';
import {
  applyStyleHexToHSL,
  applyStyles,
  checkInstanceOf,
  checkIsCollectionRail,
  checkIsHeroRail,
  checkIsRecommendationRail,
  checkIsRentalsRail,
  checkIsSeriesCatchUpRail,
} from 'utils';

import { BaseInitHook } from './components/BaseInitHook';
import MaxiPlayerViewResolver from './components/MaxiPlayerViewResolver';

import styles from './Base.module.scss';

interface Props {
  id?: string;
}

function Base({ id }: Props) {
  const rootStore = useRootStore();
  const store = rootStore?.getInternalPageById(id);
  const { presentPlayer } = store || { presentPlayer: false };
  const handleRef = useCallback(
    (node: Node) => {
      const { uiDesignElements } = store!;

      if (uiDesignElements && node) {
        applyStyles(node, uiDesignElements, false, true);
        applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
      }
    },
    [id],
  );

  return (
    <MainLayout forwardRef={handleRef} pageId={store.referenceId}>
      <BaseInitHook presentPlayer={presentPlayer} store={store} />
      <div id={BASE} className={styles.base}>
        {presentPlayer && <MaxiPlayerViewResolver pageReferenceId={store.referenceId} />}
        <div className={styles.widgets}>
          {store.internalWidgets.map((widget: any, index) => {
            if (checkInstanceOf(widget, RailStoreV2)) {
              // This is a temporary solution
              if (checkIsCollectionRail(widget)) {
                return <CollectionRail index={index} referenceId={widget.referenceId} key={widget.id} />;
              }

              if (checkIsHeroRail(widget)) {
                return (
                  <HeroRail
                    index={index}
                    referenceId={widget.referenceId}
                    pageReferenceId={widget.pageReferenceId}
                    key={widget.id}
                  />
                );
              }

              if (checkIsSeriesCatchUpRail(widget)) {
                return <SeriesCatchUpRail index={index} referenceId={widget.referenceId} key={widget.id} />;
              }

              if (checkIsRentalsRail(widget)) {
                return <RentalsRail index={index} referenceId={widget.referenceId} key={widget.id} />;
              }

              if (checkIsRecommendationRail(widget)) {
                return <RecommendationRail index={index} referenceId={widget.referenceId} key={widget.id} />;
              }

              return <RailHorisontal key={widget.id} store={widget} />;
            }
            return null;
          })}
        </div>
        <NoContent className={styles.noContent} title={store.name} />
      </div>
    </MainLayout>
  );
}

export default React.memo(Base);
