import React from 'react';
import { observer } from 'mobx-react';

import { Linear, Program, ProgramPlaceholder } from '@kaltura-ott/tvpil-shared';

import ProgramCard from '../ProgramCard/ProgramCard';

import { useProgramsListCombiner } from './hooks';

interface Props {
  channel: Linear;
  index: number;
}

function ProgramsList({ channel, index }: Props) {
  const { filteredArray } = useProgramsListCombiner(channel, index);

  return (
    <>
      {filteredArray.map((program: Program | ProgramPlaceholder) => (
        <ProgramCard key={program.data.id} program={program as Program} />
      ))}
    </>
  );
}

export default observer(ProgramsList);
