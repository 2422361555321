import React from 'react';

import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';
import TimeProgressBar from 'components/widgets/ProgressBar/TimeProgressBar';

import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import DescriptionViewTitle from './components/DescriptionViewTitle/DescriptionViewTitle';
import LogoTimeInfo from './components/LogoTimeInfo/LogoTimeInfo';

import styles from './Description.module.scss';

const DescriptionView: React.FC = () => {
  const { isLinear, program } = useVideoPlayoutContext();
  const isDescriptionView = isLinear && program;

  return (
    <div className={styles.description}>
      {isDescriptionView && (
        <>
          <DescriptionViewTitle />
          <TimeProgressBar endDate={program.data?.endDate} startDate={program.data?.startDate}>
            {(percent: number) => <ProgressBar className={styles.timeline} percent={percent} />}
          </TimeProgressBar>
          <LogoTimeInfo />
        </>
      )}
    </div>
  );
};

export default DescriptionView;
