import React from 'react';
import { observer } from 'mobx-react';

import AssetCard from '../AssetCard';
import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';
import AssetCardLogo from '../AssetCardLogo/AssetCardLogo';

function AssetCardResolveRendering() {
  const {
    resolvedProps: { isLogoAsset, isRegularAsset },
  } = useAssetCardContext();

  if (isLogoAsset) {
    return <AssetCardLogo />;
  }

  if (isRegularAsset) {
    return <AssetCard />;
  }

  return null;
}

export default observer(AssetCardResolveRendering);
