import { useEffect } from 'react';

import { PerformanceContextType, PerformanceEvents } from 'interface';

interface Props {
  isHomePageFirstRail: boolean;
  isSkeletonCondition: boolean;
  performanceEventsContext: PerformanceContextType;
}

export const useAppStartAndHomePageReady = ({
  isSkeletonCondition,
  isHomePageFirstRail,
  performanceEventsContext,
}: Props) => {
  useEffect(() => {
    const isPerformanceEvent = isHomePageFirstRail && !isSkeletonCondition;

    if (isPerformanceEvent) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.appStart });
      performanceEventsContext.setEndTime({ value: PerformanceEvents.homePageReady });
    }
  }, [isHomePageFirstRail, isSkeletonCondition, performanceEventsContext]);
};
