import { Dispatch, SetStateAction, useCallback } from 'react';

import { AppApiError } from '@kaltura-ott/tvpil-shared';

import { Form } from 'hooks';

interface Props {
  form: Form;
  error: AppApiError | null;
  setForm: (updatedForm: Form) => void;
  setError: Dispatch<SetStateAction<AppApiError | null>>;
}

export const useOnHandleChange = ({ form, error, setForm, setError }: Props) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (error) {
        const updatedForm: Form = {
          ...form.email,
          email: {
            ...form.email,
            value: name === 'email' ? value : form.email.value,
            isFailedValidation: false,
          },
          password: {
            ...form.password,
            value: name === 'password' ? value : form.password.value,
            isFailedValidation: false,
          },
        };
        setError(null);
        setForm(updatedForm);

        return;
      }

      setForm({
        ...form,
        [name]: {
          ...form[name],
          value,
          isFailedValidation: false,
        },
      });
    },
    [form.email.value, form.password.value, form.email.isFailedValidation, form.password.isFailedValidation],
  );
};
