import { useCallback } from 'react';

import { useModalContext } from 'context/modal/modal';

export const useOnDeclineChangePin = () => {
  const modalContext = useModalContext();

  const onDeclineChangePin = useCallback(() => {
    modalContext.closeModal();
    sessionStorage.removeItem('pin');
  }, []);

  return { onDeclineChangePin };
};
